import { useState } from 'react'

import { EngLocales } from '../../../assets/locales/eng'
import { IListSortObject } from '../../../constants/sort'

import { ReactComponent as SortByIcon } from '../../../assets/images/svg/sort-by-icon.svg'
import { ReactComponent as IsActiveIcon } from './isActive-icon.svg'

import './index.scss'
import { SmallTabletDevicesWidth } from '../../../constants/screensWidth'

interface SortByBtnProps {
  list: IListSortObject
  func: (name: string) => void
  needRequest: () => void
}

interface ListItem {
  name: string
  key: string
  isActive: boolean
}

function SortByBtn({ list, func, needRequest }: SortByBtnProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [localList, setLocalList] = useState<IListSortObject>(list)

  function handleItemClick(key: string, value: string) {
    const updatedList = Object.fromEntries(
      Object.entries(localList).map(([listKey, listItem]) => {
        if (listKey === key) {
          return [listKey, { ...listItem, isActive: true }]
        }
        return [listKey, { ...listItem, isActive: false }]
      }),
    )
    setLocalList(updatedList)
    setIsOpen(prev => !prev)
    func(value)
    needRequest()
  }

  return (
    <div className={isOpen ? 'sortByBtn sortByBtnActive' : 'sortByBtn'}>
      <div
        className="sortByBtnFirstItem"
        onClick={() => setIsOpen(prev => !prev)}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            setIsOpen(prev => !prev)
          }
        }}
        role="button"
        tabIndex={0}
      >
        <SortByIcon />{' '}
        {window.innerWidth > SmallTabletDevicesWidth && (
          <p>{EngLocales.ContentWrapperSearchPanel.sortByBtnText}</p>
        )}
      </div>
      {isOpen && (
        <div className="sortByBtnItems">
          <div className="sortByBtnItemForMobile">
            <SortByIcon />{' '}
            <p>{EngLocales.ContentWrapperSearchPanel.sortByBtnText}</p>
          </div>
          {Object.keys(localList).map((key: string) => {
            const listItem: ListItem = localList[key]
            return (
              <div
                key={key}
                className={
                  listItem.isActive ? 'sortByBtnItem active' : 'sortByBtnItem'
                }
                onClick={() => handleItemClick(key, listItem.key)}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleItemClick(key, listItem.key)
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <p
                  style={{
                    color: listItem.isActive ? '#8176AF' : '#EEEFF0',
                  }}
                >
                  {listItem.name}
                </p>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SortByBtn
