/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import InstrumentsBlock from './InstrumentsBlock'
import InformationBlock from './InformationBlock'
import IngredientsBlock from './IngredientsBlock'

import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'
import { api, staticApi } from '../../api'
import { EngLocales } from '../../assets/locales/eng'

import { ReactComponent as NotFavorite } from '../../assets/images/svg/not-favorite.svg'
import { ReactComponent as Favorite } from '../../assets/images/svg/favorite.svg'
import { ReactComponent as AlcoholFreeIcon } from '../../assets/images/svg/alcohol-free-icon.svg'
import { ReactComponent as ArrowBackIcon } from '../UserProfile/images/arrow-button-icon.svg'

import './index.scss'

import AlternativeCocktailsBlock from './AlternativeCocktailsBlock'
import RecipeBlock from './RecipeBlock'
import { MobileDevicesWidth } from '../../constants/screensWidth'
import { getParams } from '../../store/reducers/auth/authUtils'
import { ICocktail } from '../../@types/cocktails'
import Loader from '../../components/Loader'
import { cocktailsCustomNotification } from '../../helpers/Notifications/notificationsHelpers'

function CocktailPage() {
  const { id } = useParams()
  const {
    getCocktail,
    cocktailAnalogs,
    clearCocktail,
    clearAnalogsList,
    cocktailFavorites,
    showModal,
  } = useActions()
  const { cocktail, analogs } = useTypedSelector(state => state.cocktails)
  const [whatBlockOpen, setWhatBlockOpen] = useState<number>(1)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [localFavorite, setLocalFavorite] = useState<boolean>(false)

  useEffect(() => {
    // setLoading(true)
    getCocktail(String(id), setLoading)
    // if (getParams('access_token')) {
    //   cocktailAnalogs(String(id))
    // }

    // setTimeout(() => setLoading(false), 1000)
  }, [id])

  useEffect(() => {
    if (cocktail) {
      document.title = `${cocktail.name} | Drankly`
    }
    if (cocktail && cocktail.isFavorite) {
      setLocalFavorite(true)
    }
  }, [cocktail])

  useEffect(() => {
    return () => {
      clearCocktail()
      clearAnalogsList()
    }
  }, [])

  const onClickFavorites: React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()

    if (!localFavorite && cocktail) {
      cocktailFavorites(cocktail.id.toString(), true, [])
      cocktailsCustomNotification(() => {
        showModal('FavoritesModal', {
          favorites: {
            id: cocktail.id.toString(),
            cocktailName: cocktail.name,
            collections: cocktail.collections,
          },
        })
      })
      setLocalFavorite(true)
    } else if (localFavorite && cocktail) {
      showModal('FavoritesModal', {
        favorites: {
          id: cocktail.id.toString(),
          cocktailName: cocktail.name,
          collections: cocktail.collections,
        },
      })
    }
  }

  function openBlock(block: number, item: ICocktail) {
    switch (block) {
      case 1:
        return (
          <div className="cocktailPageBlockInner">
            <InstrumentsBlock
              tools={item.tools}
              allergens={item.allergens}
              glass={item.glass}
              flavor={item.flavor}
              strength={item.strength}
              complexity={item.complexity}
            />
            {window.innerWidth <= MobileDevicesWidth && (
              <div className="cocktailPageMobileIngredientsTitle">
                <h3>Ingredients</h3>
                <p>{item.ingredients.length} items</p>
              </div>
            )}
            <IngredientsBlock ingredients={item.ingredients} />
          </div>
        )
      case 2:
        return <RecipeBlock steps={item.recipe} />
      case 3:
        return (
          <AlternativeCocktailsBlock
            // cocktails={analogs}
            id={String(id)}
          />
        )
      default:
        return <div />
    }
  }

  function isBackArrowForMobile(item: ICocktail) {
    return (
      <div className="cocktailPageMobileHeader">
        <div className="cocktailPageMobileHeaderImage">
          {window.history.length > 2 && (
            <ArrowBackIcon
              onClick={() => {
                navigate(-1)
              }}
            />
          )}
          <img
            src={`${staticApi()}/images/flags/${item.origin.id.toString().toLowerCase()}.webp`}
            alt="cocktail country flag"
          />
        </div>
        <h3>{item.name}</h3>
        <div
          className="cocktailPageMobileHeaderFavorites"
          onClick={onClickFavorites}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              onClickFavorites
            }
          }}
          role="button"
          tabIndex={0}
        >
          {localFavorite ? <Favorite /> : <NotFavorite />}
        </div>
      </div>
    )
  }

  return loading ? (
    <Loader />
  ) : (
    cocktail && (
      <div className="cocktailPageWrapper">
        <div className="cocktailPageInner">
          {window.innerWidth <= MobileDevicesWidth &&
            isBackArrowForMobile(cocktail)}
          <div id="cocktailPageLightBox">
            {window.innerWidth > MobileDevicesWidth && (
              <div
                className="cocktailPageFavoriteIcon"
                onClick={onClickFavorites}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    onClickFavorites
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {localFavorite ? (
                  <Favorite className="cocktailPageFavorites" />
                ) : (
                  <NotFavorite className="cocktailPageFavorites" />
                )}
              </div>
            )}
            {cocktail.strength === 'Alcohol-free' && (
              <div className="cocktailPageAlcoholFreeIcon">
                <AlcoholFreeIcon />
              </div>
            )}
            <img
              className="cocktailPageMainImage"
              src={`${api()}/images/cocktails/l/${cocktail.image}`}
              alt={cocktail.name || 'Default Alt'}
            />
          </div>
          <div className="cocktailPageInnerContent">
            <InformationBlock
              origin={cocktail.origin}
              title={cocktail.name}
              description={cocktail.description}
            />
            <div className="cocktailPageBlocksWrapper">
              <div className="cocktailPageBlockHeader">
                <div
                  className={
                    whatBlockOpen === 1
                      ? 'blockHeader blockHeader-Open'
                      : 'blockHeader'
                  }
                  onClick={() => setWhatBlockOpen(1)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      setWhatBlockOpen(1)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <h4>
                    {window.innerWidth <= MobileDevicesWidth
                      ? EngLocales.CocktailPage
                          .cocktailPageBlockHeaderFirstTitleMobile
                      : EngLocales.CocktailPage
                          .cocktailPageBlockHeaderFirstTitle}
                  </h4>
                </div>
                <div
                  className={
                    whatBlockOpen === 2
                      ? 'blockHeader blockHeader-Open'
                      : 'blockHeader'
                  }
                  onClick={() => setWhatBlockOpen(2)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      setWhatBlockOpen(2)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <h4>
                    {window.innerWidth <= MobileDevicesWidth
                      ? EngLocales.CocktailPage
                          .cocktailPageBlockHeaderSecondTitleMobile
                      : EngLocales.CocktailPage
                          .cocktailPageBlockHeaderSecondTitle}
                  </h4>
                </div>
                <div
                  className={
                    whatBlockOpen === 3
                      ? 'blockHeader blockHeader-Open'
                      : 'blockHeader'
                  }
                  onClick={() => setWhatBlockOpen(3)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      setWhatBlockOpen(3)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <h4>
                    {window.innerWidth <= MobileDevicesWidth
                      ? EngLocales.CocktailPage
                          .cocktailPageBlockHeaderThirdTitleMobile
                      : EngLocales.CocktailPage
                          .cocktailPageBlockHeaderThirdTitle}
                  </h4>
                </div>
              </div>
              {openBlock(whatBlockOpen, cocktail)}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CocktailPage
