import { AppDispatch } from '../../index'
import IngredientsService from '../../../api/ingredients'
import { GetIngredientsAction, IngredientsActionEnum } from './types'
import { ICocktailSelectedFilters } from '../../../@types/cocktails'
import { IIngredientsList } from '../../../@types/ingredients'

interface GetIngredientsParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  sortValue: string
  searchFields?: string
}

const IngredientsActionCreators = {
  GetIngredientsAction: (data: IIngredientsList): GetIngredientsAction => {
    return {
      type: IngredientsActionEnum.GET_INGREDIENTS,
      payload: data,
    }
  },

  getIngredients: ({
    page,
    size,
    selectedFilters,
    sortValue,
    searchFields,
  }: GetIngredientsParams) => {
    return async (dispatch: AppDispatch) => {
      try {
        let requestWithFilters = false

        Object.keys(selectedFilters).forEach(key => {
          const values = selectedFilters[key]
          if (values.length > 0) {
            requestWithFilters = true
          }
        })

        const { data } = await IngredientsService.getIngredients({
          page,
          size,
          selectedFilters,
          searchFields,
          sortValue,
        })
        if (searchFields) {
          if (+page === 0) {
            IngredientsActionCreators.clearIngredientsList()(dispatch)
          }
          dispatch({
            type: IngredientsActionEnum.GET_INGREDIENTS_SEARCH,
            payload: { ...data, searchFields },
          })
        } else if (requestWithFilters) {
          dispatch({
            type: IngredientsActionEnum.GET_INGREDIENTS_BY_FILTER_ACTION,
            payload: data,
          })
        } else {
          dispatch({
            type: IngredientsActionEnum.GET_INGREDIENTS,
            payload: data,
          })
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  getIngredient: (id: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await IngredientsService.getIngredient(id)
        dispatch({ type: IngredientsActionEnum.GET_INGREDIENT, payload: data })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },

  clearIngredientsList: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: IngredientsActionEnum.CLEAR_INGREDIENTS_LIST })
    }
  },

  clearModalIngredient: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: IngredientsActionEnum.CLEAR_MODAL_INGREDIENT })
    }
  },

  ingredientFavorites: (id: number) => {
    return async (dispatch: AppDispatch) => {
      try {
        await IngredientsService.ingredientFavorites(id)
        dispatch({
          type: IngredientsActionEnum.FAVORITES_INGREDIENT,
          payload: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },

  deleteIngredientFavorites: (id: number) => {
    return async (dispatch: AppDispatch) => {
      try {
        await IngredientsService.deleteIngredientFavorites(id)
        dispatch({
          type: IngredientsActionEnum.FAVORITES_INGREDIENT,
          payload: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
}

export default IngredientsActionCreators
