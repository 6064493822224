import { ModalAction, ModalActionEnum, ModalState } from './types'

const initialState: ModalState = {
  modalName: '',
  modalData: {},
  modalHidden: false,
  isCocktailsFilterModal: false,
  isMixologyFilterModal: false,
  isMyIngredientFilterModal: false,
}

const modalReducer = (
  state = initialState,
  action: ModalAction,
): ModalState => {
  switch (action.type) {
    case ModalActionEnum.SHOW_MODAL:
      return {
        ...state,
        modalName: action.payload.modalName,
        modalData: action.payload.modalData,
        isCocktailsFilterModal: action.payload.isCocktailsFilterModal
          ? action.payload.isCocktailsFilterModal
          : false,
        isMixologyFilterModal: action.payload.isMixologyFilterModal
          ? action.payload.isMixologyFilterModal
          : false,
        isMyIngredientFilterModal: action.payload.isMyIngredientFilterModal
          ? action.payload.isMyIngredientFilterModal
          : false,
        modalHidden: true,
      }
    case ModalActionEnum.HIDE_MODAL:
      return {
        modalName: '',
        modalData: {},
        modalHidden: false,
        isCocktailsFilterModal: false,
        isMixologyFilterModal: false,
        isMyIngredientFilterModal: false,
      }
    default:
      return state
  }
}

export default modalReducer
