import { FC } from 'react'
import { Modal } from 'antd'
import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'

import LogoIcon from '../../assets/images/LegalAgeLogoImage.png'
import { EngLocales } from '../../assets/locales/eng'

function LegalAgeModal() {
  const { hideModal } = useActions()
  const { modalHidden } = useTypedSelector(state => state.modal)

  function agreeWithTerms() {
    localStorage.setItem('legalAgeAgree', 'true')
    hideModal()
    window.location.reload()
  }

  return (
    <Modal
      centered
      open={modalHidden}
      width={window.innerWidth > 500 ? 480 : 328}
      closable={false}
    >
      <div className="legalAgeModalWrapper">
        <div className="legalAgeModalInner">
          <img src={LogoIcon} alt="legal age approve" />
          <h3>{EngLocales.LegalAgeModal.legalAgeModalTitle}</h3>
          <p>{EngLocales.LegalAgeModal.legalAgeFirstParaph}</p>
          <p>{EngLocales.LegalAgeModal.legalAgeSecondParaph}</p>
          <div className="legalAgeModalButtonsWrapper">
            <h4>{EngLocales.LegalAgeModal.legalAgeModalButtonsHeader}</h4>
            <div className="legalAgeModalButtonsInner">
              <button
                onClick={() =>
                  window.location.replace(
                    EngLocales.LegalAgeModal.disagreeBtnLink,
                  )
                }
                type="button"
              >
                {EngLocales.LegalAgeModal.disagreeBtnText}
              </button>
              <button onClick={() => agreeWithTerms()} type="button">
                {EngLocales.LegalAgeModal.agreeBtnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LegalAgeModal
