import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { root } from './reducers'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers(root)

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk), composeEnhancers()),
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
