/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import useActions from '../../../../hooks/useActions'
import useTypedSelector from '../../../../hooks/useTypedSelector'

import NextStepButton from '../../components/NextStepButton'
import { IEventOccasion } from '../../../../@types/events'
import { api, staticApi } from '../../../../api'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'

import './index.scss'
import EventStepTitle from '../../components/EventStepTitle'

interface PartyScratchOccasionProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyScratchOccasion({
  changeStep,
  nextStep,
}: PartyScratchOccasionProps) {
  const {
    occasions,
    cocktailsPreferences,
    // userCreateParty
  } = useTypedSelector(state => state.events)
  const {
    getPartyOccasions,
    updateEventCocktailsPreferences,
    updateUserCreateEvent,
  } = useActions()

  const [selectedOccasion, setSelectedOccasion] = useState<number>(-1)

  useEffect(() => {
    if (cocktailsPreferences.occasionId) {
      setSelectedOccasion(cocktailsPreferences.occasionId)
    }
    if (!occasions.length) {
      getPartyOccasions()
    }
  }, [])

  const nextStepHandler = () => {
    updateEventCocktailsPreferences(selectedOccasion, 'occasionId')
    updateUserCreateEvent(selectedOccasion, 'occasionId')
    return changeStep(nextStep)
  }

  return (
    <div className="partyFromScratchWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <EventStepTitle
          title="Select occasion"
          text="Choose the perfect occasion for your event, and let us guide you through every detail."
        />
      )}
      <div className="partyFromScratchInner">
        {occasions.map((item: IEventOccasion) => {
          return (
            <div className="partyOccasionCardWrapper">
              <div
                key={item.id}
                className={
                  selectedOccasion === item.id
                    ? 'partyOccasionCard partyOccasionCardActive'
                    : 'partyOccasionCard'
                }
                onClick={() => {
                  setSelectedOccasion(item.id)
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    setSelectedOccasion(item.id)
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <img
                  src={`${staticApi()}/images/occasions/${item.image}`}
                  alt={item.name}
                />
                <div className="partyOccasionCardText">
                  <p>{item.name}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <NextStepButton
        handler={nextStepHandler}
        isActive={selectedOccasion > -1}
      />
    </div>
  )
}

export default PartyScratchOccasion
