/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ICocktail } from '../../@types/cocktails'

import './index.scss'
import useActions from '../../hooks/useActions'
import { cocktailsCustomNotification } from '../../helpers/Notifications/notificationsHelpers'
import { SmallTabletDevicesWidth } from '../../constants/screensWidth'
import RoutesPaths from '../../constants/navigate'
import { api } from '../../api'
import { ReactComponent as Favorite } from '../../assets/images/svg/favorite.svg'
import { ReactComponent as NotFavorite } from '../../assets/images/svg/not-favorite.svg'

interface CocktailMediumCardProps {
  cocktail: ICocktail
  isEvent?: boolean
}

export default function CocktailMediumCard({
  cocktail,
  isEvent = false,
}: CocktailMediumCardProps) {
  const { showModal, cocktailFavorites, getModalCocktail } = useActions()
  const navigate = useNavigate()

  const onClickFavorites = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()

      if (!cocktail.isFavorite) {
        cocktailFavorites(cocktail.id.toString(), true, [])
        cocktailsCustomNotification(() => {
          showModal('FavoritesModal', {
            favorites: {
              id: cocktail.id.toString(),
              cocktailName: cocktail.name,
              collections: cocktail.collections,
            },
          })
        })
      } else if (cocktail.isFavorite) {
        showModal('FavoritesModal', {
          favorites: {
            id: cocktail.id.toString(),
            cocktailName: cocktail.name,
            collections: cocktail.collections,
          },
        })
      }
    },
    [cocktail, cocktailFavorites],
  )

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (window.innerWidth <= SmallTabletDevicesWidth) {
        navigate(`/${RoutesPaths.cocktailsPage}/${cocktail.id}`)
      } else if (e.button === 0 && e.ctrlKey) {
        window.open(`/${RoutesPaths.cocktailsPage}/${cocktail.id}`, '_blank')
      } else {
        getModalCocktail(cocktail.id.toString())
        showModal('Cocktail', { item: cocktail })
      }
    },
    [cocktail.id],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && e.ctrlKey) {
        window.open(`/${RoutesPaths.cocktailsPage}/${cocktail.id}`, '_blank')
      }
    },
    [cocktail.id],
  )

  return (
    <div className="cocktailMediumCardWrapper">
      <div
        className="cocktailMediumCardInner"
        // style={{ height: isEvent ? '235px' : '205px' }}
        onClick={onClick}
        onAuxClick={e => {
          e.preventDefault()
          if (e.button === 1) {
            window.open(
              `/${RoutesPaths.cocktailsPage}/${cocktail.id}`,
              '_blank',
            )
          }
        }}
        onKeyDown={handleKeyDown}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onClick
          }
        }}
        role="button"
        tabIndex={0}
      >
        {!isEvent && (
          <div
            className="cocktailMediumCardInnerFavorite"
            onClick={onClickFavorites}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onClickFavorites
              }
            }}
            role="button"
            tabIndex={0}
          >
            {cocktail.isFavorite ? <Favorite /> : <NotFavorite />}
          </div>
        )}
        <img
          src={`${api()}/images/cocktails/m/${cocktail.image}`}
          alt={cocktail.name || 'Default Alt'}
        />
        <div className="cocktailMediumCardInnerText">
          <p>{cocktail.name}</p>
        </div>
      </div>
    </div>
  )
}
CocktailMediumCard.defaultProps = {
  isEvent: false,
}
