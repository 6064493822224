/* eslint-disable jsx-a11y/control-has-associated-label */
import './index.scss'

interface CustomRadioButtonProps {
  label: string
  checked: boolean
  onChange: () => void
}

function CustomRadioButton({
  label,
  checked,
  onChange,
}: CustomRadioButtonProps) {
  return (
    <div className="customRadioWrapper">
      <p>{label}</p>
      <div
        className={checked ? 'customRadio checked' : 'customRadio'}
        onClick={onChange}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onChange()
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="customRadioCircle" />
      </div>
    </div>
  )
}

export default CustomRadioButton
