/* eslint-disable react/jsx-props-no-spreading */
import { useForm, SubmitHandler, Controller } from 'react-hook-form'

import './index.scss'
import { useState } from 'react'
import useActions from '../../../../hooks/useActions'

type Inputs = {
  repeatNewPassword: string
  newPassword: string
  oldPassword: string
}

function SecuritySettingsBlock() {
  const { register, handleSubmit, reset, control } = useForm<Inputs>({
    // resolver: yupResolver(schema),
  })
  const { userChangePassword } = useActions()
  const [isVisible, setIsVisible] = useState({
    repeatNewPassword: false,
    newPassword: false,
    oldPassword: false,
  })

  const changeVisible = (key: string, value: boolean) => {
    setIsVisible({ ...isVisible, [key]: value })
  }

  const onSubmit: SubmitHandler<Inputs> = data => {
    const { newPassword, oldPassword, repeatNewPassword } = data
    if (newPassword === repeatNewPassword) {
      userChangePassword(oldPassword, newPassword)
    } else {
      alert('пароли не совпадают')
    }
  }

  const oldPassword = register('oldPassword')
  const newPassword = register('newPassword')
  const repeatNewPassword = register('repeatNewPassword')

  return (
    <div className="securitySettingsBlockWrapper">
      <div className="securitySettingsBlockInner">
        <h4>Change password</h4>
        <form
          name="securitySettings"
          className="securitySettingsForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="oldPassword"
            control={control}
            render={({ field, fieldState }) => (
              <div className="securitySettingsFormItem">
                <p>Enter old password</p>
                <input
                  {...field}
                  autoComplete="off"
                  placeholder="Enter old password"
                  type={isVisible.oldPassword ? 'text' : 'password'}
                />
                {/* {fieldState.error && (
                  <span style={{ color: 'red' }}>
                    {fieldState.error.message}
                  </span>
                )} */}
                <i
                  className={
                    !isVisible.oldPassword
                      ? 'far fa-eye fa-eye-slash'
                      : 'far fa-eye'
                  }
                  id="togglePassword"
                  aria-hidden="true"
                  onClick={() =>
                    changeVisible('oldPassword', !isVisible.oldPassword)
                  }
                  style={{ marginLeft: '-20px', cursor: 'pointer' }}
                />
              </div>
            )}
            rules={{ required: 'This field is required' }}
          />
          <Controller
            name="newPassword"
            control={control}
            render={({ field, fieldState }) => (
              <div className="securitySettingsFormItem">
                <p>Enter new password</p>
                <input
                  {...field}
                  autoComplete="off"
                  placeholder="Enter new password"
                  type={isVisible.newPassword ? 'text' : 'password'}
                />
                <i
                  className={
                    !isVisible.newPassword
                      ? 'far fa-eye fa-eye-slash'
                      : 'far fa-eye'
                  }
                  id="togglePassword"
                  aria-hidden="true"
                  onClick={() =>
                    changeVisible('newPassword', !isVisible.newPassword)
                  }
                  style={{ marginLeft: '-20px', cursor: 'pointer' }}
                />
              </div>
            )}
            rules={{ required: 'This field is required' }}
          />
          <Controller
            name="repeatNewPassword"
            control={control}
            render={({ field, fieldState }) => (
              <div className="securitySettingsFormItem">
                <p>Repeat new password</p>
                <input
                  {...field}
                  autoComplete="off"
                  placeholder="Repeat new password"
                  type={isVisible.repeatNewPassword ? 'text' : 'password'}
                />
                <i
                  className={
                    !isVisible.repeatNewPassword
                      ? 'far fa-eye fa-eye-slash'
                      : 'far fa-eye'
                  }
                  id="togglePassword"
                  aria-hidden="true"
                  onClick={() =>
                    changeVisible(
                      'repeatNewPassword',
                      !isVisible.repeatNewPassword,
                    )
                  }
                  style={{ marginLeft: '-20px', cursor: 'pointer' }}
                />
              </div>
            )}
            rules={{ required: 'This field is required' }}
          />

          <button type="submit">save changes</button>
        </form>
      </div>
    </div>
  )
}

export default SecuritySettingsBlock
