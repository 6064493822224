import { Routes, Route } from 'react-router-dom'

import HomePage from './page/HomePage'
import CocktailsPage from './page/CocktailsPage'
import Layout from './components/Layout'
import RoutesPaths from './constants/navigate'
import NotFound from './components/NotFound'
// import WithoutAuth from './components/HOCs/WithoutAuth'
// import LoginPage from './page/LoginPage'
// import RecoveryPassword from './page/RecoveryPassword'
import IngredientsPage from './page/IngredientsPage'
import CocktailPage from './page/CocktailPage'
import IngredientMobilePage from './page/IngredientMobilePage'
import RequiredAuth from './components/HOCs/RequireAuth'
import UserProfile from './page/UserProfile'
import PlansPage from './page/PlansPages/PlansPage'
import ChoosePlanPage from './page/PlansPages/ChoosePlanPage'
import SuccessSub from './page/PlansPages/SuccessSub'
import FailureSub from './page/PlansPages/FailureSubPage'
import UnsubscribePage from './page/UnsubscribePage'
import EventsPage from './page/EventsPages'
import PartyScratchEventPage from './page/EventsPages/EventFromScratchPage'
import EventSummaryPage from './page/EventsPages/EventSummaryPage'
import EventsType from './page/EventsPages/StartScreens/EventsType'
import PartyInvitesPage from './page/EventsPages/EventInvitesPage'
import PartyTemplateEventPage from './page/EventsPages/EventFromTemplatePage'
import IngredientsBar from './page/MyBar/IngredientsBar/index'
import FavoritesIngredientsBar from './page/MyBar/IngredientsBar/FavoritesIngredientsBar/index'
import MixologyList from './page/MyBar/IngredientsBar/MixologyList/index'
import MainCocktailsBarComponent from './page/MyBar/CocktailsBar/MainCocktailsBarComponent'
import FavoritesCocktails from './page/MyBar/CocktailsBar/FavoritesCocktails'
import CocktailsCollection from './page/MyBar/CocktailsBar/CocktailsCollection'
import CocktailsCollectionsList from './page/MyBar/CocktailsBar/CocktailsCollectionsList'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={RoutesPaths.notFound} element={<NotFound />} />
        {/* <Route
          path={RoutesPaths.registerPage}
          element={
            <WithoutAuth>
              <LoginPage />
            </WithoutAuth>
          }
        />
        <Route
          path={RoutesPaths.loginPage}
          element={
            <WithoutAuth>
              <LoginPage />
            </WithoutAuth>
          }
        />
        <Route
          path={RoutesPaths.recoveryPasswordPage}
          element={
            <WithoutAuth>
              <RecoveryPassword />
            </WithoutAuth>
          }
        />
        <Route
          path={RoutesPaths.updatePasswordPage}
          element={
            <WithoutAuth>
              <RecoveryPassword />
            </WithoutAuth>
          }
        /> */}
        <Route
          path={RoutesPaths.cocktailsPage}
          element={<CocktailsPage isEvents={false} excludeCocktails={[]} />}
        />
        <Route
          path={RoutesPaths.ingredientsPage}
          element={<IngredientsPage />}
        />
        <Route
          path={RoutesPaths.cocktailsPageCocktail}
          element={<CocktailPage />}
        />
        <Route
          path={RoutesPaths.ingredientMobilePage}
          element={<IngredientMobilePage />}
        />
        <Route
          path={RoutesPaths.profilePage}
          element={
            <RequiredAuth>
              <UserProfile />
            </RequiredAuth>
          }
        />
        <Route path={RoutesPaths.plansPage} element={<PlansPage />} />
        <Route
          path={RoutesPaths.plansSubscriptionsPage}
          element={
            <RequiredAuth>
              <ChoosePlanPage />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.successSubPage}
          element={
            <RequiredAuth>
              <SuccessSub />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.failureSubPage}
          element={
            <RequiredAuth>
              <FailureSub />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.unsubscribePage}
          element={
            <RequiredAuth>
              <UnsubscribePage />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.eventsPages}
          element={
            <RequiredAuth>
              <EventsPage />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.eventsPagesCreate}
          element={
            <RequiredAuth>
              <EventsType />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.partyEventScratchPage}
          element={
            <RequiredAuth>
              <PartyScratchEventPage />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.partyEventSummaryPage}
          element={
            <RequiredAuth>
              <EventSummaryPage />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.partyInvitesPage}
          element={
            <RequiredAuth>
              <PartyInvitesPage />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.partyEventTemplatePage}
          element={
            <RequiredAuth>
              <PartyTemplateEventPage />
            </RequiredAuth>
          }
        />
        {/* TODO: новый май бар */}
        <Route
          path={RoutesPaths.myBarCocktailsPage}
          element={
            <RequiredAuth>
              <MainCocktailsBarComponent />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.myBarIngredientsPage}
          element={
            <RequiredAuth>
              <IngredientsBar />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.myBarIngredientsFavoritesPage}
          element={
            <RequiredAuth>
              <FavoritesIngredientsBar />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.myBarIngredientsMixologyPage}
          element={
            <RequiredAuth>
              <MixologyList />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.myBarCocktailsFavorites}
          element={
            <RequiredAuth>
              <FavoritesCocktails />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.myBarCocktailsCollectionById}
          element={
            <RequiredAuth>
              <CocktailsCollection />
            </RequiredAuth>
          }
        />
        <Route
          path={RoutesPaths.myBarCocktailsCollections}
          element={
            <RequiredAuth>
              <CocktailsCollectionsList />
            </RequiredAuth>
          }
        />
      </Route>
    </Routes>
  )
}

export default AppRoutes
