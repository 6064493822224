/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import NextStepButton from '../../components/NextStepButton'

import './index.scss'
import useActions from '../../../../hooks/useActions'
import useTypedSelector from '../../../../hooks/useTypedSelector'

interface PartyScratchComplexityProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyScratchComplexity({
  changeStep,
  nextStep,
}: PartyScratchComplexityProps) {
  const [complexity, setComplexity] = useState<'EASY' | 'MEDIUM' | 'HARD'>(
    'EASY',
  )

  const { cocktailsPreferences } = useTypedSelector(state => state.events)
  const { updateEventCocktailsPreferences } = useActions()

  useEffect(() => {
    if (cocktailsPreferences.complexity) {
      setComplexity(cocktailsPreferences.complexity)
    }
  }, [])

  const nextStepHandler = () => {
    updateEventCocktailsPreferences(complexity, 'complexity')
    return changeStep(nextStep)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComplexity(event.target.value as 'EASY' | 'MEDIUM' | 'HARD')
  }

  return (
    <div className="partyScratchComplexityWrapper">
      <fieldset className="partyScratchComplexityInner">
        <div className="partyScratchComplexityInnerItem">
          <input
            id="easy"
            type="radio"
            name="complexity"
            value="EASY"
            checked={complexity === 'EASY'}
            onChange={handleChange}
          />
          <label htmlFor="easy" />
          <p>Quick and easy</p>
        </div>
        <div className="partyScratchComplexityInnerItem">
          <input
            id="medium"
            type="radio"
            name="complexity"
            value="MEDIUM"
            checked={complexity === 'MEDIUM'}
            onChange={handleChange}
          />
          <label htmlFor="medium" />
          <p>A little creativity, but not too complex</p>
        </div>
        <div className="partyScratchComplexityInnerItem">
          <input
            id="hard"
            type="radio"
            name="complexity"
            value="HARD"
            checked={complexity === 'HARD'}
            onChange={handleChange}
          />
          <label htmlFor="hard" />
          <p>Willing to spend time on complex recipes</p>
        </div>
      </fieldset>
      <NextStepButton handler={nextStepHandler} isActive={!!complexity} />
    </div>
  )
}

export default PartyScratchComplexity
