/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import useActions from '../../../../hooks/useActions'

import './index.scss'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import Loader from '../../../../components/Loader'
import { IEventsItem } from '../../../../@types/events'
import { api, staticApi } from '../../../../api'
import { MobileDevicesWidth } from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'

interface ChooseTemplateProps {
  changeStep: (step: number) => void
  nextStep: number
  selectTemplate: (template: IEventsItem | null) => void
}

function ChooseTemplate({
  changeStep,
  nextStep,
  selectTemplate,
}: ChooseTemplateProps) {
  const [loading, setLoading] = useState(false)

  const { getTemplates } = useActions()
  const { eventTemplates } = useTypedSelector(state => state.events)

  useEffect(() => {
    getTemplates(setLoading)
    selectTemplate(null)
  }, [])

  return (
    <div className="chooseTemplateWrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          {window.innerWidth > MobileDevicesWidth && (
            <EventStepTitle
              title="Choose a template"
              text="Choose the template for your party that suits your occasion."
            />
          )}
          <div className="chooseTemplateInner">
            {eventTemplates.map((item: IEventsItem) => {
              return (
                <div
                  className="chooseTemplateInnerItem"
                  key={item.id}
                  onClick={() => {
                    selectTemplate(item)
                    changeStep(nextStep)
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      selectTemplate(item)
                      changeStep(nextStep)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div className="chooseTemplateInnerItemText">
                    <h3>{item.name}</h3>
                    <h4>{item.occasion.name}</h4>
                    <h5>{item.cocktails.length} Cocktails</h5>
                  </div>
                  <img
                    src={`${staticApi()}/images/occasions/${item.occasion.image}`}
                    alt={item.name}
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default ChooseTemplate
