import { IIngredient } from '../../../@types/ingredients'
import {
  IngredientsAction,
  IngredientsActionEnum,
  IngredientsState,
} from './types'

const initialState: IngredientsState = {
  content: [],
  totalPages: 1,
  number: 0,
  ingredient: null,
  modalIngredient: null,
  totalElements: 0,
  searchField: '',
}

const ingredientsReducer = (
  state = initialState,
  action: IngredientsAction,
): IngredientsState => {
  switch (action.type) {
    case IngredientsActionEnum.GET_INGREDIENTS: {
      if (action.payload.number === 0) {
        return {
          ...state,
          content: action.payload.content,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        }
      }
      return {
        ...state,
        content: [...state.content, ...action.payload.content],
        totalPages: action.payload.totalPages,
        number: action.payload.number,
        totalElements: action.payload.totalElements,
      }
    }
    case IngredientsActionEnum.GET_INGREDIENTS_BY_FILTER_ACTION: {
      if (action.payload.number === 0) {
        return {
          ...state,
          content: action.payload.content,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        }
      }
      return {
        ...state,
        content: [...state.content, ...action.payload.content],
        totalPages: action.payload.totalPages,
        number: action.payload.number,
        totalElements: action.payload.totalElements,
      }
    }
    case IngredientsActionEnum.GET_INGREDIENTS_SEARCH: {
      if (state.searchField === action.payload.searchField) {
        return {
          ...state,
          content: [...state.content, ...action.payload.content],
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
          searchField: action.payload.searchField,
        }
      }
      return {
        ...state,
        content: action.payload.content,
        totalPages: action.payload.totalPages,
        number: action.payload.number,
        totalElements: action.payload.totalElements,
        searchField: '',
      }
    }

    case IngredientsActionEnum.GET_INGREDIENT:
      return {
        ...state,
        ingredient: action.payload,
      }

    case IngredientsActionEnum.CLEAR_INGREDIENTS_LIST:
      return {
        ...state,
        number: 0,
        searchField: '',
        content: [],
      }

    case IngredientsActionEnum.CLEAR_MODAL_INGREDIENT:
      return {
        ...state,
        modalIngredient: null,
        ingredient: null,
      }

    case IngredientsActionEnum.FAVORITES_INGREDIENT:
      return {
        ...state,
        content: state.content.map((item: IIngredient) => {
          if (item.id === action.payload) {
            return {
              ...item,
              isFavorite: !item.isFavorite,
            }
          }
          return item
        }),
      }

    default:
      return state
  }
}

export default ingredientsReducer
