/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState } from 'react'

import './index.scss'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import useActions from '../../../../hooks/useActions'
import { ReactComponent as ArrowButtonIcon } from '../../images/arrow-button-icon.svg'

interface SettingsBlockMobileSecurityProps {
  backToChoose: () => void
}

type Inputs = {
  repeatNewPassword: string
  newPassword: string
  oldPassword: string
}

function SettingsBlockMobileSecurity({
  backToChoose,
}: SettingsBlockMobileSecurityProps) {
  const { register, handleSubmit, reset, control } = useForm<Inputs>({
    // resolver: yupResolver(schema),
  })
  const { userChangePassword } = useActions()
  const [isVisible, setIsVisible] = useState({
    repeatNewPassword: false,
    newPassword: false,
    oldPassword: false,
  })

  const changeVisible = (key: string, value: boolean) => {
    setIsVisible({ ...isVisible, [key]: value })
  }

  const onSubmit: SubmitHandler<Inputs> = data => {
    const { newPassword, oldPassword, repeatNewPassword } = data
    if (newPassword === repeatNewPassword) {
      userChangePassword(oldPassword, newPassword)
      backToChoose()
    } else {
      alert('пароли не совпадают')
    }
  }

  return (
    <div className="settingsBlockMobileSecurityWrapper">
      <div className="settingsBlockMobileSecurityInner">
        <div className="settingsBlockMobileSecurityInnerTitle">
          <ArrowButtonIcon onClick={backToChoose} />
          <h4>Change password</h4>
        </div>
        <div className="settingsBlockMobileSecurityInnerText">
          <p>Here you can change your password.</p>
        </div>
        <form
          name="securitySettings"
          className="settingsBlockMobileSecurityForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h5>Change password</h5>
          <Controller
            name="oldPassword"
            control={control}
            render={({ field, fieldState }) => (
              <div className="settingsBlockMobileSecurityFormItem">
                <p>Enter old password</p>
                <input
                  {...field}
                  autoComplete="off"
                  placeholder="Enter old password"
                  type={isVisible.oldPassword ? 'text' : 'password'}
                />
                {/* {fieldState.error && (
                  <span style={{ color: 'red' }}>
                    {fieldState.error.message}
                  </span>
                )} */}
                <i
                  className={
                    !isVisible.oldPassword
                      ? 'far fa-eye fa-eye-slash'
                      : 'far fa-eye'
                  }
                  id="togglePassword"
                  aria-hidden="true"
                  onClick={() =>
                    changeVisible('oldPassword', !isVisible.oldPassword)
                  }
                  style={{ marginLeft: '-20px', cursor: 'pointer' }}
                />
              </div>
            )}
            rules={{ required: 'This field is required' }}
          />
          <Controller
            name="newPassword"
            control={control}
            render={({ field, fieldState }) => (
              <div className="settingsBlockMobileSecurityFormItem">
                <p>Enter new password</p>
                <input
                  {...field}
                  autoComplete="off"
                  placeholder="Enter new password"
                  type={isVisible.newPassword ? 'text' : 'password'}
                />
                <i
                  className={
                    !isVisible.newPassword
                      ? 'far fa-eye fa-eye-slash'
                      : 'far fa-eye'
                  }
                  id="togglePassword"
                  aria-hidden="true"
                  onClick={() =>
                    changeVisible('newPassword', !isVisible.newPassword)
                  }
                  style={{ marginLeft: '-20px', cursor: 'pointer' }}
                />
              </div>
            )}
            rules={{ required: 'This field is required' }}
          />
          <Controller
            name="repeatNewPassword"
            control={control}
            render={({ field, fieldState }) => (
              <div className="settingsBlockMobileSecurityFormItem">
                <p>Repeat new password</p>
                <input
                  {...field}
                  autoComplete="off"
                  placeholder="Repeat new password"
                  type={isVisible.repeatNewPassword ? 'text' : 'password'}
                />
                <i
                  className={
                    !isVisible.repeatNewPassword
                      ? 'far fa-eye fa-eye-slash'
                      : 'far fa-eye'
                  }
                  id="togglePassword"
                  aria-hidden="true"
                  onClick={() =>
                    changeVisible(
                      'repeatNewPassword',
                      !isVisible.repeatNewPassword,
                    )
                  }
                  style={{ marginLeft: '-20px', cursor: 'pointer' }}
                />
              </div>
            )}
            rules={{ required: 'This field is required' }}
          />

          <button type="submit">save changes</button>
        </form>
      </div>
    </div>
  )
}

export default SettingsBlockMobileSecurity
