/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useActions from '../../../../hooks/useActions'
import NextStepButton from '../../components/NextStepButton'

import { ReactComponent as TemplateIcon } from '../../assets/create-from-template-icon.svg'
import { ReactComponent as ScheduleIcon } from '../../assets/save-as-schedule-icon.svg'
import { ReactComponent as ResetIcon } from '../../assets/reset-event-icon.svg'

import './index.scss'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import RoutesPaths from '../../../../constants/navigate'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'

interface SaveEventDetailsProps {
  changeStep: (step: number) => void
  nextStep: number
}

function SaveEventDetails({ changeStep, nextStep }: SaveEventDetailsProps) {
  const [value, setValue] = useState<'TEMPLATE' | 'RESET' | 'SCHEDULE' | ''>('')
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const { updateUserCreateEvent, postUserEvent, showModal } = useActions()
  const { userCreateEvent } = useTypedSelector(state => state.events)

  const saveAsTemplate = () => {
    postUserEvent(
      { ...userCreateEvent, isTemplate: true, eventType: null },
      setLoading,
    )
    navigate(`/${RoutesPaths.eventsPages}`)
  }

  const nextStepHandler = () => {
    if (value === 'TEMPLATE') {
      saveAsTemplate()
    }
    if (value === 'RESET') {
      showModal('DeleteEvent', {})
    }
    if (value === 'SCHEDULE') {
      changeStep(nextStep)
    }
  }

  return (
    <div className="saveEventDetailsWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <EventStepTitle
          title="Save Event"
          text="Save your created event to access it later or make future modifications."
        />
      )}
      <div className="saveEventDetailsInner">
        <div
          className={
            value === 'TEMPLATE'
              ? 'saveEventDetailsItem activeItem'
              : 'saveEventDetailsItem'
          }
          role="button"
          tabIndex={0}
          onClick={() => setValue('TEMPLATE')}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setValue('TEMPLATE')
            }
          }}
        >
          <h5>Save as template</h5>
          <TemplateIcon />
        </div>
        <div
          className={
            value === 'SCHEDULE'
              ? 'saveEventDetailsItem activeItem'
              : 'saveEventDetailsItem'
          }
          role="button"
          tabIndex={0}
          onClick={() => setValue('SCHEDULE')}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setValue('SCHEDULE')
            }
          }}
        >
          <h5>Save as scheduled</h5>
          <ScheduleIcon />
        </div>
        <div
          className={
            value === 'RESET'
              ? 'saveEventDetailsItem activeItem'
              : 'saveEventDetailsItem'
          }
          role="button"
          tabIndex={0}
          onClick={() => setValue('RESET')}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setValue('RESET')
            }
          }}
        >
          <h5>Reset your event</h5>
          <ResetIcon />
        </div>
      </div>
      <NextStepButton handler={nextStepHandler} isActive={!!value.length} />
    </div>
  )
}

export default SaveEventDetails
