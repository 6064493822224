/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { ReactComponent as Possible } from '../../../assets/uCanMakeIt.svg'
import { ReactComponent as Impossible } from '../../../assets/uCantMakeIt.svg'
import { ReactComponent as PossibleOptional } from '../../../assets/uCanMakeItOptional.svg'
import { api } from '../../../../../api'
import './index.scss'
import useActions from '../../../../../hooks/useActions'
import { ICocktail } from '../../../../../@types/cocktails'
import { IIngredient } from '../../../../../@types/ingredients'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../../constants/screensWidth'
import RoutesPaths from '../../../../../constants/navigate'

interface IMixologyItem {
  mixologyItem: ICocktail
  possible: boolean
}

function MixologyItem({ mixologyItem, possible }: IMixologyItem) {
  const { getModalCocktail, showModal } = useActions()
  const [moreInfo, setMoreInfo] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const blockRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (blockRef.current) {
      const blockHeight = blockRef.current?.clientHeight
      const thresholdHeight = 130
      setShowButton(
        !!(
          blockHeight >= thresholdHeight || mixologyItem.ingredients.length >= 4
        ),
      )
    }
  }, [])

  const touchStart = useRef<number | null>(null)
  const touchEnd = useRef<number | null>(null)

  const minSwipeDistance = 50

  const onTouchStart = (e: React.TouchEvent) => {
    touchEnd.current = null
    touchStart.current = e.targetTouches[0].clientX
  }

  const onTouchMove = (e: React.TouchEvent) => {
    touchEnd.current = e.targetTouches[0].clientX
  }

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return
    const distance = touchStart.current - touchEnd.current
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) setMoreInfo(true)
    if (isRightSwipe) setMoreInfo(false)
  }

  function openModal(item: ICocktail) {
    getModalCocktail(item.id.toString())
    showModal('Cocktail', { item })
  }

  return (
    <div className="mixologyItemWrapper">
      <div
        className={
          possible
            ? 'mixologyItemContainer green'
            : 'mixologyItemContainer yellow'
        }
        onMouseEnter={() =>
          window.innerWidth >= LargeTabletDevicesWidth && setMoreInfo(true)
        }
        onMouseLeave={() =>
          window.innerWidth >= LargeTabletDevicesWidth && setMoreInfo(false)
        }
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onClick={() => {
          window.innerWidth <= MobileDevicesWidth
            ? window.open(
                `/${RoutesPaths.cocktailsPage}/${mixologyItem.id}`,
                '_blank',
              )
            : openModal(mixologyItem)
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            window.innerWidth <= MobileDevicesWidth
              ? window.open(
                  `/${RoutesPaths.cocktailsPage}/${mixologyItem.id}`,
                  '_blank',
                )
              : openModal(mixologyItem)
          }
        }}
        role="button"
        tabIndex={0}
      >
        <img
          alt={mixologyItem?.name || 'Default Alt'}
          src={`${api()}/images/cocktails/m/${mixologyItem.image}`}
        />
        <div className="mixologyItemTitle">
          <h3>{mixologyItem.name}</h3>
        </div>
        <div className={moreInfo ? 'mixologyItemInfo' : 'close'}>
          <div ref={blockRef} className="mixologyItemIngredientsList">
            {mixologyItem.ingredients
              .slice(0, showButton ? 3 : 4)
              .map((ingredient: IIngredient) => (
                <div key={ingredient.name} className="mixologyItemIngredient">
                  {(ingredient.isFavorite && <Possible />) ||
                    (!ingredient.isFavorite && ingredient.isOptional ? (
                      <PossibleOptional />
                    ) : (
                      <Impossible />
                    ))}
                  <p>{ingredient.name}</p>
                </div>
              ))}
          </div>

          <div className="mixologyItemFiltersList">
            {showButton && <p>Show more</p>}
            <hr />
            <p>
              Complexity:{' '}
              <i className={possible ? 'possible' : 'impossible'}>
                {mixologyItem.complexity}
              </i>
            </p>
            <p>
              Strength:{' '}
              <i className={possible ? 'possible' : 'impossible'}>
                {mixologyItem.strength}
              </i>
            </p>
            <p>
              Flavour:{' '}
              <i className={possible ? 'possible' : 'impossible'}>
                {mixologyItem.flavor.name}
              </i>
            </p>
            <p>
              Glass:{' '}
              <i className={possible ? 'possible' : 'impossible'}>
                {mixologyItem.glass.name}
              </i>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MixologyItem
