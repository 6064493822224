/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'
import './index.scss'
import { SubmitHandler, useForm } from 'react-hook-form'

import { ReactComponent as DefaultUserIcon } from '../../../../assets/images/svg/default-user-icon.svg'
import { ReactComponent as ArrowButtonIcon } from '../../images/arrow-button-icon.svg'

import { api } from '../../../../api'
import useProfile from '../../../../hooks/useProfile'

interface SettingsBlockMobileProfileProps {
  profileInfoName: string
  profileInfoEmail: string
  profileInfoUsername: string
  profileInfoImage: string
  updateProfileInfo: (data: Inputs) => void
  backToChoose: () => void
}

type Inputs = {
  username: string
  email: string
  name: string
  image: File
}

function SettingsBlockMobileProfile({
  profileInfoUsername,
  profileInfoEmail,
  profileInfoName,
  profileInfoImage,
  updateProfileInfo,
  backToChoose,
}: SettingsBlockMobileProfileProps) {
  const { register, handleSubmit, reset, setValue } = useForm<Inputs>({
    // resolver: yupResolver(schema),
  })
  const { setIsChangeProfile } = useProfile()

  useEffect(() => {
    setIsChangeProfile(true)
    return () => {
      setIsChangeProfile(false)
    }
  }, [])

  const onSubmit: SubmitHandler<Inputs> = data => {
    // const { email, image, name, username } = data
    updateProfileInfo(data)
  }

  const username = register('username')
  const email = register('email')
  const name = register('name')

  return (
    <div className="settingsBlockMobileProfileWrapper">
      <form
        className="settingsBlockMobileProfileInner"
        onSubmit={handleSubmit(onSubmit)}
        name="settingsBlockMobileProfile"
      >
        {/* <div className="settingsBlockMobileProfileFormImage">
          <label htmlFor="file-upload" className="custom-file-upload">
            {previewImage ? (
              <img src={previewImage} alt="Preview" />
            ) : profileInfoImage ? (
              <img
                src={`${api()}/images/users/${profileInfoImage}`}
                alt="User Photo"
              />
            ) : (
              <DefaultUserIcon />
            )}
          </label>
          <input
            id="file-upload"
            type="file"
            {...register('image')}
            onChange={handleImageChange}
          />
        </div> */}
        <div className="settingsBlockMobileProfileInnerTitle">
          <ArrowButtonIcon onClick={backToChoose} />
          <h4>Your profile data</h4>
        </div>
        <div className="settingsBlockMobileProfileInnerText">
          <p>
            {profileInfoName ?? profileInfoUsername}! Your data profile and
            settings are displayed here.
          </p>
        </div>
        <div className="settingsBlockMobileProfileForm">
          <h5>Change user data</h5>
          <div className="settingsBlockMobileProfileFormItem">
            <p>Username</p>
            <input
              disabled
              ref={username.ref}
              autoComplete="off"
              name={username.name}
              onBlur={username.onBlur}
              onChange={username.onChange}
              defaultValue={profileInfoUsername}
              placeholder="Enter username"
              type="text"
            />
          </div>
          <div className="settingsBlockMobileProfileFormItem">
            <p>Email</p>
            <input
              disabled
              ref={email.ref}
              autoComplete="off"
              name={email.name}
              onBlur={email.onBlur}
              onChange={email.onChange}
              defaultValue={profileInfoEmail}
              placeholder="Enter Email"
              type="email"
            />
          </div>
          <div className="settingsBlockMobileProfileFormItem">
            <p>Name</p>
            <input
              ref={name.ref}
              autoComplete="off"
              name={name.name}
              onBlur={name.onBlur}
              onChange={name.onChange}
              defaultValue={profileInfoName}
              placeholder="Enter Name"
              type="text"
              maxLength={24}
            />
          </div>
          <button type="submit">save changes</button>
        </div>
      </form>
    </div>
  )
}

export default SettingsBlockMobileProfile
