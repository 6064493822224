import React from 'react'

// import WelcomeImage from '../'
import { ReactComponent as StepIcon } from '../../assets/step-back-icon.svg'

import PhoneBg from '../../assets/welcome-screen-bg-image.png'
import DesktopBg from '../../assets/welcome-screen-desktop-bg-image.png'
import './index.scss'
import { MobileDevicesWidth } from '../../../../constants/screensWidth'

interface PartyWelcomeScreenProps {
  navigate: () => void
}

function PartyWelcomeScreen({ navigate }: PartyWelcomeScreenProps) {
  return (
    <div className="partyWelcomeScreenWrapper">
      <div
        className="partyWelcomeScreenInner"
        style={{
          background: `url(${window.innerWidth <= MobileDevicesWidth ? PhoneBg : DesktopBg})`,
        }}
      >
        <h2>
          Let&apos;s kick things off by curating the perfect cocktail menu from
          our extensive selection of recipes. Are you ready? Let&apos;s get this
          party started! ✨
        </h2>
        <button type="button" onClick={navigate}>
          <p>create your party</p>
          <StepIcon />
        </button>
      </div>
    </div>
  )
}

export default PartyWelcomeScreen
