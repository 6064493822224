import React, {
  Dispatch,
  ReactNode,
  createContext,
  useState,
  useMemo,
} from 'react'

interface ProfileContextType {
  isChangeProfile: boolean
  setIsChangeProfile: Dispatch<React.SetStateAction<boolean>>
}

export const ProfileContext = createContext<ProfileContextType | undefined>(
  undefined,
)

interface ProfileProviderProps {
  children: ReactNode
}

export function ProfileProvider({ children }: ProfileProviderProps) {
  const [isChangeProfile, setIsChangeProfile] = useState<boolean>(false)

  const contextValue = useMemo(
    () => ({ isChangeProfile, setIsChangeProfile }),
    [isChangeProfile, setIsChangeProfile],
  )

  return (
    <ProfileContext.Provider value={contextValue}>
      {children}
    </ProfileContext.Provider>
  )
}
