import { ICocktail } from '../../../@types/cocktails'
import { CocktailsAction, CocktailsActionEnum, CocktailsState } from './types'

const initialState: CocktailsState = {
  content: [],
  totalPages: 1,
  number: 0,
  cocktail: null,
  analogs: [],
  modalCocktail: null,
  totalElements: 0,
  searchField: '',
  popularByIngredientCocktail: [],
}

const cocktailsReducer = (
  state = initialState,
  action: CocktailsAction,
): CocktailsState => {
  switch (action.type) {
    case CocktailsActionEnum.GET_COCKTAILS: {
      if (action.payload.number === 0) {
        return {
          ...state,
          content: action.payload.content,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        }
      }
      return {
        ...state,
        content: [...state.content, ...action.payload.content],
        totalPages: action.payload.totalPages,
        number: action.payload.number,
        totalElements: action.payload.totalElements,
      }
    }
    case CocktailsActionEnum.GET_COCKTAILS_SEARCH: {
      if (state.searchField === action.payload.searchField) {
        return {
          ...state,
          content: [...state.content, ...action.payload.content],
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
          searchField: action.payload.searchField,
        }
      }
      return {
        ...state,
        content: action.payload.content,
        totalPages: action.payload.totalPages,
        number: action.payload.number,
        totalElements: action.payload.totalElements,
        searchField: '',
      }
    }

    case CocktailsActionEnum.GET_COCKTAILS_BY_FILTER_ACTION: {
      if (action.payload.number === 0) {
        return {
          ...state,
          content: action.payload.content,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        }
      }
      return {
        ...state,
        content: [...state.content, ...action.payload.content],
        totalPages: action.payload.totalPages,
        number: action.payload.number,
        totalElements: action.payload.totalElements,
      }
    }

    case CocktailsActionEnum.CLEAR_COCKTAIL_LIST:
      return {
        ...state,
        content: [],
        number: 0,
        searchField: '',
      }

    case CocktailsActionEnum.CLEAR_ANALOGS_LIST:
      return {
        ...state,
        analogs: [],
      }

    case CocktailsActionEnum.CLEAR_COCKTAIL:
      return {
        ...state,
        cocktail: null,
      }

    case CocktailsActionEnum.CLEAR_MODAL_COCKTAIL:
      return {
        ...state,
        modalCocktail: null,
      }

    case CocktailsActionEnum.GET_COCKTAIL:
      return {
        ...state,
        cocktail: action.payload,
      }

    case CocktailsActionEnum.GET_MODAL_COCKTAIL:
      return {
        ...state,
        modalCocktail: action.payload,
      }

    case CocktailsActionEnum.FAVORITES_COCKTAIL:
      return {
        ...state,
        content: state.content.map((item: ICocktail) => {
          if (item.id.toString() === action.payload.id) {
            return {
              ...item,
              isFavorite: action.payload.isFavorite,
              collections: action.payload.collections,
            }
          }
          return item
        }),
      }

    case CocktailsActionEnum.COCKTAIL_ANALOGS:
      return {
        ...state,
        analogs: action.payload,
      }

    case CocktailsActionEnum.GET_POPULAR_COCKTAILS_BY_INGREDIENTS:
      return {
        ...state,
        popularByIngredientCocktail: action.payload,
      }
    case CocktailsActionEnum.CLEAR_POPULAR_COCKTAILS_BY_INGREDIENTS:
      return {
        ...state,
        popularByIngredientCocktail: [],
      }

    default:
      return state
  }
}

export default cocktailsReducer
