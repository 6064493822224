import { FC, useState } from 'react'
import { staticApi } from '../../../api'

import { ReactComponent as MoreIcon } from '../../../assets/images/svg/more-icon.svg'
import { ReactComponent as CopyLinkIcon } from '../../../assets/images/svg/copy-link-icon.svg'
import { ReactComponent as ShareTwitterIcon } from '../../../assets/images/svg/share-twitter-icon.svg'
import { ReactComponent as ShareFacebookIcon } from '../../../assets/images/svg/share-facebook-icon.svg'

import { MobileDevicesWidth } from '../../../constants/screensWidth'
import { ICocktailFilter } from '../../../@types/cocktails'

import './index.scss'

interface InformationBlockProps {
  origin: ICocktailFilter
  title: string
  description: string
}

function InformationBlock({
  origin,
  title,
  description,
}: InformationBlockProps) {
  const [isOpenMore, setIsOpenMore] = useState<boolean>(false)

  return (
    <div className="informationBlockWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <div className="informationBlockHeader">
          <div className="informationBlockHeaderTitle">
            <img
              src={`${staticApi()}/images/flags/${origin.id.toString().toLowerCase()}.webp`}
              alt="cocktail country flag"
            />
            <h3>{title}</h3>
          </div>
          <div className="informationBlockHeaderShare">
            <MoreIcon
              className={isOpenMore ? 'informationBlockHeaderShareOpen' : ''}
              onClick={() => {
                setIsOpenMore(!isOpenMore)
              }}
              style={{ cursor: 'pointer' }}
            />
            {isOpenMore && (
              <div className="informationBlockHeaderShareLinks">
                <CopyLinkIcon />
                <ShareTwitterIcon />
                <ShareFacebookIcon />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="informationBlockCocktailDescription">
        <p>{description}</p>
      </div>
    </div>
  )
}

export default InformationBlock
