/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import { Modal } from 'antd'
import { useEffect, useState } from 'react'

import useTypedSelector from '../../hooks/useTypedSelector'
import { FiltersState } from '../../store/reducers/filters/types'
import useActions from '../../hooks/useActions'
import {
  ICocktailFilter,
  ICocktailSelectedFilters,
} from '../../@types/cocktails'
import { MobileDevicesWidth } from '../../constants/screensWidth'

interface FiltersModalProps {
  isCocktailsFilters: boolean
  isMixologyFilters: boolean
  isMyIngredientFilters: boolean
}

export default function FiltersModal({
  isCocktailsFilters,
  isMixologyFilters,
  isMyIngredientFilters,
}: FiltersModalProps) {
  const [selectedModalFilters, setSelectedModalFilters] =
    useState<ICocktailSelectedFilters>({
      ingredient: [],
      flavor: [],
      isPrimary: [],
      glass: [],
      complexity: [],
      strength: [],
      category: [],
      ingCategory: [],
      ingAllergen: [],
      allergen: [],
    })

  const {
    setSelectedFilter,
    clearSelectedCocktailsFilters,
    hideModal,
    applySelectedFilters,
  } = useActions()

  const {
    ingredient,
    flavors,
    glasses,
    isPrimary,
    categories,
    selectedFilters,
    complexity,
    allergens,
    strength,
  } = useTypedSelector<FiltersState>(state => state.filter)
  const { modalHidden } = useTypedSelector(state => state.modal)

  function handleCheckboxChange(
    item: string,
    selectedItems: string[],
    filterName: string,
  ) {
    if (selectedItems.includes(item)) {
      setSelectedFilter(
        selectedItems.filter(selectedItem => selectedItem !== item),
        filterName,
      )
    } else {
      setSelectedFilter([...selectedItems, item], filterName)
    }
  }

  const toggleFilterValue = (value: string, filterName: string) => {
    setSelectedModalFilters(prevFilters => {
      const updatedFilters = { ...prevFilters }

      if (updatedFilters[filterName].includes(value)) {
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          item => item !== value,
        )
      } else {
        updatedFilters[filterName] = [...updatedFilters[filterName], value]
      }

      return updatedFilters
    })
  }

  const removeFilterValue = (value: string, filterName: string) => {
    setSelectedModalFilters(prevFilters => {
      const updatedFilters = { ...prevFilters }

      if (updatedFilters[filterName].includes(value)) {
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          item => item !== value,
        )
      }

      return updatedFilters
    })
  }

  useEffect(() => {
    setSelectedModalFilters(selectedFilters)
    function setFullScreenBlockHeight() {
      const fullScreenFilterBlock = document.querySelector(
        '.filtersModalWrapper',
      ) as HTMLElement
      if (window.innerWidth <= MobileDevicesWidth) {
        fullScreenFilterBlock.style.height = `${window.innerHeight}px`
      }
    }

    window.addEventListener('resize', setFullScreenBlockHeight)
    window.addEventListener('orientationchange', setFullScreenBlockHeight)

    // Set initial height
    setFullScreenBlockHeight()
  }, [])

  return (
    <Modal
      centered
      open={modalHidden}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      closable={false}
      width={window.innerWidth > MobileDevicesWidth ? 620 : '100%'}
    >
      <div className="filtersModalWrapper">
        <div className="filtersModalInner">
          {isCocktailsFilters ? (
            isMixologyFilters ? (
              <div className="filtersModalInnerFilters">
                <div className="filtersModalInnerFilter">
                  <h3>
                    PRIMARY INGREDIENTS{' '}
                    {selectedModalFilters.ingredient.length > 0 &&
                      `: ${selectedModalFilters.ingredient.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {ingredient.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.ingredient.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'ingredient')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(
                                item.id.toString(),
                                'ingredient',
                              )
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    FLAVOUR{' '}
                    {selectedModalFilters.flavor.length > 0 &&
                      `: ${selectedModalFilters.flavor.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {flavors.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.flavor.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'flavor')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'flavor')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    COMPLEXITY{' '}
                    {selectedModalFilters.complexity.length > 0 &&
                      `: ${selectedModalFilters.complexity.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {complexity.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.complexity.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'complexity')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(
                                item.id.toString(),
                                'complexity',
                              )
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    ALLERGENS{' '}
                    {selectedModalFilters.allergen.length > 0 &&
                      `: ${selectedModalFilters.allergen.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {allergens.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.allergen.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'allergen')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'allergen')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="filtersModalInnerFilters">
                <div className="filtersModalInnerFilter">
                  <h3>
                    FLAVOUR{' '}
                    {selectedModalFilters.flavor.length > 0 &&
                      `: ${selectedModalFilters.flavor.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {flavors.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.flavor.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'flavor')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'flavor')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    STRENGTH{' '}
                    {selectedModalFilters.strength.length > 0 &&
                      `: ${selectedModalFilters.strength.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {strength.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.strength.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(
                              item.id.toString(),

                              'strength',
                            )
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'strength')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    COMPLEXITY{' '}
                    {selectedModalFilters.complexity.length > 0 &&
                      `: ${selectedModalFilters.complexity.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {complexity.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.complexity.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'complexity')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(
                                item.id.toString(),
                                'complexity',
                              )
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    ALLERGENS{' '}
                    {selectedModalFilters.allergen.length > 0 &&
                      `: ${selectedModalFilters.allergen.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {allergens.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.allergen.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'allergen')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'allergen')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    GLASS{' '}
                    {selectedModalFilters.glass.length > 0 &&
                      `: ${selectedModalFilters.glass.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {glasses.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.glass.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'glass')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'glass')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="filtersModalInnerFilter">
                  <h3>
                    CATEGORY{' '}
                    {selectedModalFilters.category.length > 0 &&
                      `: ${selectedModalFilters.category.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {categories.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.category.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'category')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'category')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="filtersModalInnerFilters">
              {isMyIngredientFilters && (
                <div className="filtersModalInnerFilter">
                  <h3>
                    TYPE{' '}
                    {selectedModalFilters.isPrimary.length > 0 &&
                      `: ${selectedModalFilters.isPrimary.length}`}
                  </h3>
                  <div className="filtersModalInnerFilterItems">
                    {isPrimary.map((item: ICocktailFilter) => {
                      return (
                        <div
                          key={Math.random()}
                          className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.isPrimary.includes(item.id.toString()) && 'activeItem'}`}
                          onClick={() => {
                            toggleFilterValue(item.id.toString(), 'isPrimary')
                          }}
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              toggleFilterValue(item.id.toString(), 'isPrimary')
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <p>{item.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}

              <div className="filtersModalInnerFilter">
                <h3>
                  ALLERGENS{' '}
                  {selectedModalFilters.ingAllergen.length > 0 &&
                    `: ${selectedModalFilters.ingAllergen.length}`}
                </h3>
                <div className="filtersModalInnerFilterItems">
                  {allergens.map((item: ICocktailFilter) => {
                    return (
                      <div
                        key={Math.random()}
                        className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.ingAllergen.includes(item.id.toString()) && 'activeItem'}`}
                        onClick={() => {
                          toggleFilterValue(item.id.toString(), 'ingAllergen')
                        }}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            toggleFilterValue(item.id.toString(), 'allergen')
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <p>{item.name}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="filtersModalInnerFilter">
                <h3>
                  CATEGORY{' '}
                  {selectedModalFilters.ingCategory.length > 0 &&
                    `: ${selectedModalFilters.ingCategory.length}`}
                </h3>
                <div className="filtersModalInnerFilterItems">
                  {categories.map((item: ICocktailFilter) => {
                    return (
                      <div
                        key={Math.random()}
                        className={`filtersModalInnerFilterItemsItem ${selectedModalFilters.ingCategory.includes(item.id.toString()) && 'activeItem'}`}
                        onClick={() => {
                          toggleFilterValue(item.id.toString(), 'ingCategory')
                        }}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            toggleFilterValue(item.id.toString(), 'ingCategory')
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <p>{item.name}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            // <div />
          )}
          <div className="filtersModalInnerBtns">
            <button type="button" onClick={() => hideModal()}>
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                applySelectedFilters(selectedModalFilters)
                hideModal()
              }}
            >
              apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
