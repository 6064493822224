import { SetStateAction, useEffect, useState } from 'react'
import { ReactComponent as GoogleIcon } from '../assets/GoogleIco.svg'
import { ReactComponent as FacebookIcon } from '../assets/FacebookIco.svg'
import { ReactComponent as AppleIcon } from '../assets/AppleIco.svg'
import { IAuthErrorState } from '../../../@types/auth'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import useActions from '../../../hooks/useActions'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema, signinSchema } from '../yupObjects'
import LoginComponent from './LoginComponent/index'
import SignupComponent from './SignupComponent/index'

type InputsSignIn = {
  username: string
  email: string
  password: string
}

type InputsLogin = {
  usernameORemail: string
  password: string
}

interface Props {
  forgotPassword: () => void
  verifyEmail: (email: string) => void
}

export default function AuthorizationComponent({
  forgotPassword,
  verifyEmail,
}: Props) {
  const navigate = useNavigate()
  const [state, setState] = useState<IAuthErrorState>({
    errorAnimation: false,
    stateMassage: false,
    signIn: false,
    loading: false,
  })
  const [errorField, setErrorField] = useState<string>('')
  const { userRegister, userLogin, getUserProfile } = useActions()

  const methods = useForm<InputsSignIn | InputsLogin | any>({
    resolver: state.signIn
      ? yupResolver(signinSchema)
      : yupResolver(loginSchema),
  })

  useEffect(() => {
    const time = setTimeout(
      () => setState(prev => ({ ...prev, errorAnimation: false })),
      300,
    )

    return () => clearTimeout(time)
  }, [state.errorAnimation])

  const onSubmit: SubmitHandler<InputsSignIn | InputsLogin | any> = data => {
    let username, password, email, usernameORemail

    if (state.signIn) {
      ;({ username, password, email } = data)
    } else {
      ;({ usernameORemail, password } = data)
    }
    if (state.signIn) {
      userRegister(
        email,
        username,
        password,
        verifyEmail,
        setState,
        setErrorField,
        methods.reset,
      )
    } else {
      userLogin(
        usernameORemail,
        password,
        setState,
        navigate,
        setErrorField,
        methods.reset,
        getUserProfile,
      )
    }
  }

  return (
    <>
      <h3>{!state.signIn ? 'Log in with' : 'Sing Up with'}</h3>
      <div className="anotherLoginInner">
        <button aria-label="Log in with Facebook" type="button">
          <FacebookIcon />
        </button>
        <button aria-label="Log in with Google" type="button">
          <GoogleIcon />
        </button>
        <button aria-label="Log in with Apple" type="button">
          <AppleIcon />
        </button>
      </div>
      <div className="orWrapper">
        <hr />
        <p>or</p>
        <hr />
      </div>
      <FormProvider {...methods}>
        <form
          name="login"
          className="login-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {!state.signIn ? <LoginComponent /> : <SignupComponent />}
          {!state.signIn && (
            <p onClick={() => forgotPassword()}>Forgot Password?</p>
          )}
          <button type="submit" className="login-form-button">
            {!state.signIn ? 'LOG IN' : 'SIGN UP'}
          </button>
        </form>
      </FormProvider>
      <div className="changeForm">
        <p>
          {!state.signIn
            ? 'Don’t have an account?'
            : 'Already have an account?'}
        </p>
        <button
          onClick={() => {
            setState({ ...state, signIn: !state.signIn })
          }}
        >
          {!state.signIn ? 'Create an account' : 'Log in'}
        </button>
      </div>
    </>
  )
}
