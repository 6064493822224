/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useTypedSelector from '../../../hooks/useTypedSelector'
import RoutesPaths from '../../../constants/navigate'
import PartyHeader from '../components/EventHeader'
import ChooseTemplate from './ChooseTemplate'
import { IEventsItem } from '../../../@types/events'
import TemplatePreview from './TemplatePreview'
import PartyScratchDetails from '../EventSteps/PartyScratchDetails'
import CocktailsChanges from '../EventSteps/CocktailsChanges'
import CocktailsAddOther from '../EventSteps/CocktailsAddOther'
import CocktailsAmount from '../EventSteps/CocktailsAmount'
import SaveEventDetails from '../EventSteps/SaveEventDetails'
import PartyDetailsName from '../EventSteps/PartyScratchDetailsName'
import PartyEventType from '../EventSteps/PartyEventType'
import PartyPhoto from '../EventSteps/PartyPhoto'
import useActions from '../../../hooks/useActions'
import EventsDesktopTabs from '../components/EventDesktopTabs'
import { MobileDevicesWidth } from '../../../constants/screensWidth'

function PartyTemplateEventPage() {
  const navigate = useNavigate()

  const { eventId } = useTypedSelector(state => state.events)
  const { clearEventInfo } = useActions()

  const [createStep, setCreateStep] = useState<number>(1)
  const [selectedTemplate, setSelectedTemplate] = useState<null | IEventsItem>(
    null,
  )

  useEffect(() => {
    return () => {
      clearEventInfo()
    }
  }, [])

  const goToEvents = () => {
    return navigate(`/${RoutesPaths.eventsPagesCreate}`)
  }

  const customStepBack = (number: number) => {
    if (createStep > 3 && createStep <= 7) {
      setCreateStep(3)
    }
    setCreateStep(number)
  }

  function eventSteps(step: number) {
    switch (step) {
      case 1:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                customFunc={goToEvents}
                loadingPercent="10%"
                stepTitle="Choose a template"
              />
            )}
            <div className="userEventsInner">
              <ChooseTemplate
                changeStep={setCreateStep}
                nextStep={2}
                selectTemplate={setSelectedTemplate}
              />
            </div>
          </div>
        )
      case 2:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={1}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                prevStep={1}
                stepBack={setCreateStep}
                loadingPercent="20%"
                stepTitle="Cocktails menu"
              />
            )}
            <div className="userEventsInner">
              <TemplatePreview
                changeStep={setCreateStep}
                nextStep={3}
                template={selectedTemplate}
              />
            </div>
          </div>
        )
      case 3:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={2}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                stepBack={setCreateStep}
                loadingPercent="30%"
                prevStep={2}
                stepTitle="Event Information"
              />
            )}
            <div className="userEventsInner">
              <PartyScratchDetails changeStep={setCreateStep} nextStep={7} />
            </div>
          </div>
        )
      case 7:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                stepBack={setCreateStep}
                loadingPercent="40%"
                prevStep={3}
                stepTitle="Editing event cocktails"
              />
            )}
            <div className="useEventsInnerCocktails">
              <CocktailsChanges
                changeStep={setCreateStep}
                nextStep={8}
                addStep={99}
                isTemplate={false}
              />
            </div>
          </div>
        )
      case 8:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                stepBack={setCreateStep}
                loadingPercent="50%"
                prevStep={7}
                stepTitle="Event cocktails"
              />
            )}
            <div className="useEventsInnerCocktails">
              <CocktailsAmount changeStep={setCreateStep} nextStep={9} />
            </div>
          </div>
        )
      case 9:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                stepBack={setCreateStep}
                loadingPercent="60%"
                prevStep={8}
                stepTitle="Save Event Details"
              />
            )}
            <div className="userEventsInner">
              <SaveEventDetails changeStep={setCreateStep} nextStep={10} />
            </div>
          </div>
        )
      case 10:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                stepBack={setCreateStep}
                loadingPercent="70%"
                prevStep={9}
                stepTitle="Event Details"
              />
            )}
            <div className="userEventsInner">
              <PartyDetailsName changeStep={setCreateStep} nextStep={11} />
            </div>
          </div>
        )
      case 11:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                stepBack={setCreateStep}
                loadingPercent="80%"
                prevStep={10}
                stepTitle="Event Details"
              />
            )}
            <div className="userEventsInner">
              <PartyEventType changeStep={setCreateStep} nextStep={12} />
            </div>
          </div>
        )
      case 12:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                stepBack={setCreateStep}
                loadingPercent="90%"
                prevStep={11}
                stepTitle="Photo of Event"
              />
            )}
            <div className="userEventsInner">
              <PartyPhoto />
            </div>
          </div>
        )
      case 99:
        return (
          <div className="userEventsWrapper">
            <CocktailsAddOther
              changeStep={setCreateStep}
              nextStep={7}
              prevStep={7}
            />
          </div>
        )
      default:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={customStepBack}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <PartyHeader
                customFunc={goToEvents}
                loadingPercent="10%"
                stepTitle="Choose a template"
              />
            )}
            <div className="userEventsInner">
              <ChooseTemplate
                changeStep={setCreateStep}
                nextStep={2}
                selectTemplate={setSelectedTemplate}
              />
            </div>
          </div>
        )
    }
  }

  return <div className="userEvents">{eventSteps(createStep)}</div>
}

export default PartyTemplateEventPage
