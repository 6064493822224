import { FC } from 'react'

import './index.scss'

interface PlanCardProps {
  image: string
  title: JSX.Element
  list: string[]
  btnText: string
  type: 'basic' | 'premium'
  btnAction: () => void
}

function PlanCard({
  image,
  title,
  list,
  btnText,
  type,
  btnAction,
}: PlanCardProps) {
  return (
    <div
      className={
        type === 'basic' ? 'planCardWrapper basic' : 'planCardWrapper premium'
      }
    >
      <img src={image} alt={image} />
      <div className="planCardInner">
        <div className="planCardInnerTitle">{title}</div>
        <div className="planCardLine" />
        <ul>
          {list.map((item: string) => {
            return <li key={Math.random()}>{item}</li>
          })}
        </ul>
        <button onClick={btnAction} type="button">
          {btnText}
        </button>
      </div>
    </div>
  )
}

export default PlanCard
