import axios, { AxiosResponse } from 'axios'
import { getParams } from '../../store/reducers/auth/authUtils'
import { IRegistration, IUser } from '../../@types/auth'
import axiosInstance, { api } from '..'

class AuthService {
  static async getConfig(): Promise<
    AxiosResponse<{ api: string; staticApi: string }>
  > {
    return axios.get(`../config.json`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
  }

  static async userRegister(
    email: string,
    username: string,
    password: string,
  ): Promise<AxiosResponse<IRegistration>> {
    return axiosInstance.post('/users/register', { email, username, password })
  }

  static async userLogin(
    usernameORemail: string,
    password: string,
  ): Promise<AxiosResponse<IUser>> {
    const url = '/oauth/token?grant_type=password'
    const params = new URLSearchParams()
    params.set('password', password)
    params.set('username', usernameORemail)

    const fullUrl = `${url}&${params.toString()}`

    return axiosInstance.post(
      fullUrl,
      { 'username': usernameORemail, password },
      {
        headers: {
          Authorization: 'Basic QUxDT0hPTElDUzpQT1dFUg==',
        },
      },
    )
  }

  static async userRefresh(rToken: string): Promise<AxiosResponse<IUser>> {
    const url = '/oauth/token?grant_type=refresh_token'
    const params = new URLSearchParams()
    params.set('refresh_token', rToken)

    const fullUrl = `${url}&${params.toString()}`

    return axiosInstance.post(
      fullUrl,
      {},
      {
        headers: {
          Authorization: 'Basic QUxDT0hPTElDUzpQT1dFUg==',
        },
      },
    )
  }

  static async userResetPassword(email: string): Promise<AxiosResponse> {
    const url = '/users/reset'
    const params = new URLSearchParams()
    params.set('email', email)
    const fullUrl = `${url}?${params.toString()}`

    return axiosInstance.post(
      fullUrl,
      {},
      {
        headers: {
          Authorization: 'Basic QUxDT0hPTElDUzpQT1dFUg==',
        },
      },
    )
  }

  static async userNewPassword(
    token: string,
    password: string,
  ): Promise<AxiosResponse<string>> {
    return axiosInstance.post(
      `/users/reset/${token}`,
      { password },
      {
        headers: {
          Authorization: 'Basic QUxDT0hPTElDUzpQT1dFUg==',
        },
      },
    )
  }

  static async userChangePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<AxiosResponse> {
    return axiosInstance.put(
      '/users/password',
      { oldPassword, newPassword },
      {
        headers: {
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  static async userConfirm(
    email: string,
    code: number,
  ): Promise<AxiosResponse<IRegistration>> {
    return axiosInstance.post('/users/confirm', { email, code })
  }

  static async userResendOneTimePassword(
    email: string,
  ): Promise<AxiosResponse<IRegistration>> {

    const url = '/users/resend-verification-email'
    const params = new URLSearchParams()
    params.set('email', email)
    const fullUrl = `${url}?${params.toString()}`
    return axiosInstance.post(fullUrl, {})
  }
}

export default AuthService
