import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { IEventsItem, IEventsSummary } from '../../../../@types/events'
import PartyWelcomeScreen from '../WelcomeScreen'
import EventsCard from './EventsCard'
import NoUpcomingEventsCard from './NoUpcomingEventsCard'

import RoutesPaths from '../../../../constants/navigate'

import './index.scss'
import ViewAllEvents from './ViewAllEvents'

interface PartyEventsProps {
  eventsSummary: IEventsSummary
}

function PartyEvents({ eventsSummary }: PartyEventsProps) {
  const [isViewAll, setIsViewAll] = useState<boolean>(false)
  const [allType, setAllType] = useState<'past' | 'planned'>('planned')
  const navigate = useNavigate()

  const navigateToCreate = () => {
    return navigate(`/${RoutesPaths.eventsPagesCreate}`)
  }

  const navigateToViewAll = (type: 'past' | 'planned') => {
    setIsViewAll(true)
    setAllType(type)
  }

  function whatReturn() {
    if (eventsSummary.past.length || eventsSummary.planned.length) {
      return (
        <div className="userEvents">
          <div className="userEventsWrapperEventsPage">
            <div className="partyEventsWrapper">
              <div className="partyCalendarsEventsWrapper">
                {/* {!eventsSummary.planned.length && (
                  <NoUpcomingEventsCard
                    navigate={navigateToCreate}
                    title="No Upcoming Event"
                  />
                )} */}
                <div className="partyCalendarsEventsTitle">
                  <h3>Upcoming ({eventsSummary.planned.length}) </h3>
                  {eventsSummary.planned.length > 2 && (
                    <button
                      type="button"
                      onClick={() => navigateToViewAll('planned')}
                    >
                      View All
                    </button>
                  )}
                </div>
                <div className="partyCalendarsEventsInner">
                  <NoUpcomingEventsCard
                    navigate={navigateToCreate}
                    title="New Upcoming Event"
                  />
                  {eventsSummary.planned.map(
                    (item: IEventsItem, index: number) => {
                      return (
                        index <= 1 && (
                          <Link
                            key={item.id}
                            to={`/${RoutesPaths.partyEventSummaryPage}`.replace(
                              ':id',
                              item.id,
                            )}
                          >
                            <EventsCard
                              title={item.name}
                              date={item.plannedAt}
                              guests={item.guestsCount}
                              location={item.location}
                              image={item.image}
                              type="UPCOMING"
                            />
                          </Link>
                        )
                      )
                    },
                  )}
                </div>
              </div>
              {!!eventsSummary.past.length && (
                <div className="partyCalendarsEventsWrapper">
                  <div className="partyCalendarsEventsTitle">
                    <h3>Completed events ({eventsSummary.past.length}) </h3>
                    {eventsSummary.past.length > 3 && (
                      <button
                        type="button"
                        onClick={() => navigateToViewAll('past')}
                      >
                        View All
                      </button>
                    )}
                  </div>
                  <div className="partyCalendarsEventsInner">
                    {eventsSummary.past.map(
                      (item: IEventsItem, index: number) => {
                        return (
                          index <= 2 && (
                            <Link
                              key={item.id}
                              to={`/${RoutesPaths.partyEventSummaryPage}`.replace(
                                ':id',
                                item.id,
                              )}
                            >
                              <EventsCard
                                title={item.name}
                                date={item.plannedAt}
                                guests={item.guestsCount}
                                location={item.location}
                                image={item.image}
                                type="PAST"
                              />
                            </Link>
                          )
                        )
                      },
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }

    return <PartyWelcomeScreen navigate={navigateToCreate} />
  }

  return isViewAll ? (
    <ViewAllEvents
      events={allType === 'past' ? eventsSummary.past : eventsSummary.planned}
      type={allType}
      goBackFn={setIsViewAll}
    />
  ) : (
    whatReturn()
  )
}

export default PartyEvents
