import { Navigate, useLocation } from 'react-router-dom'
import RoutesPaths from '../../constants/navigate'
import { getParams } from '../../store/reducers/auth/authUtils'

interface RequireAuthProps {
  children: JSX.Element
}

function RequiredAuth({ children }: RequireAuthProps) {
  const location = useLocation()

  if (!getParams('access_token')) {
    return (
      <Navigate to={`/${RoutesPaths.loginPage}`} state={{ from: location }} />
    )
  }

  return children
}

export default RequiredAuth
