import { Modal } from 'antd'
import Authorization from '../../components/Authorization'
import useTypedSelector from '../../hooks/useTypedSelector'
import useActions from '../../hooks/useActions'

export default function AuthModal() {
  const { modalHidden } = useTypedSelector(state => state.modal)
  const { hideModal } = useActions()

  return (
    <Modal
      open={modalHidden}
      onCancel={() => hideModal()}
      closable={false}
      style={{ position: 'absolute', top: 0, right: 0, margin: 0 }}
      width={'100%'}
    >
      <Authorization />
    </Modal>
  )
}
