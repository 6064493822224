/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react'

import useActions from '../../../../hooks/useActions'

import CustomRadioButton from '../../PublicProfileBlock/CustomRadioButton'

import { ReactComponent as ArrowButtonIcon } from '../../images/arrow-button-icon.svg'

import './index.scss'

interface SettingsBlockMobileNotificationProps {
  notificationSettings: {
    notificationsPeriod: string
    isAllowNotification: boolean
  }
  backToChoose: () => void
}

function SettingsBlockMobileNotification({
  notificationSettings,
  backToChoose,
}: SettingsBlockMobileNotificationProps) {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const { setUserProfileNotification } = useActions()

  useEffect(() => {
    if (notificationSettings) {
      setIsChecked(notificationSettings.isAllowNotification)
    }
  }, [])

  function notificationChange(allow: boolean, period: string = 'WEEKLY') {
    setUserProfileNotification({
      notificationsPeriod: period,
      isAllowNotification: allow,
    })
    setIsChecked(allow)
  }

  return (
    <div className="settingsBlockMobileNotificationWrapper">
      <div className="settingsBlockMobileNotificationInner">
        <div className="settingsBlockMobileNotificationInnerTitle">
          <ArrowButtonIcon onClick={backToChoose} />
          <h4>Notifications</h4>
        </div>
        <div className="settingsBlockMobileNotificationInnerText">
          <p>Here you can configure notifications sent to your email.</p>
        </div>
        <div className="settingsBlockMobileNotificationContent">
          <CustomRadioButton
            label="allow notification"
            checked={isChecked}
            onChange={() => notificationChange(!isChecked)}
          />
          {isChecked && (
            <div className="settingsBlockMobileNotificationPeriodWrapper">
              <div
                className="settingsBlockMobileNotificationPeriodOption"
                onClick={() => {
                  notificationChange(true, 'DAILY')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    notificationChange(true, 'DAILY')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <label
                  className={
                    notificationSettings?.notificationsPeriod === 'DAILY'
                      ? 'settingsBlockMobileNotificationPeriodCheckbox checked'
                      : 'settingsBlockMobileNotificationPeriodCheckbox'
                  }
                >
                  <input type="checkbox" checked disabled />
                </label>
                <p>daily</p>
              </div>
              <div
                className="settingsBlockMobileNotificationPeriodOption"
                onClick={() => {
                  notificationChange(true, 'WEEKLY')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    notificationChange(true, 'WEEKLY')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <label
                  className={
                    notificationSettings?.notificationsPeriod === 'WEEKLY'
                      ? 'settingsBlockMobileNotificationPeriodCheckbox checked'
                      : 'settingsBlockMobileNotificationPeriodCheckbox'
                  }
                >
                  <input type="checkbox" checked disabled />
                </label>
                <p>weekly</p>
              </div>
              <div
                className="settingsBlockMobileNotificationPeriodOption"
                onClick={() => {
                  notificationChange(true, 'MONTHLY')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    notificationChange(true, 'MONTHLY')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <label
                  className={
                    notificationSettings?.notificationsPeriod === 'MONTHLY'
                      ? 'settingsBlockMobileNotificationPeriodCheckbox checked'
                      : 'settingsBlockMobileNotificationPeriodCheckbox'
                  }
                >
                  <input type="checkbox" checked disabled />
                </label>
                <p>monthly</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SettingsBlockMobileNotification
