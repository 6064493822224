import authReducer from './auth'
import cocktailsReducer from './cocktails'
import ingredientsReducer from './ingredients'
import modalReducer from './modals'
import filtersReducer from './filters'
import userReducer from './user'
import barReducer from './bar'
import subscriptionsReducer from './subscriptions'
import eventsReducer from './events'

export const root = {
  auth: authReducer,
  cocktails: cocktailsReducer,
  ingredients: ingredientsReducer,
  modal: modalReducer,
  filter: filtersReducer,
  user: userReducer,
  bar: barReducer,
  subscriptions: subscriptionsReducer,
  events: eventsReducer,
}
