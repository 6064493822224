import {
  ICocktailFilter,
  ICocktailSelectedFilters,
} from '../../../@types/cocktails'
import { IIngredient, IIngredientsList } from '../../../@types/ingredients'

interface SelectedFilter {
  id: number[] | string[]
  filter: string
}

export interface FiltersState {
  ingredient: IIngredient[]
  flavors: ICocktailFilter[]
  glasses: ICocktailFilter[]
  categories: ICocktailFilter[]
  complexity: ICocktailFilter[]
  allergens: ICocktailFilter[]
  isPrimary: ICocktailFilter[]
  strength: ICocktailFilter[]
  selectedFilters: ICocktailSelectedFilters
}

export enum FiltersActionEnum {
  GET_FILTERS = 'GET_FILTERS',
  GET_PRIMARY_INGREDIENTS = 'GET_PRIMARY_INGREDIENTS',
  GET_FLAVORS = 'GET_FLAVORS',
  GET_GLASSES = 'GET_GLASSES',
  GET_ALLERGENS = 'GET_ALLERGENS',
  SET_SELECTED_FILTER = 'SET_SELECTED_FILTER',
  GET_CATEGORIES = 'GET_CATEGORIES',
  CLEAR_SELECTED_FILTERS = 'CLEAR_SELECTED_FILTERS',
  CLEAR_SELECTED_INGREDIENTS_FILTERS = 'CLEAR_SELECTED_INGREDIENTS_FILTERS',
  CLEAR_SELECTED_COCKTAILS_FILTERS = 'CLEAR_SELECTED_COCKTAILS_FILTERS',
  APPLY_SELECTED_FILTERS = 'APPLY_SELECTED_FILTERS',
}

export interface GetFiltersAction {
  type: FiltersActionEnum.GET_FILTERS
  payload: FiltersState
}

export interface GetPrimaryIngredientsAction {
  type: FiltersActionEnum.GET_PRIMARY_INGREDIENTS
  payload: IIngredient[]
}

export interface GetFlavorsAction {
  type: FiltersActionEnum.GET_FLAVORS
  payload: ICocktailFilter[]
}

export interface GetAllergensAction {
  type: FiltersActionEnum.GET_ALLERGENS
  payload: ICocktailFilter[]
}

export interface GetGlassesAction {
  type: FiltersActionEnum.GET_GLASSES
  payload: ICocktailFilter[]
}

export interface GetCategoriesAction {
  type: FiltersActionEnum.GET_CATEGORIES
  payload: ICocktailFilter[]
}

export interface SetSelectedFilterAction {
  type: FiltersActionEnum.SET_SELECTED_FILTER
  payload: SelectedFilter
}

export interface ClearSelectedFiltersAction {
  type: FiltersActionEnum.CLEAR_SELECTED_FILTERS
}

export interface ClearSelectedIngredientsFiltersAction {
  type: FiltersActionEnum.CLEAR_SELECTED_INGREDIENTS_FILTERS
}

export interface ClearSelectedCocktailsFiltersAction {
  type: FiltersActionEnum.CLEAR_SELECTED_COCKTAILS_FILTERS
}

export interface ApplySelectedFiltersAction {
  type: FiltersActionEnum.APPLY_SELECTED_FILTERS
  payload: ICocktailSelectedFilters
}

export type FiltersAction =
  | GetFiltersAction
  | GetPrimaryIngredientsAction
  | GetFlavorsAction
  | GetAllergensAction
  | GetGlassesAction
  | GetCategoriesAction
  | SetSelectedFilterAction
  | ClearSelectedFiltersAction
  | ClearSelectedIngredientsFiltersAction
  | ClearSelectedCocktailsFiltersAction
  | ApplySelectedFiltersAction
