import axios, { AxiosResponse } from 'axios'
import axiosInstance, { api } from '..'
import { getParams } from '../../store/reducers/auth/authUtils'
import {
  IBarCocktailsCollection,
  IBarCocktailsCollectionPatch,
  IPatchCollectionParams,
} from '../../@types/userBar'
import {
  ICocktailSelectedFilters,
  ICocktailsList,
} from '../../@types/cocktails'
import { IIngredientsList } from '../../@types/ingredients'

interface GetCocktailsRequestParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  searchField?: string
  sortValue: string
  excludeCocktails: number[]
  barUrl: string
}

interface GetIngredientsRequestParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  searchFields?: string
}

class DBarService {
  // INGREDIENTS
  static async getFavoriteIngredients({
    page,
    size,
    selectedFilters,
    searchFields,
  }: GetIngredientsRequestParams): Promise<AxiosResponse<IIngredientsList>> {
    const url = '/users/bar/ingredients'
    const searchParams = new URLSearchParams()

    Object.keys(selectedFilters).forEach(key => {
      const values = selectedFilters[key]
      if (values.length > 0 && key === 'ingAllergen') {
        searchParams.set('allergen', values.join(','))
      }
      if (values.length > 0 && key === 'ingCategory') {
        searchParams.set('category', values.join(','))
      }
      if (values.length > 0 && values.length !== 2 && key === 'isPrimary') {
        searchParams.set('isPrimary', values.join(','))
      }
    })

    if (searchFields) {
      searchParams.set('name', searchFields)
    }

    searchParams.set('page', page.toString())
    searchParams.set('size', size)

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async getMixologyList({
    page,
    size,
    selectedFilters,
    searchFields,
  }: GetIngredientsRequestParams): Promise<AxiosResponse<ICocktailsList>> {
    const url = '/users/bar/cocktails'
    const searchParams = new URLSearchParams()

    Object.keys(selectedFilters).forEach(key => {
      const values = selectedFilters[key]
      if (values.length > 0) {
        searchParams.set(key, values.join(','))
      }
    })

    if (searchFields) {
      searchParams.set('name', searchFields)
    }

    searchParams.set('page', page.toString())
    searchParams.set('size', size)

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async ingredientFavorites(id: number): Promise<AxiosResponse> {
    return axiosInstance.post(
      `/users/bar/ingredients/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  static async deleteIngredientFavorites(id: number): Promise<AxiosResponse> {
    return axiosInstance.delete(`/users/bar/ingredients/${id}`, {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }

  // IMAGES
  static async uploadCollectionImage(
    file: File,
  ): Promise<AxiosResponse<string>> {
    return axiosInstance.post(
      '/images/collections',
      { file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  // COCKTAILS
  static async getBarCocktails({
    page,
    size,
    selectedFilters,
    searchField,
    sortValue,
    excludeCocktails,
    barUrl,
  }: GetCocktailsRequestParams): Promise<AxiosResponse<ICocktailsList>> {
    const url = barUrl
    const searchParams = new URLSearchParams()

    if (excludeCocktails.length) {
      searchParams.set('exclude', excludeCocktails.join(','))
    }

    Object.keys(selectedFilters).forEach(key => {
      const values = selectedFilters[key]
      if (values.length > 0) {
        searchParams.set(key, values.join(','))
      }
    })

    if (searchField) {
      searchParams.set('name', searchField)
    }

    if (sortValue) {
      searchParams.append('sort', sortValue)
      if (sortValue === 'isClassic,DESC') {
        searchParams.append('sort', 'name,ASC')
      }
    }

    searchParams.set('page', page.toString())
    searchParams.set('size', size)

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  // COLLECTIONS
  static async createCollection(
    name: string,
    image: string,
    cocktails: number[],
  ): Promise<AxiosResponse<IBarCocktailsCollection>> {
    return axiosInstance.post(
      '/users/collections',
      {
        name,
        image,
        cocktails,
      },
      {
        headers: {
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  static async getCollections(
    name: string,
  ): Promise<AxiosResponse<IBarCocktailsCollection[]>> {
    const url = '/users/collections'
    const searchParams = new URLSearchParams()

    if (name.length) {
      searchParams.set('name', name)
    }

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async deleteCollection(collectionId: string): Promise<AxiosResponse> {
    return axiosInstance.delete(`/users/collections/${collectionId}`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async patchCollection(
    patchCollection: IPatchCollectionParams,
  ): Promise<AxiosResponse<IBarCocktailsCollectionPatch>> {
    const requestObj: Record<string, string> = {}

    if (patchCollection.name) {
      requestObj.name = patchCollection.name
    }

    if (patchCollection.image) {
      requestObj.image = patchCollection.image
    }

    return axiosInstance.patch(
      `/users/collections/${patchCollection.collectionId}`,
      requestObj,
      {
        headers: {
          Authorization: getParams('access_token')
            ? `Bearer ${getParams('access_token')}`
            : '',
        },
      },
    )
  }

  static async pinCollection(collectionId: string): Promise<AxiosResponse> {
    return axiosInstance.post(
      `/users/collections/${collectionId}/pin`,
      {},
      {
        headers: {
          Authorization: getParams('access_token')
            ? `Bearer ${getParams('access_token')}`
            : '',
        },
      },
    )
  }

  static async unpinCollection(collectionId: string): Promise<AxiosResponse> {
    return axiosInstance.delete(`/users/collections/${collectionId}/pin`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }
}

export default DBarService
