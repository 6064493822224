/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios'
import { AppDispatch } from '../..'
import {
  IProfile,
  IProfileConfigurationNotification,
  IProfileConfigurationPublic,
} from '../../../@types/userProfile'
import AuthService from '../../../api/auth'
import UserService from '../../../api/userProfile'
import { getParams, saveLoginApprove, setParams } from '../auth/authUtils'
import { GetUserAction, UserActionEnum } from './types'

const UserActionCreators = {
  getUserAction: (data: IProfile): GetUserAction => ({
    type: UserActionEnum.GET_USER,
    payload: data,
  }),

  refreshTokenError: (errorStatus: number, cb: any) => {
    return async (dispatch: AppDispatch) => {
      if (errorStatus === 401) {
        try {
          const refreshToken = getParams('refresh_token')
          if (typeof refreshToken === 'string') {
            const { data } = await AuthService.userRefresh(refreshToken)
            setParams(data)
            saveLoginApprove(true)
            cb()(dispatch)
          }
        } catch (error: unknown) {
          console.error(error)
        }
      }
    }
  },

  getUserProfile: () => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await UserService.getProfile()
        dispatch({ type: UserActionEnum.GET_USER_PROFILE, payload: data })
      } catch (error: any) {
        console.error(error)

        const errorStatus = error.request.status
        await UserActionCreators.refreshTokenError(
          errorStatus,
          UserActionCreators.getUserProfile,
        )(dispatch)
      }
    }
  },

  setUserProfileInfo: (object: IProfile) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await UserService.putUserProfileInfo(object)
        dispatch({ type: UserActionEnum.SET_USER_PROFILE_VALUE, payload: data })
      } catch (error: any) {
        console.error(error)

        const errorStatus = error.request.status
        await UserActionCreators.refreshTokenError(
          errorStatus,
          UserActionCreators.getUserProfile,
        )(dispatch)
      }
    }
  },

  setUserProfileConfiguration: (object: IProfileConfigurationPublic) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await UserService.putUserProfileConfiguration(object)
        dispatch({ type: UserActionEnum.SET_USER_PROFILE_VALUE, payload: data })
      } catch (error: any) {
        console.error(error)

        const errorStatus = error.request.status
        await UserActionCreators.refreshTokenError(
          errorStatus,
          UserActionCreators.getUserProfile,
        )(dispatch)
      }
    }
  },

  setUserProfileNotification: (object: IProfileConfigurationNotification) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await UserService.putUserProfileNotification(object)
        dispatch({ type: UserActionEnum.SET_USER_PROFILE_VALUE, payload: data })
      } catch (error: any) {
        console.error(error)

        const errorStatus = error.request.status
        await UserActionCreators.refreshTokenError(
          errorStatus,
          UserActionCreators.getUserProfile,
        )(dispatch)
      }
    }
  },

  setUserImage: (file: File, object: IProfile) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await UserService.uploadImage(file)
        await UserActionCreators.setUserProfileInfo({ ...object, image: data })(
          dispatch,
        )
      } catch (error: any) {
        console.error(error)
        const errorStatus = error.request.status
        await UserActionCreators.refreshTokenError(
          errorStatus,
          UserActionCreators.getUserProfile,
        )(dispatch)
      }
    }
  },

  clearUserProfile: () => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({ type: UserActionEnum.CLEAR_USER_PROFILE })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
}

export default UserActionCreators
