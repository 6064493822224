import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'

import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'
import RoutesPaths from '../../constants/navigate'

export default function DeleteEventModal() {
  const { hideModal, deleteUserEvent } = useActions()
  const { modalHidden } = useTypedSelector(state => state.modal)
  const { eventId } = useTypedSelector(state => state.events)
  const navigate = useNavigate()

  const deleteEvent = () => {
    if (eventId) {
      deleteUserEvent(eventId, () => navigate(`/${RoutesPaths.eventsPages}`))
      hideModal()
    } else {
      hideModal()
      navigate(`/${RoutesPaths.eventsPages}`)
    }
  }

  return (
    <Modal
      open={modalHidden}
      width={328}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      closable={false}
    >
      <div className="deleteEventModalWrapper">
        <div className="deleteEventModalInner">
          <h3>DELETE EVENT</h3>
          <p>
            Are you sure you want to delete the event? This action cannot be
            undone.
          </p>
          <div className="deleteEventModalBtnsWrapper">
            <button onClick={() => hideModal()} type="button">
              cancel
            </button>
            <button onClick={() => deleteEvent()} type="button">
              delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
