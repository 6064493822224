import React from 'react'
import { Link } from 'react-router-dom'

import { IEventsItem } from '../../../../../@types/events'
import PartyHeader from '../../../components/EventHeader'

import RoutesPaths from '../../../../../constants/navigate'
import EventsCard from '../EventsCard'

import './index.scss'

interface ViewAllEventsProps {
  type: 'past' | 'planned'
  events: IEventsItem[]
  goBackFn: (value: boolean) => void
}

function ViewAllEvents({ events, goBackFn, type }: ViewAllEventsProps) {
  return (
    <div className="userEvents">
      <div className="userEventsWrapperEventsPage">
        <PartyHeader
          loadingPercent="100%"
          customFunc={() => goBackFn(false)}
          stepTitle={
            type === 'past'
              ? `Completed events (${events.length})`
              : `Upcoming events (${events.length})`
          }
        />
        <div className="userEventsInner">
          <div className="viewAllEventsWrapper">
            {events.map((item: IEventsItem) => {
              return (
                <Link
                  key={item.id}
                  to={`/${RoutesPaths.partyEventSummaryPage}`.replace(
                    ':id',
                    item.id,
                  )}
                >
                  <EventsCard
                    title={item.name}
                    date={item.plannedAt}
                    guests={item.guestsCount}
                    location={item.location}
                    image={item.image}
                    type={type === 'planned' ? 'UPCOMING' : 'PAST'}
                  />
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewAllEvents
