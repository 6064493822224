/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useTypedSelector from '../../hooks/useTypedSelector'
import useActions from '../../hooks/useActions'
import { api } from '../../api'
import { ICocktailFilter } from '../../@types/cocktails'
import RoutesPaths from '../../constants/navigate'

import { ReactComponent as NotFavorite } from '../../assets/images/svg/not-favorite.svg'
import { ReactComponent as Favorite } from '../../assets/images/svg/favorite.svg'
import { ReactComponent as ArrowBackIcon } from '../UserProfile/images/arrow-button-icon.svg'

import './index.scss'

function IngredientMobilePage() {
  const [localFavorite, setLocalFavorite] = useState<boolean>(false)

  const { ingredient } = useTypedSelector(state => state.ingredients)
  const { popularByIngredientCocktail } = useTypedSelector(
    state => state.cocktails,
  )

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    clearModalIngredient,
    ingredientFavorites,
    deleteIngredientFavorites,
    // getBarIngredients,
    popularByIngredientCocktails,
    getIngredient,
  } = useActions()

  useEffect(() => {
    if (ingredient && ingredient.isFavorite) {
      setLocalFavorite(true)
    }
  }, [ingredient])

  useEffect(() => {
    popularByIngredientCocktails(String(id))
    getIngredient(String(id))
  }, [id])

  useEffect(() => {
    return () => {
      clearModalIngredient()
      // if (pathname === '/dbar') {
      //   getBarIngredients()
      // }
    }
  }, [])

  const onClickFavorites: React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()
    if (!localFavorite && ingredient) {
      ingredientFavorites(ingredient.id)
      setLocalFavorite(true)
    } else if (localFavorite && ingredient) {
      deleteIngredientFavorites(ingredient.id)
      setLocalFavorite(false)
    }
  }

  return (
    ingredient && (
      <div className="ingredientMobilePageWrapper">
        <div className="ingredientMobilePageInner">
          <div className="ingredientMobilePageHeader">
            {window.history.length > 2 && (
              <div className="ingredientMobilePageHeaderBackArrow">
                <ArrowBackIcon
                  onClick={() => {
                    navigate(-1)
                  }}
                />
              </div>
            )}
            <h3>{ingredient.name}</h3>
            <div
              className="ingredientMobilePageHeaderFavorites"
              onClick={onClickFavorites}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onClickFavorites
                }
              }}
              role="button"
              tabIndex={0}
            >
              {localFavorite ? <Favorite /> : <NotFavorite />}
            </div>
          </div>
          <img
            src={`${api()}/images/ingredients/m/${ingredient.image}`}
            className="ingredientMobilePageImage"
            alt={ingredient.name}
          />
          <div className="ingredientMobilePageHashtags">
            {ingredient.categories.map(
              (elem: ICocktailFilter, index: number) => (
                <div
                  className="ingredientMobilePageHashtagsItem"
                  key={Math.random()}
                >
                  <p>#{elem.name}</p>
                </div>
              ),
            )}
            <div className="ingredientMobilePageHashtagsItem">
              <p>{ingredient.abv}°</p>
            </div>
          </div>
          <div className="ingredientMobilePageDescription">
            <p>{ingredient.description}</p>
          </div>
          <div className="ingredientMobilePageCocktailsWrapper">
            <h3>Popular Cocktails</h3>
            <div className="ingredientMobilePageCocktailsInner">
              {popularByIngredientCocktail.map((item: ICocktailFilter) => {
                return (
                  <div
                    className="ingredientMobilePageCocktailsItem"
                    key={Math.random()}
                    onClick={() =>
                      navigate(`/${RoutesPaths.cocktailsPage}/${item.id}`)
                    }
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        navigate(`/${RoutesPaths.cocktailsPage}/${item.id}`)
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <div>
                      <p>{item.name}</p>
                    </div>
                    <img
                      src={`${api()}/images/cocktails/s/${item.image}`}
                      alt={item.name}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default IngredientMobilePage
