import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { EngLocales } from '../../assets/locales/eng'

import NotFoundImage from '../../assets/images/404.png'

import './index.scss'
import { PageTitles } from '../../constants/pageTitles'

function NotFound() {
  useEffect(() => {
    document.title = PageTitles.notFoundPage
  }, [])

  return (
    <div className="notFoundWrapper">
      <div className="notFoundInner">
        <img src={NotFoundImage} alt="NOT FOUND" />
        <div className="notFoundInnerContent">
          <p>{EngLocales.NotFoundPage.notFoundText}</p>
          <Link to="/">{EngLocales.NotFoundPage.btnText}</Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
