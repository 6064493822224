/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as FavoritesIcon } from '../../assets/favorites-icon.svg'
import { ReactComponent as ArrowIcon } from '../../assets/arrow-icon.svg'
import { ReactComponent as AddIcon } from '../../assets/add-icon.svg'

import BarWrapper from '../../BarWrapper'

import './index.scss'
import useActions from '../../../../hooks/useActions'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import { BarState } from '../../../../store/reducers/bar/types'
import RoutesPaths from '../../../../constants/navigate'
import { PageTitles } from '../../../../constants/pageTitles'
import CollectionCard, {
  AddCollectionCard,
  OwnCollectionCard,
} from '../CollectionCard'
import CocktailMediumCard from '../../../../components/CocktailMediumCard'
import { UserState } from '../../../../store/reducers/user/types'
import { MobileDevicesWidth } from '../../../../constants/screensWidth'

function MainCocktailsBarComponent() {
  const {
    showModal,
    getBarCocktails,
    clearBarCocktailsList,
    clearSelectedFilters,
  } = useActions()

  const { cocktailsCollections, barCocktails } = useTypedSelector<BarState>(
    state => state.bar,
  )
  const { userProfile } = useTypedSelector<UserState>(state => state.user)

  const navigate = useNavigate()

  useEffect(() => {
    document.title = PageTitles.myBarPage

    return () => {
      clearBarCocktailsList()
    }
  }, [])

  useEffect(() => {
    clearSelectedFilters()
    getBarCocktails({
      page: 0,
      size: '7',
      selectedFilters: {},
      searchField: '',
      sortValue: '',
      excludeCocktails: [],
      url: 'users/collections/favorites',
    })
  }, [])

  return (
    <BarWrapper openTab="cocktails" secondClass="">
      <div className="mainCocktailsBarComponentWrapper">
        <div className="mainCocktailsBarComponentFavorites">
          <div
            className="mainCocktailsBarComponentFavoritesHeader"
            onClick={() => {
              if (Object.keys(barCocktails.content).length > 0) {
                navigate(`/${RoutesPaths.myBarCocktailsFavorites}`)
              }
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                if (Object.keys(barCocktails.content).length > 0) {
                  navigate(`/${RoutesPaths.myBarCocktailsFavorites}`)
                }
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="mainCocktailsBarComponentFavoritesHeaderContent">
              <FavoritesIcon />
              <div className="mainCocktailsBarComponentFavoritesHeaderText">
                <h5>Favorites</h5>
                <p>{barCocktails.mainPageTotalElements} cocktails</p>
              </div>
            </div>
            <div className="mainCocktailsBarComponentFavoritesHeaderArrow">
              {window.innerWidth <= MobileDevicesWidth && <p>More</p>}
              <ArrowIcon />
            </div>
          </div>
          <div className="mainCocktailsBarComponentFavoritesContent">
            {Object.keys(barCocktails.content).length <= 0 && (
              <div className="mainCocktailsBarComponentCollectionsContentItemWrapper">
                <div
                  className="mainCocktailsBarComponentCollectionsContentAddItem"
                  onClick={() => navigate(`/${RoutesPaths.cocktailsPage}`)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      navigate(`/${RoutesPaths.cocktailsPage}`)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div>
                    <AddIcon />
                  </div>
                </div>
              </div>
            )}
            {Object.entries(barCocktails.content).map(
              ([key, item], index: number) => {
                return (
                  <div
                    className="mainCocktailsBarComponentFavoritesContentItemWrapper"
                    key={Math.random()}
                  >
                    <CocktailMediumCard cocktail={item} />
                  </div>
                )
              },
            )}
          </div>
        </div>
        <div className="mainCocktailsBarComponentCollections">
          <div
            className="mainCocktailsBarComponentCollectionsHeader"
            onClick={() =>
              navigate(`/${RoutesPaths.myBarCocktailsCollections}`)
            }
            onKeyPress={e => {
              if (e.key === 'Enter') {
                navigate(`/${RoutesPaths.myBarCocktailsCollections}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            <h5>My collections</h5>
            <div className="mainCocktailsBarComponentCollectionsHeaderArrow">
              {window.innerWidth <= MobileDevicesWidth && <p>More</p>}
              <ArrowIcon />
            </div>
          </div>
          <div className="mainCocktailsBarComponentCollectionsContent">
            <div className="mainCocktailsBarComponentCollectionsContentItemWrapper">
              <AddCollectionCard
                cb={() => showModal('NewCollectionModal', {})}
              />
            </div>
            <div className="mainCocktailsBarComponentCollectionsContentItemWrapper">
              <OwnCollectionCard
                isPremium={!!(userProfile.plan === 'PREMIUM')}
              />
            </div>
            {Object.entries(cocktailsCollections).map(
              ([key, item], index: number) => {
                return (
                  index <= 2 && (
                    <div
                      className="mainCocktailsBarComponentCollectionsContentItemWrapper"
                      key={Math.random()}
                    >
                      <CollectionCard item={item} />
                    </div>
                  )
                )
              },
            )}
          </div>
        </div>
      </div>
    </BarWrapper>
  )
}

export default MainCocktailsBarComponent
