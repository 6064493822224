import { UserState, UserAction, UserActionEnum } from './types'

const initialState: UserState = {
  userProfile: {
    username: '',
    email: '',
    id: '',
    role: '',
    name: '',
    image: '',
    configuration: {
      publicProfileConfiguration: {
        isAvatarPublic: false,
        isFavoriteCocktailsPublic: false,
        isIngredientsPublic: false,
        isEmailPublic: false,
        isPreferencesPublic: false,
      },
      notificationConfiguration: {
        notificationsPeriod: '',
        isAllowNotification: false,
      },
    },
    payments: [],
    subscriptions: [],
    plan: '',
    location: '',
  },
}

const userReducer = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case UserActionEnum.GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
      }
    case UserActionEnum.SET_USER_PROFILE_VALUE:
      return {
        ...state,
        userProfile: action.payload,
      }
    case UserActionEnum.CLEAR_USER_PROFILE:
      return {
        userProfile: {
          username: '',
          email: '',
          id: '',
          role: '',
          name: '',
          image: '',
          configuration: {
            publicProfileConfiguration: {
              isAvatarPublic: false,
              isFavoriteCocktailsPublic: false,
              isIngredientsPublic: false,
              isEmailPublic: false,
              isPreferencesPublic: false,
            },
            notificationConfiguration: {
              notificationsPeriod: '',
              isAllowNotification: false,
            },
          },
          payments: [],
          subscriptions: [],
          plan: '',
        },
      }
    default:
      return state
  }
}

export default userReducer
