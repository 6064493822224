/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import './index.scss'
import useActions from '../../../../hooks/useActions'
import NextStepButton from '../../components/NextStepButton'

import { ReactComponent as PrivateIcon } from '../../assets/private-party-icon.svg'
import { ReactComponent as FriendIcon } from '../../assets/friend-party-icon.svg'
import { ReactComponent as PublicIcon } from '../../assets/public-party-icon.svg'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'

interface PartyEventTypeProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyEventType({ changeStep, nextStep }: PartyEventTypeProps) {
  const [value, setValue] = useState<'' | 'PRIVATE' | 'EXCLUSIVE' | 'PUBLIC'>(
    '',
  )

  const { updateUserCreateEvent } = useActions()
  const { userCreateEvent } = useTypedSelector(state => state.events)

  useEffect(() => {
    if (userCreateEvent.eventType) {
      setValue(userCreateEvent.eventType)
    }
  }, [])

  const nextStepHandler = () => {
    updateUserCreateEvent(value, 'eventType')
    return changeStep(nextStep)
  }

  return (
    <div className="partyEventTypeWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <EventStepTitle
          title="Type event"
          text="Choose the nature of your event to tailor the experience."
        />
      )}
      <div className="partyEventTypeInner">
        <div
          className={
            value === 'PRIVATE'
              ? 'partyEventTypeInnerItem activeItem'
              : 'partyEventTypeInnerItem'
          }
          onClick={() => setValue('PRIVATE')}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setValue('PRIVATE')
            }
          }}
          role="button"
          tabIndex={0}
        >
          <h5>Private</h5>
          <div className="partyEventTypeInnerItemSvgWrapper">
            <PrivateIcon />
          </div>
        </div>
        <div
          className={
            value === 'EXCLUSIVE'
              ? 'partyEventTypeInnerItem activeItem'
              : 'partyEventTypeInnerItem'
          }
          onClick={() => setValue('EXCLUSIVE')}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setValue('EXCLUSIVE')
            }
          }}
          role="button"
          tabIndex={0}
        >
          <h5>With friends</h5>
          <div className="partyEventTypeInnerItemSvgWrapper">
            <FriendIcon />
          </div>
        </div>
        <div
          className={
            value === 'PUBLIC'
              ? 'partyEventTypeInnerItem activeItem'
              : 'partyEventTypeInnerItem'
          }
          onClick={() => setValue('PUBLIC')}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setValue('PUBLIC')
            }
          }}
          role="button"
          tabIndex={0}
        >
          <h5>Public</h5>
          <div className="partyEventTypeInnerItemSvgWrapper">
            <PublicIcon />
          </div>
        </div>
      </div>
      <NextStepButton handler={nextStepHandler} isActive={!!value} />
    </div>
  )
}

export default PartyEventType
