import { AppDispatch } from '../..'
import AuthService from '../../../api/auth'
import SubscriptionsService from '../../../api/subscriptions'
// import { getParams, saveLoginApprove, setParams } from '../auth/authUtils'
import {
  GetPlansAction,
  GetSubscriptionsAction,
  PostPlanAction,
  SubscriptionsActionEnum,
  SubscriptionsState,
} from './types'

const SubscriptionsActionCreators = {
  getSubscriptionsAction: (
    data: SubscriptionsState,
  ): GetSubscriptionsAction => ({
    type: SubscriptionsActionEnum.GET_SUBSCRIPTIONS,
    payload: data,
  }),

  getPlans: () => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await SubscriptionsService.getPlansCost()
        dispatch({ type: SubscriptionsActionEnum.GET_PLANS, payload: data })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  postPlan: (planId: string, priceId: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await SubscriptionsService.postPlansCost({
          planId,
          priceId,
        })
        dispatch({ type: SubscriptionsActionEnum.POST_PLAN, payload: data })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },

  getDowngradeLink: () => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await SubscriptionsService.downgradePlan()
        dispatch({
          type: SubscriptionsActionEnum.GET_DOWNGRADE_LINK,
          payload: data,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
}

export default SubscriptionsActionCreators
