import { ReactComponent as StepBackIcon } from '../../assets/step-back-icon.svg'
import { ReactComponent as NextStepIcon } from '../../assets/next-step-icon.svg'

import './index.scss'

interface NextStepButtonProps {
  handler: () => void
  isActive: boolean
  btnText?: string
  isArrow?: boolean
}

function NextStepButton({
  handler,
  isActive,
  btnText = 'next step',
  isArrow = true,
}: NextStepButtonProps) {
  return (
    <button
      className="nextStepButton"
      type="button"
      onClick={handler}
      disabled={!isActive}
    >
      <p>{btnText}</p>
      {isArrow && <NextStepIcon />}
    </button>
  )
}

NextStepButton.defaultProps = {
  btnText: 'next step',
  isArrow: true,
}

export default NextStepButton
