import { format, parseISO } from 'date-fns'

import './index.scss'
import { api } from '../../../../api'

import { ReactComponent as CalendarIcon } from '../../assets/summary-calendar-icon.svg'
import { ReactComponent as LocationIcon } from '../../assets/summary-location-icon.svg'
import { ReactComponent as GuestsIcon } from '../../assets/summary-guests-icon.svg'
import { ReactComponent as ChangeEventIcon } from '../../assets/change-event-icon.svg'
import { ReactComponent as DeleteEventIcon } from '../../assets/delete-event-icon.svg'
import { MobileDevicesWidth } from '../../../../constants/screensWidth'

interface PartySummaryHeaderProps {
  partyImage: string
  partyName: string
  partyType: string
  partyDate: string
  partyLocation: string
  partyGuests: number
  openModal: () => void
  isOwner: boolean
  eventStatus: string
  openDetails: () => void
}

export default function EventSummaryHeader({
  partyImage,
  partyDate,
  partyGuests,
  partyLocation,
  partyName,
  partyType,
  openModal,
  isOwner,
  eventStatus,
  openDetails,
}: PartySummaryHeaderProps) {
  const formatDate = (dateString: string) => {
    const date = parseISO(dateString)
    return format(date, 'dd-MM-yyyy, hh:mm a')
  }

  return (
    <div className="partySummaryHeaderWrapper">
      {isOwner && eventStatus !== 'PAST' && (
        <>
          <div className="deleteEventWrapper">
            <DeleteEventIcon onClick={() => openModal()} />
          </div>
          <div className="changeEventWrapper">
            <ChangeEventIcon onClick={() => openDetails()} />
          </div>
        </>
      )}
      <img src={`${api()}/images/events/${partyImage}`} alt={partyName} />
      {window.innerWidth <= MobileDevicesWidth && (
        <div className="partySummaryHeaderTitle">
          <h3>{partyName}</h3>
          <h4>{partyType}</h4>
        </div>
      )}
      <div className="partySummaryHeaderContent">
        {window.innerWidth > MobileDevicesWidth && (
          <div className="partySummaryHeaderTitle">
            <h3>{partyName}</h3>
            <h4>{partyType}</h4>
          </div>
        )}
        <div className="partySummaryHeaderLine" />
        <div className="partySummaryHeaderInfo">
          <div className="partySummaryHeaderDate">
            <CalendarIcon />
            <p>{formatDate(partyDate)}</p>
          </div>
          <div className="partySummaryHeaderLocation">
            <LocationIcon /> <p>{partyLocation}</p>
          </div>
          <div className="partySummaryHeaderGuests">
            <GuestsIcon /> <p>{partyGuests} Guests</p>
          </div>
        </div>
      </div>
    </div>
  )
}
