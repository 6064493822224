import useTypedSelector from '../hooks/useTypedSelector'
import AuthModal from './ModalComponents/AuthModal'

import CocktailModal from './ModalComponents/CocktailModal'
import DeleteCollectionModal from './ModalComponents/DeleteCollectionModal'
import DeleteEventModal from './ModalComponents/DeleteEventModal'
import FavoritesModal from './ModalComponents/FavoritesModal'
import FiltersModal from './ModalComponents/FiltersModal'
import IngredientModal from './ModalComponents/IngredientModal'
import LegalAgeModal from './ModalComponents/LegalAgeModal'
import MapEventModal from './ModalComponents/MapModal'
import NewCollectionModal from './ModalComponents/NewCollectionModal'
import PatchCollectionModal from './ModalComponents/PatchCollectionModal'

import './index.scss'

function ModalContainer() {
  const {
    modalName,
    modalData,
    isCocktailsFilterModal,
    isMixologyFilterModal,
    isMyIngredientFilterModal,
  } = useTypedSelector(state => state.modal)

  let modalComponent = null

  switch (modalName) {
    case 'Cocktail':
      if (modalData.item)
        modalComponent = <CocktailModal item={modalData.item} />
      break
    case 'Ingredient':
      if (modalData.item)
        modalComponent = <IngredientModal item={modalData.item} />
      break
    case 'LegalAge':
      modalComponent = <LegalAgeModal />
      break
    case 'DeleteEvent':
      modalComponent = <DeleteEventModal />
      break
    case 'FiltersModal':
      modalComponent = (
        <FiltersModal
          isCocktailsFilters={isCocktailsFilterModal}
          isMixologyFilters={isMixologyFilterModal}
          isMyIngredientFilters={isMyIngredientFilterModal}
        />
      )
      break
    case 'NewCollectionModal':
      modalComponent = <NewCollectionModal />
      break
    case 'PatchCollectionModal':
      modalComponent = <PatchCollectionModal />
      break
    case 'DeleteCollection':
      modalComponent = <DeleteCollectionModal />
      break
    case 'FavoritesModal':
      if (modalData.favorites)
        modalComponent = <FavoritesModal favorites={modalData.favorites} />
      break
    case 'MapModal':
      modalComponent = <MapEventModal />
      break
    case 'Auth':
      modalComponent = <AuthModal />
      break
    default:
      break
  }
  return <div>{modalComponent}</div>
}

export default ModalContainer
