import { toast } from 'react-toastify'

// export const resetFields = async (
//   form: Record<string, any>,
//   setLoadingApp: (cb: boolean) => void,
// ) => {
//   await setLoadingApp(true)
//   form.resetFields()
//   await setLoadingApp(false)
// }

// export const onFinishFailed = async (
//   values: Record<string, any>,
//   setState: (cb: any) => void,
// ) => {
//   const isFormValid = () =>
//     values.errorFields.some((item: any) => item.errors.length > 0)
//   if (isFormValid()) {
//     setState((prev: any) => ({ ...prev, errorAnimation: true }))
//     return
//   }
// }

export const messagesError = (text: string) => {
  toast.error(`🦄 ${text}!`, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  })
}
