/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useState } from 'react'

import useTypedSelector from '../../../hooks/useTypedSelector'
import useActions from '../../../hooks/useActions'
import { UserState } from '../../../store/reducers/user/types'

// import ProfileSettingsBlock from './ProfileSettingsBlock'
// import SecuritySettingsBlock from './SecuritySettingsBlock'
// import NotificationSettingsBlock from './NotificationSettingsBlock'

import { ReactComponent as ProfileIcon } from '../images/profile-icon.svg'
import { ReactComponent as SecurityIcon } from '../images/security-icon.svg'
import { ReactComponent as NotificationIcon } from '../images/notification-icon.svg'
import { ReactComponent as ArrowButtonIcon } from '../images/arrow-button-icon.svg'

import './index.scss'
import SettingsBlockMobileProfile from './SettingsBlockMobileProfile'
import SettingsBlockMobileSecurity from './SettingsBlockMobileSecurity'
import SettingsBlockMobileNotification from './SettingsBlockMobileNotofication'

interface BlockState {
  profile: boolean
  security: boolean
  notification: boolean
  default: boolean
  [key: string]: boolean
}

type UpdateProfileInfoType = {
  email: string
  name: string
  username: string
  image: File
}

function SettingsBlockMobile() {
  const { userProfile } = useTypedSelector<UserState>(state => state.user)
  const { setUserProfileInfo, setUserImage } = useActions()
  const [whatSettingIsOpen, setWhatSettingIsOpen] = useState<BlockState>({
    profile: false,
    security: false,
    notification: false,
    default: true,
  })

  function setSettingBlockActive(key: keyof BlockState) {
    setWhatSettingIsOpen(prevTabs => {
      const newTabs: BlockState = { ...prevTabs }

      Object.keys(newTabs).forEach(tabKey => {
        if (tabKey === key) {
          newTabs[tabKey] = true
        } else {
          newTabs[tabKey] = false
        }
      })

      return newTabs
    })
  }

  function updateProfileInfo(newData: UpdateProfileInfoType) {
    const { name } = newData

    setUserProfileInfo({
      ...userProfile,
      name,
    })
    setSettingBlockActive('default')
  }

  const memoizedUpdateProfileInfo = useCallback(updateProfileInfo, [])

  return (
    <div className="settingsBlockMobileWrapper">
      {whatSettingIsOpen.default && (
        <div className="settingsBlockMobileDefaultInner">
          <h3 className="settingsBlockMobileTitle">Settings</h3>
          <p className="settingsBlockMobileText">
            {userProfile.name ? userProfile.name : userProfile.username}! Your
            settings profile are displayed here.
          </p>
          <div className="settingsBlockMobileItems">
            <h4>Change user data</h4>
            <div
              className="settingsBlockMobileItem"
              onClick={() => setSettingBlockActive('profile')}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  setSettingBlockActive('profile')
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div className="settingsBlockMobileItemLeftSide">
                <ProfileIcon />
                <div className="settingsBlockMobileItemDescription">
                  <h5>Profile</h5>
                  <p>Picture, Name, Username</p>
                </div>
              </div>
              <ArrowButtonIcon />
            </div>
            <div
              className="settingsBlockMobileItem"
              onClick={() => setSettingBlockActive('security')}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  setSettingBlockActive('security')
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div className="settingsBlockMobileItemLeftSide">
                <SecurityIcon />
                <div className="settingsBlockMobileItemDescription">
                  <h5>Security</h5>
                  <p>Password, Data</p>
                </div>
              </div>
              <ArrowButtonIcon />
            </div>
            <div
              className="settingsBlockMobileItem"
              onClick={() => setSettingBlockActive('notification')}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  setSettingBlockActive('notification')
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div className="settingsBlockMobileItemLeftSide">
                <NotificationIcon />
                <div className="settingsBlockMobileItemDescription">
                  <h5>Notifications</h5>
                  <p>Daily, Weekly, Monthly notifications</p>
                </div>
              </div>
              <ArrowButtonIcon />
            </div>
          </div>
        </div>
      )}
      {whatSettingIsOpen.profile && (
        <SettingsBlockMobileProfile
          profileInfoUsername={
            userProfile?.username ? userProfile.username : ''
          }
          profileInfoEmail={userProfile?.email ? userProfile.email : ''}
          profileInfoName={userProfile?.name ? userProfile.name : ''}
          profileInfoImage={userProfile?.image ? userProfile.image : ''}
          updateProfileInfo={memoizedUpdateProfileInfo}
          backToChoose={() => setSettingBlockActive('default')}
        />
      )}
      {whatSettingIsOpen.security && (
        <SettingsBlockMobileSecurity
          backToChoose={() => setSettingBlockActive('default')}
        />
      )}
      {whatSettingIsOpen.notification && (
        <SettingsBlockMobileNotification
          notificationSettings={
            userProfile?.configuration.notificationConfiguration
          }
          backToChoose={() => setSettingBlockActive('default')}
        />
      )}
    </div>
  )
}

export default SettingsBlockMobile
