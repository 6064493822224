/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

import { api } from '../../../../api'

import { ReactComponent as DefaultUserIcon } from '../../../../assets/images/svg/default-user-icon.svg'

import './index.scss'

interface ProfileSettingsBlockProps {
  profileInfoName: string
  profileInfoEmail: string
  profileInfoUsername: string
  profileInfoImage: string
  updateProfileInfo: (data: Inputs) => void
}

type Inputs = {
  username: string
  email: string
  name: string
  image: File
}

function ProfileSettingsBlock({
  profileInfoUsername,
  profileInfoEmail,
  profileInfoName,
  profileInfoImage,
  updateProfileInfo,
}: ProfileSettingsBlockProps) {
  const [previewImage, setPreviewImage] = useState<string | null>(null)

  const { register, handleSubmit, reset, setValue } = useForm<Inputs>({
    // resolver: yupResolver(schema),
  })

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      // Отображение превью изображения
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result as string)
      }
      reader.readAsDataURL(file)

      // Установка значения для react-hook-form
      setValue('image', file)
    }
  }

  const onSubmit: SubmitHandler<Inputs> = data => {
    const { email, image, name, username } = data
    updateProfileInfo(data)
  }

  const username = register('username')
  const email = register('email')
  const name = register('name')

  return (
    <div className="profileSettingsBlockWrapper">
      <form
        className="profileSettingsBlockInner"
        onSubmit={handleSubmit(onSubmit)}
        name="profileSettings"
      >
        <div className="profileSettingsFormImage">
          <label htmlFor="file-upload" className="custom-file-upload">
            {previewImage ? (
              <img src={previewImage} alt="Preview" />
            ) : (
              <img
                src={`${api()}/images/users/${profileInfoImage}`}
                alt="User"
              />
            )}
          </label>
          <input
            id="file-upload"
            type="file"
            {...register('image')}
            onChange={handleImageChange}
          />
        </div>
        <h4>Your profile data</h4>
        <div className="profileSettingsForm">
          <div className="profileSettingsFormItem">
            <p>Username</p>
            <input
              disabled
              ref={username.ref}
              autoComplete="off"
              name={username.name}
              onBlur={username.onBlur}
              onChange={username.onChange}
              defaultValue={profileInfoUsername}
              placeholder="Enter username"
              type="text"
            />
          </div>
          <div className="profileSettingsFormItem">
            <p>Email</p>
            <input
              disabled
              ref={email.ref}
              autoComplete="off"
              name={email.name}
              onBlur={email.onBlur}
              onChange={email.onChange}
              defaultValue={profileInfoEmail}
              placeholder="Enter Email"
              type="email"
            />
          </div>
          <div className="profileSettingsFormItem">
            <p>Name</p>
            <input
              ref={name.ref}
              autoComplete="off"
              name={name.name}
              onBlur={name.onBlur}
              onChange={name.onChange}
              defaultValue={profileInfoName}
              placeholder="Enter Name"
              type="text"
              maxLength={24}
            />
          </div>
          <button type="submit">save changes</button>
        </div>
        {/* <form></form> */}
      </form>
    </div>
  )
}

export default ProfileSettingsBlock
