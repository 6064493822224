import './index.scss'

interface PlansPageItemProps {
  title: string
  text: string
  image: string
}

function SuccessPageItem({ title, text, image }: PlansPageItemProps) {
  return (
    <div className="successSubItemWrapper">
      <img src={image} alt={`${title}`} />
      <h5>{title}</h5>
      <div className="successSubItemWrapperLine" />
      <p>{text}</p>
    </div>
  )
}

export default SuccessPageItem
