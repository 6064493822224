/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Arrow } from '../assets/arrow.svg'
import { ReactComponent as ArrowDark } from '../assets/unactive-arrow.svg'

import BarWrapper from '../BarWrapper'

import './index.scss'
import IngredientsCard from '../../IngredientsPage/IngredientsCard'
import useActions from '../../../hooks/useActions'
import useTypedSelector from '../../../hooks/useTypedSelector'
import {
  ICocktail,
  ICocktailFilter,
  ICocktailSelectedFilters,
} from '../../../@types/cocktails'
import RoutesPaths from '../../../constants/navigate'

import MixologyItem from './MixologyList/MixologyItem'
import { BarState } from '../../../store/reducers/bar/types'
import Loader from '../../../components/Loader'
import { myIngredientsColumnsCount } from '../../../helpers/myIngredientsPageHelpers'
import { mixologyListColumnsCount } from '../../../helpers/mixologyListPageHelpers'

interface FiltersInterface {
  categories: ICocktailFilter[]
  allergens: ICocktailFilter[]
  selectedFilters: ICocktailSelectedFilters
}

export default function IngredientsBar() {
  const navigate = useNavigate()
  const {
    getFavoriteIngredients,
    clearFavoriteIngredientsList,
    getMixologyList,
    clearMixologyList,
  } = useActions()

  const { barFavoriteIngredients, barMixologyList } =
    useTypedSelector<BarState>(state => state.bar)

  useEffect(() => {
    return () => {
      clearFavoriteIngredientsList()
      clearMixologyList()
    }
  }, [])

  useEffect(() => {
    getFavoriteIngredients({
      page: 0,
      size: '7',
      selectedFilters: {
        ingredient: [],
        flavor: [],
        glass: [],
        isPrimary: [],
        complexity: [],
        strength: [],
        category: [],
        allergen: [],
        ingCategory: [],
        ingAllergen: [],
      },
      searchFields: '',
    })
    getMixologyList({
      page: 0,
      size: '6',
      selectedFilters: {
        ingredient: [],
        flavor: [],
        glass: [],
        isPrimary: [],
        complexity: [],
        strength: [],
        category: [],
        allergen: [],
        ingCategory: [],
        ingAllergen: [],
      },
      searchFields: '',
    })
  }, [])

  useEffect(() => {
    getFavoriteIngredients({
      page: 0,
      size: '7',
      selectedFilters: {
        ingredient: [],
        flavor: [],
        glass: [],
        isPrimary: [],
        complexity: [],
        strength: [],
        category: [],
        allergen: [],
        ingCategory: [],
        ingAllergen: [],
      },
      searchFields: '',
    })
  }, [barFavoriteIngredients.content.length !== myIngredientsColumnsCount()])

  function possibleToMake(item: ICocktail) {
    return item.ingredients
      .filter(ingredient => {
        return !ingredient.isOptional
      })
      .map(ingredient => {
        return ingredient.isFavorite
      })
  }

  return (
    <BarWrapper openTab="ingredients" secondClass="">
      <div className="ingredientsTab">
        <div
          className="tabHeader"
          onClick={() => {
            navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <h1>My ingredients</h1>
          <Arrow />
        </div>
        <div className="tabHeaderMobile">
          <div
            onClick={() => {
              clearFavoriteIngredientsList()
              navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                clearFavoriteIngredientsList()
                navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            <h1>My ingredients</h1>
          </div>
          <div
            onClick={() => {
              clearFavoriteIngredientsList()
              navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                clearFavoriteIngredientsList()
                navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            <h1>more</h1>
            <ArrowDark />
          </div>
        </div>
        <div className="tabItemsContainer">
          {barFavoriteIngredients.content.map(item => (
            <IngredientsCard key={item.id} item={item} />
          ))}
        </div>
      </div>

      <div className="ingredientsTab">
        <div
          className="tabHeader"
          onClick={() => {
            navigate(`/${RoutesPaths.myBarIngredientsMixologyPage}`)
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              navigate(`/${RoutesPaths.myBarIngredientsMixologyPage}`)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <h1>MIxology list</h1>
          <Arrow />
        </div>
        <div className="tabHeaderMobile">
          <div
            onClick={() => {
              clearFavoriteIngredientsList()
              navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                clearFavoriteIngredientsList()
                navigate(`/${RoutesPaths.myBarIngredientsFavoritesPage}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            <h1>MIxology list</h1>
          </div>
          <div
            onClick={() => {
              navigate(`/${RoutesPaths.myBarIngredientsMixologyPage}`)
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                navigate(`/${RoutesPaths.myBarIngredientsMixologyPage}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            <h1>more</h1>
            <ArrowDark />
          </div>
        </div>
        <div className="tabItemsContainer">
          {barMixologyList.content.length === 0 ? (
            <Loader />
          ) : (
            barMixologyList.content.map(item => (
              <MixologyItem
                key={item.id}
                mixologyItem={item}
                possible={possibleToMake(item).every(element => element)}
              />
            ))
          )}
        </div>
      </div>
    </BarWrapper>
  )
}
