import axios, { AxiosResponse } from 'axios'
import axiosInstance, { api } from '..'
import { getParams } from '../../store/reducers/auth/authUtils'
import { IPostPlansCost, ISubscription } from '../../@types/subscriptions'

class SubscriptionsService {
  static async getPlansCost(): Promise<AxiosResponse<ISubscription[]>> {
    return axiosInstance.get('/subscriptions/plans', {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }

  static async postPlansCost(
    object: IPostPlansCost,
  ): Promise<AxiosResponse<string>> {
    return axiosInstance.post('/subscriptions', object, {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }

  static async downgradePlan(): Promise<AxiosResponse<string>> {
    return axiosInstance.get('/subscriptions/stripe-portal', {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }
}

export default SubscriptionsService
