/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from 'react'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import NextStepButton from '../../components/NextStepButton'
import { ReactComponent as TrashIcon } from '../../assets/trash-icon.svg'
import { ReactComponent as AddIcon } from '../../assets/circle-plus.svg'

import { api } from '../../../../api'

import './index.scss'
import { IEventCocktail } from '../../../../@types/events'
import useActions from '../../../../hooks/useActions'
import Loader from '../../../../components/Loader'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'
import CocktailsTitle from '../../components/CocktailsTitle'
import RoutesPaths from '../../../../constants/navigate'

interface CocktailsChangesProps {
  changeStep: (step: number) => void
  nextStep: number
  addStep: number
  isTemplate: boolean
}

function CocktailsChanges({
  changeStep,
  nextStep,
  addStep,
  isTemplate,
}: CocktailsChangesProps) {
  const [loading, setLoading] = useState(false)
  const { eventCocktails, cocktailsPreferences } = useTypedSelector(
    state => state.events,
  )
  const {
    deleteEventCocktail,
    getEventCocktails,
    showModal,
    getModalCocktail,
  } = useActions()

  useEffect(() => {
    if (!eventCocktails.length) {
      getEventCocktails(cocktailsPreferences, setLoading)
    }
  }, [])

  const nextStepHandler = () => {
    return changeStep(nextStep)
  }

  // const deleteCocktail = (id: number) => {
  //   deleteEventCocktail(id)
  // }

  const deleteCocktail = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()
      deleteEventCocktail(id)
    },
    [deleteEventCocktail],
  )

  // const openCocktail = (item: IEventCocktail) => {
  //   if (window.innerWidth > MobileDevicesWidth) {
  //     getModalCocktail(item.cocktail.id.toString())
  //     showModal('Cocktail', { item: item.cocktail })
  //   } else {
  //     window.open(`/${RoutesPaths.cocktailsPage}/${item.cocktail.id}`, '_blank')
  //   }
  // }

  const openCocktail = useCallback(
    (item: IEventCocktail) =>
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        if (window.innerWidth > MobileDevicesWidth) {
          getModalCocktail(item.cocktail.id.toString())
          showModal('Cocktail', { item: item.cocktail })
        } else {
          window.open(
            `/${RoutesPaths.cocktailsPage}/${item.cocktail.id}`,
            '_blank',
          )
        }
      },
    [getModalCocktail, showModal],
  )

  return (
    <div className="cocktailsChangesWrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          {window.innerWidth > MobileDevicesWidth && (
            <CocktailsTitle
              title="Editing events cocktails"
              tooltip="Editing events cocktails"
            />
          )}
          <div className="cocktailsChangesInner">
            {eventCocktails.length ? (
              eventCocktails.map((item: IEventCocktail) => {
                return (
                  <div
                    className="cocktailsChangesInnerItemWrapper"
                    key={item.cocktail.id}
                  >
                    <div
                      className="cocktailsChangesInnerItemInner"
                      onClick={openCocktail(item)}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          openCocktail(item)
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <div
                        className="cocktailsChangesInnerItemDelete"
                        onClick={deleteCocktail(item.cocktail.id)}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            deleteCocktail(item.cocktail.id)
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <TrashIcon />
                      </div>
                      <img
                        src={`${api()}/images/cocktails/m/${item.cocktail.image}`}
                        alt={item.cocktail.name}
                      />
                      <div className="cocktailsChangesInnerItemInnerTitle">
                        <p>{item.cocktail.name}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <p>nothing</p>
            )}
          </div>
          <div className="cocktailsChangesBtnsWrapper">
            <button
              type="button"
              className="cocktailsChangesBtnsAdd"
              onClick={() => changeStep(addStep)}
            >
              <p>add other cocktails</p>
              <AddIcon />
            </button>
            <p className="cocktailsChangesBtnsText">OR</p>
            <NextStepButton handler={nextStepHandler} isActive />
          </div>
        </>
      )}
    </div>
  )
}

export default CocktailsChanges
