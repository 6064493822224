export const EngLocales = {
  HomePage: {
    titleText: 'it’s time for',
    titleHeader: 'cocktail',
    buttonText: 'FIND YOUR RECIPE NOW &#10230;',
  },
  LoginPage: {
    loginPageHeaderText: 'We are glad to see you on Drankly 👋',
    usernameInputText: 'Email Address or Username',
    passwordInputText: 'Password',
    forgotPasswordText: 'Forgot password?',
    submitButtonText: 'LOGIN',
    toRegistrationSpanText: "Don't have an account? ",
    toRegistrationLinkText: 'Create free account',
    anotherLoginText: 'OR login with',
  },
  RegistrationPage: {
    registrationPageHeaderText: 'Welcome to Drankly! Join us today 👋',
    emailInputText: 'Enter email',
    usernameInputText: 'Enter username',
    passwordInputText: 'Password',
    submitButtonText: 'SIGN UP',
    toRegistrationSpanText: 'Already have an account? ',
    toRegistrationLinkText: 'Login',
    anotherLoginText: 'OR sign in with',
  },
  RecoveryPasswordPage: {
    recoveryPageHeaderText: 'Forgot your password? Drop it here.',
    emailInputText: 'Enter email',
    submitButtonText: 'SEND RESET LINK',
    supportLinkText:
      'If you did not receive instructions to reset your password, please check your Spam folder. If you have any problems, please contact our support team at ',
    supportLinkEmail: 'support@drankly.com',
  },
  UpdatePasswordPage: {
    updatePageHeaderText: 'Set a new password',
    newPasswordInputText: 'Enter new password',
    repeatPasswordInputText: 'Repeat new password',
    submitButtonText: 'UPDATE PASSWORD',
  },
  CocktailPage: {
    cocktailPageBlockHeaderFirstTitle: 'INGREDIENTS & tools',
    cocktailPageBlockHeaderSecondTitle: 'step by step recipe',
    cocktailPageBlockHeaderThirdTitle: 'Alternative cocktail',
    cocktailPageBlockHeaderFirstTitleMobile: 'Ingredients',
    cocktailPageBlockHeaderSecondTitleMobile: 'recipe',
    cocktailPageBlockHeaderThirdTitleMobile: 'alternative',
    cocktailPageBlockToolsHeader: 'Optional Tools',
    cocktailPageBlockAllergensHeader: 'Allergen',
    cocktailPageBlockCategoricGlass: 'glass',
    cocktailPageBlockCategoricFlavour: 'flavour',
    cocktailPageBlockCategoricStrength: 'strength',
    cocktailPageBlockCategoricComplexity: 'complexity',
    cocktailPageAlternativeBtn: 'generate alternative cocktails',
    cocktailPageAlternativeTitle: 'alternative cocktail',
    cocktailPageAlternativeNoCocktails: 'Cannot find cocktail analogs',
  },
  CocktailsCard: {
    cocktailIngredients: 'Ingredients:',
    cocktailToolsComplexity: 'Complexity:',
    cocktailToolsStrength: 'Strength:',
    cocktailToolsFlavor: 'Flavor:',
    cocktailToolsGlass: 'Glass:',
  },
  CocktailsModal: {
    cocktailsModalComplexity: 'complexity',
    cocktailsModalStrength: 'strength',
    cocktailsModalFlavor: 'flavour',
    cocktailsModalGlass: 'glass',
    cocktailsModalIngredients: 'Ingredients:',
    moreDetailsButton: 'MORE DETAILS',
  },
  IngredientsModal: {
    ingredientModalPopularCocktailsTitle: 'popular cocktailS',
  },
  LegalAgeModal: {
    legalAgeModalTitle: 'welcome to drankly!',
    legalAgeFirstParaph:
      'This website is intended for individuals who are of legal drinking age. Please ensure that you are of legal drinking age in your country of residence before accessing or using this site.',
    legalAgeSecondParaph:
      'By entering this site, you confirm that you are of legal drinking age and agree to our terms and conditions. If you are not of legal drinking age, please exit this site immediately.',
    legalAgeModalButtonsHeader: 'Are you above legal drinking age?',
    agreeBtnText: 'yes',
    disagreeBtnText: 'no',
    disagreeBtnLink: 'https://google.com/',
  },
  NotFoundPage: {
    notFoundText: 'Looks like the page you were looking for is not found.',
    btnText: 'go home page',
  },
  ContentWrapperSearchPanel: {
    searchInputText: 'search',
    openFiltersBtnText: 'HIDE FILTERS',
    closeFiltersBtnText: 'SHOW FILTERS',
    sortByBtnText: 'SORT BY',
  },
  MyBarPage: {
    myBarNav: {
      cocktails: 'My cocktails',
      ingredients: 'My ingredients',
      recommendation: 'Recommendation',
    },
  },
  PlansPage: {
    FailureSubPage: {
      failureText:
        'We encountered an error confirming your subscription. Please try again. If the issue persists, contact our support team for assistance. Thank you for your patience.',
      btnText: 'try again',
    },
    ChoosePlanItem: {
      fullBenefitsText: 'maximum benefit',
      partBenefitsText: '30% benefit',
      subBtnText: 'subscribe',
    },
    PlansPage: {
      notAuthFirstBtnText: 'LOGIN AND TRY FOR FREE',
      basicProfileFirstBtnText: 'CURRENT PLAN',
      premiumProfileFirstBtnText: 'DOWNGRADE PLAN',
      notAuthSecondBtnText: 'LOGIN AND TRY FOR FREE',
      basicProfileSecondBtnText: 'UPGRADE TO PREMIUM',
      premiumProfileSecondBtnText: 'CURRENT PLAN',
      notAuthHeaderText: "Join Drankly's vibrant mixology community today 🎉",
      basicProfileHeaderText:
        "Join Drankly's vibrant mixology community today 🎉",
      premiumProfileHeaderText:
        "Congratulations! You're already enjoying the benefits of our Premium Subscription.🎉",
      notAuthParaphText:
        'Join our community of cocktail enthusiasts today and give your evenings a special taste',
      basicProfileParaphText:
        'Become a premium member of our community of cocktail enthusiasts and add a special flavor to your evenings.',
      premiumProfileParaphText:
        'Enhance your experience with exclusive features and content',
    },
    SuccessSubPage: {
      successHeaderText: 'Congratulations! You are Now a Drankly Premium! 🎉',
      successSubHeaderText:
        'We are thrilled that you have joined us on this flavorful journey to explore and master the art of cocktail crafting.',
      successBtnText: 'GO TO MY BAR',
    },
  },
  UserProfilePage: {
    userPageNav: {
      userInfo: 'User Info',
      payments: 'Payments',
      publicProfile: 'Public Profile',
      settings: 'Settings',
      logOut: 'Log out',
    },
    paymentsBlock: {
      paymentsBlockHeaderTitle: 'Payment details',
      paymentsBlockHeaderText:
        'Here you can see information about your subscriptions and payments',
      subscriptionsHeaderTitle: 'Subscriptions',
      paymentsHeaderTitle: 'Payments',
    },
    profileBlock: {
      basicProfileBlockTitle: 'Basic Account',
      premiumProfileBlockTitle: 'Premium Account',
      basicProfileBlockText:
        'You have a standard account, but you can upgrade to ',
      premiumProfileBlockText:
        'You have a premium account with access to all the features of Drankly',
    },
    publicProfileBlock: {
      publicProfileBlockHeaderTitle: 'Public Profile',
      publicProfileBlockHeaderBtnText: 'SHOW PUBLIC PROFILE',
      publicProfileBlockHeaderText:
        'Your public profile data and settings are displayed here.',
      settingsBlockTitle: 'SETTINGS PUBLIC PROFILE',
      basicProfileBlurredText: 'Unlock with Premium',
      sharePublicProfileText: 'Share public profile',
    },
    userInfoBlock: {
      userInfoBlockHeaderTitle: 'My Profile',
      userInfoUsernameField: 'User name',
      userInfoEmailField: 'Email',
    },
  },
  UnsubscribeNewsletterPage: {
    unsubscribeNewsletterPageTitle: 'Unsubscribe the drankly newsletter',
    unsubscribeNewsletterPageText:
      "We're sorry to see you go! Thank you for your time with Drankly. If you change your mind, you can always resubscribe at any time.",
    unsubscribeNewsletterPageButtonsTitle:
      'Are you sure you want to unsubscribe?',
  },
}
