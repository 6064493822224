import { FC } from 'react'

import { staticApi } from '../../../../api'
import { EngLocales } from '../../../../assets/locales/eng'
import useActions from '../../../../hooks/useActions'

import './index.scss'
import { ISubscription } from '../../../../@types/subscriptions'

function ChoosePlanItem({
  productId,
  priceId,
  name,
  description,
  image,
  amount,
  currency,
  period,
  paymentUrl,
}: ISubscription) {
  const { postPlan } = useActions()

  function whatPeriod() {
    if (period === 'FOREVER') {
      return (
        <div className="benefitsInner" style={{ background: '#1D2677' }}>
          <p>{EngLocales.PlansPage.ChoosePlanItem.fullBenefitsText}</p>
        </div>
      )
    }
    if (period === 'YEAR') {
      return (
        <div className="benefitsInner" style={{ background: '#433D60' }}>
          <p>{EngLocales.PlansPage.ChoosePlanItem.partBenefitsText}</p>
        </div>
      )
    }

    return null
  }

  return (
    <div className="choosePlanItemWrapper">
      <div className="benefitsWrapper">
        {whatPeriod()}
        <img src={`${staticApi()}/images/plans/${image}`} alt={image} />
      </div>
      <div className="choosePlanItemInner">
        <div className="choosePlanItemInnerTitle">
          <h5>{name}</h5>
        </div>
        <div className="choosePlanItemInnerPrice">
          <p>
            {period === 'FOREVER'
              ? `${amount} ${currency}`
              : `${amount} ${currency} / ${period}`}
          </p>
        </div>
        <div className="choosePlanItemInnerContent">
          <p>{description}</p>
          <button
            onClick={() => {
              if (paymentUrl) {
                window.location.replace(paymentUrl)
              } else {
                postPlan(productId, priceId)
              }
            }}
            type="button"
          >
            {EngLocales.PlansPage.ChoosePlanItem.subBtnText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChoosePlanItem
