import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import SuccessPageItem from './SuccessPageItem'
import FirstCardImage from '../images/first-card-image.png'
import SecondCardImage from '../images/second-card-image.png'
import ThirdCardImage from '../images/third-card-image.png'
import FourthCardImage from '../images/fourth-card-image.png'

import RoutesPaths from '../../../constants/navigate'
import { EngLocales } from '../../../assets/locales/eng'

import './index.scss'
import { MobileDevicesWidth } from '../../../constants/screensWidth'
import { PageTitles } from '../../../constants/pageTitles'

const successCardsArray = [
  {
    title: 'Custom Cocktails Management',
    text: 'Dive into a world of exceptional cocktails handpicked by our experts. Experience the finest drinks that mixology has to offer.',
    image: FirstCardImage,
  },
  {
    title: 'TOP Cocktail Suggestions',
    text: 'Elevate your cocktail game with a steady stream of fresh ideas. Receive daily or weekly cocktail inspirations',
    image: SecondCardImage,
  },
  {
    title: 'Social Sharing Configuration',
    text: 'Share your cocktail creations and mixology adventures with friends and fellow cocktail enthusiasts. Your sips can spark conversations and inspire others!',
    image: ThirdCardImage,
  },
  {
    title: 'unlimited possibilities',
    text: 'Immerse yourself in a world of endless creativity with unlimited access to Cocktails Analogue, Cocktails Search, Favorites, and Suggestions.',
    image: FourthCardImage,
  },
]

function SuccessSub() {
  const navigate = useNavigate()
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  useEffect(() => {
    document.title = PageTitles.plansPage
  }, [])

  return (
    <div className="successSubWrapper">
      <h3>{EngLocales.PlansPage.SuccessSubPage.successHeaderText}</h3>
      <h4>{EngLocales.PlansPage.SuccessSubPage.successSubHeaderText}</h4>
      <div className="successSubContent">
        {window.innerWidth <= MobileDevicesWidth ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Slider {...settings}>
            {successCardsArray.map(item => {
              return (
                <SuccessPageItem
                  key={Math.random()}
                  title={item.title}
                  text={item.text}
                  image={item.image}
                />
              )
            })}
          </Slider>
        ) : (
          successCardsArray.map(item => {
            return (
              <SuccessPageItem
                key={Math.random()}
                title={item.title}
                text={item.text}
                image={item.image}
              />
            )
          })
        )}
      </div>
      <button
        className="successSubNavButton"
        onClick={() => navigate(`/${RoutesPaths.myBarCocktailsPage}`)}
        type="button"
      >
        {EngLocales.PlansPage.SuccessSubPage.successBtnText}
      </button>
    </div>
  )
}

export default SuccessSub
