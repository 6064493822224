/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useActions from '../../../../hooks/useActions'
import NextStepButton from '../../components/NextStepButton'
import { api, staticApi } from '../../../../api'
import useTypedSelector from '../../../../hooks/useTypedSelector'

import { ReactComponent as DeleteImage } from '../../assets/delete-event-image-icon.svg'
import { ReactComponent as AddImage } from '../../assets/add-event-image-icon.svg'
import Loader from '../../../../components/Loader'

import './index.scss'
import { IEventOccasion } from '../../../../@types/events'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'

function PartyPhoto() {
  const [loading, setLoading] = useState<boolean>(false)
  const [isFinish, setIsFinish] = useState<boolean>(true)
  const [previewImage, setPreviewImage] = useState<string | null>(null)
  const [value, setValue] = useState<File>()

  const { uploadEventImage, postUserEvent } = useActions()
  const { userCreateEvent, occasions, eventId } = useTypedSelector(
    state => state.events,
  )
  const navigate = useNavigate()

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result as string)
      }
      reader.readAsDataURL(file)

      setValue(file)
    }
  }

  useEffect(() => {
    if (!isFinish) {
      postUserEvent(
        value ? userCreateEvent : { ...userCreateEvent, image: 'default.png' },
        setLoading,
      )
    }
  }, [isFinish])

  useEffect(() => {
    if (eventId) {
      userCreateEvent.eventType === 'PRIVATE'
        ? navigate(`/events/summary/${eventId}`)
        : navigate(`/events/${eventId}/invites/`)
    }
  }, [eventId])

  const nextStepHandler = () => {
    if (value) {
      uploadEventImage(value, setIsFinish)
    } else {
      setIsFinish(false)
    }
  }

  function isUserEventImage() {
    const occasion: IEventOccasion = occasions.find(
      (item: IEventOccasion) => item.id === userCreateEvent.occasionId,
    ) || {
      description: '',
      id: -1,
      image: '',
      name: '',
    }

    if (userCreateEvent.image) {
      return `${api()}/images/events/${userCreateEvent.image}`
    }
    if (userCreateEvent) {
      if (userCreateEvent.image) {
        return `${api()}/images/events/${userCreateEvent.image}`
      }
      return `${staticApi()}/images/occasions/${occasion.image}`
    }

    return `${staticApi()}/images/occasions/`
  }

  return (
    <div className="partyPhotoWrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          {window.innerWidth > MobileDevicesWidth && (
            <EventStepTitle
              title="Photo of event"
              text="Upload a photo that represents your event. This image will be displayed in the event description and invitations."
            />
          )}
          <div className="partyPhotoInner">
            <div className="partyPhotoInnerDelete">
              <DeleteImage onClick={() => setPreviewImage(null)} />
            </div>
            <div className="partyPhotoInnerImage">
              {previewImage ? (
                <img src={previewImage} alt="Preview" />
              ) : (
                <img src={isUserEventImage()} alt="event pic" />
              )}
            </div>
            <div className="partyPhotoInnerInput">
              <label htmlFor="file-upload" className="custom-file-upload">
                <AddImage />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleImageChange}
              />
            </div>
          </div>
          <NextStepButton handler={nextStepHandler} isActive btnText="Finish" />
        </>
      )}
    </div>
  )
}

export default PartyPhoto
