import {
  SubscriptionsState,
  SubscriptionsAction,
  SubscriptionsActionEnum,
} from './types'

const initialState: SubscriptionsState = {
  subscriptions: [],
  stripeLink: '',
  downgradeLink: '',
}

const subscriptionsReducer = (
  state = initialState,
  action: SubscriptionsAction,
) => {
  switch (action.type) {
    case SubscriptionsActionEnum.GET_PLANS:
      return {
        ...state,
        subscriptions: action.payload,
      }
    case SubscriptionsActionEnum.POST_PLAN:
      return {
        ...state,
        stripeLink: action.payload,
      }
    case SubscriptionsActionEnum.GET_DOWNGRADE_LINK:
      return {
        ...state,
        downgradeLink: action.payload,
      }
    default:
      return state
  }
}

export default subscriptionsReducer
