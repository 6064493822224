import {
  ICocktail,
  ICocktailFilter,
  ICocktailsBarList,
  ICocktailsList,
} from '../../../@types/cocktails'
import { IIngredient, IIngredientsList } from '../../../@types/ingredients'
import { IBarCocktailsCollection } from '../../../@types/userBar'

export interface BarState {
  barFavoriteIngredients: {
    content: IIngredient[]
    totalPages: number
    number: number
    ingredient: IIngredient | null
    totalElements: number
    modalIngredient: IIngredient | null
    searchField: string
  }
  barMixologyList: {
    content: ICocktail[]
    totalPages: number
    number: number
    totalElements: number
    cocktail: ICocktail | null
    analogs: ICocktail[]
    modalCocktail: ICocktail | null
    searchField: string
    popularByIngredientCocktail: ICocktailFilter[]
  }
  cocktailsCollections: Record<string, IBarCocktailsCollection>
  collectionImage: string
  selectedCollection: IBarCocktailsCollection
  barCocktails: {
    content: Record<string, ICocktail>
    totalPages: number
    number: number
    totalElements: number
    cocktail: ICocktail | null
    modalCocktail: ICocktail | null
    searchField: string
    mainPageTotalElements: number
  }
}

export enum BarActionEnum {
  GET_MIXOLOGY_LIST = 'GET_MIXOLOGY_LIST',
  GET_MIXOLOGY_LIST_BY_FILTER_ACTION = 'GET_MIXOLOGY_LIST_BY_FILTER_ACTION',
  GET_MIXOLOGY_LIST_SEARCH = 'GET_MIXOLOGY_LIST_SEARCH',
  CLEAR_MIXOLOGY_LIST = 'CLEAR_MIXOLOGY_LIST_',
  GET_FAVORITES_INGREDIENTS = 'GET_FAVORITES_INGREDIENTS',
  GET_FAVORITES_INGREDIENTS_BY_FILTER_ACTION = 'GET_FAVORITES_INGREDIENTS_BY_FILTER_ACTION',
  GET_FAVORITES_INGREDIENTS_SEARCH = 'GET_FAVORITES_INGREDIENTS_SEARCH',
  CLEAR_FAVORITES_INGREDIENTS_LIST = 'CLEAR_FAVORITES_INGREDIENTS_LIST',
  FAVORITES_INGREDIENT = 'FAVORITES_INGREDIENT',
  CREATE_COCKTAIL_COLLECTION = 'CREATE_COCKTAIL_COLLECTION',
  UPLOAD_COLLECTION_IMAGE = 'UPLOAD_COLLECTION_IMAGE',
  CLEAR_COLLECTION_IMAGE = 'CLEAR_COLLECTION_IMAGE',
  GET_BAR_COCKTAILS = 'GET_BAR_COCKTAILS',
  GET_BAR_COCKTAILS_MAIN_PAGE = 'GET_BAR_COCKTAILS_MAIN_PAGE',
  GET_BAR_COCKTAILS_BY_FILTER_ACTION = 'GET_BAR_COCKTAILS_BY_FILTER_ACTION',
  CLEAR_BAR_COCKTAIL_LIST = 'CLEAR_BAR_COCKTAIL_LIST',
  GET_BAR_COCKTAILS_SEARCH = 'GET_COCKTAILS_SEARCH',
  GET_BAR_COLLECTIONS = 'GET_BAR_COLLECTIONS',
  SET_SELECTED_COLLECTION = 'SET_SELECTED_COLLECTION',
  PATCH_COCKTAILS_COLLECTION = 'PATCH_COCKTAILS_COLLECTION',
  DELETE_COCKTAILS_COLLECTION = 'DELETE_COCKTAILS_COLLECTION',
  PIN_COCKTAILS_COLLECTION = 'PIN_COCKTAILS_COLLECTION',
  UNPIN_COCKTAILS_COLLECTION = 'UNPIN_COCKTAILS_COLLECTION',
  BAR_FAVORITES_COCKTAIL = 'BAR_FAVORITES_COCKTAIL',
}

export interface GetFavoritesIngredientsBarAction {
  type: BarActionEnum.GET_FAVORITES_INGREDIENTS
  payload: IIngredientsList
}

export interface GetFavoritesIngredientsSearchBarAction {
  type: BarActionEnum.GET_FAVORITES_INGREDIENTS_SEARCH
  payload: IIngredientsList
}

export interface GetFavoritesIngredientsByFilterBarAction {
  type: BarActionEnum.GET_FAVORITES_INGREDIENTS_BY_FILTER_ACTION
  payload: IIngredientsList
}

export interface ClearFavoritesIngredientsListBarAction {
  type: BarActionEnum.CLEAR_FAVORITES_INGREDIENTS_LIST
}

export interface FavoritesIngredientAction {
  type: BarActionEnum.FAVORITES_INGREDIENT
  payload: number
}

export interface GetMixologyListBarAction {
  type: BarActionEnum.GET_MIXOLOGY_LIST
  payload: ICocktailsList
}

export interface GetMixologyListSearchBarAction {
  type: BarActionEnum.GET_MIXOLOGY_LIST_SEARCH
  payload: ICocktailsList
}

export interface GetMixologyListByFilterBarAction {
  type: BarActionEnum.GET_MIXOLOGY_LIST_BY_FILTER_ACTION
  payload: ICocktailsList
}

export interface ClearMixologyListListBarAction {
  type: BarActionEnum.CLEAR_MIXOLOGY_LIST
}

export interface CreateCocktailsCollectionAction {
  type: BarActionEnum.CREATE_COCKTAIL_COLLECTION
  payload: IBarCocktailsCollection
}

export interface UploadCollectionImageAction {
  type: BarActionEnum.UPLOAD_COLLECTION_IMAGE
  payload: string
}

export interface ClearCollectionImageAction {
  type: BarActionEnum.CLEAR_COLLECTION_IMAGE
}

export interface GetBarCocktailsAction {
  type: BarActionEnum.GET_BAR_COCKTAILS
  payload: ICocktailsBarList
}

export interface GetBarCocktailsMainPageAction {
  type: BarActionEnum.GET_BAR_COCKTAILS_MAIN_PAGE
  payload: ICocktailsBarList
}

export interface GetCocktailsSearchAction {
  type: BarActionEnum.GET_BAR_COCKTAILS_SEARCH
  payload: ICocktailsBarList
}

export interface GetCocktailsByFilterAction {
  type: BarActionEnum.GET_BAR_COCKTAILS_BY_FILTER_ACTION
  payload: ICocktailsBarList
}

export interface ClearCocktailsListAction {
  type: BarActionEnum.CLEAR_BAR_COCKTAIL_LIST
}

export interface GetBarCollectionsAction {
  type: BarActionEnum.GET_BAR_COLLECTIONS
  // payload: IBarCocktailsCollection[]
  payload: Record<string, IBarCocktailsCollection>
}

export interface SetSelectedCollectionAction {
  type: BarActionEnum.SET_SELECTED_COLLECTION
  payload: IBarCocktailsCollection
}

export interface PatchCocktailsCollectionAction {
  type: BarActionEnum.PATCH_COCKTAILS_COLLECTION
  payload: {
    id: string
    name?: string
    image?: string
    isPinned?: boolean
  }
}

export interface DeleteCocktailsCollectionAction {
  type: BarActionEnum.DELETE_COCKTAILS_COLLECTION
  payload: string
}

export interface PinCocktailsCollectionAction {
  type: BarActionEnum.PIN_COCKTAILS_COLLECTION
  payload: string
}

export interface UnpinCocktailsCollectionAction {
  type: BarActionEnum.UNPIN_COCKTAILS_COLLECTION
  payload: string
}

export interface BarFavoritesCocktailAction {
  type: BarActionEnum.BAR_FAVORITES_COCKTAIL
  payload: {
    id: string
    isFavorite: boolean
    collections: IBarCocktailsCollection[]
  }
}

export type BarAction =
  | CreateCocktailsCollectionAction
  | UploadCollectionImageAction
  | ClearCollectionImageAction
  | GetBarCocktailsAction
  | GetCocktailsSearchAction
  | GetCocktailsByFilterAction
  | ClearCocktailsListAction
  | GetBarCollectionsAction
  | SetSelectedCollectionAction
  | PatchCocktailsCollectionAction
  | DeleteCocktailsCollectionAction
  | PinCocktailsCollectionAction
  | UnpinCocktailsCollectionAction
  | BarFavoritesCocktailAction
  | GetFavoritesIngredientsBarAction
  | GetFavoritesIngredientsSearchBarAction
  | GetFavoritesIngredientsByFilterBarAction
  | ClearFavoritesIngredientsListBarAction
  | FavoritesIngredientAction
  | GetMixologyListBarAction
  | GetMixologyListSearchBarAction
  | GetMixologyListByFilterBarAction
  | ClearMixologyListListBarAction
  | GetBarCocktailsMainPageAction
