export const PageTitles = {
  mainPage: 'Drankly: The Ultimate Cocktail Companion',
  cocktailsPage: 'Cocktails | Drankly',
  ingredientsPage: 'Ingredients | Drankly',
  myBarPage: 'My Bar | Drankly',
  userProfilePage: 'Profile | Drankly',
  plansPage: 'Plans | Drankly',
  loginPage: 'Login | Drankly',
  notFoundPage: '404 | Drankly',
}
