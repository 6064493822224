/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  Popup,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'

const DefaultIcon = L.icon({
  iconRetinaUrl:
    'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41], // Размер иконки
  iconAnchor: [12, 20], // Центр иконки
})

L.Marker.prototype.options.icon = DefaultIcon

const points = [
  {
    lat: 54.881978896737515,
    lng: 27.56156712770462,
    label: '1',
  },
  {
    lat: 53.881978896737515,
    lng: 287.56156712770462,
    label: '2',
  },
  {
    lat: 55.881978896737515,
    lng: 27.56156712770462,
    label: '3',
  },
  {
    lat: 55.881978896737515,
    lng: 28.56156712770462,
    label: '4',
  },
]

const reverseGeocode = async (lat: number, lon: number): Promise<string> => {
  const response = await fetch(
    `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&addressdetails=1`,
  )
  const data = await response.json()
  if (data && data.address) {
    const { road, city, state, country, county, town, house_number } =
      data.address
    return `${house_number ? house_number + ', ' : ''}${road ? road + ', ' : ''}${town ? town + ', ' : ''}${city ? city + ', ' : ''}${state ? state + ', ' : ''}${country}`
  } else {
    return 'Address not found'
  }
}

interface LocationMarkerProps {
  onLocationSelect: (location: string) => void
}

function LocationMarker({ onLocationSelect }: LocationMarkerProps) {
  const [position, setPosition] = useState<L.LatLng | null>(null)

  useMapEvents({
    click: async e => {
      const { lat, lng } = e.latlng
      setPosition(e.latlng)
      const address = await reverseGeocode(lat, lng)
      onLocationSelect(address)
    },
  })

  return position === null ? null : <Marker position={position} />
}

export default function MapEventModal() {
  const [userLocation, setUserLocation] = useState<[number, number] | null>(
    null,
  )
  const { modalHidden } = useTypedSelector(state => state.modal)
  const [location, setLocation] = useState<string>('')
  const mapRef = useRef<L.Map | null>(null)

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize()
    }
  }, [])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setUserLocation([position.coords.latitude, position.coords.longitude])
      })
    }
  }, [])

  useEffect(() => {
    console.log(location)
  }, [location])

  const eventHandlers = useMemo(
    () => ({
      click() {
        alert(userLocation)
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <Modal
      open={modalHidden}
      width={800}
      // onOk={() => hideModal()}
      // onCancel={() => hideModal()}
      closable={false}
    >
      {userLocation && (
        <MapContainer
          center={userLocation}
          zoom={15}
          style={{ height: '400px', width: '800px' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <LocationMarker onLocationSelect={setLocation} />
          {/* <Marker position={userLocation} eventHandlers={eventHandlers}>
            <Popup>Вы здесь</Popup>
          </Marker> */}
        </MapContainer>
      )}
    </Modal>
  )
}
