import './index.scss'

interface PopularCocktailsItemProps {
  id: number
  name: string
  image: string
  navigate: () => void
  hideModal: () => void
}

function PopularCocktailsItem({
  id,
  name,
  image,
  navigate,
  hideModal,
}: PopularCocktailsItemProps) {
  return (
    <div
      key={id}
      className="popularCocktailsItem"
      onClick={() => {
        navigate()
        hideModal()
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          navigate()
          hideModal()
        }
      }}
      role="button"
      tabIndex={0}
    >
      <p>{name}</p>
      <img src={image} alt={name} />
    </div>
  )
}

export default PopularCocktailsItem
