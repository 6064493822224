import BarWrapper from '../../BarWrapper'
import CollectionWrapper from '../CollectionWrapper'

import FavoritesImage from '../../assets/favorites-image.jpg'

function FavoritesCocktails() {
  return (
    <BarWrapper openTab="cocktails" secondClass="mediumCockInnerHeight">
      <CollectionWrapper
        isFavorite
        headerTitle="Favorites"
        requestUrl="users/collections/favorites"
        imageUrl={FavoritesImage}
      />
    </BarWrapper>
  )
}

export default FavoritesCocktails
