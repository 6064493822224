import { useState } from 'react'
import AuthorizationComponent from './AuthorizationComponent'
import './index.scss'
import PasswordRecoveryComponent from './PasswordRecovery'
import VerifyComponent from './AuthorizationComponent/VerifyComponent'
import { ReactComponent as Close } from './assets/Close.svg'
import useActions from '../../hooks/useActions'

export default function Authorization() {
  const [state, setState] = useState({
    auth: true,
    verify: false,
    password: false,
    email: '',
  })
  const { hideModal } = useActions()

  return (
    <div className="authWrapper">
      <div className="closeAuth" onClick={hideModal}>
        <Close />
      </div>

      {state.auth && (
        <AuthorizationComponent
          forgotPassword={() =>
            setState({ ...state, auth: false, password: true })
          }
          verifyEmail={(email: string) =>
            setState({ ...state, auth: false, verify: true, email })
          }
        />
      )}
      {state.password && <PasswordRecoveryComponent />}
      {state.verify && <VerifyComponent email={state.email} />}
    </div>
  )
}
