/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useState } from 'react'

import NoObject from '../../../../assets/images/NoObjectCocktail.webp'
import useActions from '../../../../hooks/useActions'

interface AlternativeCocktailsItemProps {
  imageSource: string
  description: string
  id: string
}

function AlternativeCocktailsItem({
  imageSource,
  description,
  id,
}: AlternativeCocktailsItemProps) {
  const { getModalCocktail, showModal } = useActions()

  const onClick = useCallback(() => {
    getModalCocktail(`${id}`)
    showModal('Cocktail', { item: { id, description, image: imageSource } })
  }, [id])

  return (
    <div
      className="alternativeCocktailsItem"
      onClick={onClick}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onClick
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="alternativeCocktailsItemText">
        <p>{description}</p>
      </div>
      <img src={imageSource} alt={description} />
    </div>
  )
}

export default AlternativeCocktailsItem
