/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import { debounce } from 'lodash'

import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'
import { api } from '../../api'

import { ReactComponent as AddIcon } from '../../page/MyBar/assets/add-icon.svg'
import FavoritesImage from '../../page/MyBar/assets/favorites-image.jpg'
import { IModalDataFavorites } from '../../@types/modals'
import { IBarCocktailsCollection } from '../../@types/userBar'
import { BarState } from '../../store/reducers/bar/types'
import { MobileDevicesWidth } from '../../constants/screensWidth'

interface FavoritesModalProps {
  favorites: IModalDataFavorites
}

export default function FavoritesModal({ favorites }: FavoritesModalProps) {
  const [selectedCollections, setSelectedCollections] = useState<
    IBarCocktailsCollection[]
  >([])
  const [isFavoriteCollection, setIsFavoriteCollection] =
    useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isPlaceholderHidden, setIsPlaceholderHidden] = useState<boolean>(false)

  const {
    hideModal,
    getBarCocktailsCollections,
    cocktailFavorites,
    createCocktailsCollection,
  } = useActions()
  const { modalHidden } = useTypedSelector(state => state.modal)
  const { cocktailsCollections } = useTypedSelector<BarState>(
    state => state.bar,
  )
  const classNames = ['favoritesModalInnerHeaderSearch']

  const navigate = useNavigate()

  if (isPlaceholderHidden) {
    classNames.push('hidePlaceholder')
  }

  const classString = classNames.join(' ')

  const debouncedOnChange = debounce((value: string) => {
    setSearchValue(value)
  }, 200)

  const handleFocus = () => {
    setIsPlaceholderHidden(true)
  }

  const handleBlur = () => {
    if (searchValue === '') {
      setIsPlaceholderHidden(false)
    }
  }

  const pushSelectedCollections = (item: IBarCocktailsCollection) => {
    setSelectedCollections((prev: IBarCocktailsCollection[]) => {
      const exists = prev.some(collection => collection.id === item.id)

      if (exists) {
        return prev.filter(collection => collection.id !== item.id)
      }

      return [...prev, item]
    })
  }

  useEffect(() => {
    getBarCocktailsCollections(searchValue)
  }, [searchValue])

  useEffect(() => {
    setSelectedCollections(favorites.collections)
  }, [])

  const onSubmit = () => {
    cocktailFavorites(
      favorites.id.toString(),
      isFavoriteCollection,
      selectedCollections,
    )
    hideModal()
  }

  const onCreateCollectionSubmit = () => {
    cocktailFavorites(
      favorites.id.toString(),
      isFavoriteCollection,
      selectedCollections,
    )
    createCocktailsCollection(
      `${favorites.cocktailName}`,
      'default.png',
      [+favorites.id],
      navigate,
    )
    hideModal()
  }

  function mobileStyles() {
    if (window.innerWidth > MobileDevicesWidth) {
      return {}
    }

    return { top: 'calc(100vh - 436px)', paddingBottom: 0, margin: 0 }
  }

  return (
    <Modal
      centered={window.innerWidth > MobileDevicesWidth}
      open={modalHidden}
      width={window.innerWidth > MobileDevicesWidth ? 448 : '100%'}
      closable={false}
      zIndex={1051}
      style={mobileStyles()}
    >
      <div className="favoritesModalWrapper">
        <div className="favoritesModalInner">
          <div className="favoritesModalInnerHeader">
            <h3>ADD TO COLLECTION</h3>
            <div className={classString}>
              <input
                className="favoritesModalInnerHeaderSearchInput"
                type="text"
                placeholder={isPlaceholderHidden ? '' : 'Search collections'}
                onChange={event => debouncedOnChange(event.target.value.trim())}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
            <div
              className="favoritesModalInnerContentItem"
              onClick={() => onCreateCollectionSubmit()}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onCreateCollectionSubmit()
                }
              }}
              role="button"
              tabIndex={0}
            >
              <div className="favoritesModalInnerContentItemAdd">
                <AddIcon />
              </div>
              <p>Create a new collection</p>
            </div>
          </div>
          <div className="favoritesModalInnerContent">
            <div
              className={`favoritesModalInnerContentItem ${isFavoriteCollection && 'activeCollection'}`}
              onClick={() => setIsFavoriteCollection((prev: boolean) => !prev)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  setIsFavoriteCollection((prev: boolean) => !prev)
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img src={FavoritesImage} alt="favorites" />
              <p>Favorites</p>
            </div>
            {Object.entries(cocktailsCollections).map(([key, item]) => {
              const isActive = selectedCollections.some(
                collection => collection.id === item.id,
              )
              return (
                <div
                  key={key}
                  className={`favoritesModalInnerContentItem ${isActive && 'activeCollection'}`}
                  onClick={() => pushSelectedCollections(item)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      pushSelectedCollections(item)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <img
                    src={`${api()}/images/collections/${item.image}`}
                    alt={item.name || 'Default Alt'}
                  />
                  <p>{item.name}</p>
                </div>
              )
            })}
          </div>
          <div className="favoritesModalInnerButtons">
            <button type="button" onClick={() => hideModal()}>
              CANcel
            </button>
            <button type="button" onClick={() => onSubmit()}>
              SAVE
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
