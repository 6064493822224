/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import './index.scss'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import { FiltersState } from '../../../../store/reducers/filters/types'
import NextStepButton from '../../components/NextStepButton'
import { ICocktailFilter } from '../../../../@types/cocktails'
import useActions from '../../../../hooks/useActions'
import { staticApi } from '../../../../api'

interface PartyScratchFlavorsProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyScratchFlavors({
  changeStep,
  nextStep,
}: PartyScratchFlavorsProps) {
  const [selectedFlavors, setSelectedFlavors] = useState<number[]>([])

  const { flavors } = useTypedSelector<FiltersState>(state => state.filter)
  const { cocktailsPreferences } = useTypedSelector(state => state.events)
  const { updateEventCocktailsPreferences } = useActions()

  useEffect(() => {
    if (cocktailsPreferences.flavors) {
      setSelectedFlavors(cocktailsPreferences.flavors)
    }
  }, [])

  const nextStepHandler = () => {
    updateEventCocktailsPreferences(selectedFlavors, 'flavors')
    return changeStep(nextStep)
  }

  const pushSelectedFlavors = (id: number) => {
    setSelectedFlavors((prev: number[]) => {
      if (prev.includes(id)) {
        return prev.filter(flavorId => flavorId !== id)
      }
      return [...prev, id]
    })
  }

  return (
    <div className="partyScratchFlavorsWrapper">
      <div className="partyScratchFlavorsInner">
        {flavors.map((item: ICocktailFilter) => {
          const isActive = selectedFlavors.includes(+item.id)
          return (
            <div
              key={item.id}
              className={`partyScratchFlavorsInnerItem ${isActive ? 'activeFlavor' : ''}`}
              onClick={() => pushSelectedFlavors(+item.id)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  pushSelectedFlavors(+item.id)
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img
                src={`${staticApi()}/images/flavors/${item.image}`}
                alt={item.name}
              />
              <p>{item.name}</p>
            </div>
          )
        })}
      </div>
      <NextStepButton
        handler={nextStepHandler}
        isActive={!!selectedFlavors.length}
      />
    </div>
  )
}

export default PartyScratchFlavors
