/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

import useTypedSelector from '../../../hooks/useTypedSelector'
import useActions from '../../../hooks/useActions'

import RoutesPaths from '../../../constants/navigate'
import { EngLocales } from '../../../assets/locales/eng'

import PlanCard from './PlanCard'

import BasicPlanImage from '../images/basic-plan-image.jpg'
import PremiumPlanImage from '../images/premium-plan-image.jpg'

import { ReactComponent as PremiumPlanIcon } from '../images/premium-plan-icon.svg'
import { ReactComponent as BasicPlanIcon } from '../images/basic-plan-icon.svg'

import './index.scss'

import { MobileDevicesWidth } from '../../../constants/screensWidth'
import PlanCardMobile from './PlanCardMobile'
import { UserState } from '../../../store/reducers/user/types'
import { PageTitles } from '../../../constants/pageTitles'

const basicPlanList = [
  'More than 1000 cocktails',
  'Inventory Management',
  'Recipe Management',
  'Drink Suggestions',
  'Favorites and Collections',
  'Social Sharing',
  'Cocktail analogues',
]

const premiumPlanList = [
  'Basic Plan features',
  'Custom Cocktail Management',
  'Unlimited Cocktail analogs',
  'Unlimited Cocktail search',
  'Unlimited Favorites',
  'Unlimited Suggestions',
  'Unlimited Inventory Management',
  'TOP Cocktail Suggestions',
  'Daily/Weekly Recommendations',
  'Social Sharing Configuration',
]

function PlansPage() {
  const navigate = useNavigate()
  const { userProfile } = useTypedSelector<UserState>(state => state.user)
  const { plan } = userProfile
  const { downgradeLink } = useTypedSelector(state => state.subscriptions)
  const { getDowngradeLink } = useActions()

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    document.title = PageTitles.plansPage
  }, [])

  useEffect(() => {
    if (plan && plan !== 'BASIC') {
      getDowngradeLink()
    }
  }, [plan])

  function planCardBtnText(
    value: string,
    noValueText: string,
    basicValueText: string,
    defaultText: string,
  ) {
    if (!value) {
      return noValueText
    }
    if (value === 'BASIC') {
      return basicValueText
    }

    return defaultText
  }

  return (
    <div className="plansPageWrapper">
      <div className="plansPageInner">
        {window.innerWidth > MobileDevicesWidth && (
          <>
            <PlanCard
              image={BasicPlanImage}
              title={
                <>
                  <h5>free BASIC</h5>
                  <BasicPlanIcon />
                </>
              }
              list={basicPlanList}
              btnText={planCardBtnText(
                plan,
                EngLocales.PlansPage.PlansPage.notAuthFirstBtnText,
                EngLocales.PlansPage.PlansPage.basicProfileFirstBtnText,
                EngLocales.PlansPage.PlansPage.premiumProfileFirstBtnText,
              )}
              btnAction={() => {
                if (!plan) {
                  navigate(`/${RoutesPaths.loginPage}`)
                } else if (plan && plan !== 'BASIC') {
                  // window.location.replace(downgradeLink)
                  window.open(downgradeLink)
                }
              }}
              type="basic"
            />
            <PlanCard
              image={PremiumPlanImage}
              title={
                <>
                  <h5>premium</h5>
                  <PremiumPlanIcon />
                  <h5>from 4,19 $</h5>
                </>
              }
              list={premiumPlanList}
              btnText={planCardBtnText(
                plan,
                EngLocales.PlansPage.PlansPage.notAuthSecondBtnText,
                EngLocales.PlansPage.PlansPage.basicProfileSecondBtnText,
                EngLocales.PlansPage.PlansPage.premiumProfileSecondBtnText,
              )}
              btnAction={() => {
                if (!plan) {
                  navigate(`/${RoutesPaths.loginPage}`)
                } else if (plan === 'BASIC') {
                  navigate(`/${RoutesPaths.plansSubscriptionsPage}`)
                }
              }}
              type="premium"
            />
            <div className="plansPageInnerTextBlock">
              <h4>
                {planCardBtnText(
                  plan,
                  EngLocales.PlansPage.PlansPage.notAuthHeaderText,
                  EngLocales.PlansPage.PlansPage.basicProfileHeaderText,
                  EngLocales.PlansPage.PlansPage.premiumProfileHeaderText,
                )}
              </h4>
              <p>
                {planCardBtnText(
                  plan,
                  EngLocales.PlansPage.PlansPage.notAuthParaphText,
                  EngLocales.PlansPage.PlansPage.basicProfileParaphText,
                  EngLocales.PlansPage.PlansPage.premiumProfileParaphText,
                )}
              </p>
            </div>
          </>
        )}
        {window.innerWidth <= MobileDevicesWidth && (
          <div className="plansPageInnerContent">
            <Slider {...settings}>
              <PlanCardMobile
                image={BasicPlanImage}
                title={
                  <>
                    <h5>free BASIC</h5>
                    <BasicPlanIcon />
                  </>
                }
                list={basicPlanList}
                btnText={planCardBtnText(
                  plan,
                  EngLocales.PlansPage.PlansPage.notAuthFirstBtnText,
                  EngLocales.PlansPage.PlansPage.basicProfileFirstBtnText,
                  EngLocales.PlansPage.PlansPage.premiumProfileFirstBtnText,
                )}
                btnBg="linear-gradient(90deg, #242426 0%, #433d60 100%)"
                btnAction={() => {
                  if (!plan) {
                    navigate(`/${RoutesPaths.loginPage}`)
                  } else if (plan && plan !== 'BASIC') {
                    // window.location.replace(downgradeLink)
                    window.open(downgradeLink)
                  }
                }}
                type="basic"
              />
              <PlanCardMobile
                image={PremiumPlanImage}
                title={
                  <>
                    <h5>premium</h5>
                    <PremiumPlanIcon />
                    <h5>from 4,19 $</h5>
                  </>
                }
                list={premiumPlanList}
                btnText={planCardBtnText(
                  plan,
                  EngLocales.PlansPage.PlansPage.notAuthSecondBtnText,
                  EngLocales.PlansPage.PlansPage.basicProfileSecondBtnText,
                  EngLocales.PlansPage.PlansPage.premiumProfileSecondBtnText,
                )}
                btnBg="linear-gradient(90deg, #433D60 0%, rgba(120, 30, 152, 0.6) 100%)"
                btnAction={() => {
                  if (!plan) {
                    navigate(`/${RoutesPaths.loginPage}`)
                  } else if (plan === 'BASIC') {
                    navigate(`/${RoutesPaths.plansSubscriptionsPage}`)
                  }
                }}
                type="premium"
              />
            </Slider>
          </div>
        )}
      </div>
    </div>
  )
}

export default PlansPage
