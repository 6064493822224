/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import PartyEvents from './StartScreens/EventsPage'

import './index.scss'
import useActions from '../../hooks/useActions'
import Loader from '../../components/Loader'
import useTypedSelector from '../../hooks/useTypedSelector'

function EventsPage() {
  const [loading, setLoading] = useState<boolean>(false)

  const { getEventsSummary, clearEventId } = useActions()
  const { eventsSummary } = useTypedSelector(state => state.events)

  useEffect(() => {
    clearEventId()
    getEventsSummary(setLoading)
  }, [])

  return loading ? (
    <div className="userEvents">
      <div className="userEventsWrapperEventsPage">
        <Loader />
      </div>
    </div>
  ) : (
    <PartyEvents eventsSummary={eventsSummary} />
  )
}

export default EventsPage
