/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import './index.scss'
import useActions from '../../../../hooks/useActions'
import NextStepButton from '../../components/NextStepButton'

import { ReactComponent as TimeIcon } from '../../assets/time-icon.svg'
import { ReactComponent as LocationIcon } from '../../assets/location-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/calendar-icon.svg'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'

interface PartyDetailsNameProps {
  changeStep: (step: number) => void
  nextStep: number
}

interface IsActiveState {
  [key: string]: boolean
  date: boolean
  time: boolean
  location: boolean
}

function PartyDetailsName({ changeStep, nextStep }: PartyDetailsNameProps) {
  const [dateError, setDateError] = useState<boolean>(false)
  const [timeError, setTimeError] = useState<boolean>(false)
  const [eventDate, setEventDate] = useState<string>('')
  const [eventTime, setEventTime] = useState<string>('')
  const [eventLocation, setEventLocation] = useState<string>('')
  const [isActive, setIsActive] = useState<IsActiveState>({
    date: false,
    time: false,
    location: false,
  })

  const { updateUserCreateEvent, showModal } = useActions()
  const { userCreateEvent } = useTypedSelector(state => state.events)

  const formatDateToISOStringWithoutTimezone = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }

  const splitDateAndTime = (dateTimeString: string) => {
    const dateTimeObject = new Date(dateTimeString)

    setEventDate(
      formatDateToISOStringWithoutTimezone(dateTimeObject).slice(0, 10),
    )
    setEventTime(
      formatDateToISOStringWithoutTimezone(dateTimeObject).slice(11, 19),
    )
  }

  const combineDateAndTime = (date: string, time: string): Date => {
    const dateTimeString =
      time.length === 8 ? `${date}T${time}` : `${date}T${time}:00`
    const dateTimeObject = new Date(dateTimeString)

    return dateTimeObject
  }

  const modalFn = () => {
    showModal('MapModal', {})
  }

  // const nextStepHandler = () => {
  //   const dateTime = combineDateAndTime(eventDate, eventTime)

  //   if (dateTime > new Date()) {
  //     updateUserCreateEvent(dateTime.toISOString(), 'plannedAt')
  //     updateUserCreateEvent(eventLocation, 'location')
  //     changeStep(nextStep)
  //   } else {
  //     setDateError(true)
  //     setTimeout(() => {
  //       setDateError(false)
  //     }, 2000)
  //   }
  // }

  const nextStepHandler = () => {
    const dateTime = combineDateAndTime(eventDate, eventTime)
    const now = new Date()

    if (dateTime < now) {
      if (new Date(eventDate) < new Date(now.toISOString().split('T')[0])) {
        setDateError(true)
        setTimeout(() => {
          setDateError(false)
        }, 2000)
      } else {
        setTimeError(true)
        setTimeout(() => {
          setTimeError(false)
        }, 2000)
      }
      return
    }

    const localISOString = formatDateToISOStringWithoutTimezone(dateTime)

    updateUserCreateEvent(localISOString, 'plannedAt')
    updateUserCreateEvent(eventLocation, 'location')
    changeStep(nextStep)
  }

  const setInputActive = (key: keyof IsActiveState, value: boolean) => {
    setIsActive(prev => {
      const newObj: IsActiveState = { ...prev }

      Object.keys(newObj).forEach(tabKey => {
        if (tabKey === key) {
          newObj[tabKey] = value
        }
      })

      return newObj
    })
  }

  const dateInputClassNames = [
    'partyDetailsNameInnerItemInput',
    dateError && 'errorInput',
    isActive.date && 'activeInput',
  ]
    .filter(Boolean)
    .join(' ')

  const timeInputClassNames = [
    'partyDetailsNameInnerItemInput',
    timeError && 'errorInput',
    isActive.date && 'activeInput',
  ]
    .filter(Boolean)
    .join(' ')

  useEffect(() => {
    if (userCreateEvent.location) {
      setEventLocation(userCreateEvent.location)
    }
    if (userCreateEvent.plannedAt) {
      splitDateAndTime(userCreateEvent.plannedAt)
    }
  }, [])

  return (
    <div className="partyDetailsNameWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <EventStepTitle
          title="Event details"
          text="Review all the details of your event, including the cocktail list, number of guests, and other important information."
        />
      )}
      <div className="partyDetailsNameInner">
        <div className="partyDetailsNameInnerItem">
          <h4>Event Date</h4>
          <div className={dateInputClassNames}>
            <input
              type="date"
              defaultValue={eventDate}
              onChange={event => setEventDate(event.target.value)}
              onFocus={() => setInputActive('date', true)}
              onBlur={() => setInputActive('date', false)}
            />
            <CalendarIcon />
          </div>
        </div>
        <div className="partyDetailsNameInnerItem">
          <h4>Time</h4>
          <div className={timeInputClassNames}>
            <input
              type="time"
              defaultValue={eventTime}
              onChange={event => setEventTime(event.target.value)}
              onFocus={() => setInputActive('time', true)}
              onBlur={() => setInputActive('time', false)}
            />
            <TimeIcon />
          </div>
        </div>
        <div className="partyDetailsNameInnerItem">
          <h4>Location</h4>
          <div
            className={
              isActive.location
                ? 'partyDetailsNameInnerItemInput activeInput'
                : 'partyDetailsNameInnerItemInput'
            }
          >
            <input
              type="text"
              defaultValue={eventLocation}
              onChange={event => setEventLocation(event.target.value)}
              onFocus={() => setInputActive('location', true)}
              onBlur={() => setInputActive('location', false)}
            />
            <LocationIcon />
          </div>
        </div>
      </div>
      <NextStepButton
        handler={nextStepHandler}
        isActive={!!eventDate && !!eventLocation && !!eventTime}
        // handler={modalFn}
        // isActive
      />
    </div>
  )
}

export default PartyDetailsName
