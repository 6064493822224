import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'

import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'
import { BarState } from '../../store/reducers/bar/types'

export default function DeleteCollectionModal() {
  const { hideModal, deleteCollection } = useActions()
  const { modalHidden } = useTypedSelector(state => state.modal)
  const { selectedCollection } = useTypedSelector<BarState>(state => state.bar)
  const navigate = useNavigate()

  const deleteEvent = () => {
    deleteCollection(
      selectedCollection.id,
      () => navigate(-1),
      () => hideModal(),
    )
  }

  return (
    <Modal
      open={modalHidden}
      width={328}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      closable={false}
    >
      <div className="deleteEventModalWrapper">
        <div className="deleteEventModalInner">
          <h3>DELETE COLLECTION</h3>
          <p>
            Are you sure you want to delete the {selectedCollection.name}{' '}
            collection?
          </p>
          <div className="deleteEventModalBtnsWrapper">
            <button onClick={() => hideModal()} type="button">
              cancel
            </button>
            <button onClick={() => deleteEvent()} type="button">
              delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
