/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch } from '../..'
import { ICocktail } from '../../../@types/cocktails'
import {
  ICreateEvent,
  ICreateEventCocktail,
  IEventCocktail,
  IEventCocktailsPreferences,
  IEventInviteUsers,
  IEventShoppingListItem,
  IEventsItem,
  IUpdateEvent,
} from '../../../@types/events'
import EventsService from '../../../api/partyPlanning'
import { EventsEnum } from './types'

const EventsCreators = {
  // TODO:
  getPartyOccasions: () => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await EventsService.getOccasions()
        dispatch({ type: EventsEnum.GET_OCCASIONS, payload: data })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  updateUserCreateEvent: (
    value: string | number | boolean | ICreateEventCocktail[],
    key: string,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          // type: PartyPlanningEnum.UPDATE_USER_CREATE_PARTY,
          type: EventsEnum.UPDATE_USER_CREATE_EVENT,
          payload: { value, key },
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  // TODO:
  updateEventCocktailsPreferences: (
    value: number | number[] | boolean | 'EASY' | 'MEDIUM' | 'HARD',
    key: string,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: EventsEnum.UPDATE_EVENT_COCKTAILS_PREFERENCES,
          payload: { value, key },
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  getUserEvent: (id: string, setLoading: (value: boolean) => void) => {
    return async (dispatch: AppDispatch) => {
      try {
        setLoading(true)
        const { data } = await EventsService.getEvent(id)
        dispatch({
          type: EventsEnum.GET_EVENT,
          payload: data,
        })
        dispatch({
          type: EventsEnum.POST_USER_EVENT,
          payload: data.event.id,
        })
        setLoading(false)
      } catch (error: unknown) {
        console.error(error)
        setLoading(false)
      }
    }
  },
  getEventCocktails: (
    preferences: IEventCocktailsPreferences,
    setLoading: (value: boolean) => void,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        setLoading(true)
        const { data } = await EventsService.getEventCocktails(preferences)
        const transformData = data.map((item: ICocktail) => {
          return {
            amount: 1,
            cocktail: item,
          }
        })
        dispatch({
          type: EventsEnum.GET_EVENT_COCKTAILS,
          payload: transformData,
        })
        setLoading(false)
      } catch (error: unknown) {
        console.error(error)
        setLoading(false)
      }
    }
  },
  addEventCocktails: (items: IEventCocktail[]) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: EventsEnum.ADD_EVENT_COCKTAILS,
          payload: items,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  deleteEventCocktail: (id: number) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: EventsEnum.DELETE_EVENT_COCKTAIL,
          payload: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  changeEventCocktailAmount: (id: number, amount: number) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: EventsEnum.CHANGE_COCKTAIL_AMOUNT,
          payload: { id, amount },
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  uploadEventImage: (file: File, setIsFinish: (value: boolean) => void) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await EventsService.uploadEventImage(file)
        dispatch({
          type: EventsEnum.UPDATE_USER_CREATE_EVENT,
          payload: {
            key: 'image',
            value: data,
          },
        })
        setIsFinish(false)
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  postUserEvent: (
    event: ICreateEvent,
    setLoading: (value: boolean) => void,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        setLoading(true)
        const { data } = await EventsService.postEvent(event)
        dispatch({ type: EventsEnum.POST_USER_EVENT, payload: data.id })
        setLoading(false)
      } catch (error: unknown) {
        console.error(error)
        setLoading(false)
      }
    }
  },
  deleteUserEvent: (eventId: string, cb: () => void) => {
    return async () => {
      try {
        await EventsService.deleteEvent(eventId)
        cb()
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  postEventInvites: (
    eventId: string,
    invites: string[],
    setSuccess: (value: boolean) => void,
  ) => {
    return async () => {
      try {
        await EventsService.postEventInvites(eventId, invites)
        setSuccess(true)
      } catch (error: unknown) {
        console.error(error)
        setSuccess(false)
      }
    }
  },
  getEventsSummary: (setLoading: (loading: boolean) => void) => {
    return async (dispatch: AppDispatch) => {
      try {
        setLoading(true)
        const { data } = await EventsService.getEventsSummary()
        dispatch({ type: EventsEnum.GET_EVENTS_SUMMARY, payload: data })
        setLoading(false)
      } catch (error: unknown) {
        console.error(error)
        setLoading(false)
      }
    }
  },
  getTemplates: (setLoading: (loading: boolean) => void) => {
    return async (dispatch: AppDispatch) => {
      try {
        setLoading(true)
        const { data } = await EventsService.getTemplates()
        dispatch({
          type: EventsEnum.GET_TEMPLATES,
          payload: data,
        })
        setLoading(false)
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  copyDataFromTemplate: (data: IEventsItem) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: EventsEnum.COPY_FROM_TEMPLATE,
          payload: {
            name: data.name,
            guestsCount: data.guestsCount,
            notes: data.notes,
            occasionId: data.occasion.id,
            cocktails: [],
            eventType: '',
            image: data.image,
            plannedAt: data.plannedAt,
            location: data.location,
            isTemplate: false,
          },
        })
        dispatch({
          type: EventsEnum.GET_EVENT_COCKTAILS,
          payload: data.cocktails,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  patchUserEvent: (
    updateData: IUpdateEvent,
    eventId: string,
    notification: boolean,
    shoppingList: IEventShoppingListItem[],
    invites: IEventInviteUsers[],
    closeDetails: () => void,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await EventsService.patchEvent(
          updateData,
          eventId,
          notification,
        )
        dispatch({
          type: EventsEnum.GET_EVENT,
          payload: {
            event: data,
            invites,
            shoppingList,
          },
        })
        closeDetails()
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  clearEventInfo: () => {
    return (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: EventsEnum.CLEAR_CREATE_EVENT_INFO,
          payload: {
            userCreateEvent: {
              name: '',
              guestsCount: 1,
              notes: '',
              occasionId: -1,
              cocktails: [],
              eventType: '',
              image: '',
              plannedAt: '',
              location: '',
              isTemplate: false,
            },
            cocktailsPreferences: {
              occasionId: -1,
              flavors: [],
              allergens: [],
              categories: [],
              complexity: 'EASY',
              isNonAlcoholicEvent: false,
            },
            eventCocktails: [],
          },
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  clearEventId: () => {
    return (dispatch: AppDispatch) => {
      try {
        dispatch({ type: EventsEnum.CLEAR_EVENT_ID, payload: '' })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
}

export default EventsCreators
