/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../../../api'
import useActions from '../../../hooks/useActions'

import { ReactComponent as NotFavorite } from '../../../assets/images/svg/not-favorite.svg'
import { ReactComponent as Favorite } from '../../../assets/images/svg/favorite.svg'
import { ReactComponent as AlcoholFreeIcon } from '../../../assets/images/svg/alcohol-free-icon.svg'

import { EngLocales } from '../../../assets/locales/eng'
import { IIngredient } from '../../../@types/ingredients'
import RoutesPaths from '../../../constants/navigate'
import { SmallTabletDevicesWidth } from '../../../constants/screensWidth'
import { ICocktail } from '../../../@types/cocktails'
import { cocktailsCustomNotification } from '../../../helpers/Notifications/notificationsHelpers'

interface CocktailsCardProps {
  item: ICocktail
  isShowFilters: boolean
  isEvents?: boolean
  selectCocktail?: (value: ICocktail) => void
  excludeCocktails?: ICocktail[]
}

function CocktailsCard({
  item,
  isShowFilters,
  isEvents,
  selectCocktail,
  excludeCocktails,
}: CocktailsCardProps) {
  const [isSelect, setIsSelect] = useState<boolean>(false)

  const { getModalCocktail, showModal, cocktailFavorites } = useActions()
  const navigate = useNavigate()

  useEffect(() => {
    if (excludeCocktails && excludeCocktails.length) {
      if (excludeCocktails.includes(item)) setIsSelect(true)
    }
  }, [])

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (!isEvents) {
        if (window.innerWidth <= SmallTabletDevicesWidth) {
          navigate(`/${RoutesPaths.cocktailsPage}/${item.id}`)
        } else if (e.button === 0 && e.ctrlKey) {
          window.open(`/${RoutesPaths.cocktailsPage}/${item.id}`, '_blank')
        } else {
          getModalCocktail(item.id.toString())
          showModal('Cocktail', { item })
        }
      } else {
        setIsSelect((prev: boolean) => !prev)
        if (selectCocktail) {
          selectCocktail(item)
        }
      }
    },
    [item.id],
  )

  const onClickFavorites = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()

      if (!item.isFavorite) {
        cocktailFavorites(item.id.toString(), true, [])
        cocktailsCustomNotification(() => {
          showModal('FavoritesModal', {
            favorites: {
              id: item.id.toString(),
              cocktailName: item.name,
              collections: item.collections,
            },
          })
        })
      } else if (item.isFavorite) {
        showModal('FavoritesModal', {
          favorites: {
            id: item.id.toString(),
            cocktailName: item.name,
            collections: item.collections,
          },
        })
      }
    },
    [item, cocktailFavorites],
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && e.ctrlKey) {
        window.open(`/${RoutesPaths.cocktailsPage}/${item.id}`, '_blank')
      }
    },
    [item.id],
  )

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // if (e.button === 0 && e.ctrlKey) {
      //   e.preventDefault()
      //   window.open(`/${RoutesPaths.cocktailsPage}/${item.id}`, '_blank')
      // }
    },
    [item.id],
  )

  const classNames = [
    'cocktailCardWrapper',
    isSelect && 'activeCocktailCard',
    isShowFilters && 'cocktailCardWithFilters',
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <a
      href={`/${RoutesPaths.cocktailsPage}/${item.id}`}
      onClick={onClick}
      className="cocktailCardWrapperLink"
    >
      <div
        className={classNames}
        onClick={onClick}
        onAuxClick={e => {
          e.preventDefault()
          if (e.button === 1) {
            window.open(`/${RoutesPaths.cocktailsPage}/${item.id}`, '_blank')
          }
        }}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onClick
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div className="cocktailCardInner">
          <div id="lightbox">
            <div
              className="favorite-icon"
              onClick={onClickFavorites}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onClickFavorites
                }
              }}
              role="button"
              tabIndex={0}
            >
              {item.isFavorite ? (
                <Favorite className="img-favorites-active" />
              ) : (
                <NotFavorite className="img-favorites" />
              )}
            </div>
            {item.strength === 'Alcohol-free' && (
              <div className="cocktail-alcohol-free-icon">
                <AlcoholFreeIcon />
              </div>
            )}
            <img
              alt={item.name || 'Default Alt'}
              src={`${api()}/images/cocktails/m/${item.image}`}
            />
          </div>
          <div className="cocktailCardDescription">
            <h3>{item.name}</h3>
            <div className="cocktailIngredients">
              <p>{EngLocales.CocktailsCard.cocktailIngredients}</p>
              <ul>
                {item.ingredients.map((value: IIngredient) => {
                  return <li key={Math.random()}>{value.name}</li>
                })}
              </ul>
            </div>
            <div className="cocktailsCardLine" />
            <div className="cocktailTools">
              <p>
                {EngLocales.CocktailsCard.cocktailToolsComplexity}{' '}
                {item.complexity}
              </p>
              <p>
                {EngLocales.CocktailsCard.cocktailToolsStrength} {item.strength}
              </p>
              <p>
                {EngLocales.CocktailsCard.cocktailToolsFlavor}{' '}
                {item.flavor.name}
              </p>
              <p>
                {EngLocales.CocktailsCard.cocktailToolsGlass} {item.glass.name}
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

CocktailsCard.defaultProps = {
  isEvents: false,
  selectCocktail: () => null,
  excludeCocktails: [],
}

export default CocktailsCard
