/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import useTypedSelector from '../../hooks/useTypedSelector'
import useActions from '../../hooks/useActions'
import { api } from '../../api'
import RoutesPaths from '../../constants/navigate'
import { removeParams } from '../../store/reducers/auth/authUtils'

import { ReactComponent as Logo } from '../../assets/images/NewLogo.svg'
import { ReactComponent as LoginUserIcon } from '../../assets/images/svg/header-user-icon.svg'
import { ReactComponent as HomeIcon } from './icons/home-icon.svg'
import { ReactComponent as UserIcon } from './icons/user-icon.svg'
import { ReactComponent as CocktailsIcon } from './icons/cocktails-icon.svg'
import { ReactComponent as IngredientsIcon } from './icons/ingredients-icon.svg'
import { ReactComponent as PlansIcon } from './icons/plans-icon.svg'
import { ReactComponent as LogOutIcon } from './icons/logout-icon.svg'
import { ReactComponent as DBarIcon } from './icons/dbar-icon.svg'
import { UserState } from '../../store/reducers/user/types'

interface LinkItemProp {
  key: string
  label: string
  needAuth: boolean
}

const linkItem: LinkItemProp[] = [
  { key: RoutesPaths.cocktailsPage, label: 'Find a Recipe', needAuth: false },
  { key: RoutesPaths.ingredientsPage, label: 'Ingredients', needAuth: false },
  { key: RoutesPaths.eventsPages, label: 'Events', needAuth: true },
  { key: RoutesPaths.plansPage, label: 'Plans', needAuth: false },
  { key: RoutesPaths.myBarCocktailsPage, label: 'My Bar', needAuth: true },
]

const burgerLinkItem = [
  { key: 'main', label: 'Home', icon: <HomeIcon />, link: '', needAuth: false },
  {
    key: RoutesPaths.cocktailsPage,
    label: 'Find a Recipe',
    icon: <CocktailsIcon />,
    link: RoutesPaths.cocktailsPage,
    needAuth: false,
  },
  {
    key: RoutesPaths.ingredientsPage,
    label: 'Ingredients',
    icon: <IngredientsIcon />,
    link: RoutesPaths.ingredientsPage,
    needAuth: false,
  },
  {
    key: RoutesPaths.eventsPages,
    label: 'Events',
    icon: <DBarIcon />,
    link: RoutesPaths.eventsPages,
    needAuth: true,
  },
  // { key: 'blog', label: 'Blog', icon: <BlogIcon />, link: '/blog' },
  {
    key: RoutesPaths.plansPage,
    label: 'Plans',
    icon: <PlansIcon />,
    link: RoutesPaths.plansPage,
    needAuth: false,
  },
  {
    key: RoutesPaths.myBarCocktailsPage,
    label: 'My Bar',
    icon: <DBarIcon />,
    link: RoutesPaths.myBarCocktailsPage,
    needAuth: true,
  },
  {
    key: RoutesPaths.profilePage,
    label: 'My Account',
    icon: <UserIcon />,
    link: RoutesPaths.profilePage,
    needAuth: true,
  },
]

interface TabState {
  cocktails: boolean
  ingredients: boolean
  blog: boolean
  plans: boolean
  dbar: boolean
  profile: boolean
  main: boolean
  events: boolean
  [key: string]: boolean
}

function Header() {
  const [whatTabIsOpen, setWhatTabIsOpen] = useState<TabState>({
    cocktails: false,
    ingredients: false,
    blog: false,
    plans: false,
    dbar: false,
    profile: false,
    main: false,
    login: false,
    events: false,
  })
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)
  const { userProfile } = useTypedSelector<UserState>(state => state.user)
  const { showModal, clearUserProfile } = useActions()

  const navigate = useNavigate()
  const location = useLocation()

  const headerListHandler = (key: string) => {
    navigate(key)
  }

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen)
  }

  function setLiActive(value: string) {
    setWhatTabIsOpen(prevTabs => {
      const newTabs: TabState = { ...prevTabs }

      Object.keys(newTabs).forEach(tabKey => {
        if (value.split('/')[1].includes(tabKey)) {
          newTabs[tabKey] = true
        } else {
          newTabs[tabKey] = false
        }
      })

      if (value.length === 1) {
        newTabs.main = true
      }

      return newTabs
    })
  }

  const userLogOut = () => {
    removeParams()
    clearUserProfile()
    navigate('/')
  }

  useEffect(() => {
    setLiActive(location.pathname)
  }, [location])

  return (
    <div className="header">
      <div
        className="logo"
        onClick={() => {
          headerListHandler('/')
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            headerListHandler('/')
          }
        }}
        role="button"
        tabIndex={0}
        aria-label="Navigate to homepage"
      >
        <Logo style={{ width: '119px', height: '24px' }} />
      </div>
      <ul className="header-list">
        {linkItem.map((item: LinkItemProp) => {
          if (item.needAuth && userProfile.id) {
            return (
              <li
                key={item.key}
                className={
                  whatTabIsOpen[item.key.split('/')[0]]
                    ? 'header-list-item active'
                    : 'header-list-item'
                }
              >
                <Link to={item.key}>{item.label}</Link>
              </li>
            )
          }
          if (!item.needAuth) {
            return (
              <li
                key={item.key}
                className={
                  whatTabIsOpen[item.key]
                    ? 'header-list-item active'
                    : 'header-list-item'
                }
              >
                <Link to={item.key}>{item.label}</Link>
              </li>
            )
          }
          return null
        })}
        {userProfile.image ? (
          <li className="isAuth">
            <Link to={RoutesPaths.profilePage}>
              <img
                src={`${api()}/images/users/${userProfile.image}`}
                alt="user profile"
              />
            </Link>
          </li>
        ) : (
          <div
            className="login-button"
            onClick={() => {
              showModal('Auth', {})
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                showModal('Auth', {})
              }
            }}
            role="button"
            tabIndex={0}
          >
            <LoginUserIcon />
            <p>Login or Sign Up</p>
          </div>
        )}
      </ul>
      {window.innerWidth < 742 && (
        <>
          <div
            className={`burgerMenu ${burgerMenuOpen ? 'open' : ''}`}
            onClick={toggleBurgerMenu}
          >
            <div className="burgerMenuInner" onClick={e => e.stopPropagation()}>
              <div className="burgerMenuCloseWrapper">
                <div
                  className="burgerMenuClose"
                  role="button"
                  onClick={toggleBurgerMenu}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      toggleBurgerMenu()
                    }
                  }}
                  tabIndex={0}
                  aria-label="close menu"
                >
                  <div className="closeBar" />
                </div>
              </div>
              <ul>
                {burgerLinkItem.map(item => {
                  if (item.needAuth && userProfile.id) {
                    return (
                      <li
                        key={item.key}
                        className={whatTabIsOpen[item.key] ? 'active' : ''}
                        onClick={toggleBurgerMenu}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            toggleBurgerMenu()
                          }
                        }}
                      >
                        <Link to={item.link}>
                          {item.icon} {item.label}
                        </Link>
                      </li>
                    )
                  }
                  if (!item.needAuth) {
                    return (
                      <li
                        key={item.key}
                        className={whatTabIsOpen[item.key] ? 'active' : ''}
                        onClick={toggleBurgerMenu}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            toggleBurgerMenu()
                          }
                        }}
                      >
                        <Link to={item.link}>
                          {item.icon} {item.label}
                        </Link>
                      </li>
                    )
                  }
                  return null
                })}
                {!userProfile.id && (
                  <li
                    className={whatTabIsOpen.login ? 'active' : ''}
                    onClick={() => {
                      showModal('Auth', {})
                      toggleBurgerMenu()
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        showModal('Auth', {})
                        toggleBurgerMenu()
                      }
                    }}
                  >
                    <div>
                      <UserIcon /> Login or Sign In
                    </div>
                  </li>
                )}
              </ul>
              {userProfile.id && (
                <div className="burgerMenuLogOut">
                  <button
                    onClick={() => {
                      userLogOut()
                      toggleBurgerMenu()
                    }}
                    type="button"
                  >
                    <LogOutIcon /> Log out
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            className={`burgerMenuOpenButton ${burgerMenuOpen ? 'active' : ''}`}
            onClick={toggleBurgerMenu}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                toggleBurgerMenu()
              }
            }}
            role="button"
            tabIndex={0}
            aria-label="close menu"
          >
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
        </>
      )}
    </div>
  )
}

export default Header
