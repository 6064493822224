import axios from 'axios'

export const api = () =>
  process.env.REACT_APP_PROFILE === 'DEV'
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_MAIN_API
export const staticApi = () => process.env.REACT_APP_STATIC_API

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_PROFILE === 'DEV'
      ? process.env.REACT_APP_DEV_API
      : process.env.REACT_APP_MAIN_API,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
})

export default axiosInstance
