import { FC } from 'react'
import { Tooltip } from 'antd'
import { staticApi } from '../../../api'
import { EngLocales } from '../../../assets/locales/eng'

import { ReactComponent as AllergenFree } from '../assets/allergen-free.svg'

import './index.scss'
import useHorizontalScroll from '../../../hooks/useHorizontalScroll'
import { MobileDevicesWidth } from '../../../constants/screensWidth'
import { ICocktailFilter } from '../../../@types/cocktails'

interface InstrumentsBlockProps {
  tools: ICocktailFilter[]
  allergens: ICocktailFilter[]
  glass: ICocktailFilter
  flavor: ICocktailFilter
  complexity: 'Hard' | 'Medium' | 'Easy'
  strength: 'Light' | 'Alcohol-free' | 'Medium' | 'Strong'
}

type StrengthKeyType = 'Light' | 'Alcohol-free' | 'Medium' | 'Strong'
type ComplexityKeyType = 'Hard' | 'Medium' | 'Easy'

function whatStrength(strength: StrengthKeyType) {
  switch (strength) {
    case 'Alcohol-free':
      return 0
    case 'Light':
      return 1
    case 'Medium':
      return 2
    case 'Strong':
      return 3
    default:
      return 0
  }
}

function whatComplexity(complexity: ComplexityKeyType) {
  switch (complexity) {
    case 'Easy':
      return 1
    case 'Medium':
      return 2
    case 'Hard':
      return 3
    default:
      return 0
  }
}

function InstrumentsBlock({
  tools,
  allergens,
  glass,
  flavor,
  complexity,
  strength,
}: InstrumentsBlockProps) {
  const numStrengthFilledCircles = whatStrength(strength)
  const numComplexityFilledCircles = whatComplexity(complexity)

  const ref = useHorizontalScroll()

  return (
    <div className="instrumentsBlockWrapper">
      <div className="instrumentsBlockSection">
        <h4>{EngLocales.CocktailPage.cocktailPageBlockToolsHeader}</h4>
        <div className="instrumentsBlockSectionTools" ref={ref}>
          {tools.map((item: ICocktailFilter) => {
            return (
              <div className="instrumentsBlockSectionToolsItem">
                <Tooltip title={item.name} placement="bottom">
                  <img
                    key={item.id}
                    src={`${staticApi()}/images/tools/${item.image}`}
                    alt={item.name}
                  />
                </Tooltip>
              </div>
            )
          })}
        </div>
      </div>
      <div className="instrumentsBlockSection">
        <h4>{EngLocales.CocktailPage.cocktailPageBlockAllergensHeader}</h4>
        <div
          className={
            allergens.length
              ? 'instrumentsBlockSectionAllergens'
              : 'instrumentsBlockSectionAllergens noAllergens'
          }
        >
          {allergens.length ? (
            allergens.map((item: ICocktailFilter) => {
              return (
                <div className="instrumentsBlockSectionAllergensItem">
                  <Tooltip title={item.name} placement="bottom">
                    <img
                      key={item.id}
                      src={`${staticApi()}/images/allergens/${item.image}`}
                      alt={item.name}
                    />
                  </Tooltip>
                </div>
              )
            })
          ) : (
            <AllergenFree />
          )}
        </div>
      </div>
      {window.innerWidth <= MobileDevicesWidth && (
        <div className="instrumentsBlockSection">
          <div className="instrumentsBlockCategoriesItem">
            <div className="instrumentsBlockCategoriesItemInner">
              <img
                src={`${staticApi()}/images/flavors/${flavor.image}`}
                alt={flavor.name}
              />
              <p>
                {flavor.name}{' '}
                {EngLocales.CocktailPage.cocktailPageBlockCategoricFlavour}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="instrumentsBlockCategories">
        {window.innerWidth > MobileDevicesWidth && (
          <div className="instrumentsBlockCategoriesItem">
            <div className="instrumentsBlockCategoriesItemInner">
              <img
                src={`${staticApi()}/images/flavors/${flavor.image}`}
                alt={flavor.name}
              />
              <p>
                {flavor.name}{' '}
                {EngLocales.CocktailPage.cocktailPageBlockCategoricFlavour}
              </p>
            </div>
          </div>
        )}
        <div className="instrumentsBlockCategoriesItem">
          <div className="instrumentsBlockCategoriesItemInner">
            <img
              src={`${staticApi()}/images/glasses/${glass.image}`}
              alt={glass.name}
            />
            <p>
              {glass.name}{' '}
              {EngLocales.CocktailPage.cocktailPageBlockCategoricGlass}
            </p>
          </div>
        </div>
        <div className="instrumentsBlockCategoriesItem">
          <div className="instrumentsBlockCategoriesItemInner">
            <div className="instrumentsBlockCategoriesItemInnerCircles">
              {[1, 2, 3].map((_, index) => (
                <div
                  key={Math.random()}
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    border: '1.5px solid #EEEFF0',
                    backgroundColor:
                      index < numComplexityFilledCircles
                        ? '#EEEFF0'
                        : 'transparent',
                    marginRight: '5px',
                  }}
                />
              ))}
            </div>
            <p>
              {EngLocales.CocktailPage.cocktailPageBlockCategoricComplexity}
            </p>
          </div>
        </div>
        <div className="instrumentsBlockCategoriesItem">
          <div className="instrumentsBlockCategoriesItemInner">
            <div className="instrumentsBlockCategoriesItemInnerCircles">
              {[1, 2, 3].map((_, index) => (
                <div
                  key={Math.random()}
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    border: '1.5px solid #EEEFF0',
                    backgroundColor:
                      index < numStrengthFilledCircles
                        ? '#EEEFF0'
                        : 'transparent',
                    marginRight: '5px',
                  }}
                />
              ))}
            </div>
            <p>{EngLocales.CocktailPage.cocktailPageBlockCategoricStrength}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstrumentsBlock
