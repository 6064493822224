/* eslint-disable @typescript-eslint/no-loop-func */
import { ReactNode } from 'react'
import { IListSortObject } from '../../constants/sort'
import { SearchFilters } from '../SearchFilters'

interface ContentWrapperProps {
  children: ReactNode
  setCurrentPage: (page: number) => void
  searchField: string
  setSearchValue: (value: string) => void
  setRequest: (value: boolean) => void
  sortByList: IListSortObject
  sortByListFunc: (name: string) => void
  isIngredientsList: boolean
}

function ContentWrapper({
  children,
  setCurrentPage,
  searchField,
  setSearchValue,
  setRequest,
  sortByList,
  isIngredientsList,
  sortByListFunc,
}: ContentWrapperProps) {
  return (
    <>
      <SearchFilters
        searchField={searchField}
        setCurrentPage={setCurrentPage}
        setRequest={setRequest}
        setSearchValue={setSearchValue}
        isCocktailsFilters={!isIngredientsList}
        isMixologyFilters={false}
        isMyIngredientFilters={false}
        isIngredientsList={isIngredientsList}
        sortByList={sortByList}
        sortByListFunc={sortByListFunc}
      />
      <div className="contentWrapper">
        <div className="contentInner">{children}</div>
      </div>
    </>
  )
}

export default ContentWrapper
