import * as yup from 'yup'

 export const loginSchema = yup.object().shape({
  usernameORemail: yup
    .string()
    // .email('Incorrect email. Please check your credentials and try again.')
    .required(
      'Incorrect email or username. Please check your credentials and try again.',
    ),
  password: yup
    .string()
    // .matches(
    //   /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
    //   'Incorrect password. Please check your credentials and try again.',
    // )
    .min(6, 'Incorrect password. Please check your credentials and try again.')
    .required(
      'Incorrect password. Please check your credentials and try again.',
    ),
})

export const signinSchema = yup.object().shape({
  username: yup
    .string()
    // .matches(
    //   /^[A-Za-z\d._+-]{4,20}$/,
    //   'Incorrect username. Please check your credentials and try again.',
    // )
    .required(
      'Incorrect username. Please check your credentials and try again.',
    ),
  email: yup
    .string()
    // .email('Incorrect email. Please check your credentials and try again.')
    .required('Incorrect email. Please check your credentials and try again.'),
  password: yup
    .string()
    // .matches(
    //   /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
    //   'Incorrect password. Please check your credentials and try again.',
    // )
    .min(6, 'Incorrect password. Please check your credentials and try again.')
    .required(
      'Incorrect password. Please check your credentials and try again.',
    ),
})

export const emailSchema = yup.object().shape({
  email: yup
  .string()
  .email('Incorrect email. Please check your credentials and try again.')
  .required('Incorrect email. Please check your credentials and try again.'),
})

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    // .matches(
    //   /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
    //   'Incorrect password. Please check your credentials and try again.',
    // )
    .min(6, 'Incorrect password. Please check your credentials and try again.')
    .required(
      'Incorrect password. Please check your credentials and try again.',
    ),
    resetPassword: yup
    .string()
    // .matches(
    //   /^(?=.*\d)(?=.*[A-Z]).{8,}$/,
    //   'Incorrect password. Please check your credentials and try again.',
    // )
    .min(6, 'Incorrect password. Please check your credentials and try again.')
    .required(
      'Incorrect password. Please check your credentials and try again.',
    ),
})
