import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'

import PartyEmailsAddress from './PartyEmailsAddresses'
import PartyEmailsSuccess from './PartyEmailsSuccess'

import SendEmailsImage from '../assets/send-emails-image.jpg'
import NextStepButton from '../components/NextStepButton'
import RoutesPaths from '../../../constants/navigate'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../constants/screensWidth'
import EventHeader from '../components/EventHeader'
import EventsDesktopTabs from '../components/EventDesktopTabs'

import './index.scss'
import EventStepTitle from '../components/EventStepTitle'

function PartyInvitesPage() {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isAddress, setIsAddress] = useState<boolean>(false)

  const nextStepHandler = () => {
    setIsAddress(true)
  }

  const whatBlock = () => {
    if (isAddress && !isSuccess) {
      return (
        <PartyEmailsAddress eventId={id || ''} setIsSuccess={setIsSuccess} />
      )
    }
    if (isSuccess) {
      return <PartyEmailsSuccess eventId={id || ''} />
    }
    return (
      <>
        <div className="partyEmailsImage">
          <img src={SendEmailsImage} alt="send emails pic" />
        </div>
        <div className="partyEmailsBtns">
          {window.innerWidth <= MobileDevicesWidth && (
            <h5>Do you want to send e-invite?</h5>
          )}
          <NextStepButton handler={nextStepHandler} isActive btnText="Yes" />
          <button
            onClick={() => navigate(`/events/summary/${id || ''}`)}
            type="button"
            className="partyEmailsBtnsExit"
          >
            No, maybe later
          </button>
        </div>
      </>
    )
  }

  return (
    <div className="userEvents">
      <div className="userEventsWrapper userEventsWrapperSteps">
        {window.innerWidth > MobileDevicesWidth ? (
          <EventsDesktopTabs
            currentStep={20}
            stepBack={(value: number) => value}
            isCreateEvent={false}
            isSendEmail={false}
          />
        ) : (
          <EventHeader
            loadingPercent="100%"
            stepTitle="Send E-invite"
            customFunc={() => navigate(`/${RoutesPaths.eventsPages}`)}
          />
        )}

        <div className="userEventsInner">
          <div className="partyEmailsWrapper">
            {window.innerWidth > MobileDevicesWidth && (
              <EventStepTitle
                title="Send E-invite"
                text="Send electronic invitations to your guests directly from the app. Customize the invitation with your event details and the uploaded photo."
              />
            )}
            {whatBlock()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartyInvitesPage
