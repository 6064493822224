/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react'
import './index.scss'
import NextStepButton from '../../components/NextStepButton'
import useActions from '../../../../hooks/useActions'

import { ReactComponent as MinusIcon } from '../../assets/minus-icon.svg'
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'

interface PartyScratchDetailsProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyScratchDetails({
  changeStep,
  nextStep,
}: PartyScratchDetailsProps) {
  const { updateUserCreateEvent } = useActions()
  const { userCreateEvent } = useTypedSelector(state => state.events)

  const [partyName, setPartyName] = useState<string>('')
  const [partyPersonCount, setPartyPersonCount] = useState<number>(1)
  const [partyNotes, setPartyNotes] = useState<string>('')

  useEffect(() => {
    if (userCreateEvent.name) {
      setPartyName(userCreateEvent.name)
    }
    if (userCreateEvent.guestsCount) {
      setPartyPersonCount(userCreateEvent.guestsCount)
    }
    if (userCreateEvent.notes) {
      setPartyNotes(userCreateEvent.notes)
    }
  }, [])

  const nextStepHandler = () => {
    updateUserCreateEvent(partyName, 'name')
    updateUserCreateEvent(partyPersonCount, 'guestsCount')
    updateUserCreateEvent(partyNotes, 'notes')
    return changeStep(nextStep)
  }

  const validateFields = (): boolean => {
    if (partyName.trim() === '' || partyPersonCount === 0) {
      return false
    }
    return true
  }

  return (
    <div className="partyScratchDetailsWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <EventStepTitle
          text="Craft a fitting name for your cocktail party. Specify guest count
            for precise drink planning. Add notes for special instructions,
            dress code, or any unique details."
          title="Event information"
        />
      )}
      <div className="partyScratchDetailsInner">
        <div className="partyScratchDetailsInnerSection">
          <h5>Event Name</h5>
          <input
            type="text"
            defaultValue={partyName}
            onChange={event => setPartyName(event.target.value.trim())}
          />
        </div>
        <div className="partyScratchDetailsInnerSection">
          <h5>Number of persons</h5>
          <div className="partyScratchDetailsInnerSectionCounter">
            <button
              type="button"
              onClick={() => {
                setPartyPersonCount(prev => {
                  return prev > 1 ? prev - 1 : prev
                })
              }}
            >
              <MinusIcon />
            </button>
            <p>{partyPersonCount}</p>
            <button
              type="button"
              onClick={() => {
                setPartyPersonCount(prev => prev + 1)
              }}
            >
              <PlusIcon />
            </button>
          </div>
        </div>
        <div className="partyScratchDetailsInnerSection">
          <h5>Notes</h5>
          <textarea
            className="partyScratchDetailsInnerSectionNotes"
            defaultValue={partyNotes}
            onChange={event => setPartyNotes(event.target.value.trim())}
          />
        </div>
      </div>
      <NextStepButton handler={nextStepHandler} isActive={validateFields()} />
    </div>
  )
}

export default PartyScratchDetails
