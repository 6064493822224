import { AppDispatch } from '../../index'
import FiltersService from '../../../api/filters'
import { FiltersActionEnum, FiltersState, GetFiltersAction } from './types'
import { ICocktailSelectedFilters } from '../../../@types/cocktails'

const FiltersActionCreators = {
  GetFiltersAction: (data: FiltersState): GetFiltersAction => ({
    type: FiltersActionEnum.GET_FILTERS,
    payload: data,
  }),

  getPrimaryIngredients: () => async (dispatch: AppDispatch) => {
    try {
      const { data } = await FiltersService.getPrimaryIngredients()
      dispatch({ type: FiltersActionEnum.GET_PRIMARY_INGREDIENTS, payload: data.content })
    } catch (error: unknown) {
      console.error(error)
    }
  },

  getFlavors: () => async (dispatch: AppDispatch) => {
    try {
      const { data } = await FiltersService.getFlavors()
      dispatch({ type: FiltersActionEnum.GET_FLAVORS, payload: data })
    } catch (error: unknown) {
      console.error(error)
    }
  },

  getGlasses: () => async (dispatch: AppDispatch) => {
    try {
      const { data } = await FiltersService.getGlasses()
      dispatch({ type: FiltersActionEnum.GET_GLASSES, payload: data })
    } catch (error: unknown) {
      console.error(error)
    }
  },

  getAllergens: () => async (dispatch: AppDispatch) => {
    try {
      const { data } = await FiltersService.getAllergens()
      dispatch({ type: FiltersActionEnum.GET_ALLERGENS, payload: data })
    } catch (error: unknown) {
      console.error(error)
    }
  },

  getCategories: (isPrimary: boolean) => async (dispatch: AppDispatch) => {
    try {
      const { data } = await FiltersService.getCategories(isPrimary)
      dispatch({ type: FiltersActionEnum.GET_CATEGORIES, payload: data })
    } catch (error: unknown) {
      console.error(error)
    }
  },

  setSelectedFilter:
    (id: number[] | string[], filter: string) => (dispatch: AppDispatch) => {
      dispatch({
        type: FiltersActionEnum.SET_SELECTED_FILTER,
        payload: { id, filter },
      })
    },

  clearSelectedFilters: () => (dispatch: AppDispatch) => {
    dispatch({
      type: FiltersActionEnum.CLEAR_SELECTED_FILTERS,
    })
  },

  clearSelectedCocktailsFilters: () => (dispatch: AppDispatch) => {
    dispatch({
      type: FiltersActionEnum.CLEAR_SELECTED_COCKTAILS_FILTERS,
    })
  },

  clearSelectedIngredientsFilters: () => (dispatch: AppDispatch) => {
    dispatch({
      type: FiltersActionEnum.CLEAR_SELECTED_INGREDIENTS_FILTERS,
    })
  },

  applySelectedFilters:
    (selectedFilters: ICocktailSelectedFilters) => (dispatch: AppDispatch) => {
      dispatch({
        type: FiltersActionEnum.APPLY_SELECTED_FILTERS,
        payload: selectedFilters,
      })
    },
}

export default FiltersActionCreators
