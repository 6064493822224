import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as CompleteIcon } from '../../assets/tabs-complete-icons.svg'
import { ReactComponent as CurrentIcon } from '../../assets/tabs-current-icon.svg'

import './index.scss'
import RoutesPaths from '../../../../constants/navigate'

interface EventsDesktopTabsProps {
  currentStep: number
  stepBack: (value: number) => void
  isCreateEvent: boolean
  isSendEmail: boolean
}

export default function EventsDesktopTabs({
  currentStep,
  stepBack,
  isCreateEvent,
  isSendEmail,
}: EventsDesktopTabsProps) {
  const navigate = useNavigate()

  function whatClassName(globalStep: number, itemStep: number) {
    if (globalStep > itemStep) {
      return 'eventsDesktopTabsInnerItem passItem'
    }
    if (globalStep === itemStep) {
      return 'eventsDesktopTabsInnerItem activeItem'
    }

    return 'eventsDesktopTabsInnerItem'
  }

  function whatIcon(globalStep: number, itemStep: number) {
    if (globalStep > itemStep) {
      return <CompleteIcon />
    }
    if (globalStep === itemStep) {
      return <CurrentIcon />
    }

    return false
  }

  const goToEvents = () => {
    return navigate(`/${RoutesPaths.eventsPagesCreate}`)
  }

  const handleClick = (step: number, prevStep = step) => {
    if (currentStep - step === 1) {
      stepBack(prevStep)
    }
  }

  return (
    <div className="eventsDesktopTabsWrapper">
      <div className="eventsDesktopTabsInner">
        <div
          className={
            isCreateEvent
              ? 'eventsDesktopTabsInnerItem activeItem'
              : 'eventsDesktopTabsInnerItem passItem'
          }
          onClick={() => {
            if (currentStep === 1) goToEvents()
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              if (currentStep === 1) goToEvents()
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Create a new event</p>
          {isCreateEvent ? <CurrentIcon /> : <CompleteIcon />}
        </div>
        <div
          className={whatClassName(currentStep, 1)}
          onClick={() => handleClick(1)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(1)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Occasion</p>
          {whatIcon(currentStep, 1)}
        </div>
        <div
          className={whatClassName(currentStep, 2)}
          onClick={() => handleClick(2)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(2)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Event Information</p>
          {whatIcon(currentStep, 2)}
        </div>
        <div
          className={whatClassName(currentStep, 3)}
          onClick={() => handleClick(3)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(3)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Preferences</p>
          {whatIcon(currentStep, 3)}
        </div>
        <div
          className={whatClassName(currentStep, 4)}
          onClick={() => handleClick(4)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(4)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Major Drinks</p>
          {whatIcon(currentStep, 4)}
        </div>
        <div className={whatClassName(currentStep, 5)}>
          <p>Allergens </p>
          {whatIcon(currentStep, 5)}
        </div>
        <div
          className={whatClassName(currentStep, 7)}
          onClick={() => handleClick(7)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(7)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Events Cocktails</p>
          {whatIcon(currentStep, 7)}
        </div>
        <div
          className={whatClassName(currentStep, 8)}
          onClick={() => handleClick(8)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(8)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Number of servings</p>
          {whatIcon(currentStep, 8)}
        </div>
        <div
          className={whatClassName(currentStep, 9)}
          onClick={() => handleClick(9)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(9)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Save event</p>
          {whatIcon(currentStep, 9)}
        </div>
        <div
          className={whatClassName(currentStep, 10)}
          onClick={() => handleClick(10)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(10)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Event details</p>
          {whatIcon(currentStep, 10)}
        </div>
        <div
          className={whatClassName(currentStep, 11)}
          onClick={() => handleClick(11)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(11)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Type of event</p>
          {whatIcon(currentStep, 11)}
        </div>
        <div
          className={whatClassName(currentStep, 12)}
          onClick={() => handleClick(12)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleClick(12)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>Photo of Event</p>
          {whatIcon(currentStep, 12)}
        </div>
        <div className={whatClassName(currentStep, 20)}>
          <p>Send E-invite</p>
          {whatIcon(currentStep, 20)}
        </div>
      </div>
    </div>
  )
}
