import React, { ReactNode } from 'react'

import { ReactComponent as ArrowIcon } from '../../../MyBar/assets/arrow-icon.svg'

import { api } from '../../../../api'

import './index.scss'

interface MobileMorePageProps {
  title: string
  count: string
  children: ReactNode
  image: string
  goBack: () => void
}

export default function MobileMorePage({
  children,
  count,
  title,
  image,
  goBack,
}: MobileMorePageProps) {
  return (
    <div className="mobileMorePageWrapper">
      <div className="mobileMorePageWrapperHeader">
        <div className="mobileMorePageWrapperHeaderImage">
          <ArrowIcon onClick={goBack} />
          <img src={`${api()}/images/events/${image}`} alt="title" />
        </div>
        <div className="mobileMorePageWrapperHeaderTitle">
          <h5>{title}</h5>
          <p>{count}</p>
        </div>
      </div>
      <div className="mobileMorePageInner">{children}</div>
    </div>
  )
}
