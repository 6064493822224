import { ICocktailFilter } from '../../../@types/cocktails'
import { IModalData, IModalName } from '../../../@types/modals'
import {
  ModalActionEnum,
  SetModalHideAction,
  SetModalShowAction,
} from './types'

const ModalActionCreators = {
  showModal: (
    modalName: IModalName,
    modalData: IModalData,
    isCocktailsFilterModal?: boolean,
    isMixologyFilterModal?: boolean,
    isMyIngredientFilterModal?: boolean
  ): SetModalShowAction => ({
    type: ModalActionEnum.SHOW_MODAL,
    payload: { modalName, modalData, isCocktailsFilterModal, isMixologyFilterModal, isMyIngredientFilterModal },
  }),
  hideModal: (): SetModalHideAction => ({ type: ModalActionEnum.HIDE_MODAL }),
}

export default ModalActionCreators
