/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import './index.scss'
import useTypedSelector from '../../../hooks/useTypedSelector'
import useActions from '../../../hooks/useActions'
import Loader from '../../../components/Loader'
import PartyHeader from '../components/EventHeader'
import EventSummaryHeader from './EventSummaryHeader'
import RoutesPaths from '../../../constants/navigate'
import EventSummaryCocktails from './EventSummaryCocktails'
import EventSummaryShoppingList from './EventSummaryShoppingList'
import NextStepButton from '../components/NextStepButton'
import ChangeEventDetails from './ChangeEventDetails'
import EventSummaryInvites from './EventSummaryInvites'
import { IEventSummary } from '../../../@types/events'
import { MobileDevicesWidth } from '../../../constants/screensWidth'
import MobileMorePage from './MobileMorePage'
import { api } from '../../../api'

export default function EventSummaryPage() {
  const [loading, setLoading] = useState<boolean>(false)
  const [isChangeDetails, setIsChangeDetails] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<1 | 2 | 3>(1)
  const [whatPage, setWhatPage] = useState<
    'MAIN' | 'COCKTAILS' | 'SHOP' | 'INVITE'
  >('MAIN')

  const { id } = useParams()
  const navigate = useNavigate()

  const { event } = useTypedSelector(state => state.events)
  const { getUserEvent, showModal } = useActions()

  useEffect(() => {
    if (id) {
      getUserEvent(id, setLoading)
    }
  }, [])

  const goBack = () => {
    return navigate(`/${RoutesPaths.eventsPages}`)
  }

  const openModal = () => {
    showModal('DeleteEvent', {})
  }

  const closeDetails = () => {
    return setIsChangeDetails(false)
  }

  const openDetails = () => {
    return setIsChangeDetails(true)
  }

  const whatTabOpen = (userEvent: IEventSummary) => {
    switch (activeTab) {
      case 1:
        return (
          <EventSummaryCocktails
            cocktailsList={userEvent.event.cocktails}
            isPreview={false}
          />
        )
      case 2:
        return (
          <EventSummaryShoppingList
            shoppingList={userEvent.shoppingList}
            isPreview={false}
          />
        )

      case 3:
        return (
          <EventSummaryInvites
            invites={userEvent.invites}
            eventId={userEvent.event.id}
            isPreview={false}
          />
        )
      default:
        return (
          <EventSummaryCocktails
            cocktailsList={userEvent.event.cocktails}
            isPreview={false}
          />
        )
    }
  }

  const whatReturn = () => {
    switch (whatPage) {
      case 'MAIN':
        return (
          <div className="eventSummary">
            <div className="summaryPageWrapper">
              {loading ? (
                <Loader />
              ) : (
                event && (
                  <div className="userEventsInner summaryPageInner">
                    <EventSummaryHeader
                      partyDate={event.event.plannedAt}
                      partyGuests={event.event.guestsCount}
                      partyImage={event.event.image}
                      partyLocation={event.event.location}
                      partyName={event.event.name}
                      partyType={
                        event.event.occasion.name
                          ? event.event.occasion.name
                          : ''
                      }
                      isOwner={event.event.isOwner}
                      eventStatus={event.event.eventStatusType}
                      openModal={openModal}
                      openDetails={openDetails}
                    />
                    {/* {event.event.notes && (
                    <div className="partySummaryNotes">
                      <h5>{event.event.notes}</h5>
                    </div>
                  )} */}
                    {window.innerWidth > MobileDevicesWidth ? (
                      <div className="summaryPageInnerContent">
                        <div className="summaryPageInnerContentTitle">
                          <div
                            className={`summaryPageInnerContentTitleItem ${activeTab === 1 ? 'activeTab' : ''}`}
                            onClick={() => {
                              setActiveTab(1)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                setActiveTab(1)
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <h5>cocktails menu</h5>
                            <div className="summaryPageInnerContentTitleItemLine" />
                          </div>
                          <div
                            className={`summaryPageInnerContentTitleItem ${activeTab === 2 && 'activeTab'}`}
                            onClick={() => {
                              setActiveTab(2)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                setActiveTab(2)
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <h5>Shopping list</h5>
                            <div className="summaryPageInnerContentTitleItemLine" />
                          </div>
                          <div
                            className={`summaryPageInnerContentTitleItem ${activeTab === 3 && 'activeTab'}`}
                            onClick={() => {
                              setActiveTab(3)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                setActiveTab(3)
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <h5>invitations</h5>
                            <div className="summaryPageInnerContentTitleItemLine" />
                          </div>
                        </div>
                        <div className="summaryPageInnerContentInner">
                          {whatTabOpen(event)}
                        </div>
                      </div>
                    ) : (
                      <div className="summaryPageInnerContentMobile">
                        <div className="summaryPageInnerContentMobileInner">
                          <div className="summaryPageInnerContentMobileTitle">
                            <p>Cocktails menu</p>
                            <button
                              type="button"
                              onClick={() => setWhatPage('COCKTAILS')}
                            >
                              View All
                            </button>
                          </div>
                          <EventSummaryCocktails
                            cocktailsList={event.event.cocktails}
                            isPreview
                          />
                        </div>
                        <div className="summaryPageInnerContentMobileInner">
                          <div className="summaryPageInnerContentMobileTitle">
                            <p>Shopping list</p>
                            <button
                              type="button"
                              onClick={() => setWhatPage('SHOP')}
                            >
                              View All
                            </button>
                          </div>
                          <EventSummaryShoppingList
                            shoppingList={event.shoppingList}
                            isPreview
                          />
                        </div>
                        <div className="summaryPageInnerContentMobileInner">
                          <div className="summaryPageInnerContentMobileTitle">
                            <p>Invitations</p>
                            <button
                              type="button"
                              onClick={() => setWhatPage('INVITE')}
                            >
                              View All
                            </button>
                          </div>
                          <EventSummaryInvites
                            invites={event.invites}
                            eventId={event.event.id}
                            isPreview
                          />
                        </div>
                      </div>
                    )}

                    {window.innerWidth <= MobileDevicesWidth && (
                      <NextStepButton
                        handler={goBack}
                        isActive
                        btnText="Go to main"
                        isArrow
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )
      case 'COCKTAILS':
        return (
          <div className="eventSummary">
            <div className="summaryPageWrapper">
              {loading ? (
                <Loader />
              ) : (
                event && (
                  <MobileMorePage
                    title={event.event.name}
                    count={`${event.event.cocktails.length} cocktails`}
                    goBack={() => setWhatPage('MAIN')}
                    image={event.event.image}
                  >
                    <EventSummaryCocktails
                      cocktailsList={event.event.cocktails}
                      isPreview={false}
                    />
                  </MobileMorePage>
                )
              )}
            </div>
          </div>
        )
      case 'SHOP':
        return (
          <div className="eventSummary">
            <div className="summaryPageWrapper">
              {loading ? (
                <Loader />
              ) : (
                event && (
                  <MobileMorePage
                    title={event.event.name}
                    count={`${event.shoppingList.length} ingredients`}
                    goBack={() => setWhatPage('MAIN')}
                    image={event.event.image}
                  >
                    <EventSummaryShoppingList
                      shoppingList={event.shoppingList}
                      isPreview={false}
                    />
                  </MobileMorePage>
                )
              )}
            </div>
          </div>
        )
      case 'INVITE':
        return (
          <div className="eventSummary">
            <div className="summaryPageWrapper">
              {loading ? (
                <Loader />
              ) : (
                event && (
                  <MobileMorePage
                    title={event.event.name}
                    count={`${event.invites.length} invitations`}
                    goBack={() => setWhatPage('MAIN')}
                    image={event.event.image}
                  >
                    <EventSummaryInvites
                      invites={event.invites}
                      eventId={event.event.id}
                      isPreview={false}
                    />
                  </MobileMorePage>
                )
              )}
            </div>
          </div>
        )
      default:
        return (
          <div className="eventSummary">
            <div className="summaryPageWrapper">
              {loading ? (
                <Loader />
              ) : (
                event && (
                  <div className="userEventsInner summaryPageInner">
                    <EventSummaryHeader
                      partyDate={event.event.plannedAt}
                      partyGuests={event.event.guestsCount}
                      partyImage={event.event.image}
                      partyLocation={event.event.location}
                      partyName={event.event.name}
                      partyType={
                        event.event.occasion.name
                          ? event.event.occasion.name
                          : ''
                      }
                      isOwner={event.event.isOwner}
                      eventStatus={event.event.eventStatusType}
                      openModal={openModal}
                      openDetails={openDetails}
                    />
                    {/* {event.event.notes && (
                    <div className="partySummaryNotes">
                      <h5>{event.event.notes}</h5>
                    </div>
                  )} */}
                    {window.innerWidth > MobileDevicesWidth ? (
                      <div className="summaryPageInnerContent">
                        <div className="summaryPageInnerContentTitle">
                          <div
                            className={`summaryPageInnerContentTitleItem ${activeTab === 1 ? 'activeTab' : ''}`}
                            onClick={() => {
                              setActiveTab(1)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                setActiveTab(1)
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <h5>cocktails menu</h5>
                            <div className="summaryPageInnerContentTitleItemLine" />
                          </div>
                          <div
                            className={`summaryPageInnerContentTitleItem ${activeTab === 2 && 'activeTab'}`}
                            onClick={() => {
                              setActiveTab(2)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                setActiveTab(2)
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <h5>Shopping list</h5>
                            <div className="summaryPageInnerContentTitleItemLine" />
                          </div>
                          <div
                            className={`summaryPageInnerContentTitleItem ${activeTab === 3 && 'activeTab'}`}
                            onClick={() => {
                              setActiveTab(3)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                setActiveTab(3)
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <h5>invitations</h5>
                            <div className="summaryPageInnerContentTitleItemLine" />
                          </div>
                        </div>
                        <div className="summaryPageInnerContentInner">
                          {whatTabOpen(event)}
                        </div>
                      </div>
                    ) : (
                      <div className="summaryPageInnerContentMobile">
                        <div className="summaryPageInnerContentMobileInner">
                          <div className="summaryPageInnerContentMobileTitle">
                            <p>Cocktails menu</p>
                            <button type="button">View All</button>
                          </div>
                          <EventSummaryCocktails
                            cocktailsList={event.event.cocktails}
                            isPreview
                          />
                        </div>
                        <div className="summaryPageInnerContentMobileInner">
                          <div className="summaryPageInnerContentMobileTitle">
                            <p>Shopping list</p>
                            <button type="button">View All</button>
                          </div>
                          <EventSummaryShoppingList
                            shoppingList={event.shoppingList}
                            isPreview
                          />
                        </div>
                        <div className="summaryPageInnerContentMobileInner">
                          <div className="summaryPageInnerContentMobileTitle">
                            <p>Invitations</p>
                            <button type="button">View All</button>
                          </div>
                          <EventSummaryInvites
                            invites={event.invites}
                            eventId={event.event.id}
                            isPreview
                          />
                        </div>
                      </div>
                    )}

                    {window.innerWidth <= MobileDevicesWidth && (
                      <NextStepButton
                        handler={goBack}
                        isActive
                        btnText="Go to main"
                        isArrow
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )
    }
  }

  return whatReturn()
}
