/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './index.scss'
import RoutesPaths from '../../../constants/navigate'

import { ReactComponent as MyIngredient } from '../assets/my-ingredient.svg'
import { ReactComponent as MyIngredientActive } from '../assets/my-ingredient-active.svg'
import { ReactComponent as MyCocktail } from '../assets/my-cocktail.svg'
import { ReactComponent as MyCocktailActive } from '../assets/my-cocktail-active.svg'

interface BarWrapperProps {
  openTab: 'cocktails' | 'ingredients'
  children: ReactNode
  secondClass:
    | ''
    | 'mediumIngInnerHeight'
    | 'largeIngInnerHeight'
    | 'mediumCockInnerHeight'
}

function BarWrapper({ openTab, children, secondClass }: BarWrapperProps) {
  const [isCocktails, setIsCocktails] = useState<boolean>(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (openTab === 'ingredients') {
      setIsCocktails(false)
    }
  }, [])

  return (
    <div className="myBarWrapper">
      <div className={`myBarInner ${secondClass}`}>
        <div className="myBarInnerHeader">
          <div
            className={
              isCocktails
                ? 'myBarInnerHeaderItem activeTab'
                : 'myBarInnerHeaderItem'
            }
            onClick={() => {
              setIsCocktails(true)
              navigate(`/${RoutesPaths.myBarCocktailsPage}`)
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setIsCocktails(true)
                navigate(`/${RoutesPaths.myBarCocktailsPage}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            {isCocktails ? <MyCocktailActive /> : <MyCocktail />}
            <h4>My cocktails</h4>
            <div className="myBarInnerHeaderItemBottomLine" />
          </div>
          <div
            className={
              !isCocktails
                ? 'myBarInnerHeaderItem activeTab'
                : 'myBarInnerHeaderItem'
            }
            onClick={() => {
              setIsCocktails(false)
              navigate(`/${RoutesPaths.myBarIngredientsPage}`)
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setIsCocktails(false)
                navigate(`/${RoutesPaths.myBarIngredientsPage}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            {!isCocktails ? <MyIngredientActive /> : <MyIngredient />}
            <h4>My ingredients</h4>
            <div className="myBarInnerHeaderItemBottomLine" />
          </div>
        </div>
        <div className="myBarInnerContent">{children}</div>
      </div>
    </div>
  )
}

export default BarWrapper
