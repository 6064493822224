import axios, { AxiosResponse } from 'axios'
import axiosInstance from '..'
import { getParams } from '../../store/reducers/auth/authUtils'
import {
  ICocktail,
  ICocktailSelectedFilters,
  ICocktailsList,
} from '../../@types/cocktails'
import { IBarCocktailsCollection } from '../../@types/userBar'

interface GetCocktailsRequestParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  searchField?: string
  sortValue: string
  excludeCocktails: number[]
}

class CocktailsService {
  static async getCocktails({
    page,
    size,
    selectedFilters,
    searchField,
    sortValue,
    excludeCocktails,
  }: GetCocktailsRequestParams): Promise<AxiosResponse<ICocktailsList>> {
    const url = '/cocktails'
    const searchParams = new URLSearchParams()

    if (excludeCocktails.length) {
      searchParams.set('exclude', excludeCocktails.join(','))
    }

    Object.keys(selectedFilters).forEach(key => {
      const values = selectedFilters[key]
      if (values.length > 0) {
        searchParams.set(key, values.join(','))
      }
    })

    if (searchField) {
      searchParams.set('name', searchField)
    }

    if (sortValue) {
      searchParams.append('sort', sortValue)
      if (sortValue === 'isClassic,DESC') {
        searchParams.append('sort', 'name,ASC')
      }
    }

    searchParams.set('page', page.toString())
    searchParams.set('size', size)

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async getCocktail(id: string): Promise<AxiosResponse<ICocktail>> {
    return axiosInstance.get(`/cocktails/${id}`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async cocktailFavorites(
    id: string,
    isFavorites: boolean,
    collections: IBarCocktailsCollection[],
  ): Promise<AxiosResponse> {
    const collectionsMap = collections.map((item: IBarCocktailsCollection) => {
      return item.id
    })

    return axiosInstance.post(
      `/users/collections/cocktails/${id}`,
      {
        collections: collectionsMap,
        addToFavorites: isFavorites,
      },
      {
        headers: {
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  static async cocktailAnalogs(
    id: string,
  ): Promise<AxiosResponse<ICocktail[]>> {
    return axiosInstance.get(`/cocktails/${id}/analogs`, {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }

  static async popularByIngredientCocktails(
    id: string,
  ): Promise<AxiosResponse<ICocktail[]>> {
    return axiosInstance.get(`/cocktails/ingredients/${id}`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }
}

export default CocktailsService
