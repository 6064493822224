import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

export default function ForgetPasswordComponent() {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <>
      <div className="login-form-input">
        {errors.email?.message && (
          <p className={'errorField'}>{String(errors.email?.message)}</p>
        )}
        <input
          type="email"
          {...register('email')}
          placeholder="Email Address"
          className={errors.email?.message ? 'error-border' : ''}
          required
        />
      </div>
    </>
  )
}
