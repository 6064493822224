/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthService from '../../../api/auth'
import { AppDispatch } from '../../index'
import { saveApiPath, saveLoginApprove, setParams } from './authUtils'
import { AuthActionEnum, DeleteAuthAction, SetAuthAction } from './types'
import { messagesError } from '../../../components/Login/actions'
import RoutesPaths from '../../../constants/navigate'
import { IAuthConfirmState, IAuthErrorState } from '../../../@types/auth'

const AuthActionCreators = {
  setAuthorized: (): SetAuthAction => ({ type: AuthActionEnum.AUTHORIZED }),
  deleteAuthorized: (): DeleteAuthAction => ({
    type: AuthActionEnum.UNAUTHORIZED,
  }),

  config: () => async () => {
    try {
      const { data } = await AuthService.getConfig()
      saveApiPath(data.api, data.staticApi)
    } catch (error: unknown) {
      console.error(error)
    }
  },

  userRegister:
    (
      email: string,
      username: string,
      password: string,
      verifyEmail: (arg0: string) => void,
      setState: (cb: (prev: IAuthErrorState) => IAuthErrorState) => void,
      setErrorField: (param: string) => void,
      reset: () => void,
    ) =>
    async () => {
      try {
        setState((prev: IAuthErrorState) => ({ ...prev, loading: true }))
        await AuthService.userRegister(email, username, password)
        setState((prev: IAuthErrorState) => ({ ...prev, stateMassage: true }))
        // await setParams(data)
        setState((prev: IAuthErrorState) => ({ ...prev, loading: false }))
        verifyEmail(email)
        reset()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setState((prev: IAuthErrorState) => ({
          ...prev,
          loading: true,
        }))
        setState((prev: IAuthErrorState) => ({ ...prev, errorAnimation: true }))
        setState((prev: IAuthErrorState) => ({ ...prev, loading: false }))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setErrorField(error.response.data[0].field)
        setTimeout(() => {
          setErrorField('')
        }, 5000)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        messagesError(error.response.data[0].error_message)
        console.error(error)
      }
    },

  userLogin: (
    usernameORemail: string,
    password: string,
    setState: (cb: (prev: IAuthErrorState) => IAuthErrorState) => void,
    navigate: (path: any) => void,
    setErrorField: (param: string) => void,
    reset: () => void,
    getProfile: () => void,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await AuthService.userLogin(usernameORemail, password)
        if (window.history.length <= 2) {
          navigate(`/${RoutesPaths.cocktailsPage}`)
        } else {
          navigate(-1)
        }
        setParams(data)
        getProfile()
        dispatch({ type: AuthActionEnum.AUTHORIZED })
        saveLoginApprove(true)
        reset()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: unknown) {
        saveLoginApprove(false)
        setState((prev: IAuthErrorState) => ({ ...prev, errorAnimation: true }))
        setErrorField('all')
        setTimeout(() => {
          setErrorField('')
        }, 5000)
        messagesError('Incorrect email or password')
        console.error(error)
      }
    }
  },
  userResetPassword:
    (
      email: string,
      setState: (cb: (prev: IAuthErrorState) => IAuthErrorState) => void,
      setErrorField: (param: string) => void,
    ) =>
    async () => {
      try {
        setState((prev: IAuthErrorState) => ({ ...prev, loading: true }))
        await AuthService.userResetPassword(email)
        setState((prev: IAuthErrorState) => ({ ...prev, stateMassage: true }))
        setState((prev: IAuthErrorState) => ({ ...prev, loading: false }))
        setState((prev: IAuthErrorState) => ({ ...prev, resetPassword: false }))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setState((prev: IAuthErrorState) => ({ ...prev, errorAnimation: true }))
        saveLoginApprove(false)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setErrorField(error.response.data[0].field)
        setTimeout(() => {
          setErrorField('')
        }, 5000)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        messagesError(error.response.data[0].error_message)
        console.error(error)
      }
    },

  userChangePassword:
    (oldPassword: string, newPassword: string) => async () => {
      try {
        await AuthService.userChangePassword(oldPassword, newPassword)
        alert('Пароль изменён')
      } catch (error: unknown) {
        alert('ошибка')
        console.error(error)
      }
    },

  userNewPassword:
    (
      password: string,
      token: string,
      setState: (cb: (prev: IAuthErrorState) => IAuthErrorState) => void,
      setErrorField: (param: string) => void,
      // reset: () => void,
    ) =>
    async () => {
      try {
        setState((prev: IAuthErrorState) => ({ ...prev, loading: true }))
        await AuthService.userNewPassword(token, password)
        setState((prev: IAuthErrorState) => ({ ...prev, stateMassage: true }))
        setState((prev: IAuthErrorState) => ({ ...prev, loading: false }))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setState((prev: IAuthErrorState) => ({ ...prev, errorAnimation: true }))
        saveLoginApprove(false)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setErrorField(error.response.data[0].field)
        setTimeout(() => {
          setErrorField('')
        }, 5000)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        messagesError(error.response.data[0].error_message)
        console.error(error)
      }
    },
    userConfirm:
    (
      email: string,
      code: number,
      setState: (cb: (prev: IAuthConfirmState) => IAuthConfirmState) => void,
    ) =>
    async () => {
      try {
        setState((prev: IAuthConfirmState) => ({ ...prev, loading: true }))
        await AuthService.userConfirm(email, code)
        setState((prev: IAuthConfirmState) => ({ ...prev, access: true }))
        // await setParams(data)
        setState((prev: IAuthConfirmState) => ({ ...prev, loading: false }))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setState((prev: IAuthConfirmState) => ({
          ...prev,
          loading: true,
        }))
        setState((prev: IAuthConfirmState) => ({ ...prev, loading: false }))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        messagesError(error.response.data[0].error_message)
        console.error(error)
      }
    },
    userResendOneTimePassword:
    (
      email: string,
      setState: (cb: (prev: IAuthConfirmState) => IAuthConfirmState) => void,
    ) =>
    async () => {
      try {
        setState((prev: IAuthConfirmState) => ({ ...prev, loading: true }))
        await AuthService.userResendOneTimePassword(email)
        // await setParams(data)
        setState((prev: IAuthConfirmState) => ({ ...prev, loading: false }))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setState((prev: IAuthConfirmState) => ({
          ...prev,
          loading: true,
        }))
        setState((prev: IAuthConfirmState) => ({ ...prev, loading: false }))
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        messagesError(error.response.data[0].error_message)
        console.error(error)
      }
    },
}

export default AuthActionCreators
