import { useNavigate } from 'react-router-dom'
import NextStepButton from '../../components/NextStepButton'

import { ReactComponent as SuccessInviteIcon } from '../../assets/success-invite-icon.svg'

interface PartyEmailsSuccessProps {
  eventId: string
}

function PartyEmailsSuccess({ eventId }: PartyEmailsSuccessProps) {
  const navigate = useNavigate()

  return (
    <>
      <div className="partyEmailsAddressWrapperSuccess">
        <h3>Invitations to the specified emails have been sent.</h3>
        <SuccessInviteIcon />
      </div>
      <NextStepButton
        handler={() => navigate(`/events/summary/${eventId}`)}
        isActive
        btnText="review event"
        isArrow={false}
      />
    </>
  )
}

export default PartyEmailsSuccess
