/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useState } from 'react'
import { FixedSizeGrid as Grid } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { AutoSizer } from 'react-virtualized'

import ContentWrapper from '../../components/ContentWrapper'
import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'
import CocktailsCard from './CocktailsCard'
import SelectCheckbox from '../../components/SelectCheckbox'

import { CocktailsSort } from '../../constants/sort'
import {
  cocktailsColumnsWidth,
  cocktailsColumnsCount,
  cocktailsRowsHeight,
  cocktailsRowsCount,
  cocktailsItemIndex,
} from '../../helpers/cocktailsPageHelpers'
import { MobileDevicesWidth } from '../../constants/screensWidth'
import { ICocktail, ICocktailSelectedFilters } from '../../@types/cocktails'

import { ReactComponent as ClearFiltersIcon } from '../../assets/images/svg/clear-filters-icon.svg'
import { ReactComponent as ApplyFiltersIcon } from '../../assets/images/svg/apply-filters-icon.svg'
import { FiltersState } from '../../store/reducers/filters/types'
import { PageTitles } from '../../constants/pageTitles'

const SIZE = '30'

interface CellProps {
  columnIndex: number
  rowIndex: number
  style: object
}

interface CocktailsPageProps {
  isEvents: boolean
  excludeCocktails: number[]
  selectCocktail?: (value: ICocktail) => void
  selectedCocktails?: ICocktail[]
  cardsCount?: number
  cardsWidth?: number
  cardsHeight?: number
  isFromEvents?: boolean
}

function CocktailsPage({
  isEvents,
  excludeCocktails,
  selectCocktail,
  selectedCocktails,
  cardsCount,
  cardsWidth,
  cardsHeight,
  isFromEvents = false,
}: CocktailsPageProps) {
  const {
    getCocktails,
    clearCocktailsList,
    setSelectedFilter,
    clearSelectedCocktailsFilters,
    clearSelectedFilters,
  } = useActions()

  const { content, totalPages, totalElements } = useTypedSelector(
    state => state.cocktails,
  )
  const {
    flavors,
    glasses,
    categories,
    selectedFilters,
    complexity,
    allergens,
    strength,
  } = useTypedSelector<FiltersState>(state => state.filter)

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [isShowFilters, setIsShowFilters] = useState<boolean>(false)
  const [needRequest, setNeedRequest] = useState<boolean>(false)
  const [searchField, setSearchValue] = useState<string>('')
  const [sortItem, setSortItem] = useState<string>('')

  useEffect(() => {
    document.title = PageTitles.cocktailsPage

    return () => {
      // clearSelectedFilters()
      clearCocktailsList()
      setCurrentPage(0)
    }
  }, [])

  useEffect(() => {
    if (needRequest) {
      setCurrentPage(0)
      clearCocktailsList()
      getCocktails({
        page: currentPage,
        size: SIZE,
        selectedFilters,
        searchField,
        sortValue: sortItem,
        excludeCocktails,
      })
      setNeedRequest(false)
    }
  }, [needRequest])

  useEffect(() => {
    getCocktails({
      page: currentPage,
      size: SIZE,
      selectedFilters,
      searchField,
      sortValue: sortItem,
      excludeCocktails,
    })
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(0)
    clearCocktailsList()
    getCocktails({
      page: 0,
      size: SIZE,
      selectedFilters,
      searchField,
      sortValue: sortItem,
      excludeCocktails,
    })
  }, [selectedFilters])

  const memoContent = useMemo(() => content, [content.length])

  const loadOnScroll = () => {
    if (currentPage !== totalPages - 1) {
      return setCurrentPage(prev => prev + 1)
    }

    return setCurrentPage(prev => prev)
  }

  const Cell = ({ columnIndex, rowIndex, style }: CellProps) => {
    const index = cocktailsItemIndex(rowIndex, columnIndex)
    const item = content[index]
    if (item) {
      return (
        <div style={style}>
          <CocktailsCard
            key={index}
            isShowFilters={isFromEvents}
            item={item}
            isEvents={isEvents}
            selectCocktail={selectCocktail}
            excludeCocktails={selectedCocktails}
          />
        </div>
      )
    }
    return <div style={style} />
  }

  return (
    <ContentWrapper
      setCurrentPage={setCurrentPage}
      searchField={searchField}
      setSearchValue={setSearchValue}
      setRequest={setNeedRequest}
      sortByList={CocktailsSort}
      sortByListFunc={(name: string) => setSortItem(name)}
      isIngredientsList={false}
    >
      <div className="childrenWrapper">
        <div style={{ width: '100%', height: 'auto' }}>
          <AutoSizer>
            {({ width, height }) => (
              <InfiniteLoader
                isItemLoaded={index => index < memoContent.length - 1}
                loadMoreItems={loadOnScroll}
                itemCount={totalElements}
                threshold={6}
              >
                {({ onItemsRendered, ref }) => (
                  <Grid
                    width={width}
                    height={height}
                    rowHeight={
                      cardsHeight || cocktailsRowsHeight(isShowFilters)
                    }
                    rowCount={
                      cardsCount
                        ? memoContent.length / cardsCount
                        : cocktailsRowsCount(memoContent.length)
                    }
                    columnCount={cardsCount || cocktailsColumnsCount()}
                    columnWidth={cardsWidth || cocktailsColumnsWidth(width)}
                    ref={ref}
                    onItemsRendered={gridData => {
                      const {
                        visibleRowStartIndex,
                        visibleRowStopIndex,
                        visibleColumnStopIndex,
                        overscanRowStartIndex,
                        overscanRowStopIndex,
                        overscanColumnStopIndex,
                      } = gridData

                      const overscanStartIndex =
                        overscanRowStartIndex * (overscanColumnStopIndex + 1)
                      const overscanStopIndex =
                        overscanRowStopIndex * (overscanColumnStopIndex + 1)
                      const visibleStartIndex =
                        visibleRowStartIndex * (visibleColumnStopIndex + 1)
                      const visibleStopIndex =
                        visibleRowStopIndex * (visibleColumnStopIndex + 1)

                      onItemsRendered({
                        overscanStartIndex,
                        overscanStopIndex,
                        visibleStartIndex,
                        visibleStopIndex,
                      })
                    }}
                  >
                    {Cell}
                  </Grid>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default CocktailsPage
