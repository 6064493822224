/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useContext } from 'react'
import { ProfileContext } from '../contexts/ProfileContext'

function useProfile() {
  const context = useContext(ProfileContext)
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider')
  }
  return context
}

export default useProfile
