/* eslint-disable react/require-default-props */
import { ReactComponent as StepBackIcon } from '../../assets/step-back-icon.svg'

import './index.scss'

interface PartyHeaderProps {
  prevStep?: number
  stepBack?: (value: number) => void
  customFunc?: () => void
  stepTitle: string
  loadingPercent: string
}

function EventHeader({
  prevStep,
  stepBack,
  stepTitle,
  loadingPercent,
  customFunc,
}: PartyHeaderProps) {
  function whatBack() {
    if (customFunc) {
      return customFunc()
    }
    if (prevStep && stepBack) {
      return stepBack(prevStep)
    }

    return false
  }

  return (
    <div className="partyHeaderWrapper">
      <div className="partyHeaderTitle">
        <StepBackIcon onClick={() => whatBack()} />
        <h2>{stepTitle}</h2>
      </div>
      <div className="partyHeaderProgressBar">
        <div
          className="partyHeaderProgressBarDone"
          style={{ width: loadingPercent }}
        />
      </div>
    </div>
  )
}

export default EventHeader
