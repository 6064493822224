/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as CreateFromScratchIcon } from '../../assets/create-from-scratch-icon.svg'
import { ReactComponent as CreateFromTemplateIcon } from '../../assets/create-from-template-icon.svg'
import { ReactComponent as JoinEventIcon } from '../../assets/party-location-icon.svg'

import NextStepButton from '../../components/NextStepButton'
import useActions from '../../../../hooks/useActions'

import './index.scss'
import RoutesPaths from '../../../../constants/navigate'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventsDesktopTabs from '../../components/EventDesktopTabs'
import EventHeader from '../../components/EventHeader'
import EventStepTitle from '../../components/EventStepTitle'

function EventsType() {
  const [partyType, setPartyType] = useState<
    '' | 'scratch' | 'template' | 'join'
  >('')
  const navigate = useNavigate()

  const { occasions } = useTypedSelector(state => state.events)
  const { getPartyOccasions } = useActions()

  useEffect(() => {
    if (!occasions.length) {
      getPartyOccasions()
    }
  }, [])

  const nextStepHandler = () => {
    switch (partyType) {
      case 'scratch':
        return navigate(`/${RoutesPaths.partyEventScratchPage}`)
      case 'template':
        return navigate(`/${RoutesPaths.partyEventTemplatePage}`)
      default:
        return null
    }
  }

  return (
    <div className="userEvents">
      <div className="userEventsWrapper userEventsWrapperSteps">
        {window.innerWidth > MobileDevicesWidth ? (
          <EventsDesktopTabs
            currentStep={0}
            stepBack={() => navigate(`/${RoutesPaths.eventsPages}`)}
            isCreateEvent
            isSendEmail={false}
          />
        ) : (
          <EventHeader
            customFunc={() => navigate(`/${RoutesPaths.eventsPages}`)}
            loadingPercent="5%"
            stepTitle="Create a New Event"
          />
        )}
        <div className="userEventsInner">
          <div className="partyPlanningTypeWrapper">
            {window.innerWidth > MobileDevicesWidth && (
              <EventStepTitle
                title="Create a New Event"
                text="Choose from one of the following options to get started:"
              />
            )}
            <div className="partyPlanningTypeCards">
              <div
                className={
                  partyType === 'scratch'
                    ? 'partyPlanningTypeCard activeCard'
                    : 'partyPlanningTypeCard'
                }
                onClick={() => {
                  setPartyType('scratch')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    setPartyType('scratch')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <p>Create from scratch</p>
                <CreateFromScratchIcon />
              </div>
              <div
                className={
                  partyType === 'template'
                    ? 'partyPlanningTypeCard activeCard'
                    : 'partyPlanningTypeCard'
                }
                onClick={() => {
                  setPartyType('template')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    setPartyType('template')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <p>Create from template</p>
                <CreateFromTemplateIcon />
              </div>
              <div
                className={
                  partyType === 'join'
                    ? 'partyPlanningTypeCard activeCard'
                    : 'partyPlanningTypeCard'
                }
                onClick={() => {
                  setPartyType('join')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    setPartyType('join')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <p>Join an event near me</p>
                <JoinEventIcon />
              </div>
            </div>
            <NextStepButton handler={nextStepHandler} isActive={!!partyType} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventsType
