const RoutesPaths = {
  notFound: '*',
  loginPage: 'login',
  registerPage: 'register',
  recoveryPasswordPage: 'recovery-password',
  updatePasswordPage: 'update-password',
  cocktailsPage: 'cocktails',
  cocktailsPageCocktail: 'cocktails/:id',
  ingredientsPage: 'ingredients',
  profilePage: 'profile',
  plansPage: 'plans',
  plansSubscriptionsPage: 'plans/subscriptions',
  successSubPage: 'plans/subscriptions/success',
  failureSubPage: 'plans/subscriptions/failure',
  ingredientMobilePage: 'ingredients/:id',
  unsubscribePage: 'profile/notifications/unsubscribe',
  eventsPages: 'events',
  eventsPagesCreate: 'events/create',
  partyEventScratchPage: 'events/create/scratch',
  partyEventSummaryPage: 'events/summary/:id',
  partyInvitesPage: 'events/:id/invites',
  partyEventTemplatePage: 'events/create/template',
  // TODO: Новый май бар
  myBarCocktailsPage: 'dbar/cocktails',
  myBarIngredientsPage: 'dbar/ingredients',
  myBarIngredientsFavoritesPage: 'dbar/ingredients/favorites',
  myBarIngredientsMixologyPage: 'dbar/ingredients/mixology',
  myBarCocktailsFavorites: 'dbar/cocktails/favorites',
  myBarCocktailsCollections: 'dbar/cocktails/collections',
  myBarCocktailsCollectionById: 'dbar/cocktails/collections/:id',
}

export default RoutesPaths
