/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeGrid as Grid, FixedSizeList as List } from 'react-window'
import { AutoSizer } from 'react-virtualized'

import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'

import BarWrapper from '../../BarWrapper'

import './index.scss'
import IngredientsCard from '../../../IngredientsPage/IngredientsCard'
import useActions from '../../../../hooks/useActions'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import {
  ICocktailFilter,
  ICocktailSelectedFilters,
} from '../../../../@types/cocktails'
import RoutesPaths from '../../../../constants/navigate'

import { PageTitles } from '../../../../constants/pageTitles'
import { BarState } from '../../../../store/reducers/bar/types'
import { SearchFilters } from '../../../../components/SearchFilters'
import { FiltersState } from '../../../../store/reducers/filters/types'
import {
  myIngredientsColumnsCount,
  myIngredientsColumnsWidth,
  myIngredientsItemIndex,
  myIngredientsRowsCount,
  myIngredientsRowsHeight,
} from '../../../../helpers/myIngredientsPageHelpers'
import { MobileDevicesWidth } from '../../../../constants/screensWidth'

interface FiltersInterface {
  categories: ICocktailFilter[]
  allergens: ICocktailFilter[]
  isPrimary: ICocktailFilter[]
  selectedFilters: ICocktailSelectedFilters
}

interface ICocktailFilterComponent extends ICocktailFilter {
  filterName: string
}

interface CellProps {
  columnIndex: number
  rowIndex: number
  style: object
}

export default function FavoritesIngredientsBar() {
  const navigate = useNavigate()
  const { getFavoriteIngredients, setSelectedFilter } = useActions()

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [searchField, setSearchValue] = useState<string>('')
  const [needRequest, setNeedRequest] = useState<boolean>(false)
  const [matchingObjects, setMatchingObjects] = useState<
    ICocktailFilterComponent[]
  >([])

  const { barFavoriteIngredients } = useTypedSelector<BarState>(
    state => state.bar,
  )

  const { selectedFilters, isPrimary, allergens, categories } =
    useTypedSelector<FiltersInterface>(state => state.filter)

  const findMatchingObjects = (
    stringArrays: ICocktailSelectedFilters,
    objectArrays: Record<string, ICocktailFilter[]>,
  ) => {
    const arr: ICocktailFilterComponent[] = []

    Object.keys(stringArrays).forEach(key => {
      const stringArray = stringArrays[key]
      const objectArray = objectArrays[key]
      if (objectArray) {
        stringArray.forEach(str => {
          const matchingObject = objectArray.find(
            (obj: ICocktailFilter) => obj.id.toString() === str,
          )
          if (matchingObject) {
            arr.push({ ...matchingObject, filterName: key })
          }
        })
      }
    })

    return setMatchingObjects(arr)
  }

  if (
    Object.values(selectedFilters).some(value => value.length !== 0) &&
    currentPage !== 0
  ) {
    setCurrentPage(0)
  }

  // useEffect(() => {
  //   getFavoriteIngredients({
  //     page: currentPage,
  //     size: '54',
  //     selectedFilters,
  //     searchFields: searchField,
  //   })
  //   findMatchingObjects(selectedFilters, {
  //     isPrimary,
  //     ingAllergen: allergens,
  //     ingCategory: categories,
  //   })
  // }, [selectedFilters, searchField, currentPage])

  const fetchIngredients = () => {
    getFavoriteIngredients({
      page: currentPage,
      size: '54',
      selectedFilters,
      searchFields: searchField,
    })
    findMatchingObjects(selectedFilters, {
      isPrimary,
      ingAllergen: allergens,
      ingCategory: categories,
    })
  }

  useEffect(() => {
    setCurrentPage(0)
    // clearIngredientsList()
  }, [selectedFilters, searchField])

  useEffect(() => {
    fetchIngredients()
  }, [currentPage, selectedFilters, searchField])

  const memoContent = useMemo(
    () => barFavoriteIngredients.content,
    [barFavoriteIngredients.content.length],
  )

  function handleCheckboxChange(
    item: string,
    selectedItems: string[],
    filterName: string,
  ) {
    if (selectedItems.includes(item)) {
      setSelectedFilter(
        selectedItems.filter(selectedItem => selectedItem !== item),
        filterName,
      )
    } else {
      setSelectedFilter([...selectedItems, item], filterName)
    }
  }

  const loadOnScroll = () => {
    if (currentPage !== barFavoriteIngredients.totalPages - 1) {
      return setCurrentPage(prev => prev + 1)
    }

    return setCurrentPage(prev => prev)
  }

  const Cell = ({ columnIndex, rowIndex, style }: CellProps) => {
    const index = myIngredientsItemIndex(rowIndex, columnIndex)
    const item = barFavoriteIngredients.content[index]
    if (item) {
      return (
        <div style={style}>
          <IngredientsCard key={index} item={item} />
        </div>
      )
    }
    return <div style={style} />
  }

  const hasValuesInSelectedFilters = (
    values: ICocktailSelectedFilters,
  ): boolean => {
    for (const [key, value] of Object.entries(values)) {
      if (
        value.length &&
        (key === 'isPrimary' || key === 'ingCategory' || key === 'ingAllergen')
      ) {
        return true
      }
      console.log(value)
    }
    return false
  }

  return (
    <BarWrapper openTab="ingredients" secondClass="mediumIngInnerHeight">
      <div className="myBarIngredientsWrapper">
        <div
          className="tabHeader"
          onClick={() => {
            navigate(`/${RoutesPaths.myBarIngredientsPage}`)
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              navigate(`/${RoutesPaths.myBarIngredientsPage}`)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Arrow />
          <h1>My ingredients</h1>
        </div>
        <SearchFilters
          searchField={searchField}
          setCurrentPage={setCurrentPage}
          setRequest={setNeedRequest}
          setSearchValue={setSearchValue}
          isCocktailsFilters={false}
          isMixologyFilters={false}
          isIngredientsList
          isMyIngredientFilters
        />
        <div className="myBarCollectionFiltersWrapper">
          {matchingObjects.map(
            (item: ICocktailFilterComponent, index: number) => {
              return (
                index < 9 && (
                  <div
                    className="myBarCollectionFiltersWrapperItem"
                    key={Math.random()}
                    onClick={() =>
                      handleCheckboxChange(
                        item.id.toString(),
                        selectedFilters[item.filterName],
                        item.filterName,
                      )
                    }
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        handleCheckboxChange(
                          item.id.toString(),
                          selectedFilters[item.filterName],
                          item.filterName,
                        )
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <p>{item.name}</p>
                    <CloseIcon />
                  </div>
                )
              )
            },
          )}
        </div>
        <div
          className={`myBarIngredientsInner ${hasValuesInSelectedFilters(selectedFilters) ? 'withFilters' : ''}`}
        >
          <AutoSizer>
            {({ width, height }) => (
              <InfiniteLoader
                isItemLoaded={index => index < memoContent.length - 1}
                loadMoreItems={loadOnScroll}
                itemCount={barFavoriteIngredients.totalElements}
                threshold={12}
              >
                {({ onItemsRendered, ref }) => (
                  <Grid
                    width={width}
                    height={
                      height
                      // window.innerWidth <= MobileDevicesWidth ? height : 580
                    }
                    rowHeight={myIngredientsRowsHeight()}
                    rowCount={myIngredientsRowsCount(memoContent.length)}
                    columnCount={myIngredientsColumnsCount()}
                    columnWidth={myIngredientsColumnsWidth(width)}
                    ref={ref}
                    onItemsRendered={gridData => {
                      const {
                        visibleRowStartIndex,
                        visibleRowStopIndex,
                        visibleColumnStopIndex,
                        overscanRowStartIndex,
                        overscanRowStopIndex,
                        overscanColumnStopIndex,
                      } = gridData

                      const overscanStartIndex =
                        overscanRowStartIndex * (overscanColumnStopIndex + 1)
                      const overscanStopIndex =
                        overscanRowStopIndex * (overscanColumnStopIndex + 1)
                      const visibleStartIndex =
                        visibleRowStartIndex * (visibleColumnStopIndex + 1)
                      const visibleStopIndex =
                        visibleRowStopIndex * (visibleColumnStopIndex + 1)

                      onItemsRendered({
                        overscanStartIndex,
                        overscanStopIndex,
                        visibleStartIndex,
                        visibleStopIndex,
                      })
                    }}
                  >
                    {Cell}
                  </Grid>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
    </BarWrapper>
  )
}
