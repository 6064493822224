/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'antd'

import PopularCocktailsItem from '../../components/PopularCocktailsItem'

import { api } from '../../api'
import useTypedSelector from '../../hooks/useTypedSelector'
import useActions from '../../hooks/useActions'
import useHorizontalScroll from '../../hooks/useHorizontalScroll'
import RoutesPaths from '../../constants/navigate'
import { EngLocales } from '../../assets/locales/eng'

import { ReactComponent as NotFavorite } from '../../assets/images/svg/not-favorite.svg'
import { ReactComponent as Favorite } from '../../assets/images/svg/favorite.svg'
import { ICocktailFilter } from '../../@types/cocktails'

interface IngredientModalProps {
  item: ICocktailFilter
}

function IngredientModal({ item }: IngredientModalProps) {
  const navigate = useNavigate()

  const {
    hideModal,
    clearModalIngredient,
    ingredientFavorites,
    deleteIngredientFavorites,
    // getBarIngredients,
    clearPopularByIngredientCocktails,
  } = useActions()
  const { ingredient } = useTypedSelector(state => state.ingredients)
  const { popularByIngredientCocktail } = useTypedSelector(
    state => state.cocktails,
  )
  const { modalHidden } = useTypedSelector(state => state.modal)
  const [localFavorite, setLocalFavorite] = useState<boolean>(false)
  const { pathname } = useLocation()

  useEffect(() => {
    if (ingredient && ingredient.isFavorite) {
      setLocalFavorite(true)
    }
  }, [ingredient])

  useEffect(() => {
    return () => {
      clearModalIngredient()
      clearPopularByIngredientCocktails()
      // if (pathname === '/dbar') {
      //   getBarIngredients()
      // }
    }
  }, [])

  const onClickFavorites = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()

      if (!localFavorite) {
        ingredientFavorites(+item.id)
        setLocalFavorite(true)
      } else if (localFavorite) {
        deleteIngredientFavorites(+item.id)
        setLocalFavorite(false)
      }
    },
    [localFavorite],
  )

  const scrollRef = useHorizontalScroll()

  return (
    <Modal
      centered
      open={modalHidden}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      width={711}
    >
      <div className="ingredientModalWrapper">
        {ingredient && (
          <div id="modalLightbox">
            <div
              className="favorite-modal-icon"
              onClick={onClickFavorites}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onClickFavorites
                }
              }}
              role="button"
              tabIndex={0}
            >
              {localFavorite ? (
                <Favorite className="img-favorites-active" />
              ) : (
                <NotFavorite className="img-favorites" />
              )}
            </div>
            <img
              alt={ingredient.name || 'Default Alt'}
              src={`${api()}/images/ingredients/m/${ingredient.image}`}
              className="ingredientModalImage"
            />
          </div>
        )}
        <div className="ingredientModalWrapperDescription">
          {ingredient && (
            <div className="ingredientModalWrapperDescriptionMain">
              <h3>{ingredient.name}</h3>
              <div className="ingredientModalCategoriesWrapper">
                {ingredient?.categories?.map((elem: ICocktailFilter) => (
                  <div key={elem.id} className="ingredientModalCategories">
                    <p>#{elem.name}</p>
                  </div>
                ))}
                {ingredient.abv !== 0 && (
                  <div className="ingredientModalCategories">
                    <p>{ingredient.abv}°</p>
                  </div>
                )}
              </div>
              <div className="ingredientModalDescription">
                <p>{ingredient.description}</p>
              </div>
            </div>
          )}

          <div className="ingredientModalWrapperPopularCocktails">
            {popularByIngredientCocktail.length ? (
              <h3>
                {
                  EngLocales.IngredientsModal
                    .ingredientModalPopularCocktailsTitle
                }
              </h3>
            ) : (
              <div />
            )}
            <div
              className="ingredientModalWrapperPopularCocktailsWrapper"
              ref={scrollRef}
            >
              {popularByIngredientCocktail.map((elem: ICocktailFilter) => (
                <PopularCocktailsItem
                  key={elem.id}
                  id={+elem.id}
                  name={elem.name ? elem.name : ''}
                  image={`${api()}/images/cocktails/s/${elem?.image}`}
                  navigate={() =>
                    navigate(`${RoutesPaths.cocktailsPage}/${elem.id}`)
                  }
                  hideModal={() => hideModal()}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default IngredientModal
