import React from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { IEventInviteUsers } from '../../../../@types/events'

import { ReactComponent as SendIcon } from '../../assets/send-invite-icon.svg'
import { ReactComponent as AcceptIcon } from '../../assets/accept-invite-icon.svg'
import { ReactComponent as DiscardIcon } from '../../assets/discard-invite-icon.svg'

import './index.scss'

interface PartySummaryInvitesProps {
  invites: IEventInviteUsers[]
  eventId: string
  isPreview: boolean
}

function EventSummaryInvites({
  invites,
  eventId,
  isPreview,
}: PartySummaryInvitesProps) {
  const inviteStatus = (status: 'PENDING' | 'ACCEPTED' | 'DECLINED') => {
    switch (status) {
      case 'ACCEPTED':
        return <AcceptIcon />
      case 'DECLINED':
        return <DiscardIcon />
      case 'PENDING':
        return <SendIcon />
      default:
        return <SendIcon />
    }
  }

  return (
    <div className="partySummaryInvitesWrapper">
      {!isPreview && <h5>Guests List</h5>}
      <div className="partySummaryInvitesInner">
        {invites.map((item: IEventInviteUsers, index: number) => {
          if (isPreview) {
            return (
              index < 2 && (
                <div
                  className="partySummaryInvitesInnerItem"
                  key={Math.random()}
                >
                  <p>{item.email}</p>
                  {inviteStatus(item.status)}
                </div>
              )
            )
          }
          return (
            <div className="partySummaryInvitesInnerItem" key={Math.random()}>
              <p>{item.email}</p>
              {inviteStatus(item.status)}
            </div>
          )
        })}
      </div>
      {!isPreview && (
        <div className="moreInvites">
          <Link to={`/events/${eventId}/invites`}>
            If you want to send another invitation, <b>click here</b>
          </Link>
        </div>
      )}
    </div>
  )
}

export default EventSummaryInvites
