/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'

import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'

import AddImage from '../../page/MyBar/assets/add-collection-image.jpg'
import {
  IBarCocktailsCollection,
  IPatchCollectionParams,
} from '../../@types/userBar'
import { api } from '../../api'
import { BarState } from '../../store/reducers/bar/types'
import { MobileDevicesWidth } from '../../constants/screensWidth'

export default function PatchCollectionModal() {
  const [collectionName, setCollectionName] = useState<string>('')

  const [isFinish, setIsFinish] = useState<boolean>(true)
  const [previewImage, setPreviewImage] = useState<string | null>(null)
  const [value, setValue] = useState<File>()

  const { hideModal, patchCollection, uploadCollectionImage } = useActions()
  const { modalHidden } = useTypedSelector(state => state.modal)
  const { collectionImage, selectedCollection } = useTypedSelector<BarState>(
    state => state.bar,
  )

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result as string)
      }
      reader.readAsDataURL(file)

      setValue(file)
    }
  }

  const createCollection = () => {
    if (value) {
      uploadCollectionImage(value, setIsFinish)
    } else {
      setIsFinish(false)
    }
  }

  useEffect(() => {
    if (!isFinish) {
      const patchObj: IPatchCollectionParams = {
        collectionId: selectedCollection.id,
      }

      if (value) {
        patchObj.image = collectionImage
      }

      if (collectionName !== selectedCollection.name) {
        patchObj.name = collectionName
      }

      patchCollection(patchObj)
      hideModal()
    }
  }, [isFinish])

  useEffect(() => {
    setPreviewImage(`${api()}/images/collections/${selectedCollection.image}`)
    setCollectionName(selectedCollection.name)
  }, [])

  function mobileStyles() {
    if (window.innerWidth > MobileDevicesWidth) {
      return {}
    }

    return { top: 'calc(100vh - 342px)', paddingBottom: 0, margin: 0 }
  }

  return (
    <Modal
      centered={window.innerWidth > MobileDevicesWidth}
      open={modalHidden}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      closable={false}
      width={window.innerWidth > MobileDevicesWidth ? 506 : '100%'}
      style={mobileStyles()}
    >
      <div className="newCollectionModalWrapper">
        <div className="newCollectionModalInner">
          <h3>EDIT COLLECTION</h3>
          <div className="newCollectionModalInputs">
            <div className="newCollectionModalInputsImage">
              <label htmlFor="file-upload" className="custom-file-upload">
                {previewImage ? (
                  <img src={previewImage} alt="Preview" />
                ) : (
                  <img src={AddImage} alt="event pic" />
                )}
                <p>Change image</p>
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleImageChange}
              />
            </div>
            <input
              type="text"
              className="newCollectionModalInputsName"
              onChange={event => setCollectionName(event.target.value)}
              defaultValue={collectionName}
              placeholder="Name collection"
            />
          </div>
          <div className="newCollectionModalBtnsWrapper">
            <button onClick={() => hideModal()} type="button">
              cancel
            </button>
            <button onClick={() => createCollection()} type="button">
              change
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
