/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EventHeader from '../components/EventHeader'
import CocktailsChanges from '../EventSteps/CocktailsChanges'
import PartyScratchOccasion from '../EventSteps/PartyScratchOccasions'
import PartyScratchDetails from '../EventSteps/PartyScratchDetails'
import PartyScratchFlavors from '../EventSteps/PartyScratchFlavors'
import PartyScratchDrinks from '../EventSteps/PartyScratchDrinks'
import PartyScratchComplexity from '../EventSteps/PartyScratchComplexity'
import PartyScratchAllergens from '../EventSteps/PartyScratchAllergens'
import CocktailsAmount from '../EventSteps/CocktailsAmount'
import SaveEventDetails from '../EventSteps/SaveEventDetails'
import PartyDetailsName from '../EventSteps/PartyScratchDetailsName'
import PartyEventType from '../EventSteps/PartyEventType'
import PartyPhoto from '../EventSteps/PartyPhoto'
import CocktailsAddOther from '../EventSteps/CocktailsAddOther'

import RoutesPaths from '../../../constants/navigate'
import useActions from '../../../hooks/useActions'
import EventsDesktopTabs from '../components/EventDesktopTabs'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../constants/screensWidth'
import PartyScratchFlavorsDesktop from '../EventSteps/PartyScratchFlavorsDesktop'

function PartyScratchEventPage() {
  const navigate = useNavigate()

  const { clearEventInfo } = useActions()

  const [createStep, setCreateStep] = useState<number>(1)

  const goToEvents = () => {
    return navigate(`/${RoutesPaths.eventsPagesCreate}`)
  }

  useEffect(() => {
    return () => {
      clearEventInfo()
    }
  }, [])

  function eventSteps(step: number) {
    switch (step) {
      case 1:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                loadingPercent="10%"
                customFunc={goToEvents}
                stepTitle="Event occasion"
              />
            )}
            <div className="userEventsInner">
              <PartyScratchOccasion changeStep={setCreateStep} nextStep={2} />
            </div>
          </div>
        )
      case 2:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="15%"
                prevStep={1}
                stepTitle="Event Information"
              />
            )}
            <div className="userEventsInner">
              <PartyScratchDetails changeStep={setCreateStep} nextStep={3} />
            </div>
          </div>
        )
      case 3:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="22.5%"
                prevStep={2}
                stepTitle="Preferred flavours"
              />
            )}
            <div className="userEventsInner">
              {window.innerWidth > MobileDevicesWidth ? (
                <PartyScratchFlavorsDesktop
                  changeStep={setCreateStep}
                  nextStep={4}
                />
              ) : (
                <PartyScratchFlavors changeStep={setCreateStep} nextStep={4} />
              )}
            </div>
          </div>
        )
      case 4:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="30%"
                prevStep={3}
                stepTitle="Major drinks"
              />
            )}
            <div className="userEventsInner">
              <PartyScratchDrinks
                changeStep={setCreateStep}
                nextStep={window.innerWidth > MobileDevicesWidth ? 6 : 5}
              />
            </div>
          </div>
        )
      case 5:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            <EventHeader
              stepBack={setCreateStep}
              loadingPercent="37.5%"
              prevStep={4}
              stepTitle="Drinks complexity"
            />
            <div className="userEventsInner">
              <PartyScratchComplexity changeStep={setCreateStep} nextStep={6} />
            </div>
          </div>
        )
      case 6:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={5}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="45%"
                prevStep={5}
                stepTitle="Allergens (exclude)"
              />
            )}
            <div className="userEventsInner">
              <PartyScratchAllergens changeStep={setCreateStep} nextStep={7} />
            </div>
          </div>
        )
      case 7:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="52.5%"
                prevStep={6}
                stepTitle="Editing event cocktails"
              />
            )}
            <div className="useEventsInnerCocktails">
              <CocktailsChanges
                changeStep={setCreateStep}
                nextStep={8}
                addStep={99}
                isTemplate={false}
              />
            </div>
          </div>
        )
      case 8:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="60%"
                prevStep={7}
                stepTitle="Event cocktails"
              />
            )}
            <div className="useEventsInnerCocktails">
              <CocktailsAmount changeStep={setCreateStep} nextStep={9} />
            </div>
          </div>
        )
      case 9:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="67.5%"
                prevStep={8}
                stepTitle="Save Event Details"
              />
            )}
            <div className="userEventsInner">
              <SaveEventDetails changeStep={setCreateStep} nextStep={10} />
            </div>
          </div>
        )
      case 10:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="75%"
                prevStep={9}
                stepTitle="Event Details"
              />
            )}
            <div className="userEventsInner">
              <PartyDetailsName changeStep={setCreateStep} nextStep={11} />
            </div>
          </div>
        )
      case 11:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="82.5%"
                prevStep={10}
                stepTitle="Event Details"
              />
            )}
            <div className="userEventsInner">
              <PartyEventType changeStep={setCreateStep} nextStep={12} />
            </div>
          </div>
        )
      case 12:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > MobileDevicesWidth ? (
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            ) : (
              <EventHeader
                stepBack={setCreateStep}
                loadingPercent="90%"
                prevStep={11}
                stepTitle="Photo of Event"
              />
            )}
            <div className="userEventsInner">
              <PartyPhoto />
            </div>
          </div>
        )
      case 99:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            {window.innerWidth > LargeTabletDevicesWidth && (
              <EventsDesktopTabs
                currentStep={7}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
            )}
            <CocktailsAddOther
              changeStep={setCreateStep}
              nextStep={7}
              prevStep={7}
            />
          </div>
        )
      default:
        return (
          <div className="userEventsWrapper userEventsWrapperSteps">
            <EventHeader
              loadingPercent="10%"
              customFunc={goToEvents}
              stepTitle="Event occasion"
            />
            <div className="userEventsContainer">
              <EventsDesktopTabs
                currentStep={createStep}
                stepBack={setCreateStep}
                isCreateEvent={false}
                isSendEmail={false}
              />
              <div className="userEventsInner">
                <PartyScratchOccasion changeStep={setCreateStep} nextStep={2} />
              </div>
            </div>
          </div>
        )
    }
  }

  return <div className="userEvents">{eventSteps(createStep)}</div>
}

export default PartyScratchEventPage
