/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import { FiltersState } from '../../../../store/reducers/filters/types'
import useActions from '../../../../hooks/useActions'
import NextStepButton from '../../components/NextStepButton'
import { ICocktailFilter } from '../../../../@types/cocktails'

import AlcoFreeImage from '../../assets/alco-free-icon.svg'

import './index.scss'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'
import { staticApi } from '../../../../api'

interface PartyScratchDrinksProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyScratchDrinks({ changeStep, nextStep }: PartyScratchDrinksProps) {
  const [selectedDrinks, setSelectedDrinks] = useState<number[]>([])
  const [isNonAlcoholicEvent, setIsNonAlcoholicEvent] = useState<boolean>(false)

  const { categories } = useTypedSelector<FiltersState>(state => state.filter)
  const { cocktailsPreferences } = useTypedSelector(state => state.events)
  const { updateEventCocktailsPreferences, getCategories } = useActions()

  useEffect(() => {
    getCategories(true)

    if (cocktailsPreferences.categories) {
      setSelectedDrinks(cocktailsPreferences.categories)
    }
    if (cocktailsPreferences.isNonAlcoholicEvent) {
      setIsNonAlcoholicEvent(cocktailsPreferences.isNonAlcoholicEvent)
    }
  }, [])

  const nextStepHandler = () => {
    updateEventCocktailsPreferences(selectedDrinks, 'categories')
    updateEventCocktailsPreferences(isNonAlcoholicEvent, 'isNonAlcoholicEvent')
    return changeStep(nextStep)
  }

  const pushSelectedDrinks = (id: number) => {
    setSelectedDrinks((prev: number[]) => {
      if (prev.includes(id)) {
        return prev.filter(flavorId => flavorId !== id)
      }
      return [...prev, id]
    })
  }

  const validateFields = (): boolean => {
    if (isNonAlcoholicEvent || selectedDrinks.length) {
      return true
    }
    return false
  }

  return (
    <div className="partyScratchDrinksWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <EventStepTitle
          title="Major drink"
          text="Pick the primary spirits that will serve as the foundation for your cocktail selection."
        />
      )}
      <div className="partyScratchDrinksInner">
        {categories.map((item: ICocktailFilter) => {
          const isActive = selectedDrinks.includes(+item.id)
          return (
            <div
              key={item.id}
              className={`partyScratchDrinksInnerItem ${isActive ? 'activeDrink' : ''}`}
              onClick={() => pushSelectedDrinks(+item.id)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  pushSelectedDrinks(+item.id)
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img
                src={`${staticApi()}/images/ingredient-category/${item.image}`}
                alt={item.name}
              />
              <h5>{item.name}</h5>
            </div>
          )
        })}
        <div
          className={`partyScratchDrinksInnerItem ${isNonAlcoholicEvent ? 'activeDrink' : ''}`}
          onClick={() => setIsNonAlcoholicEvent(prev => !prev)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              setIsNonAlcoholicEvent(prev => !prev)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <img src={AlcoFreeImage} alt="Alcohol Free" />
          <h5>Alcohol Free</h5>
        </div>
      </div>
      <NextStepButton handler={nextStepHandler} isActive={validateFields()} />
    </div>
  )
}

export default PartyScratchDrinks
