import { SetStateAction, useEffect, useState } from 'react'
import { IAuthErrorState } from '../../../@types/auth'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import useActions from '../../../hooks/useActions'
import { yupResolver } from '@hookform/resolvers/yup'
import { emailSchema, resetPasswordSchema } from '../yupObjects'
import SetNewPasswordComponent from './SetNewPasswordComponent'
import ForgetPasswordComponent from './ForgotPasswordComponent'

type InputsEmail = {
  email: string
}

type InputsPasswordRecovery = {
  repeatPassword: string
  password: string
}

export default function PasswordRecoveryComponent() {
  const [state, setState] = useState<IAuthErrorState>({
    errorAnimation: false,
    stateMassage: false,
    loading: false,
    resetPassword: true,
  })
  const [errorField, setErrorField] = useState<string>('')
  const { userResetPassword, userNewPassword, userChangePassword } =
    useActions()

  const methods = useForm<InputsEmail | InputsPasswordRecovery | any>({
    resolver: state.resetPassword
      ? yupResolver(emailSchema)
      : yupResolver(resetPasswordSchema),
  })

  useEffect(() => {
    const time = setTimeout(
      () => setState(prev => ({ ...prev, errorAnimation: false })),
      300,
    )

    return () => clearTimeout(time)
  }, [state.errorAnimation])

  const onSubmit: SubmitHandler<
    InputsEmail | InputsPasswordRecovery | any
  > = data => {
    console.log(data, location.search.slice(7))
    let password, email, resetPassword

    if (state.resetPassword) {
      ;({ email } = data)
    } else {
      ;({ resetPassword, password } = data)
    }
    if (state.resetPassword) {
      userResetPassword(email, setState, setErrorField)
    } else {
      if (password == resetPassword) {
        userNewPassword(
          password,
          location.search.slice(7),
          setState,
          setErrorField,
        )
      } else {
        alert('password !== resetPassword')
      }
    }
  }

  return (
    <>
      {state.resetPassword ? (
        <h3>
          Forgot your password? <br></br> Drop it here
        </h3>
      ) : (
        <h3>Set a new password</h3>
      )}
      <FormProvider {...methods}>
        <form
          name="login"
          className="login-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {state.resetPassword ? (
            <ForgetPasswordComponent />
          ) : (
            <SetNewPasswordComponent />
          )}
          <button type="submit" className="login-form-button">
            {state.resetPassword ? 'SEND RESET LINK' : 'UPDATE PASSWORD'}
          </button>
        </form>
      </FormProvider>
      {state.resetPassword && (
        <p>
          In case you haven't received instructions on how to reset your
          password, please check your spam folder. If you have any problems,
          please contact our support team at{' '}
          <a href=" mailto: support@drankly.com">support@drankly.com</a>
        </p>
      )}
    </>
  )
}
