import { AppDispatch } from '../../index'
import CocktailsService from '../../../api/cocktails'
import { CocktailsActionEnum, GetCocktailsAction } from './types'
import {
  ICocktail,
  ICocktailSelectedFilters,
  ICocktailsList,
} from '../../../@types/cocktails'
import { IBarCocktailsCollection } from '../../../@types/userBar'
import BarActionCreators from '../bar/action-creators'

interface GetCocktailsParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  sortValue: string
  excludeCocktails: number[]
  searchField?: string
}

const CocktailsActionCreators = {
  GetCocktailsAction: (data: ICocktailsList): GetCocktailsAction => {
    return {
      type: CocktailsActionEnum.GET_COCKTAILS,
      payload: data,
    }
  },

  getCocktails: ({
    page,
    size,
    selectedFilters,
    searchField,
    sortValue,
    excludeCocktails,
  }: GetCocktailsParams) => {
    return async (dispatch: AppDispatch) => {
      try {
        let requestWithFilters = false

        Object.keys(selectedFilters).forEach(key => {
          const values = selectedFilters[key]
          if (values.length > 0) {
            requestWithFilters = true
          }
        })

        const { data } = await CocktailsService.getCocktails({
          page,
          size,
          selectedFilters,
          searchField,
          sortValue,
          excludeCocktails,
        })
        if (searchField) {
          if (+page === 0) {
            CocktailsActionCreators.clearCocktailsList()(dispatch)
          }
          dispatch({
            type: CocktailsActionEnum.GET_COCKTAILS_SEARCH,
            payload: { ...data, searchField },
          })
        } else if (requestWithFilters) {
          dispatch({
            type: CocktailsActionEnum.GET_COCKTAILS_BY_FILTER_ACTION,
            payload: data,
          })
        } else {
          dispatch({ type: CocktailsActionEnum.GET_COCKTAILS, payload: data })
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  getCocktail: (id: string, setLoading: (value: boolean) => void) => {
    return async (dispatch: AppDispatch) => {
      try {
        setLoading(true)
        const { data } = await CocktailsService.getCocktail(id)
        dispatch({ type: CocktailsActionEnum.GET_COCKTAIL, payload: data })
        setLoading(false)
      } catch (error: unknown) {
        console.error(error)
        setLoading(false)
      }
    }
  },

  getModalCocktail: (id: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await CocktailsService.getCocktail(id)
        dispatch({
          type: CocktailsActionEnum.GET_MODAL_COCKTAIL,
          payload: data,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },

  cocktailFavorites: (
    id: string,
    isFavorite: boolean,
    collections: IBarCocktailsCollection[],
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        await CocktailsService.cocktailFavorites(id, isFavorite, collections)
        dispatch({
          type: CocktailsActionEnum.FAVORITES_COCKTAIL,
          payload: {
            id,
            isFavorite,
            collections,
          },
        })
        await BarActionCreators.barCocktailFavorites(
          id,
          isFavorite,
          collections,
        )(dispatch)
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },

  cocktailAnalogs: (id: string, setLoading: (value: boolean) => void) => {
    return async (dispatch: AppDispatch) => {
      try {
        setLoading(true)
        const { data } = await CocktailsService.cocktailAnalogs(id)
        dispatch({ type: CocktailsActionEnum.COCKTAIL_ANALOGS, payload: data })
        setLoading(false)
      } catch (error: unknown) {
        console.error(error)
        setLoading(false)
      }
    }
  },

  popularByIngredientCocktails: (id: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await CocktailsService.popularByIngredientCocktails(id)
        dispatch({
          type: CocktailsActionEnum.GET_POPULAR_COCKTAILS_BY_INGREDIENTS,
          payload: data,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },

  clearCocktailsList: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: CocktailsActionEnum.CLEAR_COCKTAIL_LIST })
    }
  },
  clearCocktail: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: CocktailsActionEnum.CLEAR_COCKTAIL })
    }
  },
  clearModalCocktail: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: CocktailsActionEnum.CLEAR_MODAL_COCKTAIL })
    }
  },
  clearAnalogsList: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: CocktailsActionEnum.CLEAR_ANALOGS_LIST })
    }
  },
  clearPopularByIngredientCocktails: () => {
    return (dispatch: AppDispatch) => {
      dispatch({
        type: CocktailsActionEnum.CLEAR_POPULAR_COCKTAILS_BY_INGREDIENTS,
      })
    }
  },
}

export default CocktailsActionCreators
