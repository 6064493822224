interface ListItem {
  name: string
  key: string
  isActive: boolean
}

export type IListSortObject = Record<string, ListItem>

export const CocktailsSort: IListSortObject = {
  0: {
    name: 'name: a-z',
    key: 'name,ASC',
    isActive: true,
  },
  1: {
    name: 'name: Z-A',
    key: 'name,DESC',
    isActive: false,
  },
  2: {
    name: 'classic first',
    key: 'isClassic,DESC',
    isActive: false,
  },
}

export const IngredientsSort: IListSortObject = {
  0: {
    name: 'name: a-z',
    key: 'name,ASC',
    isActive: true,
  },
  1: {
    name: 'name: Z-A',
    key: 'name,DESC',
    isActive: false,
  },
  2: {
    name: 'abv: ASC',
    key: 'abv,ASC',
    isActive: false,
  },
  3: {
    name: 'abv: DESC',
    key: 'abv,DESC',
    isActive: false,
  },
}
