/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import CustomRadioButton from '../../PublicProfileBlock/CustomRadioButton'
import './index.scss'
import useActions from '../../../../hooks/useActions'

interface NotificationSettingsBlockProps {
  notificationSettings: {
    notificationsPeriod: string
    isAllowNotification: boolean
  }
}

function NotificationSettingsBlock({
  notificationSettings,
}: NotificationSettingsBlockProps) {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const { setUserProfileNotification } = useActions()

  useEffect(() => {
    if (notificationSettings) {
      setIsChecked(notificationSettings.isAllowNotification)
    }
  }, [])

  function notificationChange(allow: boolean, period: string = 'WEEKLY') {
    setUserProfileNotification({
      notificationsPeriod: period,
      isAllowNotification: allow,
    })
    setIsChecked(allow)
  }

  return (
    <div className="notificationSettingsBlockWrapper">
      <div className="notificationSettingsBlockInner">
        <h4>Notifications</h4>
        <div className="notificationSettingsContent">
          <CustomRadioButton
            label="allow notification"
            checked={isChecked}
            onChange={() => notificationChange(!isChecked)}
          />
          {isChecked && (
            <div className="notificationSettingsPeriodWrapper">
              <div
                className="settingsPeriodOption"
                onClick={() => {
                  notificationChange(true, 'DAILY')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    notificationChange(true, 'DAILY')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <label
                  className={
                    notificationSettings?.notificationsPeriod === 'DAILY'
                      ? 'settingsPeriodCheckbox checked'
                      : 'settingsPeriodCheckbox'
                  }
                >
                  <input type="checkbox" checked disabled />
                </label>
                <p>daily</p>
              </div>
              <div
                className="settingsPeriodOption"
                onClick={() => {
                  notificationChange(true, 'WEEKLY')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    notificationChange(true, 'WEEKLY')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <label
                  className={
                    notificationSettings?.notificationsPeriod === 'WEEKLY'
                      ? 'settingsPeriodCheckbox checked'
                      : 'settingsPeriodCheckbox'
                  }
                >
                  <input type="checkbox" checked disabled />
                </label>
                <p>weekly</p>
              </div>
              <div
                className="settingsPeriodOption"
                onClick={() => {
                  notificationChange(true, 'MONTHLY')
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    notificationChange(true, 'MONTHLY')
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <label
                  className={
                    notificationSettings?.notificationsPeriod === 'MONTHLY'
                      ? 'settingsPeriodCheckbox checked'
                      : 'settingsPeriodCheckbox'
                  }
                >
                  <input type="checkbox" checked disabled />
                </label>
                <p>monthly</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationSettingsBlock
