import { FC } from 'react'
import { IIngredient } from '../../../@types/ingredients'
import './index.scss'
import { api } from '../../../api'
import IngredientBlockCard from './IngredientsBlockCard'
import useHorizontalScroll from '../../../hooks/useHorizontalScroll'
import { MobileDevicesWidth } from '../../../constants/screensWidth'

interface IngredientsBlockProps {
  ingredients: IIngredient[]
}

function IngredientsBlock({ ingredients }: IngredientsBlockProps) {
  const ref = useHorizontalScroll()

  const renderIngredients = () => {
    const ingredientBlocks: JSX.Element[] = []

    for (let i = 0; i < ingredients.length; i += 2) {
      const ingredient1 = ingredients[i]
      const ingredient2 = ingredients[i + 1]

      if (ingredient1 && ingredient2) {
        ingredientBlocks.push(
          <div key={i / 2} className="ingredientBlockBigItem">
            <IngredientBlockCard
              imageSource={`${api()}/images/ingredients/s/${ingredient1.image}`}
              description={`${ingredient1.amount} ${ingredient1.measurement} ${ingredient1.name}`}
              isFavorite={ingredient1.isFavorite}
              ingId={ingredient1.id}
            />
            <IngredientBlockCard
              imageSource={`${api()}/images/ingredients/s/${ingredient2.image}`}
              description={`${ingredient2.amount} ${ingredient2.measurement} ${ingredient2.name}`}
              isFavorite={ingredient2.isFavorite}
              ingId={ingredient2.id}
            />
          </div>,
        )
      } else if (ingredient1) {
        ingredientBlocks.push(
          <div key={i / 2} className="ingredientBlockBigItem">
            <IngredientBlockCard
              imageSource={`${api()}/images/ingredients/s/${ingredient1.image}`}
              description={`${ingredient1.amount} ${ingredient1.measurement} ${ingredient1.name}`}
              isFavorite={ingredient1.isFavorite}
              ingId={ingredient1.id}
            />
          </div>,
        )
      }
    }

    return ingredientBlocks
  }

  function cardCount(deviceWidth: number, length: number) {
    if (deviceWidth <= MobileDevicesWidth) {
      return (
        <div className="ingredientsBlockSmallInner">
          {ingredients.map((item: IIngredient) => {
            return (
              <IngredientBlockCard
                key={item.id}
                imageSource={`${api()}/images/ingredients/s/${item.image}`}
                description={`${item.amount} ${item.measurement} ${item.name}`}
                isFavorite={item.isFavorite}
                ingId={item.id}
              />
            )
          })}
        </div>
      )
    }

    if (deviceWidth > MobileDevicesWidth && length > 8) {
      return (
        <div className="ingredientsBlockBigInner" ref={ref}>
          {renderIngredients()}
        </div>
      )
    }

    return (
      <div className="ingredientsBlockSmallInner">
        {ingredients.map((item: IIngredient) => {
          return (
            <IngredientBlockCard
              key={item.id}
              imageSource={`${api()}/images/ingredients/s/${item.image}`}
              description={`${item.amount} ${item.measurement} ${item.name}`}
              isFavorite={item.isFavorite}
              ingId={item.id}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div className="ingredientsBlockWrapper">
      {cardCount(window.innerWidth, ingredients.length)}
    </div>
  )
}

export default IngredientsBlock
