/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'

import { ReactComponent as BasicAccountIcon } from '../images/basic-account-icon.svg'
import { ReactComponent as PremiumAccountIcon } from '../images/premium-account-icon.svg'
import { ReactComponent as ChangeImageMobileIcon } from '../images/change-image-mobile-icon.svg'

import './index.scss'
import { api } from '../../../api'
import { EngLocales } from '../../../assets/locales/eng'
import RoutesPaths from '../../../constants/navigate'
import useProfile from '../../../hooks/useProfile'
import useTypedSelector from '../../../hooks/useTypedSelector'
import { UserState } from '../../../store/reducers/user/types'
import useActions from '../../../hooks/useActions'
import { SmallTabletDevicesWidth } from '../../../constants/screensWidth'

type Inputs = {
  image: File
}

function ProfileBlock() {
  const { userProfile } = useTypedSelector<UserState>(state => state.user)
  const { setUserImage } = useActions()
  const { image, plan, name, username } = userProfile
  const navigate = useNavigate()

  const { isChangeProfile } = useProfile()

  const [previewImage, setPreviewImage] = useState<string | null>(null)

  const { register, handleSubmit, reset, setValue } = useForm<Inputs>({
    // resolver: yupResolver(schema),
  })

  function mobileBgClassNameByPlan(value: string) {
    if (value === 'BASIC') {
      return 'profileBlockWrapperMobileBasic'
    }
    return 'profileBlockWrapperMobilePremium'
  }

  const onSubmit: SubmitHandler<Inputs> = data => {
    setUserImage(data.image, userProfile)
  }

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result as string)
      }
      reader.readAsDataURL(file)
      setValue('image', file)

      await handleSubmit(onSubmit)()
    }
  }

  return (
    <div
      className={`profileBlockWrapper ${
        window.innerWidth <= SmallTabletDevicesWidth &&
        mobileBgClassNameByPlan(plan)
      }`}
    >
      <div className="profileBlockWrapperImageBlock">
        {window.innerWidth <= SmallTabletDevicesWidth && isChangeProfile ? (
          <form
            className="profileSettingsBlockInner"
            onSubmit={handleSubmit(onSubmit)}
            name="profileSettings"
          >
            <label htmlFor="file-upload" className="custom-file-upload">
              <ChangeImageMobileIcon />
            </label>
            <input
              id="file-upload"
              type="file"
              {...register('image')}
              onChange={handleImageChange}
            />
          </form>
        ) : null}
        {previewImage ? (
          <img
            src={previewImage}
            alt="Preview"
            className="profileBlockUserImage"
          />
        ) : (
          <img
            src={`${api()}/images/users/${image}`}
            className="profileBlockUserImage"
            alt="user"
          />
        )}
      </div>
      <h4>{name ?? username}</h4>
      <div className="profileBlockTitle">
        {plan === 'BASIC' ? (
          <>
            <h5>
              {EngLocales.UserProfilePage.profileBlock.basicProfileBlockTitle}
            </h5>
            <BasicAccountIcon />
          </>
        ) : (
          <>
            <h5 className="premiumAccount">
              {EngLocales.UserProfilePage.profileBlock.premiumProfileBlockTitle}
            </h5>
            <PremiumAccountIcon />
          </>
        )}
      </div>
      <div className="profileBlockLine" />
      {plan === 'BASIC' ? (
        <p>
          {EngLocales.UserProfilePage.profileBlock.basicProfileBlockText}{' '}
          {window.innerWidth > SmallTabletDevicesWidth ? (
            'premium'
          ) : (
            <Link to={`/${RoutesPaths.plansPage}`}>PREMIUM</Link>
          )}
        </p>
      ) : (
        <p>{EngLocales.UserProfilePage.profileBlock.premiumProfileBlockText}</p>
      )}
      {window.innerWidth > SmallTabletDevicesWidth && (
        <div
          className={
            plan === 'BASIC' ? 'profileBlockPlan' : 'profileBlockPlan premium'
          }
          onClick={() =>
            plan === 'BASIC' && navigate(`/${RoutesPaths.plansPage}`)
          }
          onKeyPress={e => {
            if (e.key === 'Enter') {
              plan === 'BASIC' && navigate(`/${RoutesPaths.plansPage}`)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <p>{plan === 'BASIC' ? 'Upgrade to Premium' : 'Premium account'}</p>
        </div>
      )}
    </div>
  )
}

export default ProfileBlock
