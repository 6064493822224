import React from 'react'

import './index.scss'
import { IEventCocktail, IEventsItem } from '../../../../@types/events'
import NextStepButton from '../../components/NextStepButton'
import { api, staticApi } from '../../../../api'
import useActions from '../../../../hooks/useActions'
import { ReactComponent as StepBackIcon } from '../../../MyBar/assets/arrow-icon.svg'
import CocktailMediumCard from '../../../../components/CocktailMediumCard'

interface TemplatePreviewProps {
  changeStep: (step: number) => void
  nextStep: number
  template: IEventsItem | null
}

function TemplatePreview({
  changeStep,
  nextStep,
  template,
}: TemplatePreviewProps) {
  const { copyDataFromTemplate } = useActions()

  const nextStepHandler = () => {
    if (template) {
      copyDataFromTemplate(template)
      changeStep(nextStep)
    }
  }

  return (
    <div className="templatePreviewWrapper">
      <div className="templatePreviewHeader">
        <div className="templatePreviewHeaderImage">
          <StepBackIcon onClick={() => changeStep(1)} />
          <img
            src={`${staticApi()}/images/occasions/${template?.occasion.image}`}
            alt={template?.name}
          />
        </div>
        <div className="templatePreviewHeaderContent">
          <h3>{template?.name}</h3>
          <p>{template?.occasion.name}</p>
          <p>{template?.cocktails.length} cocktails</p>
          <div className="templatePreviewHeaderContentLine" />
        </div>
      </div>
      <div className="templatePreviewInner">
        {template &&
          template.cocktails.map((eventCocktail: IEventCocktail) => {
            return (
              <div
                key={Math.random()}
                className="templatePreviewCocktailWrapper"
              >
                <CocktailMediumCard cocktail={eventCocktail.cocktail} isEvent />
              </div>
            )
          })}
      </div>
      <NextStepButton handler={nextStepHandler} isActive btnText="choose" />
    </div>
  )
}

export default TemplatePreview
