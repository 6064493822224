/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-boolean-value */

import React, { useEffect, useMemo, useState } from 'react'
import { FixedSizeGrid as Grid, FixedSizeList as List } from 'react-window'
import { useNavigate } from 'react-router-dom'
import { AutoSizer } from 'react-virtualized'

import { ReactComponent as Arrow } from '../../assets/arrow.svg'

import BarWrapper from '../../BarWrapper'

import './index.scss'
import useActions from '../../../../hooks/useActions'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import {
  ICocktail,
  ICocktailFilter,
  ICocktailSelectedFilters,
} from '../../../../@types/cocktails'
import RoutesPaths from '../../../../constants/navigate'
import { MobileDevicesWidth } from '../../../../constants/screensWidth'
import { PageTitles } from '../../../../constants/pageTitles'
import { BarState } from '../../../../store/reducers/bar/types'
import MixologyItem from './MixologyItem'
import { SearchFilters } from '../../../../components/SearchFilters'
import Loader from '../../../../components/Loader'
import { UserState } from '../../../../store/reducers/user/types'
import {
  mixologyListColumnsCount,
  mixologyListColumnsWidth,
  mixologyListRowsCount,
  mixologyListRowsHeight,
} from '../../../../helpers/mixologyListPageHelpers'

interface FiltersInterface {
  categories: ICocktailFilter[]
  allergens: ICocktailFilter[]
  type: ICocktailFilter[]
  selectedFilters: ICocktailSelectedFilters
}

interface CellProps {
  columnIndex: number
  rowIndex: number
  style: object
}

export default function MixologyList() {
  const navigate = useNavigate()
  const {
    getMixologyList,
    clearMixologyList,
    clearSelectedCocktailsFilters,
    setSelectedFilter,
  } = useActions()

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [searchField, setSearchValue] = useState<string>('')
  const [needRequest, setNeedRequest] = useState<boolean>(false)

  const { barMixologyList } = useTypedSelector<BarState>(state => state.bar)
  const { userProfile } = useTypedSelector<UserState>(state => state.user)
  const { selectedFilters } = useTypedSelector<FiltersInterface>(
    state => state.filter,
  )

  useEffect(() => {
    document.title = PageTitles.myBarPage

    return () => {
      // clearSelectedFilters()
      clearMixologyList()
      setCurrentPage(0)
    }
  }, [])

  // useEffect(() => {
  //   if (needRequest) {
  //     setCurrentPage(0)
  //     clearMixologyList()
  //     getMixologyList({
  //       page: currentPage,
  //       size: userProfile.plan === 'PREMIUM' ? '50' : '20',
  //       selectedFilters,
  //       searchFields: searchField,
  //     })
  //     setNeedRequest(false)
  //   }
  // }, [needRequest])

  useEffect(() => {
    if (userProfile.plan) {
      getMixologyList({
        page: currentPage,
        size: userProfile.plan === 'PREMIUM' ? '50' : '20',
        selectedFilters,
        searchFields: searchField,
      })
    }
  }, [selectedFilters, searchField, userProfile.plan])

  const memoContent = useMemo(
    () => barMixologyList.content,
    [barMixologyList.content.length],
  )

  function possibleToMake(item: ICocktail) {
    return item.ingredients
      .filter(ingredient => {
        return !ingredient.isOptional
      })
      .map(ingredient => {
        return ingredient.isFavorite
      })
  }

  const Cell = ({ columnIndex, rowIndex, style }: CellProps) => {
    const index = rowIndex * mixologyListColumnsCount() + columnIndex
    const item = barMixologyList.content[index]
    if (item) {
      return (
        <div style={style} key={item.name}>
          <MixologyItem
            mixologyItem={item}
            possible={possibleToMake(item).every(element => element)}
          />
        </div>
      )
    }
    return <></>
  }

  return (
    <BarWrapper openTab="ingredients" secondClass="largeIngInnerHeight">
      <div className="myBarMixologyWrapper">
        <div
          className="tabHeader"
          onClick={() => {
            navigate(`/${RoutesPaths.myBarIngredientsPage}`)
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              navigate(`/${RoutesPaths.myBarIngredientsPage}`)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Arrow />
          <h1>Mixology List</h1>
        </div>
        <SearchFilters
          searchField={searchField}
          setCurrentPage={setCurrentPage}
          setRequest={setNeedRequest}
          setSearchValue={setSearchValue}
          isIngredientsList={false}
          isCocktailsFilters={true}
          isMixologyFilters={true}
          isMyIngredientFilters={false}
        />
        <div className="myBarMixologyInner">
          {barMixologyList.content.length === 0 ? (
            <Loader />
          ) : (
            <div style={{ width: '100%', height: 'auto' }}>
              <AutoSizer>
                {({ width, height }) => (
                  <Grid
                    width={width}
                    height={height}
                    rowHeight={mixologyListRowsHeight()}
                    rowCount={
                      mixologyListRowsCount(barMixologyList.content.length) +
                      0.5
                    }
                    columnCount={mixologyListColumnsCount()}
                    columnWidth={mixologyListColumnsWidth(width)}
                  >
                    {Cell}
                  </Grid>
                )}
              </AutoSizer>
            </div>
          )}
        </div>
      </div>
    </BarWrapper>
  )
}
