import {
  LaptopDevicesWidth,
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
  SmallTabletDevicesWidth,
} from '../constants/screensWidth'

export function mixologyListColumnsWidth(width: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return width / 2
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return width / 4
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return  width / 4
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return width / 5
  }
  return width / 6
}

export function mixologyListColumnsCount() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 2
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return 4
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 4
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 5
  }
  return 6
}

export function mixologyListRowsHeight() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 210
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return 275
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 306
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 306
  }
  return 298
}

export function mixologyListRowsCount(length: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return Math.ceil(length / 2)
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return Math.ceil(length / 4)
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return Math.ceil(length / 4)
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return Math.ceil(length / 5)
  }
  return Math.ceil(length / 6)
}

export function mixologyListItemIndex(
  row: number,
  column: number,
) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return row * 2 + column
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return row * 4 + column
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return row * 4 + column
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return row * 5 + column
  }
  return row * 6 + column
}
