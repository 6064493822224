/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from '../../../assets/arrow-icon.svg'
import { ReactComponent as MoreDetailsIcon } from '../../../assets/more-details-icon.svg'
import { ReactComponent as ShareIcon } from '../../../assets/share-icon.svg'
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg'
import { ReactComponent as PinIcon } from '../../../assets/pin-icon.svg'

import useActions from '../../../../../hooks/useActions'
import useTypedSelector from '../../../../../hooks/useTypedSelector'

import { BarState } from '../../../../../store/reducers/bar/types'
import { SearchFilters } from '../../../../../components/SearchFilters'

import './index.scss'

interface CollectionHeaderProps {
  isFavorite: boolean
  title: string
  count: number
  searchField: string
  imageUrl: string
  setCurrentPage: (page: number) => void
  setRequest: (value: boolean) => void
  setSearchValue: (value: string) => void
}

function CollectionHeader({
  isFavorite,
  title,
  count,
  searchField,
  imageUrl,
  setCurrentPage,
  setRequest,
  setSearchValue,
}: CollectionHeaderProps) {
  const [isMoreDetails, setIsMoreDetails] = useState<boolean>(false)
  const [cocktailsCount, setCocktailsCount] = useState<number>()
  const cocktailsCountRef = useRef(false)

  const moreDetailsRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        moreDetailsRef.current &&
        !moreDetailsRef.current.contains(event.target as Node)
      ) {
        setIsMoreDetails(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setIsMoreDetails])

  useEffect(() => {
    if (!cocktailsCountRef.current && count > 0) {
      setCocktailsCount(count)
      cocktailsCountRef.current = true
    }
  }, [count])

  useEffect(() => {
    return () => {
      cocktailsCountRef.current = false
      setCocktailsCount(0)
    }
  }, [])

  const { showModal, pinCollection, unpinCollection } = useActions()
  const { selectedCollection } = useTypedSelector<BarState>(state => state.bar)

  const navigate = useNavigate()

  const pinCocktailsCollection = (collectionId: string) => {
    if (selectedCollection.isPinned) {
      unpinCollection(collectionId)
    } else {
      pinCollection(collectionId)
    }
  }

  return (
    <div className="collectionHeaderWrapper">
      <div className="collectionHeaderImage">
        <ArrowIcon
          className="collectionHeaderBackIcon"
          onClick={() => navigate(-1)}
        />
        <img src={imageUrl} alt={title} />
      </div>

      <div className="collectionHeaderTitle">
        <div className="collectionHeaderTitleName">
          <h4>{title}</h4>
          <h5>{cocktailsCount} cocktails</h5>
        </div>
        <div className="collectionHeaderTitleNameMore">
          <MoreDetailsIcon
            onClick={() => setIsMoreDetails((prev: boolean) => !prev)}
          />
          {isMoreDetails && (
            <div
              className="collectionHeaderTitleNameMoreDetails"
              ref={moreDetailsRef}
            >
              <div
                className={`collectionHeaderTitleNameMoreDetailsItem ${isFavorite && 'oneItem'}`}
              >
                <ShareIcon /> <p>Share collection</p>
              </div>
              {!isFavorite && (
                <>
                  <div
                    className="collectionHeaderTitleNameMoreDetailsItem"
                    onClick={() => {
                      showModal('PatchCollectionModal', {})
                      setIsMoreDetails(false)
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        showModal('PatchCollectionModal', {})
                        setIsMoreDetails(false)
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <EditIcon /> <p>Edit collection</p>
                  </div>
                  <div
                    className="collectionHeaderTitleNameMoreDetailsItem"
                    onClick={() => {
                      pinCocktailsCollection(selectedCollection.id)
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        pinCocktailsCollection(selectedCollection.id)
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <PinIcon />{' '}
                    <p>
                      {selectedCollection.isPinned
                        ? 'Unpin collection'
                        : 'Pin collection'}
                    </p>
                  </div>
                  <div
                    className="collectionHeaderTitleNameMoreDetailsItem"
                    onClick={() => {
                      showModal('DeleteCollection', {})
                      setIsMoreDetails(false)
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        showModal('DeleteCollection', {})
                        setIsMoreDetails(false)
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <DeleteIcon /> <p>Delete collections</p>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <SearchFilters
        isCocktailsFilters
        isMixologyFilters={false}
        isMyIngredientFilters={false}
        searchField={searchField}
        isIngredientsList={false}
        setCurrentPage={setCurrentPage}
        setRequest={setRequest}
        setSearchValue={setSearchValue}
      />
    </div>
  )
}

export default CollectionHeader
