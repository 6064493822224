import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

export default function SetNewPasswordComponent() {
  const [isVisible, setIsVisible] = useState<boolean[]>([false, false])
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      <div className="login-form-input">
        <input
          autoComplete="new-password"
          type={isVisible[0] ? 'text' : 'password'}
          {...register('password')}
          placeholder="New password"
          className={errors.password?.message ? 'error-border' : ''}
          required
        />
        <i
          className={
            isVisible[0]
              ? 'fas fa-eye fa-eye-slash fa-flip-horizontal'
              : 'fas fa-eye'
          }
          id="togglePassword"
          aria-hidden="true"
          onClick={() => setIsVisible([!isVisible[0], isVisible[1]])}
          style={{
            marginLeft: '-35px',
            cursor: 'pointer',
            color: '#BEC8D6',
            transition: 'all 1s ease-out',
          }}
        />
      </div>
      <div className="login-form-input">
        <input
          autoComplete="new-password"
          type={isVisible[1] ? 'text' : 'password'}
          {...register('resetPassword')}
          placeholder="Repeat new password"
          className={errors.password?.message ? 'error-border' : ''}
          required
        />
        <i
          className={
            isVisible[1]
              ? 'fas fa-eye fa-eye-slash fa-flip-horizontal'
              : 'fas fa-eye'
          }
          id="togglePassword"
          aria-hidden="true"
          onClick={() => setIsVisible([isVisible[0], !isVisible[1]])}
          style={{
            marginLeft: '-35px',
            cursor: 'pointer',
            color: '#BEC8D6',
            transition: 'all 1s ease-out',
          }}
        />
      </div>
    </>
  )
}
