/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useActions from '../../../hooks/useActions'
import { api } from '../../../api'

import { ReactComponent as NotFavorite } from '../../../assets/images/svg/not-favorite.svg'
import { ReactComponent as Favorite } from '../../../assets/images/svg/favorite.svg'
import RoutesPaths from '../../../constants/navigate'
import { SmallTabletDevicesWidth } from '../../../constants/screensWidth'
import { IIngredient } from '../../../@types/ingredients'

interface IngredientsCardProps {
  item: IIngredient
}

function IngredientsCard({ item }: IngredientsCardProps) {
  const {
    getIngredient,
    showModal,
    deleteIngredientFavorites,
    ingredientFavorites,
    popularByIngredientCocktails,
  } = useActions()
  const navigate = useNavigate()

  const onClick = useCallback(() => {
    if (window.innerWidth <= SmallTabletDevicesWidth) {
      navigate(`/${RoutesPaths.ingredientsPage}/${item.id}`)
    } else {
      showModal('Ingredient', { item })
      popularByIngredientCocktails(item.id.toString())
      getIngredient(item.id.toString())
    }
  }, [item.id])

  const onClickFavorites = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      if (!item.isFavorite) {
        ingredientFavorites(item.id)
      } else if (item.isFavorite) {
        deleteIngredientFavorites(item.id)
      }
    },
    [item, ingredientFavorites, deleteIngredientFavorites],
  )

  return (
    <div className="ingredientItemWrapper">
      <div
        className="ingredientWrapper"
        onClick={onClick}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onClick
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div id="ingredient-lightbox">
          <div
            className="ingredient-favorite-icon"
            onClick={onClickFavorites}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onClickFavorites
              }
            }}
            role="button"
            tabIndex={0}
          >
            {item.isFavorite ? (
              <Favorite className="img-favorites-active" />
            ) : (
              <NotFavorite className="img-favorites" />
            )}
          </div>
        </div>
        <img
          alt={item?.name || 'Default Alt'}
          src={`${api()}/images/ingredients/s/${item.image}`}
        />
        <div className="ingredientTitle">
          <h3>{item.name}</h3>
        </div>
      </div>
    </div>
  )
}

export default IngredientsCard
