/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, {
  // lazy,
  memo,
  RefObject,
  useEffect,
  useRef,
  useState,
  Suspense,
  useMemo,
} from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import useActions from './hooks/useActions'
import { getParams } from './store/reducers/auth/authUtils'

// import { AnimatedContainer } from './components/AnimatedContainer'
import ModalContainer from './modals/ModalContainer'
import Loader from './components/Loader'
import AppRoutes from './AppRoutes'

export const ScrollContext = React.createContext<unknown>(null)

export const App = memo(() => {
  const {
    config,
    getUserProfile,
    showModal,
    getCategories,
    getAllergens,
    getFlavors,
    getPrimaryIngredients,
    getGlasses,
    getBarCocktailsCollections,
  } = useActions()
  const location = useLocation()
  const scrollRef: RefObject<OverlayScrollbarsComponent> = useRef(null)

  const value = useMemo(
    () => scrollRef?.current?.osInstance() || {},
    [scrollRef],
  )

  function setFullScreenBlockHeight() {
    const fullScreenBlock = document.querySelector('.wrapper') as HTMLElement

    if (fullScreenBlock) {
      fullScreenBlock.style.height = `${window.innerHeight}px`
      fullScreenBlock.style.overflowY = `hidden`
    }
  }

  function resetFullScreenBlockHeight() {
    const fullScreenBlock = document.querySelector('.wrapper') as HTMLElement

    if (fullScreenBlock) {
      fullScreenBlock.style.height = `${window.innerHeight}px`
      fullScreenBlock.style.overflowY = `auto`
    }
  }

  useEffect(() => {
    getAllergens()
    getCategories(false)
    getFlavors()
    getPrimaryIngredients()
    getGlasses()
    getBarCocktailsCollections('')
    config()
    if (getParams('refresh_token')) {
      getUserProfile()
    }
    if (!localStorage.getItem('legalAgeAgree')) {
      showModal('LegalAge', {})
    }
  }, [])

  useEffect(() => {
    const pathsToNotApply = ['/cocktails', '/ingredients']
    if (
      location.pathname.startsWith('/dbar') ||
      location.pathname === '/events/create' ||
      pathsToNotApply.includes(location.pathname)
    ) {
      // if (pathsToNotApply.includes(location.pathname)) {
      setFullScreenBlockHeight()
      window.addEventListener('resize', setFullScreenBlockHeight)
      window.addEventListener('orientationchange', setFullScreenBlockHeight)
    } else {
      resetFullScreenBlockHeight()
      window.removeEventListener('resize', setFullScreenBlockHeight)
      window.removeEventListener('orientationchange', setFullScreenBlockHeight)
    }

    return () => {
      window.removeEventListener('resize', setFullScreenBlockHeight)
      window.removeEventListener('orientationchange', setFullScreenBlockHeight)
    }
  }, [location])

  function getStandardBackground(pathname: string) {
    const pathsWithStandardBackground = [
      '/login',
      '/register',
      '/recovery-password',
      '/update-password',
      '/',
    ]

    return pathsWithStandardBackground.includes(pathname)
  }

  const standardBackground = getStandardBackground(location.pathname)

  return (
    <div
      className={
        standardBackground ? 'wrapper' : 'wrapper wrapper-cocktailsBackground'
      }
    >
      <div className="app-container">
        <ScrollContext.Provider value={value}>
          <Suspense fallback={<Loader />}>
            <AppRoutes />
          </Suspense>

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <ModalContainer />
        </ScrollContext.Provider>
      </div>
    </div>
  )
})
