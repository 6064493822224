/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { format, parseISO } from 'date-fns'

import { ReactComponent as EditIcon } from '../images/edit-icon.svg'
import { ReactComponent as CloseIcon } from '../images/close-icon.svg'

import './index.scss'
import { EngLocales } from '../../../assets/locales/eng'
import useTypedSelector from '../../../hooks/useTypedSelector'
import useActions from '../../../hooks/useActions'
import { MobileDevicesWidth } from '../../../constants/screensWidth'
import {
  IProfilePayment,
  IProfileSubscription,
} from '../../../@types/userProfile'

interface PaymentsBlockProps {
  subscriptionsInfo: IProfileSubscription[]
  paymentsInfo: IProfilePayment[]
  plan: string
}

function PaymentsBlock({
  subscriptionsInfo,
  paymentsInfo,
  plan,
}: PaymentsBlockProps) {
  const { downgradeLink } = useTypedSelector(state => state.subscriptions)
  const { getDowngradeLink } = useActions()

  useEffect(() => {
    if (plan && plan !== 'BASIC') {
      getDowngradeLink()
    }
  }, [plan])

  function formatDate(date: string, formatType: string, isNextPay: boolean) {
    const formattedDate = format(parseISO(date), formatType)

    return `${isNextPay ? 'Next payment on' : ''} ${formattedDate}`
  }

  function subType(text1: string, text2: string, text3: string) {
    if (subscriptionsInfo[0].isTrial) {
      return text1
    }
    if (subscriptionsInfo[0].isActive) {
      return text2
    }

    return text3
  }

  return (
    <div className="paymentsBlockWrapper">
      <div className="paymentsBlockInner">
        <div className="paymentsBlockInnerHeader">
          <h3>
            {EngLocales.UserProfilePage.paymentsBlock.paymentsBlockHeaderTitle}
          </h3>
          <p>
            {EngLocales.UserProfilePage.paymentsBlock.paymentsBlockHeaderText}
          </p>
        </div>

        <div className="paymentsBlockSubscription">
          <h4>
            {EngLocales.UserProfilePage.paymentsBlock.subscriptionsHeaderTitle}
          </h4>
          {subscriptionsInfo[0] ? (
            <div className="paymentsBlockSubscriptionCurrent">
              <p>Premium Plan</p>
              <p className={subType('trialSub', 'activeSub', 'notActiveSub')}>
                {subType('Trial', 'Active', 'Not Active')}
              </p>
              {window.innerWidth > MobileDevicesWidth ? (
                <>
                  <p>
                    {formatDate(
                      subscriptionsInfo[0].endDate,
                      'MMMM d, yyyy',
                      true,
                    )}
                  </p>
                  <p>Billing monthly</p>
                </>
              ) : (
                <div className="paymentsBlockSubscriptionCurrentMobileDiv">
                  <p>
                    {formatDate(subscriptionsInfo[0].endDate, 'MMMM d', true)}
                  </p>
                  <p>Billing monthly</p>
                </div>
              )}
              <div className="paymentsBlockSubscriptionTools">
                <EditIcon
                  onClick={() => {
                    window.open(downgradeLink)
                  }}
                />
              </div>
            </div>
          ) : (
            <p className="paymentsBlockSubscriptionNone">
              You don&apos;t have any subscriptions yet.
            </p>
          )}
        </div>

        <div className="paymentsBlockPayInfo">
          <h4>
            {EngLocales.UserProfilePage.paymentsBlock.paymentsHeaderTitle}
          </h4>
          {paymentsInfo[0] ? (
            <>
              <div className="paymentsBlockPayInfoHeader">
                <div>
                  <p>Description</p>
                </div>
                <div>
                  <p>amount</p>
                  <p>date</p>
                </div>
              </div>
              {paymentsInfo.reverse().map(item => {
                return (
                  <div className="paymentsBlockPayInfoItem">
                    <div>
                      <p>{item.eventType}</p>
                      <p
                        className={
                          item.status === 'Succeeded'
                            ? 'activePay'
                            : 'notActivePay'
                        }
                      >
                        {item.status}
                      </p>
                    </div>
                    <div>
                      <p>
                        {item.amount} {item.currency}
                      </p>
                      <p className="dateParagraph">
                        {formatDate(item.createdAt, 'MMM d, hh.mm a', false)}
                      </p>
                    </div>
                  </div>
                )
              })}{' '}
            </>
          ) : (
            <p className="paymentsBlockPayInfoNone">
              You don&apos;t have any payments yet.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentsBlock
