import { getParams } from './authUtils'
import { AuthAction, AuthActionEnum, AuthState } from './types'

const initialState: AuthState = {
  isAuth: !getParams('approve') ?? false,
}

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthActionEnum.AUTHORIZED:
      return { ...state, isAuth: true }
    case AuthActionEnum.UNAUTHORIZED:
      return { ...state, isAuth: false }

    default:
      return state
  }
}

export default authReducer
