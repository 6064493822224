import Cookies from 'universal-cookie'
import { IUser } from '../../../@types/auth'

const cookies = new Cookies()

export const saveLoginApprove = (approve: boolean) => {
  cookies.set('approve', approve, { path: '/' })
}
// export const getLoginApprove = (): boolean => {
//   return cookies.get('approve')
// }

export const removeLoginApprove = () => {
  cookies.remove('approve')
}

export const setParams = (params: IUser) => {
  Object.keys(params).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      cookies.set(key, params[key], { path: '/' })
    }
  })
}

export const getParams = (name: string): string | undefined => {
  return cookies.get(name) as string | undefined
}

export const saveApiPath = (api: string, staticApi: string) => {
  localStorage.setItem('api', JSON.stringify(api))
  localStorage.setItem('staticApi', JSON.stringify(staticApi))
}

export const getApiPath = (): string | null => {
  return localStorage.getItem('api')
}

export const getStaticApiPath = (): string | null => {
  return localStorage.getItem('staticApi')
}

export const removeApiPath = () => {
  localStorage.removeItem('api')
}

export const removeParams = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const allCookies: Record<string, unknown> = cookies.getAll()

  Object.keys(allCookies).forEach(cookieName => {
    cookies.remove(cookieName)
  })
}
