/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import useTypedSelector from '../../../../hooks/useTypedSelector'
import { FiltersState } from '../../../../store/reducers/filters/types'
import NextStepButton from '../../components/NextStepButton'
import { ICocktailFilter } from '../../../../@types/cocktails'
import useActions from '../../../../hooks/useActions'

import './index.scss'
import EventStepTitle from '../../components/EventStepTitle'
import { LargeTabletDevicesWidth } from '../../../../constants/screensWidth'
import { staticApi } from '../../../../api'

interface PartyScratchFlavorsDesktopProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyScratchFlavorsDesktop({
  changeStep,
  nextStep,
}: PartyScratchFlavorsDesktopProps) {
  const [selectedFlavors, setSelectedFlavors] = useState<number[]>([])
  const [complexity, setComplexity] = useState<'EASY' | 'MEDIUM' | 'HARD'>(
    'EASY',
  )

  const { flavors } = useTypedSelector<FiltersState>(state => state.filter)
  const { cocktailsPreferences } = useTypedSelector(state => state.events)
  const { updateEventCocktailsPreferences } = useActions()

  useEffect(() => {
    if (cocktailsPreferences.flavors) {
      setSelectedFlavors(cocktailsPreferences.flavors)
    }
  }, [])

  const nextStepHandler = () => {
    updateEventCocktailsPreferences(selectedFlavors, 'flavors')
    updateEventCocktailsPreferences(complexity, 'complexity')
    return changeStep(nextStep)
  }

  const pushSelectedFlavors = (id: number) => {
    setSelectedFlavors((prev: number[]) => {
      if (prev.includes(id)) {
        return prev.filter(flavorId => flavorId !== id)
      }
      return [...prev, id]
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComplexity(event.target.value as 'EASY' | 'MEDIUM' | 'HARD')
  }

  return (
    <div className="partyScratchFlavorsDesktopWrapper">
      <EventStepTitle
        title="Preferred Flavours & Drinks Complexity"
        text="Tailor your event by selecting your desired flavor profiles and cocktail complexity levels."
      />

      <div className="partyScratchFlavorsDesktopInnerFlavors">
        <h5>Flavour</h5>
        <div className="partyScratchFlavorsDesktopInnerFlavorsInner">
          {flavors.map((item: ICocktailFilter) => {
            const isActive = selectedFlavors.includes(+item.id)
            return (
              <div
                key={item.id}
                className={`partyScratchFlavorsDesktopInnerItem ${isActive ? 'activeFlavor' : ''}`}
                onClick={() => pushSelectedFlavors(+item.id)}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    pushSelectedFlavors(+item.id)
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <img
                  src={`${staticApi()}/images/flavors/${item.image}`}
                  alt={item.name}
                />
                <p>{item.name}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className="partyScratchFlavorsDesktopInnerComplexity">
        <h5>Complexity</h5>
        <div className="partyScratchFlavorsDesktopInnerComplexityInner">
          <div
            className={`partyScratchFlavorsDesktopInnerComplexityInnerItem ${complexity === 'EASY' ? 'activeComplexity' : ''}`}
            onClick={() => setComplexity('EASY')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setComplexity('EASY')
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="circles">
              <div className="fill" />
              <div />
              <div />
            </div>
            <p>LIGHT</p>
          </div>
          <div
            className={`partyScratchFlavorsDesktopInnerComplexityInnerItem ${complexity === 'MEDIUM' ? 'activeComplexity' : ''}`}
            onClick={() => setComplexity('MEDIUM')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setComplexity('MEDIUM')
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="circles">
              <div className="fill" />
              <div className="fill" />
              <div />
            </div>
            <p>MEDIUM</p>
          </div>
          <div
            className={`partyScratchFlavorsDesktopInnerComplexityInnerItem ${complexity === 'HARD' ? 'activeComplexity' : ''}`}
            onClick={() => setComplexity('HARD')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setComplexity('HARD')
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="circles">
              <div className="fill" />
              <div className="fill" />
              <div className="fill" />
            </div>
            <p>STRONG</p>
          </div>
        </div>
      </div>

      <NextStepButton
        handler={nextStepHandler}
        isActive={!!selectedFlavors.length && !!complexity.length}
        // isActive={false}
      />
    </div>
  )
}

export default PartyScratchFlavorsDesktop
