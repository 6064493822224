/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ICreateEvent,
  ICreateEventCocktail,
  IEventsItem,
  IEventCocktail,
  IEventCocktailsPreferences,
  IEventsSummary,
  IEventOccasion,
  IEventSummary,
} from '../../../@types/events'

export interface EventsState {
  occasions: IEventOccasion[]
  event: IEventSummary | null
  cocktailsPreferences: IEventCocktailsPreferences
  userCreateEvent: ICreateEvent
  eventCocktails: IEventCocktail[]
  eventsSummary: IEventsSummary
  eventId: string
  eventTemplates: IEventsItem[]
}

export interface UpdateUserCreateEvent {
  value: string | number | boolean | ICreateEventCocktail[]
  key: string
}

export interface UpdateEventCocktailsPreferences {
  value: number | number[] | boolean | 'EASY' | 'MEDIUM' | 'HARD'
  key: string
}

export enum EventsEnum {
  GET_OCCASIONS = 'GET_OCCASIONS',
  UPDATE_USER_CREATE_EVENT = 'UPDATE_USER_CREATE_EVENT',
  UPDATE_EVENT_COCKTAILS_PREFERENCES = 'UPDATE_EVENT_COCKTAILS_PREFERENCES',
  GET_EVENT = 'GET_EVENT',
  GET_EVENTS = 'GET_EVENTS',
  GET_EVENT_COCKTAILS = 'GET_EVENT_COCKTAILS',
  ADD_EVENT_COCKTAILS = 'ADD_EVENT_COCKTAILS',
  DELETE_EVENT_COCKTAIL = 'DELETE_EVENT_COCKTAIL',
  POST_USER_EVENT = 'POST_USER_EVEN',
  CHANGE_COCKTAIL_AMOUNT = 'CHANGE_COCKTAIL_AMOUNT',
  // GET_EVENT_SHOPPING_LIST = 'GET_EVENT_SHOPPING_LIST',
  // UPDATE_USER_EVENT_DETAILS = 'UPDATE_USER_EVENT_DETAILS',
  // DELETE_USER_EVENT = 'DELETE_USER_EVENT',
  GET_EVENTS_SUMMARY = 'GET_EVENTS_SUMMARY',
  GET_TEMPLATES = 'GET_TEMPLATES',
  COPY_FROM_TEMPLATE = 'COPY_FROM_TEMPLATE',
  CLEAR_CREATE_EVENT_INFO = 'CLEAR_CREATE_EVENT_INFO',
  CLEAR_EVENT_ID = 'CLEAR_EVENT_ID',
}

export interface GetPartyOccasionsAction {
  type: EventsEnum.GET_OCCASIONS
  payload: IEventOccasion[]
}

export interface GetEventAction {
  type: EventsEnum.GET_EVENT
  payload: IEventSummary
}

export interface GetEventsAction {
  type: EventsEnum.GET_EVENTS
  payload: IEventsItem[]
}

export interface GetEventsSummaryAction {
  type: EventsEnum.GET_EVENTS_SUMMARY
  payload: IEventsSummary
}

export interface UpdateUserCreateEventAction {
  type: EventsEnum.UPDATE_USER_CREATE_EVENT
  payload: UpdateUserCreateEvent
}

export interface UpdateEventCocktailsPreferencesAction {
  type: EventsEnum.UPDATE_EVENT_COCKTAILS_PREFERENCES
  payload: UpdateEventCocktailsPreferences
}

export interface GetEventsCocktailsAction {
  type: EventsEnum.GET_EVENT_COCKTAILS
  payload: IEventCocktail[]
}

export interface DeleteEventCocktailAction {
  type: EventsEnum.DELETE_EVENT_COCKTAIL
  payload: number
}

export interface AddEventCocktailsAction {
  type: EventsEnum.ADD_EVENT_COCKTAILS
  payload: IEventCocktail[]
}

export interface ChangeEventCocktailAmountAction {
  type: EventsEnum.CHANGE_COCKTAIL_AMOUNT
  payload: {
    id: number
    amount: number
  }
}

export interface PostUserEventAction {
  type: EventsEnum.POST_USER_EVENT
  payload: string
}

export interface GetTemplatesAction {
  type: EventsEnum.GET_TEMPLATES
  payload: IEventsItem[]
}

export interface CopyFromTemplateAction {
  type: EventsEnum.COPY_FROM_TEMPLATE
  payload: ICreateEvent
}

export interface ClearCreateEventInfoAction {
  type: EventsEnum.CLEAR_CREATE_EVENT_INFO
  payload: {
    cocktailsPreferences: IEventCocktailsPreferences
    userCreateEvent: ICreateEvent
    eventCocktails: IEventCocktail[]
  }
}

export interface ClearEventIdAction {
  type: EventsEnum.CLEAR_EVENT_ID
  payload: ''
}

export type EventsAction =
  | GetPartyOccasionsAction
  | GetEventAction
  | GetEventsAction
  | GetEventsSummaryAction
  | UpdateUserCreateEventAction
  | UpdateEventCocktailsPreferencesAction
  | GetEventsCocktailsAction
  | DeleteEventCocktailAction
  | AddEventCocktailsAction
  | ChangeEventCocktailAmountAction
  | PostUserEventAction
  | GetTemplatesAction
  | CopyFromTemplateAction
  | ClearCreateEventInfoAction
  | ClearEventIdAction
