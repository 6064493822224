import {
  LaptopDevicesWidth,
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
  SmallTabletDevicesWidth,
} from '../constants/screensWidth'

export function myIngredientsColumnsWidth(width: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return width / 3
  }
  if (window.innerWidth <= MobileDevicesWidth + 200) {
    return width / 4
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return width / 5
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return  width / 5
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return width / 6
  }
  return width / 7
}

export function myIngredientsColumnsCount() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 3
  }
  if (window.innerWidth <= MobileDevicesWidth + 200) {
    return 4
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return 5
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 5
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 6
  }
  return 7
}

export function myIngredientsRowsHeight() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 161
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return 180
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 218
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 230
  }
  return 238
}

export function myIngredientsRowsCount(length: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return Math.ceil(length / 3 )
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return Math.ceil(length / 5)
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return Math.ceil(length / 5)
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return Math.ceil(length / 6)
  }
  return Math.ceil(length / 7)
}

export function myIngredientsItemIndex(
  row: number,
  column: number,
) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return row * 3 + column
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return row * 5 + column
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return row * 5 + column
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return row * 6 + column
  }
  return row * 7 + column
}
