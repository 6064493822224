import './index.scss'

interface EventStepTitleProps {
  title: string
  text: string
}

export default function EventStepTitle({ text, title }: EventStepTitleProps) {
  return (
    <div className="eventStepTitleWrapper">
      <h5>{title}</h5>
      <p>{text}</p>
    </div>
  )
}
