/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as FavoriteIng } from '../../assets/onBar.svg'
import { ReactComponent as NotFavoriteIng } from '../../assets/notOnBar.svg'
import useActions from '../../../../hooks/useActions'
import RoutesPaths from '../../../../constants/navigate'
import { SmallTabletDevicesWidth } from '../../../../constants/screensWidth'

interface IngredientBlockCardProps {
  imageSource: string
  description: string
  isFavorite: boolean
  ingId: number
}

function IngredientBlockCard({
  imageSource,
  description,
  isFavorite,
  ingId,
}: IngredientBlockCardProps) {
  const { getIngredient, showModal, popularByIngredientCocktails } =
    useActions()
  const item = {
    id: ingId,
    img: imageSource,
    description,
  }

  const navigate = useNavigate()

  const onClick = useCallback(() => {
    if (window.innerWidth <= SmallTabletDevicesWidth) {
      navigate(`/${RoutesPaths.ingredientsPage}/${ingId}`)
    } else {
      showModal('Ingredient', {
        item: { id: ingId, description, image: imageSource },
      })
      popularByIngredientCocktails(String(ingId))
      getIngredient(String(ingId))
    }
  }, [ingId])

  return (
    <div
      className="ingredientsBlockItem"
      onClick={onClick}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onClick
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="ingredientsBlockSoloItemText">
        <p>{description}</p>
      </div>
      <div className="ingredientsBlockItemImageWrapper">
        <div className="ingredientsBlockItemImageWrapperFav">
          {isFavorite ? <FavoriteIng /> : <NotFavoriteIng />}
        </div>
        <img src={imageSource} alt={description} />
      </div>
    </div>
  )
}

export default IngredientBlockCard
