import { useState } from 'react'
import useActions from '../../../../hooks/useActions'
import { IAuthConfirmState } from '../../../../@types/auth'
import { ReactComponent as Verified } from '../../assets/Verified.svg'
import { useNavigate } from 'react-router-dom'

type VerifyType = {
  email: string
}

export default function VerifyComponent({ email }: VerifyType) {
  const [state, setState] = useState<IAuthConfirmState>({
    errorAnimation: false,
    stateMassage: false,
    access: false,
    loading: false,
  })
  const { userConfirm, userResendOneTimePassword, hideModal } = useActions()
  const navigate = useNavigate()

  function onInput(e: any) {
    e.preventDefault()

    const value = e.target.value.trim()
    const n = parseInt(e.target.dataset.n, 10)

    if (value.length === 1) {
      e.target.value = value[0]
      e.target.classList.add('hasNumber')

      const next = document.querySelector(
        `input[data-n="${n + 1}"]`,
      ) as HTMLInputElement
      if (next) {
        next.focus()
      }
    } else {
      e.target.value = ''
    }

    if (value === '' && n > 0) {
      e.target.classList.remove('hasNumber')
      const prev = document.querySelector(
        `input[data-n="${n - 1}"]`,
      ) as HTMLInputElement
      if (prev) {
        prev.focus()
      }
    }
  }

  function onPaste(e: any) {
    e.preventDefault()
    const inputs = Array.from(document.getElementsByClassName('verifyInput'))
    const pasteData = e.clipboardData.getData('text').trim()
    const inputArray = pasteData.split('')
    inputs.forEach((input: any, i: number) => {
      input.value = Number(inputArray[i]) || ''
      input.classList.add('hasNumber')
    })
    const last = document.querySelector(
      `input[data-n="${pasteData.length}"]`,
    ) as HTMLInputElement
    if (last) {
      last.focus()
    }
  }

  function inputsMaker() {
    const inputsArray = []
    for (let index = 0; index <= 5; index++) {
      inputsArray.push(
        <input
          min={0}
          max={9}
          maxLength={1}
          type="number"
          className={'verifyInput'}
          data-n={index}
          onInputCapture={onInput}
          key={`verifyInputN${index}`}
          onPasteCapture={onPaste}
        ></input>,
      )
    }
    return inputsArray
  }

  const resendOneTimePassword = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    userResendOneTimePassword(email, setState)
  }

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const inputs = Array.from(document.getElementsByClassName('verifyInput'))
    const values: string[] = []

    inputs.forEach((input: any) => {
      values.push(input.value)
    })
    userConfirm(email, Number(values.join('')), setState)
  }

  return (
    <>
      {state.access ? (
        <>
          <h3>
            Your Drankly account has been successfully verified! You can now log
            in and start exploring the world of home bartending.
          </h3>
          <p>Cheers to creating amazing drinks!</p>
          <Verified />
          <button
            className="go-to-main-button"
            onClick={() => {
              navigate('/')
              hideModal()
            }}
          >
            GO TO MAIN PAGE
          </button>
        </>
      ) : (
        <>
          <h3>Please enter the One-Time Password to verify your account</h3>
          <p>
            A One-Time Password has been sent to {email.split('@')[0] + '**@**'}
          </p>
          <form name="login" className="login-form" onSubmit={onSubmit}>
            <div className="verifyInputContainer">{inputsMaker()}</div>
            <button type="submit" className="login-form-button">
              VALIDATE
            </button>
          </form>
          <div className="changeForm">
            <button onClick={resendOneTimePassword}>
              Resend One-Time Password
            </button>
          </div>
        </>
      )}
    </>
  )
}
