import { AxiosResponse } from 'axios'
import {
  IEventsItem,
  IEventCocktailsPreferences,
  IEventsSummary,
  IEventOccasion,
  ICreateEvent,
  IEventSummary,
  IUpdateEvent,
} from '../../@types/events'
import axiosInstance from '..'
import { getParams } from '../../store/reducers/auth/authUtils'
import { ICocktail } from '../../@types/cocktails'

class EventsService {
  // TODO:
  static async getOccasions(): Promise<AxiosResponse<IEventOccasion[]>> {
    return axiosInstance.get('/occasions')
  }

  // TODO:
  static async deleteEvent(eventId: string): Promise<AxiosResponse> {
    return axiosInstance.delete(`/events/${eventId}`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  // TODO:
  static async getEvents(
    type: 'PLANNED',
  ): Promise<AxiosResponse<IEventsItem[]>> {
    const url = '/events'
    const searchParams = new URLSearchParams()
    searchParams.set('type', type)
    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  // FIXME: переписать тип ивента
  static async getEvent(
    eventId: string,
  ): Promise<AxiosResponse<IEventSummary>> {
    return axiosInstance.get(`/events/${eventId}`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  // TODO:
  static async getEventsSummary(): Promise<AxiosResponse<IEventsSummary>> {
    return axiosInstance.get('/events/summary', {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async getEventCocktails(
    preferences: IEventCocktailsPreferences,
  ): Promise<AxiosResponse<ICocktail[]>> {
    return axiosInstance.post('/events/cocktails', preferences, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async uploadEventImage(file: File): Promise<AxiosResponse<string>> {
    return axiosInstance.post(
      '/images/events',
      { file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  static async postEvent(
    event: ICreateEvent,
  ): Promise<AxiosResponse<IEventsItem>> {
    return axiosInstance.post('/events', event, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async postEventInvites(
    eventId: string,
    invites: string[],
  ): Promise<AxiosResponse> {
    return axiosInstance.post(
      `/events/${eventId}/invites`,
      { emails: invites },
      {
        headers: {
          Authorization: getParams('access_token')
            ? `Bearer ${getParams('access_token')}`
            : '',
        },
      },
    )
  }

  static async getTemplates(): Promise<AxiosResponse<IEventsItem[]>> {
    return axiosInstance.get('/events/templates', {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async getTemplateById(
    templateId: string,
  ): Promise<AxiosResponse<IEventsItem>> {
    return axiosInstance.get(`/events/templates/${templateId}`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async patchEvent(
    updateData: IUpdateEvent,
    eventId: string,
    notification: boolean,
  ): Promise<AxiosResponse<IEventsItem>> {
    const url = `/events/${eventId}`
    const searchParams = new URLSearchParams()

    searchParams.set('isNotify', notification.toString())

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.patch(fullUrl, updateData, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }
}

export default EventsService
