import { useState } from 'react'

import NextStepButton from '../../components/NextStepButton'
import useActions from '../../../../hooks/useActions'

interface PartyEmailsAddressProps {
  eventId: string
  setIsSuccess: (value: boolean) => void
}

function PartyEmailsAddress({
  eventId,
  setIsSuccess,
}: PartyEmailsAddressProps) {
  const [emails, setEmails] = useState<string>('')

  const { postEventInvites } = useActions()

  const nextStepHandler = () => {
    postEventInvites(
      eventId,
      emails.split(',').filter((item: string) => item.trim()),
      setIsSuccess,
    )
  }

  return (
    <>
      <div className="partyEmailsAddressWrapper">
        {/* <h4>Enter the email addresses</h4> */}
        <textarea
          placeholder="For example: guest1@mail.com, guest2@mail.com"
          onChange={event => setEmails(event.target.value)}
        />
      </div>
      <NextStepButton
        handler={nextStepHandler}
        isActive={!!emails}
        btnText="SEND"
        isArrow={false}
      />
    </>
  )
}

export default PartyEmailsAddress
