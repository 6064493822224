import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import ImageForMobile from './mobile-size-image.jpg'
import RoutesPaths from '../../constants/navigate'
import { EngLocales } from '../../assets/locales/eng'
import { MobileDevicesWidth } from '../../constants/screensWidth'
import { PageTitles } from '../../constants/pageTitles'

function HomePage() {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = PageTitles.mainPage
  }, [])

  const onClick = () => {
    navigate(RoutesPaths.cocktailsPage)
  }

  return (
    <div className="site-layout">
      <div
        className="site-layout-background"
        style={{
          minHeight: window.innerWidth > MobileDevicesWidth ? 380 : 'auto',
        }}
      >
        {window.innerWidth <= 500 && (
          <div className="imageForMobile">
            <img src={ImageForMobile} alt="home page" />
          </div>
        )}
        <div className="content-text">
          <p>{EngLocales.HomePage.titleText}</p>
          <h3>{EngLocales.HomePage.titleHeader}</h3>
        </div>
        <button
          className="button-start"
          onClick={onClick}
          type="button"
          aria-label="To cocktails page"
        >
          <span
            dangerouslySetInnerHTML={{ __html: EngLocales.HomePage.buttonText }}
          />
        </button>
      </div>
    </div>
  )
}

export default HomePage
