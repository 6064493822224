/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { removeParams } from '../../store/reducers/auth/authUtils'
import useActions from '../../hooks/useActions'
import { EngLocales } from '../../assets/locales/eng'
import useTypedSelector from '../../hooks/useTypedSelector'

import ProfileBlock from './ProfileBlock'
import UserInfo from './UserInfoBlock'
import PaymentsBlock from './PaymentsBlock'
import PublicProfileBlock from './PublicProfileBlock'
import SettingsBlockMobile from './SettingsBlockMobile'
import SettingsBlock from './SettingsBlock'

import { ReactComponent as PaymentsIcon } from './images/payments-icon.svg'
import { ReactComponent as PublicProfileIcon } from './images/public-profile-icon.svg'
import { ReactComponent as SettingsIcon } from './images/settings-icon.svg'
import { ReactComponent as UserInfoIcon } from './images/user-info-icon.svg'
import { ReactComponent as LogOutIcon } from './images/logout-icon.svg'

import './index.scss'
import { SmallTabletDevicesWidth } from '../../constants/screensWidth'
import { UserState } from '../../store/reducers/user/types'
import { PageTitles } from '../../constants/pageTitles'

interface TabState {
  userInfo: boolean
  payments: boolean
  publicProfile: boolean
  settings: boolean
  [key: string]: boolean
}

function UserProfile() {
  const navigate = useNavigate()
  const [whatTabOpen, setWhatTabOpen] = useState<TabState>({
    userInfo: true,
    payments: false,
    publicProfile: false,
    settings: false,
  })

  const { getUserProfile, clearUserProfile } = useActions()
  const { userProfile } = useTypedSelector<UserState>(state => state.user)

  function setLiActive(key: keyof TabState) {
    setWhatTabOpen(prevTabs => {
      const newTabs: TabState = { ...prevTabs }

      Object.keys(newTabs).forEach(tabKey => {
        if (tabKey === key) {
          newTabs[tabKey] = true
        } else {
          newTabs[tabKey] = false
        }
      })

      return newTabs
    })
  }

  const userLogOut = () => {
    removeParams()
    clearUserProfile()
    navigate('/')
  }

  useEffect(() => {
    document.title = PageTitles.userProfilePage
    getUserProfile()
  }, [])

  return (
    <div className="userProfileWrapper">
      {userProfile && (
        <div className="userProfileInner">
          <div className="userProfileNav">
            <div className="userProfileNavMenu">
              <ul>
                <li
                  onClick={() => setLiActive('userInfo')}
                  className={whatTabOpen.userInfo ? 'active' : ''}
                >
                  <UserInfoIcon />{' '}
                  {EngLocales.UserProfilePage.userPageNav.userInfo}
                </li>
                <li
                  onClick={() => setLiActive('payments')}
                  className={whatTabOpen.payments ? 'active' : ''}
                >
                  <PaymentsIcon />{' '}
                  {EngLocales.UserProfilePage.userPageNav.payments}
                </li>
                <li
                  onClick={() => setLiActive('publicProfile')}
                  className={whatTabOpen.publicProfile ? 'active' : ''}
                >
                  <PublicProfileIcon className="specialForPublicProfile" />{' '}
                  {EngLocales.UserProfilePage.userPageNav.publicProfile}
                </li>
                <li
                  onClick={() => setLiActive('settings')}
                  className={whatTabOpen.settings ? 'active' : ''}
                >
                  <SettingsIcon />{' '}
                  {EngLocales.UserProfilePage.userPageNav.settings}
                </li>
              </ul>
            </div>
            <button
              className="userProfileLogOutBtn"
              onClick={userLogOut}
              type="button"
            >
              <LogOutIcon /> {EngLocales.UserProfilePage.userPageNav.logOut}
            </button>
          </div>
          <div className="userProfileContent">
            {whatTabOpen.userInfo && (
              <>
                <UserInfo
                  userInfo={{
                    username: userProfile.username,
                    email: userProfile.email,
                    name: userProfile.name ?? '',
                  }}
                />
                <ProfileBlock />
              </>
            )}
            {whatTabOpen.payments && (
              <>
                <PaymentsBlock
                  subscriptionsInfo={userProfile.subscriptions}
                  paymentsInfo={userProfile.payments}
                  plan={userProfile.plan}
                />
                <ProfileBlock />
              </>
            )}
            {whatTabOpen.publicProfile && (
              <>
                <PublicProfileBlock
                  publicConfiguration={
                    userProfile.configuration.publicProfileConfiguration
                  }
                  profileInfo={{
                    username: userProfile.username,
                    plan: userProfile.plan,
                    name: userProfile.name ? userProfile.name : '',
                  }}
                />
                <ProfileBlock />
              </>
            )}
            {whatTabOpen.settings && (
              <>
                {window.innerWidth > SmallTabletDevicesWidth ? (
                  <SettingsBlock />
                ) : (
                  <SettingsBlockMobile />
                )}
                {window.innerWidth <= SmallTabletDevicesWidth && (
                  <ProfileBlock />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default UserProfile
