/* eslint-disable no-case-declarations */
import { FiltersActionEnum, FiltersState, FiltersAction } from './types'

const initialState: FiltersState = {
  ingredient: [],
  flavors: [],
  glasses: [],
  categories: [],
  allergens: [],
  isPrimary: [
    { id: 'true', name: 'Primary' },
    { id: 'false', name: 'Optional' },
  ],
  complexity: [
    { id: 'HARD', name: 'Hard' },
    { id: 'MEDIUM', name: 'Medium' },
    { id: 'EASY', name: 'Easy' },
  ],
  strength: [
    { id: 'ALCOHOL_FREE', name: 'Alcohol Free' },
    { id: 'LIGHT', name: 'Light' },
    { id: 'MEDIUM', name: 'Medium' },
    { id: 'STRONG', name: 'Strong' },
  ],
  selectedFilters: {
    ingredient: [],
    flavor: [],
    glass: [],
    complexity: [],
    strength: [],
    category: [],
    isPrimary: [],
    ingCategory: [],
    ingAllergen: [],
    allergen: [],
  },
}

const filtersReducer = (state = initialState, action: FiltersAction) => {
  switch (action.type) {
    case FiltersActionEnum.GET_PRIMARY_INGREDIENTS:
      return {
        ...state,
        ingredient: action.payload,
      }
    case FiltersActionEnum.GET_FLAVORS:
      return {
        ...state,
        flavors: action.payload,
      }
    case FiltersActionEnum.GET_GLASSES:
      return {
        ...state,
        glasses: action.payload,
      }
    case FiltersActionEnum.GET_ALLERGENS:
      return {
        ...state,
        allergens: action.payload,
      }
    case FiltersActionEnum.GET_CATEGORIES:
      return { ...state, categories: action.payload }
    case FiltersActionEnum.SET_SELECTED_FILTER: {
      const { id, filter } = action.payload
      const updatedSelectedFilters = {
        ...state.selectedFilters,
        [filter]: id,
      }
      return {
        ...state,
        selectedFilters: updatedSelectedFilters,
      }
    }
    case FiltersActionEnum.CLEAR_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ingredient: [],
          flavor: [],
          glass: [],
          isPrimary: [],
          complexity: [],
          strength: [],
          category: [],
          allergen: [],
          ingCategory: [],
          ingAllergen: [],
        },
      }
    case FiltersActionEnum.CLEAR_SELECTED_COCKTAILS_FILTERS: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          ingredient: [],
          flavor: [],
          glass: [],
          complexity: [],
          strength: [],
          category: [],
          allergen: [],
        },
      }
    }
    case FiltersActionEnum.CLEAR_SELECTED_INGREDIENTS_FILTERS: {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          isPrimary: [],
          ingCategory: [],
          ingAllergen: [],
        },
      }
    }
    case FiltersActionEnum.APPLY_SELECTED_FILTERS: {
      const updatedSelectedFilters = {
        ...state.selectedFilters,
        ...action.payload,
      }
      return {
        ...state,
        selectedFilters: updatedSelectedFilters,
      }
    }
    default:
      return state
  }
}

export default filtersReducer
