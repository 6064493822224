/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useTypedSelector from '../../../../hooks/useTypedSelector'
import { FiltersState } from '../../../../store/reducers/filters/types'
import useActions from '../../../../hooks/useActions'
import NextStepButton from '../../components/NextStepButton'
import { ICocktailFilter } from '../../../../@types/cocktails'
import { staticApi } from '../../../../api'

import './index.scss'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'
import EventStepTitle from '../../components/EventStepTitle'

interface PartyScratchAllergensProps {
  changeStep: (step: number) => void
  nextStep: number
}

function PartyScratchAllergens({
  changeStep,
  nextStep,
}: PartyScratchAllergensProps) {
  const [selectedAllergens, setSelectedAllergens] = useState<number[]>([])

  const { allergens } = useTypedSelector<FiltersState>(state => state.filter)
  const { cocktailsPreferences } = useTypedSelector(state => state.events)
  const { updateEventCocktailsPreferences } = useActions()

  useEffect(() => {
    if (cocktailsPreferences.allergens) {
      setSelectedAllergens(cocktailsPreferences.allergens)
    }
  }, [])

  const nextStepHandler = () => {
    updateEventCocktailsPreferences(selectedAllergens, 'allergens')
    return changeStep(nextStep)
  }

  const pushSelectedAllergens = (id: number) => {
    setSelectedAllergens((prev: number[]) => {
      if (prev.includes(id)) {
        return prev.filter(flavorId => flavorId !== id)
      }
      return [...prev, id]
    })
  }

  const selectAllAllergens = () => {
    const newArr = allergens.map((item: ICocktailFilter) => +item.id)
    if (newArr.length === selectedAllergens.length) {
      return setSelectedAllergens([])
    }
    return setSelectedAllergens(newArr)
  }

  return (
    <div className="partyScratchAllergensWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <EventStepTitle
          title="Allergens (exclude)"
          text="Ensure guest safety by specifying any allergens to be avoided in your cocktail creations."
        />
      )}

      <div className="partyScratchAllergensInner">
        {allergens.map((item: ICocktailFilter) => {
          const isActive = selectedAllergens.includes(+item.id)
          return (
            <div
              key={item.id}
              className={`partyScratchAllergensInnerItem ${isActive ? 'activeAllergen' : ''}`}
              onClick={() => pushSelectedAllergens(+item.id)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  pushSelectedAllergens(+item.id)
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img
                src={`${staticApi()}/images/allergens/${item.image}`}
                alt={item.name}
              />
              <h5>{item.name}</h5>
            </div>
          )
        })}
      </div>

      <div className="partyScratchAllergensCheckboxWrapper">
        <div
          className="changeFilterOption"
          onClick={() => {
            selectAllAllergens()
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              selectAllAllergens()
            }
          }}
          role="button"
          tabIndex={0}
        >
          <label
            className={
              allergens.length === selectedAllergens.length
                ? 'change-custom-checkbox checked'
                : 'change-custom-checkbox'
            }
          >
            <input
              type="checkbox"
              checked={allergens.length === selectedAllergens.length}
              disabled
            />
          </label>
          <p>Exclude allergens</p>
        </div>
        <p>All cocktails containing allergens will be excluded</p>
      </div>

      <NextStepButton handler={nextStepHandler} isActive />
    </div>
  )
}

export default PartyScratchAllergens
