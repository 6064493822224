import { ColorRing } from 'react-loader-spinner'

export default function Loader() {
  return (
    <div className="loaderWrapper">
      <ColorRing
        visible
        height="80"
        width="80"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#5B02F1', '#312DF9', '#D301F9', '#B952CC', '#5202F0']}
      />
    </div>
  )
}
