/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import Slider from 'react-slick'

import useActions from '../../../hooks/useActions'
import useTypedSelector from '../../../hooks/useTypedSelector'

import ChoosePlanItem from './ChoosePlanItem'

import PremiumPlansHeader from '../images/premium-plans-header-image.png'

import './index.scss'
import { MobileDevicesWidth } from '../../../constants/screensWidth'
import { ISubscription } from '../../../@types/subscriptions'
import { PageTitles } from '../../../constants/pageTitles'

function ChoosePlanPage() {
  const { getPlans } = useActions()
  const { subscriptions, stripeLink } = useTypedSelector(
    state => state.subscriptions,
  )

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  useEffect(() => {
    document.title = PageTitles.plansPage

    if (!subscriptions.length) {
      getPlans()
    }
  }, [])

  useEffect(() => {
    if (stripeLink.length) {
      window.location.replace(stripeLink)
    }
  }, [stripeLink])

  return (
    <div className="plansPageWrapper">
      <div className="choosePlanInner">
        <img
          className="choosePlanInnerHeader"
          src={PremiumPlansHeader}
          alt="Choose your plan Unlock endless possibilities"
        />
        <div className="choosePlanInnerContentWrapper">
          <div className="choosePlanInnerContent">
            {window.innerWidth > MobileDevicesWidth ? (
              subscriptions.map((item: ISubscription) => {
                const {
                  amount,
                  currency,
                  description,
                  image,
                  name,
                  paymentUrl,
                  period,
                  priceId,
                  productId,
                } = item
                return (
                  <ChoosePlanItem
                    key={Math.random()}
                    name={name}
                    amount={amount}
                    currency={currency}
                    period={period}
                    productId={productId}
                    priceId={priceId}
                    description={description}
                    image={image}
                    paymentUrl={paymentUrl}
                  />
                )
              })
            ) : (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Slider {...settings}>
                {subscriptions.map((item: ISubscription, index) => {
                  const {
                    amount,
                    currency,
                    description,
                    image,
                    name,
                    paymentUrl,
                    period,
                    priceId,
                    productId,
                  } = item
                  return (
                    <ChoosePlanItem
                      key={Math.random()}
                      name={name}
                      amount={amount}
                      currency={currency}
                      period={period}
                      productId={productId}
                      priceId={priceId}
                      description={description}
                      image={image}
                      paymentUrl={paymentUrl}
                    />
                  )
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChoosePlanPage
