/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { api } from '../../../api'
import AlternativeCocktailsItem from './AlternativeCocktailsItem'
import CocktailsCard from '../../CocktailsPage/CocktailsCard'
import { MobileDevicesWidth } from '../../../constants/screensWidth'
import RoutesPaths from '../../../constants/navigate'

import Loader from '../../../components/Loader'

import './index.scss'
import { ICocktail } from '../../../@types/cocktails'
import useActions from '../../../hooks/useActions'
import useTypedSelector from '../../../hooks/useTypedSelector'
import { getParams } from '../../../store/reducers/auth/authUtils'

interface AlternativeCocktailsBlockProps {
  id: string
  // cocktails?: ICocktail[]
}

function AlternativeCocktailsBlock({
  id,
  // cocktails,
}: AlternativeCocktailsBlockProps) {
  const navigate = useNavigate()

  const { cocktailAnalogs } = useActions()
  const { analogs } = useTypedSelector(state => state.cocktails)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (getParams('access_token') && !analogs.length) {
      cocktailAnalogs(id, setLoading)
    }
  }, [id])

  return loading ? (
    <div className="alternativeCocktailsWrapper">
      <Loader />
    </div>
  ) : (
    <div className="alternativeCocktailsWrapper">
      {analogs.length && window.innerWidth > MobileDevicesWidth
        ? analogs.map((item: ICocktail) => {
            return (
              <AlternativeCocktailsItem
                key={item.id}
                id={item.id.toString()}
                description={item.name}
                imageSource={`${api()}/images/cocktails/m/${item.image}`}
              />
            )
          })
        : analogs.map((item: ICocktail) => {
            return (
              <CocktailsCard isShowFilters={false} item={item} key={item.id} />
            )
          })}
      {!analogs.length && (
        <div className="alternativeCocktailsWrapperUnauthorizeWrapper">
          <div className="alternativeCocktailsWrapperUnauthorizeInner">
            <h3>unauthorized users cannot view alternative cocktails</h3>
            <button
              onClick={() => navigate(`/${RoutesPaths.loginPage}`)}
              type="button"
            >
              LOGIN AND TRY FOR FREE
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AlternativeCocktailsBlock
