import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import UnSubPageImage from './main-image.png'

import './index.scss'
import { EngLocales } from '../../assets/locales/eng'
import useActions from '../../hooks/useActions'
import { PageTitles } from '../../constants/pageTitles'

function UnsubscribePage() {
  const { setUserProfileNotification } = useActions()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = PageTitles.mainPage
  }, [])

  return (
    <div className="unsubscribePageWrapper">
      <div className="unsubscribePageInner">
        <img src={UnSubPageImage} alt="Unsubscription page" />
        <div className="unsubscribePageDescription">
          <h2>
            {
              EngLocales.UnsubscribeNewsletterPage
                .unsubscribeNewsletterPageTitle
            }
          </h2>
          <p>
            {EngLocales.UnsubscribeNewsletterPage.unsubscribeNewsletterPageText}
          </p>
          <h3>
            {
              EngLocales.UnsubscribeNewsletterPage
                .unsubscribeNewsletterPageButtonsTitle
            }
          </h3>
          <div className="unsubscribePageInnerButtons">
            <button
              onClick={() => {
                navigate('/')
              }}
              type="button"
            >
              NO
            </button>
            <button
              onClick={() => {
                setUserProfileNotification({
                  notificationsPeriod: 'DAILY',
                  isAllowNotification: false,
                })
                navigate('/')
              }}
              type="button"
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnsubscribePage
