import axios, { AxiosResponse } from 'axios'
import axiosInstance from '..'
import { ICocktailFilter, ICocktailSelectedFilters } from '../../@types/cocktails'
import { getParams } from '../../store/reducers/auth/authUtils'
import { IIngredientsList } from '../../@types/ingredients'

class FiltersService {
  static async getPrimaryIngredients(): Promise<AxiosResponse<IIngredientsList>> {
    return axiosInstance.get('/users/bar/ingredients?isPrimary=true&page=0', {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async getFlavors(): Promise<AxiosResponse<ICocktailFilter[]>> {
    return axiosInstance.get('/flavors')
  }

  static async getGlasses(): Promise<AxiosResponse<ICocktailFilter[]>> {
    return axiosInstance.get('/glasses', {})
  }

  static async getCategories(
    isPrimary: boolean,
  ): Promise<AxiosResponse<ICocktailFilter[]>> {
    const url = '/ingredient-category'
    const searchParams = new URLSearchParams()

    if (isPrimary) {
      searchParams.set('isPrimary', isPrimary.toString())
    }

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl)
  }

  static async getAllergens(): Promise<AxiosResponse<ICocktailFilter[]>> {
    return axiosInstance.get('/allergens')
  }
}

export default FiltersService
