import { AppDispatch } from '../..'
import DBarService from '../../../api/userBar'
import {
  BarActionEnum,
  GetFavoritesIngredientsBarAction,
  GetMixologyListBarAction,
} from './types'
import {
  ICocktail,
  ICocktailSelectedFilters,
  ICocktailsList,
} from '../../../@types/cocktails'
import {
  IBarCocktailsCollection,
  IPatchCollectionParams,
} from '../../../@types/userBar'
import RoutesPaths from '../../../constants/navigate'
import { IIngredientsList } from '../../../@types/ingredients'

interface GetCocktailsParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  sortValue: string
  excludeCocktails: number[]
  searchField?: string
  url: string
}

interface GetFavoriteIngredientsParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  searchFields?: string
}

interface GetMixologyListParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  searchFields?: string
}

const BarActionCreators = {
  // INGREDIENTS
  GetFavoriteIngredientsAction: (
    data: IIngredientsList,
  ): GetFavoritesIngredientsBarAction => {
    return {
      type: BarActionEnum.GET_FAVORITES_INGREDIENTS,
      payload: data,
    }
  },

  getFavoriteIngredients: ({
    page,
    size,
    selectedFilters,
    searchFields,
  }: GetFavoriteIngredientsParams) => {
    return async (dispatch: AppDispatch) => {
      try {
        let requestWithFilters = false

        Object.keys(selectedFilters).forEach(key => {
          const values = selectedFilters[key]
          if (values.length > 0) {
            requestWithFilters = true
          }
        })

        const { data } = await DBarService.getFavoriteIngredients({
          page,
          size,
          selectedFilters,
          searchFields,
        })
        if (searchFields) {
          if (+page === 0) {
            BarActionCreators.clearFavoriteIngredientsList()(dispatch)
          }
          dispatch({
            type: BarActionEnum.GET_FAVORITES_INGREDIENTS_SEARCH,
            payload: { ...data, searchFields },
          })
        } else if (requestWithFilters) {
          dispatch({
            type: BarActionEnum.GET_FAVORITES_INGREDIENTS_BY_FILTER_ACTION,
            payload: data,
          })
        } else {
          dispatch({
            type: BarActionEnum.GET_FAVORITES_INGREDIENTS,
            payload: data,
          })
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  clearFavoriteIngredientsList: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: BarActionEnum.CLEAR_FAVORITES_INGREDIENTS_LIST })
    }
  },
  ingredientFavorites: (id: number) => {
    return async (dispatch: AppDispatch) => {
      try {
        await DBarService.ingredientFavorites(id)
        dispatch({
          type: BarActionEnum.FAVORITES_INGREDIENT,
          payload: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },

  deleteIngredientFavorites: (id: number) => {
    return async (dispatch: AppDispatch) => {
      try {
        await DBarService.deleteIngredientFavorites(id)
        dispatch({
          type: BarActionEnum.FAVORITES_INGREDIENT,
          payload: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
    // MIXOLOGY_LIST
  GetMixologyListAction: (data: ICocktailsList): GetMixologyListBarAction => {
    return {
      type: BarActionEnum.GET_MIXOLOGY_LIST,
      payload: data,
    }
  },
  getMixologyList: ({
    page,
    size,
    selectedFilters,
    searchFields,
  }: GetMixologyListParams) => {
    return async (dispatch: AppDispatch) => {
      try {
        let requestWithFilters = false

        Object.keys(selectedFilters).forEach(key => {
          const values = selectedFilters[key]
          if (values.length > 0) {
            requestWithFilters = true
          }
        })

        const { data } = await DBarService.getMixologyList({
          page,
          size,
          selectedFilters,
          searchFields,
        })
        if (searchFields) {
          if (+page === 0) {
            BarActionCreators.clearMixologyList()(dispatch)
          }
          dispatch({
            type: BarActionEnum.GET_MIXOLOGY_LIST_SEARCH,
            payload: { ...data, searchFields },
          })
        } else if (requestWithFilters) {
          dispatch({
            type: BarActionEnum.GET_MIXOLOGY_LIST_BY_FILTER_ACTION,
            payload: data,
          })
        } else {
          dispatch({
            type: BarActionEnum.GET_MIXOLOGY_LIST,
            payload: data,
          })
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  clearMixologyList: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: BarActionEnum.CLEAR_MIXOLOGY_LIST })
    }
  },
  // IMAGES
  uploadCollectionImage: (
    image: File,
    setIsFinish: (value: boolean) => void,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await DBarService.uploadCollectionImage(image)
        dispatch({ type: BarActionEnum.UPLOAD_COLLECTION_IMAGE, payload: data })
        setIsFinish(false)
      } catch (error: unknown) {
        console.error(error)
        setIsFinish(false)
      }
    }
  },
  clearCollectionImage: () => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({ type: BarActionEnum.CLEAR_COLLECTION_IMAGE })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  // COCKTAILS
  getBarCocktails: ({
    page,
    size,
    selectedFilters,
    searchField,
    sortValue,
    excludeCocktails,
    url,
  }: GetCocktailsParams) => {
    return async (dispatch: AppDispatch) => {
      try {
        let requestWithFilters = false

        Object.keys(selectedFilters).forEach(key => {
          const values = selectedFilters[key]
          if (values.length > 0) {
            requestWithFilters = true
          }
        })

        const { data } = await DBarService.getBarCocktails({
          page,
          size,
          selectedFilters,
          searchField,
          sortValue,
          excludeCocktails,
          barUrl: url,
        })
        if (searchField) {
          if (+page === 0) {
            BarActionCreators.clearBarCocktailsList()(dispatch)
          }
          dispatch({
            type: BarActionEnum.GET_BAR_COCKTAILS_SEARCH,
            payload: {
              ...data,
              content: data.content.reduce(
                (acc, item) => {
                  if (item !== null) {
                    acc[item.id] = item
                  }
                  return acc
                },
                {} as { [key: string]: ICocktail },
              ),
              searchField,
            },
          })
        } else if (requestWithFilters) {
          dispatch({
            type: BarActionEnum.GET_BAR_COCKTAILS_BY_FILTER_ACTION,
            payload: {
              ...data,
              content: data.content.reduce(
                (acc, item) => {
                  if (item !== null) {
                    acc[item.id] = item
                  }
                  return acc
                },
                {} as { [key: string]: ICocktail },
              ),
            },
          })
        } else if (size === '7') {
          dispatch({
            type: BarActionEnum.GET_BAR_COCKTAILS_MAIN_PAGE,
            payload: {
              ...data,
              mainPageTotalElements: data.totalElements,
              totalElements: 0,
              content: data.content.reduce(
                (acc, item) => {
                  acc[item.id] = item
                  return acc
                },
                {} as { [key: string]: ICocktail },
              ),
            },
          })
        } else {
          dispatch({
            type: BarActionEnum.GET_BAR_COCKTAILS,
            payload: {
              ...data,
              content: data.content.reduce(
                (acc, item) => {
                  acc[item.id] = item
                  return acc
                },
                {} as { [key: string]: ICocktail },
              ),
            },
          })
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  clearBarCocktailsList: () => {
    return (dispatch: AppDispatch) => {
      dispatch({ type: BarActionEnum.CLEAR_BAR_COCKTAIL_LIST })
    }
  },
  barCocktailFavorites: (
    id: string,
    isFavorite: boolean,
    collections: IBarCocktailsCollection[],
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: BarActionEnum.BAR_FAVORITES_COCKTAIL,
          payload: {
            id,
            isFavorite,
            collections,
          },
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  // COCKTAILS COLLECTIONS
  createCocktailsCollection: (
    name: string,
    image: string,
    cocktails: number[],
    navigate?: (path: string) => void,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await DBarService.createCollection(
          name,
          image,
          cocktails,
        )
        dispatch({
          type: BarActionEnum.CREATE_COCKTAIL_COLLECTION,
          payload: data,
        })
        navigate &&
          navigate(`/${RoutesPaths.myBarCocktailsCollections}/${data.id}`)
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  getBarCocktailsCollections: (name: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await DBarService.getCollections(name)
        dispatch({
          type: BarActionEnum.GET_BAR_COLLECTIONS,
          payload: data.reduce(
            (acc, item) => {
              acc[item.id] = item
              return acc
            },
            {} as { [key: string]: IBarCocktailsCollection },
          ),
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  setSelectedCollection: (collection: IBarCocktailsCollection) => {
    return async (dispatch: AppDispatch) => {
      try {
        dispatch({
          type: BarActionEnum.SET_SELECTED_COLLECTION,
          payload: collection,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  patchCollection: (patchCollection: IPatchCollectionParams) => {
    return async (dispatch: AppDispatch) => {
      try {
        const { data } = await DBarService.patchCollection(patchCollection)
        dispatch({
          type: BarActionEnum.PATCH_COCKTAILS_COLLECTION,
          payload: {
            id: patchCollection.collectionId,
            ...data,
          },
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  deleteCollection: (
    collectionId: string,
    cb: () => void,
    hide: () => void,
  ) => {
    return async (dispatch: AppDispatch) => {
      try {
        await DBarService.deleteCollection(collectionId)
        dispatch({
          type: BarActionEnum.DELETE_COCKTAILS_COLLECTION,
          payload: collectionId,
        })
        cb()
        hide()
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  pinCollection: (collectionId: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        await DBarService.pinCollection(collectionId)
        dispatch({
          type: BarActionEnum.PIN_COCKTAILS_COLLECTION,
          payload: collectionId,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
  unpinCollection: (collectionId: string) => {
    return async (dispatch: AppDispatch) => {
      try {
        await DBarService.unpinCollection(collectionId)
        dispatch({
          type: BarActionEnum.UNPIN_COCKTAILS_COLLECTION,
          payload: collectionId,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    }
  },
}

export default BarActionCreators
