/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import { message, notification } from 'antd'

import useTypedSelector from '../../../../hooks/useTypedSelector'
import BarWrapper from '../../BarWrapper'
import RoutesPaths from '../../../../constants/navigate'
import { api } from '../../../../api'

import { ReactComponent as ArrowIcon } from '../../assets/arrow-icon.svg'

import './index.scss'
import useActions from '../../../../hooks/useActions'
import { cocktailsCustomNotification } from '../../../../helpers/Notifications/notificationsHelpers'
import CollectionCard, {
  AddCollectionCard,
  OwnCollectionCard,
} from '../CollectionCard'
import { BarState } from '../../../../store/reducers/bar/types'
import { UserState } from '../../../../store/reducers/user/types'

export default function CocktailsCollectionsList() {
  const { cocktailsCollections } = useTypedSelector<BarState>(
    state => state.bar,
  )
  const { userProfile } = useTypedSelector<UserState>(state => state.user)
  const { getBarCocktailsCollections, showModal } = useActions()
  const navigate = useNavigate()

  const [isPlaceholderHidden, setIsPlaceholderHidden] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const classNames = ['myBarCocktailsCollectionListHeaderInputWrapper']

  if (isPlaceholderHidden) {
    classNames.push('hidePlaceholder')
  }

  const classString = classNames.join(' ')

  const debouncedOnChange = debounce((value: string) => {
    setSearchValue(value)
  }, 200)

  const handleFocus = () => {
    setIsPlaceholderHidden(true)
  }

  const handleBlur = () => {
    if (searchValue === '') {
      setIsPlaceholderHidden(false)
    }
  }

  useEffect(() => {
    getBarCocktailsCollections(searchValue)
  }, [searchValue])

  return (
    <BarWrapper openTab="cocktails" secondClass="mediumCockInnerHeight">
      <div className="myBarCocktailsCollectionListWrapper">
        <div className="myBarCocktailsCollectionListHeader">
          <div
            className="myBarCocktailsCollectionListHeaderNav"
            onClick={() => {
              navigate(`/${RoutesPaths.myBarCocktailsPage}`)
              // cocktailsCustomNotification(() => showModal('NewCollectionModal'))
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                navigate(`/${RoutesPaths.myBarCocktailsPage}`)
              }
            }}
            role="button"
            tabIndex={0}
          >
            <ArrowIcon /> <p>My collections</p>
          </div>
          <div className={classString}>
            <input
              className="myBarCocktailsCollectionListHeaderInput"
              type="text"
              placeholder={isPlaceholderHidden ? '' : 'Search collections'}
              onChange={event => debouncedOnChange(event.target.value.trim())}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div className="myBarCocktailsCollectionList">
          <div className="myBarCocktailsCollectionListInner">
            {!searchValue.length && (
              <>
                <div className="myBarCocktailsCollectionListInnerItem">
                  <AddCollectionCard
                    cb={() => showModal('NewCollectionModal', {})}
                  />
                </div>
                <div className="myBarCocktailsCollectionListInnerItem">
                  <OwnCollectionCard
                    isPremium={!!(userProfile.plan === 'PREMIUM')}
                  />
                </div>
              </>
            )}
            {Object.entries(cocktailsCollections)
              .sort(([key1, a], [key2, b]) => {
                if (a.isPinned && !b.isPinned) {
                  return -1
                }
                if (!a.isPinned && b.isPinned) {
                  return 1
                }
                return 0
              })
              .map(([key, item]) => {
                return (
                  <div
                    className="myBarCocktailsCollectionListInnerItem"
                    key={key}
                  >
                    <CollectionCard item={item} />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </BarWrapper>
  )
}
