import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'

import { ReactComponent as PartyLocationIcon } from '../../../assets/party-location-icon.svg'
import EventCardImage from '../../../assets/completed-events-image.png'

import './index.scss'
import { api } from '../../../../../api'

interface EventsCardProps {
  title: string
  date: string
  guests: number
  location: string
  image: string
  type: 'UPCOMING' | 'PAST'
}

function EventsCard({
  date,
  guests,
  location,
  title,
  type,
  image,
}: EventsCardProps) {
  const transformDate = (dateString: string) => {
    const newDate = new Date(dateString)
    const formattedDate = format(newDate, 'MM-dd-yyyy, hh:mm a', {
      locale: enUS,
    })
    return formattedDate
  }

  return (
    <div
      className="eventsCardWrapper"
      style={{
        background:
          type === 'UPCOMING'
            ? 'linear-gradient(90deg, rgba(67, 59, 117, 0.916145) 0%, #731BB9 35%, #A22C7A 68%, #A8174B 100%)'
            : 'linear-gradient(90deg, #433d60 0%, #232a35 100%)',
      }}
    >
      <div className="eventsCardDescription">
        <h4>{title}</h4>
        <p>{transformDate(date)}</p>
        <p>
          {guests} Guest{guests > 1 && 's'}
        </p>
        <div className="eventsCardDescriptionLocation">
          <PartyLocationIcon />
          <p>{location}</p>
        </div>
      </div>
      <img src={`${api()}/images/events/${image}`} alt={title} />
    </div>
  )
}

export default EventsCard
