import axios, { AxiosResponse } from 'axios'
import axiosInstance, { api } from '..'
import { getParams } from '../../store/reducers/auth/authUtils'
import {
  IProfile,
  IProfileConfigurationNotification,
  IProfileConfigurationPublic,
} from '../../@types/userProfile'

class UserService {
  static async getProfile(): Promise<AxiosResponse<IProfile>> {
    return axiosInstance.get('/users/profile', {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }

  static async putUserProfileInfo(
    object: IProfile,
  ): Promise<AxiosResponse<IProfile>> {
    return axiosInstance.put('/users/profile', object, {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }

  static async putUserProfileConfiguration(
    object: IProfileConfigurationPublic,
  ): Promise<AxiosResponse<IProfile>> {
    return axiosInstance.put('/users/profile/configuration/public', object, {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }

  static async putUserProfileNotification(
    object: IProfileConfigurationNotification,
  ): Promise<AxiosResponse<IProfile>> {
    return axiosInstance.put(
      '/users/profile/configuration/notification',
      object,
      {
        headers: {
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  static async uploadImage(file: File): Promise<AxiosResponse<string>> {
    const url = new URL(`${api()}/images/users`)
    return axios.post(
      url.toString(),
      {
        file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }
}

export default UserService
