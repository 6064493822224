/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useState } from 'react'
import { FixedSizeGrid as Grid } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { AutoSizer } from 'react-virtualized'

import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg'

import useActions from '../../../../hooks/useActions'
import CollectionHeader from './CollectionHeader'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import { BarState } from '../../../../store/reducers/bar/types'
import { FiltersState } from '../../../../store/reducers/filters/types'
import {
  barCocktailsColumnsCount,
  barCocktailsColumnsWidth,
  barCocktailsItemIndex,
  barCocktailsRowsCount,
  barCocktailsRowsHeight,
} from '../../../../helpers/cocktailsPageHelpers'
import {
  ICocktailFilter,
  ICocktailSelectedFilters,
} from '../../../../@types/cocktails'
import CocktailMediumCard from '../../../../components/CocktailMediumCard'

import './index.scss'

interface CollectionWrapperProps {
  isFavorite: boolean
  headerTitle: string
  requestUrl: string
  imageUrl: string
}

interface CellProps {
  columnIndex: number
  rowIndex: number
  style: object
}

interface ICocktailFilterComponent extends ICocktailFilter {
  filterName: string
}

const SIZE = '28'

export default function CollectionWrapper({
  isFavorite,
  headerTitle,
  requestUrl,
  imageUrl,
}: CollectionWrapperProps) {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [needRequest, setNeedRequest] = useState<boolean>(false)
  const [searchField, setSearchValue] = useState<string>('')
  const [matchingObjects, setMatchingObjects] = useState<
    ICocktailFilterComponent[]
  >([])

  const {
    getBarCocktails,
    clearBarCocktailsList,
    clearSelectedFilters,
    setSelectedFilter,
  } = useActions()

  const { barCocktails } = useTypedSelector<BarState>(state => state.bar)
  const {
    selectedFilters,
    allergens,
    categories,
    complexity,
    flavors,
    glasses,
    strength,
  } = useTypedSelector<FiltersState>(state => state.filter)

  const findMatchingObjects = (
    stringArrays: ICocktailSelectedFilters,
    objectArrays: Record<string, ICocktailFilter[]>,
  ) => {
    const arr: ICocktailFilterComponent[] = []
    Object.keys(stringArrays).forEach(key => {
      const stringArray = stringArrays[key]
      const objectArray = objectArrays[key]
      if (objectArray) {
        stringArray.forEach(str => {
          const matchingObject = objectArray.find(
            (obj: ICocktailFilter) => obj.id.toString() === str,
          )
          if (matchingObject) {
            arr.push({ ...matchingObject, filterName: key })
          }
        })
      }
    })
    return setMatchingObjects(arr)
  }

  useEffect(() => {
    return () => {
      clearSelectedFilters()
      clearBarCocktailsList()
      setCurrentPage(0)
    }
  }, [])

  useEffect(() => {
    if (needRequest) {
      setCurrentPage(0)
      clearBarCocktailsList()
      getBarCocktails({
        page: currentPage,
        size: SIZE,
        selectedFilters,
        searchField,
        sortValue: '',
        excludeCocktails: [],
        url: requestUrl,
      })
      setNeedRequest(false)
    }
  }, [needRequest])

  useEffect(() => {
    getBarCocktails({
      page: currentPage,
      size: SIZE,
      selectedFilters,
      searchField,
      sortValue: '',
      excludeCocktails: [],
      url: requestUrl,
    })
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(0)
    clearBarCocktailsList()
    getBarCocktails({
      page: currentPage,
      size: SIZE,
      selectedFilters,
      searchField,
      sortValue: '',
      excludeCocktails: [],
      url: requestUrl,
    })
    findMatchingObjects(selectedFilters, {
      flavor: flavors,
      allergen: allergens,
      category: categories,
      complexity,
      glass: glasses,
      strength,
    })
  }, [selectedFilters])

  const memoContent = useMemo(
    () => Object.values(barCocktails.content),
    [barCocktails.content],
  )

  const loadOnScroll = () => {
    if (currentPage !== barCocktails.totalPages - 1) {
      return setCurrentPage(prev => prev + 1)
    }

    return setCurrentPage(prev => prev)
  }

  const Cell = ({ columnIndex, rowIndex, style }: CellProps) => {
    const index = barCocktailsItemIndex(rowIndex, columnIndex)
    const keys = Object.keys(barCocktails.content)
    const key = keys[index]
    const item = barCocktails.content[key]
    if (item) {
      return (
        <div style={style} key={Math.random()}>
          <CocktailMediumCard cocktail={item} />
        </div>
      )
    }
    return <div style={style} />
  }

  const hasValuesInSelectedFilters = (
    values: ICocktailSelectedFilters,
  ): boolean => {
    for (const [key, value] of Object.entries(values)) {
      if (
        value.length &&
        (key === 'flavor' ||
          key === 'glass' ||
          key === 'complexity' ||
          key === 'strength' ||
          key === 'category' ||
          key === 'allergen')
      ) {
        return true
      }
    }
    return false
  }

  function handleCheckboxChange(
    item: string,
    selectedItems: string[],
    filterName: string,
  ) {
    if (selectedItems.includes(item)) {
      setSelectedFilter(
        selectedItems.filter(selectedItem => selectedItem !== item),
        filterName,
      )
    } else {
      setSelectedFilter([...selectedItems, item], filterName)
    }
  }

  return (
    <div className="myBarCollectionWrapper">
      <CollectionHeader
        isFavorite={isFavorite}
        title={headerTitle}
        count={barCocktails.totalElements}
        setCurrentPage={setCurrentPage}
        searchField={searchField}
        setRequest={setNeedRequest}
        setSearchValue={setSearchValue}
        imageUrl={imageUrl}
      />
      {hasValuesInSelectedFilters(selectedFilters) && (
        <>
          <div className="myBarCollectionFiltersWrapper">
            {matchingObjects.map(
              (item: ICocktailFilterComponent, index: number) => {
                return (
                  index < 9 && (
                    <div
                      className="myBarCollectionFiltersWrapperItem"
                      key={Math.random()}
                      onClick={() =>
                        handleCheckboxChange(
                          item.id.toString(),
                          selectedFilters[item.filterName],
                          item.filterName,
                        )
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          handleCheckboxChange(
                            item.id.toString(),
                            selectedFilters[item.filterName],
                            item.filterName,
                          )
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <p>{item.name}</p>
                      <CloseIcon />
                    </div>
                  )
                )
              },
            )}
          </div>
          <div className="myBarCollectionFiltersCount">
            <p>{barCocktails.totalElements} cocktails found</p>
          </div>
        </>
      )}
      <div
        className={`myBarCollectionInner ${hasValuesInSelectedFilters(selectedFilters) ? 'withFilters' : ''}`}
      >
        <div style={{ width: '100%', height: 'auto' }}>
          <AutoSizer>
            {({ width, height }) => (
              <InfiniteLoader
                isItemLoaded={index => index < memoContent.length - 1}
                loadMoreItems={loadOnScroll}
                itemCount={barCocktails.totalElements}
                threshold={6}
              >
                {({ onItemsRendered, ref }) => (
                  <Grid
                    width={width}
                    height={height}
                    rowHeight={barCocktailsRowsHeight()}
                    rowCount={barCocktailsRowsCount(memoContent.length)}
                    columnCount={barCocktailsColumnsCount()}
                    columnWidth={barCocktailsColumnsWidth(width)}
                    ref={ref}
                    onItemsRendered={gridData => {
                      const {
                        visibleRowStartIndex,
                        visibleRowStopIndex,
                        visibleColumnStopIndex,
                        overscanRowStartIndex,
                        overscanRowStopIndex,
                        overscanColumnStopIndex,
                      } = gridData

                      const overscanStartIndex =
                        overscanRowStartIndex * (overscanColumnStopIndex + 1)
                      const overscanStopIndex =
                        overscanRowStopIndex * (overscanColumnStopIndex + 1)
                      const visibleStartIndex =
                        visibleRowStartIndex * (visibleColumnStopIndex + 1)
                      const visibleStopIndex =
                        visibleRowStopIndex * (visibleColumnStopIndex + 1)

                      onItemsRendered({
                        overscanStartIndex,
                        overscanStopIndex,
                        visibleStartIndex,
                        visibleStopIndex,
                      })
                    }}
                  >
                    {Cell}
                  </Grid>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  )
}
