import { ICocktail, ICocktailFilter } from '../../../@types/cocktails'
import { IIngredient } from '../../../@types/ingredients'
import { IModalData, IModalName } from '../../../@types/modals'

export enum ModalActionEnum {
  SHOW_MODAL = 'SHOW_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
}

export interface ModalState {
  modalName: IModalName
  modalData: IModalData
  modalHidden: boolean
  isCocktailsFilterModal: boolean
  isMixologyFilterModal: boolean
  isMyIngredientFilterModal: boolean

}

export interface SetModalShowAction {
  type: ModalActionEnum.SHOW_MODAL
  payload: {
    modalName: IModalName
    modalData: IModalData
    isCocktailsFilterModal?: boolean
    isMixologyFilterModal?: boolean
    isMyIngredientFilterModal?: boolean
  }
}

export interface SetModalHideAction {
  type: ModalActionEnum.HIDE_MODAL
}

export type ModalAction = SetModalHideAction | SetModalShowAction
