import { IEventShoppingListItem } from '../../../../@types/events'
import { api } from '../../../../api'

import './index.scss'

interface PartySummaryShoppingListProps {
  shoppingList: IEventShoppingListItem[]
  isPreview: boolean
}

export default function EventSummaryShoppingList({
  shoppingList,
  isPreview,
}: PartySummaryShoppingListProps) {
  return (
    <div className="partySummaryShoppingListWrapper">
      {/* <h4>Shopping list</h4> */}
      <div className="partySummaryShoppingListInner">
        {shoppingList.map((item: IEventShoppingListItem, index: number) => {
          if (isPreview) {
            return (
              index <= 2 && (
                <div
                  key={item.ingredient.id}
                  className="partySummaryShoppingListCardWrapper"
                >
                  <div className="partySummaryShoppingListCardInner">
                    <img
                      src={`${api()}/images/ingredients/m/${item.ingredient.image}`}
                      alt={item.ingredient.name}
                    />
                    <div className="partySummaryShoppingListCardInnerText">
                      <p>
                        {item.amount} {item.measurement}
                      </p>
                      <div className="partySummaryShoppingListCardInnerTextLine" />
                      <p>{item.ingredient.name}</p>
                    </div>
                  </div>
                </div>
              )
            )
          }
          return (
            <div
              key={item.ingredient.id}
              className="partySummaryShoppingListCardWrapper"
            >
              <div className="partySummaryShoppingListCardInner">
                <img
                  src={`${api()}/images/ingredients/m/${item.ingredient.image}`}
                  alt={item.ingredient.name}
                />
                <div className="partySummaryShoppingListCardInnerText">
                  <p>
                    {item.amount} {item.measurement}
                  </p>
                  <div className="partySummaryShoppingListCardInnerTextLine" />
                  <p>{item.ingredient.name}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
