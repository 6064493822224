import { ISubscription } from '../../../@types/subscriptions'

export interface SubscriptionsState {
  subscriptions: ISubscription[]
  stripeLink: string
  downgradeLink: string
}

export enum SubscriptionsActionEnum {
  GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS',
  GET_PLANS = 'GET_PLANS',
  POST_PLAN = 'POST_PLAN',
  GET_DOWNGRADE_LINK = 'GET_DOWNGRADE_LINK',
}

export interface GetSubscriptionsAction {
  type: SubscriptionsActionEnum.GET_SUBSCRIPTIONS
  payload: SubscriptionsState
}

export interface GetPlansAction {
  type: SubscriptionsActionEnum.GET_PLANS
  payload: ISubscription[]
}

export interface PostPlanAction {
  type: SubscriptionsActionEnum.POST_PLAN
  payload: string
}

export interface GetDowngradeLinkAction {
  type: SubscriptionsActionEnum.GET_DOWNGRADE_LINK
  payload: string
}

export type SubscriptionsAction =
  | GetSubscriptionsAction
  | GetPlansAction
  | PostPlanAction
  | GetDowngradeLinkAction
