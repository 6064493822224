import {
  ICocktail,
  ICocktailFilter,
  ICocktailsList,
} from '../../../@types/cocktails'
import { IBarCocktailsCollection } from '../../../@types/userBar'

export interface CocktailsState {
  content: ICocktail[]
  totalPages: number
  number: number
  totalElements: number
  cocktail: ICocktail | null
  analogs: ICocktail[]
  modalCocktail: ICocktail | null
  searchField: string
  popularByIngredientCocktail: ICocktailFilter[]
}

export enum CocktailsActionEnum {
  GET_COCKTAILS = 'GET_COCKTAILS',
  GET_COCKTAILS_SEARCH = 'GET_COCKTAILS_SEARCH',
  GET_COCKTAIL = 'GET_COCKTAIL',
  FAVORITES_COCKTAIL = 'FAVORITES_COCKTAIL',
  COCKTAIL_ANALOGS = 'COCKTAIL_ANALOGS',
  GET_COCKTAILS_BY_FILTER_ACTION = 'GET_COCKTAILS_BY_FILTER_ACTION',
  CLEAR_COCKTAIL_LIST = 'CLEAR_COCKTAIL_LIST',
  GET_MODAL_COCKTAIL = 'GET_MODAL_COCKTAIL',
  GET_POPULAR_COCKTAILS_BY_INGREDIENTS = 'GET_POPULAR_COCKTAILS_BY_INGREDIENTS',
  CLEAR_COCKTAIL = 'CLEAR_COCKTAIL',
  CLEAR_MODAL_COCKTAIL = 'CLEAR_MODAL_COCKTAIL',
  CLEAR_ANALOGS_LIST = 'CLEAR_ANALOGS_LIST',
  CLEAR_POPULAR_COCKTAILS_BY_INGREDIENTS = 'CLEAR_POPULAR_COCKTAILS_BY_INGREDIENTS',
}

export interface GetCocktailsAction {
  type: CocktailsActionEnum.GET_COCKTAILS
  payload: ICocktailsList
}

export interface GetCocktailsSearchAction {
  type: CocktailsActionEnum.GET_COCKTAILS_SEARCH
  payload: ICocktailsList
}

export interface GetCocktailsByFilterAction {
  type: CocktailsActionEnum.GET_COCKTAILS_BY_FILTER_ACTION
  payload: ICocktailsList
}

export interface GetCocktailAction {
  type: CocktailsActionEnum.GET_COCKTAIL
  payload: ICocktail
}

export interface GetModalCocktailAction {
  type: CocktailsActionEnum.GET_MODAL_COCKTAIL
  payload: ICocktail
}

export interface GetCocktailAnalogsAction {
  type: CocktailsActionEnum.COCKTAIL_ANALOGS
  payload: ICocktail[]
}

export interface FavoritesCocktailAction {
  type: CocktailsActionEnum.FAVORITES_COCKTAIL
  payload: {
    id: string
    isFavorite: boolean
    collections: IBarCocktailsCollection[]
  }
}

export interface ClearCocktailsListAction {
  type: CocktailsActionEnum.CLEAR_COCKTAIL_LIST
}

export interface ClearModalCocktailAction {
  type: CocktailsActionEnum.CLEAR_MODAL_COCKTAIL
}

export interface ClearCocktailAction {
  type: CocktailsActionEnum.CLEAR_COCKTAIL
}

export interface ClearAnalogsListAction {
  type: CocktailsActionEnum.CLEAR_ANALOGS_LIST
}

export interface GetPopularCocktailsByIngredientsAction {
  type: CocktailsActionEnum.GET_POPULAR_COCKTAILS_BY_INGREDIENTS
  payload: ICocktail[]
}

export interface ClearPopularCocktailsByIngredientsAction {
  type: CocktailsActionEnum.CLEAR_POPULAR_COCKTAILS_BY_INGREDIENTS
}

export type CocktailsAction =
  | GetCocktailsAction
  | GetCocktailsSearchAction
  | GetCocktailAction
  | FavoritesCocktailAction
  | GetCocktailAnalogsAction
  | GetCocktailsByFilterAction
  | ClearCocktailsListAction
  | GetModalCocktailAction
  | ClearModalCocktailAction
  | ClearCocktailAction
  | ClearAnalogsListAction
  | GetPopularCocktailsByIngredientsAction
  | ClearPopularCocktailsByIngredientsAction
