import { IEventCocktail } from '../../../../@types/events'
import { api } from '../../../../api'
import CocktailMediumCard from '../../../../components/CocktailMediumCard'

import './index.scss'

interface PartySummaryCocktailsProps {
  cocktailsList: IEventCocktail[]
  isPreview: boolean
}

export default function EventSummaryCocktails({
  cocktailsList,
  isPreview,
}: PartySummaryCocktailsProps) {
  return (
    <div className="partySummaryCocktailsWrapper">
      {/* <h4>Cocktails menu</h4> */}
      <div className="partySummaryCocktailsInner">
        {cocktailsList.map((item: IEventCocktail, index: number) => {
          if (isPreview) {
            return (
              index <= 2 && (
                <div
                  className="partySummaryCocktailsInnerItem"
                  key={Math.random()}
                >
                  <CocktailMediumCard cocktail={item.cocktail} isEvent />
                </div>
              )
            )
          }
          return (
            <div className="partySummaryCocktailsInnerItem" key={Math.random()}>
              <CocktailMediumCard cocktail={item.cocktail} isEvent />
            </div>
          )
        })}
      </div>
    </div>
  )
}
