import NoUpcomingEventsImage from '../../../assets/upcoming-events-image.png'

import './index.scss'

interface NoUpcomingEventsCardProps {
  navigate: () => void
  title: string
}

function NoUpcomingEventsCard({ navigate, title }: NoUpcomingEventsCardProps) {
  return (
    <div className="noEventsCardWrapper">
      <div className="noEventsCardDescription">
        <h4>{title}</h4>
        <p>Plan your party</p>
        <button type="button" onClick={navigate}>
          CREATE OR JOIN
        </button>
      </div>
      <img src={NoUpcomingEventsImage} alt="no upcoming events" />
    </div>
  )
}

export default NoUpcomingEventsCard
