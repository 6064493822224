/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal, Tooltip } from 'antd'

import { api, staticApi } from '../../api'
import useTypedSelector from '../../hooks/useTypedSelector'
import useActions from '../../hooks/useActions'
import useHorizontalScroll from '../../hooks/useHorizontalScroll'
import RoutesPaths from '../../constants/navigate'
import { EngLocales } from '../../assets/locales/eng'

import { ReactComponent as NotFavorite } from '../../assets/images/svg/not-favorite.svg'
import { ReactComponent as Favorite } from '../../assets/images/svg/favorite.svg'
import { ReactComponent as AlcoholFreeIcon } from '../../assets/images/svg/alcohol-free-icon.svg'
// import { ReactComponent as FavoriteIng } from '../../assets/onBar.svg'
import { ReactComponent as FavoriteIng } from '../../page/CocktailPage/assets/onBar.svg'
import { ReactComponent as NotFavoriteIng } from '../../page/CocktailPage/assets/notOnBar.svg'

import { ICocktailFilter } from '../../@types/cocktails'
import { IIngredient } from '../../@types/ingredients'
import { cocktailsCustomNotification } from '../../helpers/Notifications/notificationsHelpers'

interface CocktailModalProps {
  item: ICocktailFilter
}

function CocktailModal({ item }: CocktailModalProps) {
  const {
    hideModal,
    cocktailFavorites,
    clearModalCocktail,
    showModal,
    // getAllDBarInfo,
  } = useActions()
  const { modalCocktail } = useTypedSelector(state => state.cocktails)
  const { modalHidden } = useTypedSelector(state => state.modal)
  const [localFavorite, setLocalFavorite] = useState<boolean>(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onClickFavorites = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()

      if (!localFavorite) {
        cocktailFavorites(item.id.toString(), true, [])
        cocktailsCustomNotification(() => {
          showModal('FavoritesModal', {
            favorites: {
              id: item.id.toString(),
              cocktailName: item.name || '',
              collections: modalCocktail?.collections || [],
            },
          })
        })
        setLocalFavorite(true)
      } else if (localFavorite) {
        showModal('FavoritesModal', {
          favorites: {
            id: item.id.toString(),
            cocktailName: item.name || '',
            collections: modalCocktail?.collections || [],
          },
        })
      }
    },
    [localFavorite],
  )

  useEffect(() => {
    if (modalCocktail && modalCocktail.isFavorite) {
      setLocalFavorite(true)
    }
  }, [modalCocktail])

  useEffect(() => {
    // getModalCocktail(item.id)

    return () => {
      clearModalCocktail()
      // if (pathname === '/dbar') {
      //   getAllDBarInfo()
      // }
    }
  }, [])

  const scrollRef = useHorizontalScroll()

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.button === 1) {
        e.preventDefault()
        window.open(`/${RoutesPaths.cocktailsPage}/${item.id}`, '_blank')
      }
    },
    [item.id],
  )

  return (
    <Modal
      centered
      open={modalHidden}
      onOk={() => hideModal()}
      onCancel={() => hideModal()}
      width={800}
    >
      <div className="cocktailModalWrapper">
        {modalCocktail && (
          <div id="modalLightbox">
            <div
              className="favorite-modal-icon"
              onClick={onClickFavorites}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onClickFavorites
                }
              }}
              role="button"
              tabIndex={0}
            >
              {localFavorite ? (
                <Favorite className="img-favorites-active" />
              ) : (
                <NotFavorite className="img-favorites" />
              )}
            </div>
            {modalCocktail.strength === 'Alcohol-free' && (
              <div className="modal-alcohol-free-icon">
                <AlcoholFreeIcon />
              </div>
            )}
            <img
              alt={modalCocktail.name || 'Default Alt'}
              src={`${api()}/images/cocktails/m/${modalCocktail.image}`}
            />
          </div>
        )}
        <div className="cocktailModalWrapperDescription">
          {modalCocktail && (
            <>
              <div className="cocktailModalHeader">
                {modalCocktail.origin?.label && (
                  <img
                    src={`${staticApi()}/images/flags/${modalCocktail.origin.id.toString().toLowerCase()}.webp`}
                    alt="cocktail country flag"
                  />
                )}
                <h3>{modalCocktail.name}</h3>
              </div>
              <div className="cocktailModalCategoriesWrapper">
                <div className="cocktailModalCategories">
                  <p>{EngLocales.CocktailsModal.cocktailsModalGlass}</p>
                  <p>{modalCocktail?.glass?.name}</p>
                </div>
                <div className="cocktailModalCategories">
                  <p>{EngLocales.CocktailsModal.cocktailsModalFlavor}</p>
                  <p>{modalCocktail?.flavor?.name}</p>
                </div>
                <div className="cocktailModalCategories">
                  <p>{EngLocales.CocktailsModal.cocktailsModalStrength}</p>
                  <p>{modalCocktail.strength}</p>
                </div>
                <div className="cocktailModalCategories">
                  <p>{EngLocales.CocktailsModal.cocktailsModalComplexity}</p>
                  <p>{modalCocktail.complexity}</p>
                </div>
              </div>
              <div className="cocktailModalTools">
                {modalCocktail?.tools?.map((elem: ICocktailFilter) => (
                  <div className="modalToolImg" key={elem.id}>
                    <Tooltip title={elem.name} placement="bottom">
                      <img
                        alt={elem.name || 'Default Alt'}
                        src={`${staticApi()}/images/tools/${elem.image}`}
                      />
                    </Tooltip>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="cocktailModalIngredients">
            {modalCocktail && (
              <h4>{EngLocales.CocktailsModal.cocktailsModalIngredients}</h4>
            )}
            <div className="cocktailModalIngredientsWrapper" ref={scrollRef}>
              {modalCocktail?.ingredients?.map((elem: IIngredient) => (
                <div className="cocktailModalIngredient" key={Math.random()}>
                  <div className="cocktailModalIngredientText">
                    <p>
                      {elem.amount} {elem.measurement}
                    </p>
                    <p>{elem.name}</p>
                  </div>
                  <div className="cocktailModalIngredientImage">
                    <div className="cocktailModalIngredientImageFav">
                      {elem.isFavorite ? <FavoriteIng /> : <NotFavoriteIng />}
                    </div>
                    <img
                      src={`${api()}/images/ingredients/s/${elem.image}`}
                      alt={elem.name}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {modalCocktail && (
            <button
              type="submit"
              className="moreDetailsButton"
              onClick={() => {
                // navigate(`/${RoutesPaths.cocktailsPage}/${modalCocktail.id}`)
                // hideModal()
                window.open(
                  `/${RoutesPaths.cocktailsPage}/${modalCocktail.id}`,
                  '_blank',
                )
              }}
              onMouseDown={handleMouseDown}
            >
              {EngLocales.CocktailsModal.moreDetailsButton}
            </button>
          )}
          {/* <div className="cocktailRecipe">{cocktail.recipe}</div> */}
        </div>
      </div>
    </Modal>
  )
}

export default CocktailModal
