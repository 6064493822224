/* eslint-disable jsx-a11y/control-has-associated-label */
import NextStepButton from '../../components/NextStepButton'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import useActions from '../../../../hooks/useActions'
import { IEventCocktail } from '../../../../@types/events'
import { api } from '../../../../api'

import { ReactComponent as MinusIcon } from '../../assets/minus-icon.svg'
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg'
import CocktailsTitle from '../../components/CocktailsTitle'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../../constants/screensWidth'

interface CocktailsAmountProps {
  changeStep: (step: number) => void
  nextStep: number
}

function CocktailsAmount({ changeStep, nextStep }: CocktailsAmountProps) {
  const { eventCocktails } = useTypedSelector(state => state.events)
  const { changeEventCocktailAmount, updateUserCreateEvent } = useActions()

  const nextStepHandler = () => {
    const formatCocktails = eventCocktails.map((item: IEventCocktail) => {
      return { amount: item.amount, cocktailId: item.cocktail.id }
    })
    updateUserCreateEvent(formatCocktails, 'cocktails')
    return changeStep(nextStep)
  }

  const changeAmount = (id: number, amount: number) => {
    return changeEventCocktailAmount(id, amount)
  }

  return (
    <div className="cocktailsChangesWrapper">
      {window.innerWidth > MobileDevicesWidth && (
        <CocktailsTitle
          title="Number of servings"
          tooltip="Number of servings"
        />
      )}

      <div className="cocktailsChangesInner">
        {eventCocktails.length &&
          eventCocktails.map((item: IEventCocktail) => {
            return (
              <div
                className="cocktailsChangesInnerItemWrapper"
                key={item.cocktail.id}
              >
                <div className="cocktailsChangesInnerItemInner">
                  <div className="cocktailsChangesInnerItemAmount">
                    <button
                      type="button"
                      onClick={() => {
                        changeAmount(
                          item.cocktail.id,
                          item.amount > 1 ? item.amount - 1 : item.amount,
                        )
                      }}
                    >
                      <MinusIcon />
                    </button>

                    <p>{item.amount}</p>

                    <button
                      type="button"
                      onClick={() => {
                        changeAmount(item.cocktail.id, item.amount + 1)
                      }}
                    >
                      <PlusIcon />
                    </button>
                  </div>
                  <img
                    src={`${api()}/images/cocktails/s/${item.cocktail.image}`}
                    alt={item.cocktail.name}
                  />

                  <div className="cocktailsChangesInnerItemInnerTitle">
                    <p>{item.cocktail.name}</p>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
      <NextStepButton handler={nextStepHandler} isActive />
    </div>
  )
}

export default CocktailsAmount
