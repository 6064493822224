import { useState } from 'react'

import useActions from '../../../hooks/useActions'
import CustomRadioButton from './CustomRadioButton'

import { ReactComponent as FacebookIcon } from '../../../assets/images/svg/share-facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/images/svg/share-twitter-icon.svg'
import { ReactComponent as CopyIcon } from '../../../assets/images/svg/copy-link-icon.svg'
import blurredImage from '../images/blur-icon.png'

import './index.scss'
import { EngLocales } from '../../../assets/locales/eng'
import {
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
} from '../../../constants/screensWidth'
import { IProfileConfigurationPublic } from '../../../@types/userProfile'

interface CheckboxState {
  profilePhoto: boolean
  showIngredients: boolean
  yourTools: boolean
  favoriteCocktails: boolean
  showEmail: boolean
  showPreferences: boolean
  [key: string]: boolean
}

interface PublicProfileBlockProps {
  publicConfiguration: IProfileConfigurationPublic
  profileInfo: {
    username: string
    plan: string
    name: string
  }
}

function PublicProfileBlock({
  publicConfiguration,
  profileInfo,
}: PublicProfileBlockProps) {
  const {
    isAvatarPublic,
    isEmailPublic,
    isFavoriteCocktailsPublic,
    isIngredientsPublic,
    isPreferencesPublic,
    isToolsPublic,
  } = publicConfiguration
  const { plan, name, username } = profileInfo

  const { setUserProfileConfiguration } = useActions()

  function checkboxChange(
    key: keyof IProfileConfigurationPublic,
    value: boolean,
  ) {
    setUserProfileConfiguration({ ...publicConfiguration, [key]: value })
  }

  function publicProfileHeaderBtnText() {
    if (window.innerWidth > LargeTabletDevicesWidth) {
      return EngLocales.UserProfilePage.publicProfileBlock
        .publicProfileBlockHeaderBtnText
    }
    EngLocales.UserProfilePage.publicProfileBlock
      .publicProfileBlockHeaderBtnText
    return 'show'
  }

  return (
    <div className="publicProfileWrapper">
      <div className="publicProfileInner">
        <div className="publicProfileInnerHeader">
          <div className="publicProfileInnerHeaderTitle">
            <h3>
              {
                EngLocales.UserProfilePage.publicProfileBlock
                  .publicProfileBlockHeaderTitle
              }
            </h3>
            {window.innerWidth > MobileDevicesWidth && (
              <button type="button">{publicProfileHeaderBtnText()}</button>
            )}
          </div>
          <p>
            {name ?? username}!{' '}
            {
              EngLocales.UserProfilePage.publicProfileBlock
                .publicProfileBlockHeaderText
            }
          </p>
        </div>
        <div className="publicProfileInnerContent">
          <h4>
            {EngLocales.UserProfilePage.publicProfileBlock.settingsBlockTitle}
          </h4>
          <div
            className={`publicProfileInnerContentRadioButtons ${
              plan === 'BASIC' ? 'blurred' : ''
            }`}
          >
            <CustomRadioButton
              checked={isAvatarPublic}
              label="show profile photo"
              onChange={() => {
                checkboxChange('isAvatarPublic', !isAvatarPublic)
              }}
            />
            <CustomRadioButton
              checked={isFavoriteCocktailsPublic}
              label="show favourite cocktails"
              onChange={() => {
                checkboxChange(
                  'isFavoriteCocktailsPublic',
                  !isFavoriteCocktailsPublic,
                )
              }}
            />
            <CustomRadioButton
              checked={isIngredientsPublic}
              label="show ingredients"
              onChange={() => {
                checkboxChange('isIngredientsPublic', !isIngredientsPublic)
              }}
            />
            <CustomRadioButton
              checked={isEmailPublic}
              label="show email "
              onChange={() => {
                checkboxChange('isEmailPublic', !isEmailPublic)
              }}
            />
            <CustomRadioButton
              checked={isPreferencesPublic}
              label="show preferences"
              onChange={() => {
                checkboxChange('isPreferencesPublic', !isPreferencesPublic)
              }}
            />
            {plan === 'BASIC' && (
              <div className="blurredContent">
                <img src={blurredImage} alt="blur" />
                <p>
                  {
                    EngLocales.UserProfilePage.publicProfileBlock
                      .basicProfileBlurredText
                  }
                </p>
              </div>
            )}
          </div>
          {window.innerWidth <= MobileDevicesWidth && (
            <div className="publicProfileMobileButtonWrapper">
              <button type="button">
                {
                  EngLocales.UserProfilePage.publicProfileBlock
                    .publicProfileBlockHeaderBtnText
                }
              </button>
            </div>
          )}
          <div className="publicProfileInnerContentLinksWrapper">
            <h4>
              {
                EngLocales.UserProfilePage.publicProfileBlock
                  .sharePublicProfileText
              }
            </h4>
            <div className="publicProfileInnerContentLinks">
              <FacebookIcon />
              <TwitterIcon />
              <CopyIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicProfileBlock
