import { IIngredient, IIngredientsList } from '../../../@types/ingredients'

export interface IngredientsState {
  content: IIngredient[]
  totalPages: number
  number: number
  ingredient: IIngredient | null
  totalElements: number
  modalIngredient: IIngredient | null
  searchField: string
}

export enum IngredientsActionEnum {
  GET_INGREDIENTS = 'GET_INGREDIENTS',
  GET_INGREDIENT = 'GET_INGREDIENT',
  GET_INGREDIENTS_BY_FILTER_ACTION = 'GET_INGREDIENTS_BY_FILTER_ACTION',
  GET_INGREDIENTS_SEARCH = 'GET_INGREDIENTS_SEARCH',
  CLEAR_INGREDIENTS_LIST = 'CLEAR_INGREDIENTS_LIST',
  CLEAR_MODAL_INGREDIENT = 'CLEAR_MODAL_INGREDIENT',
  FAVORITES_INGREDIENT = 'FAVORITES_INGREDIENT',
}

export interface GetIngredientsAction {
  type: IngredientsActionEnum.GET_INGREDIENTS
  payload: IIngredientsList
}

export interface GetIngredientsSearchAction {
  type: IngredientsActionEnum.GET_INGREDIENTS_SEARCH
  payload: IIngredientsList
}

export interface GetIngredientsByFilterAction {
  type: IngredientsActionEnum.GET_INGREDIENTS_BY_FILTER_ACTION
  payload: IIngredientsList
}

export interface GetIngredientAction {
  type: IngredientsActionEnum.GET_INGREDIENT
  payload: IIngredient
}

export interface FavoritesIngredientAction {
  type: IngredientsActionEnum.FAVORITES_INGREDIENT
  payload: number
}

export interface ClearIngredientsListAction {
  type: IngredientsActionEnum.CLEAR_INGREDIENTS_LIST
}

export interface ClearModalIngredientAction {
  type: IngredientsActionEnum.CLEAR_MODAL_INGREDIENT
}

export type IngredientsAction =
  | GetIngredientsAction
  | GetIngredientAction
  | GetIngredientsSearchAction
  | ClearIngredientsListAction
  | ClearModalIngredientAction
  | FavoritesIngredientAction
  | GetIngredientsByFilterAction
