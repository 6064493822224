import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

export default function SignupComponent() {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <>
      {(errors.password?.message ||
        errors.email?.message ||
        errors.username?.message) && (
        <p className={'errorField'}>
          {String(
            errors.password?.message ||
              errors.email?.message ||
              errors.username?.message,
          )}
        </p>
      )}
      <div className="login-form-input">
        <input
          autoComplete="off"
          type="email"
          {...register('email')}
          placeholder="Enter email"
          className={errors.email?.message ? 'error-border' : ''}
          required
        />
      </div>
      <div className="login-form-input">
        <input
          autoComplete="off"
          type="text"
          {...register('username')}
          placeholder="Enter Username"
          className={errors.username?.message ? 'error-border' : ''}
          maxLength={20}
          required
        />
      </div>
      <div className="login-form-input">
        <input
          autoComplete="new-password"
          type={isVisible ? 'text' : 'password'}
          {...register('password')}
          placeholder="Password"
          className={errors.password?.message ? 'error-border' : ''}
          required
        />
        <i
          className={
            isVisible
              ? 'fas fa-eye fa-eye-slash fa-flip-horizontal'
              : 'fas fa-eye'
          }
          id="togglePassword"
          aria-hidden="true"
          onClick={() => setIsVisible(!isVisible)}
          style={{
            marginLeft: '-35px',
            cursor: 'pointer',
            color: '#BEC8D6',
            transition: 'all 1s ease-out',
          }}
        />
      </div>
    </>
  )
}
