/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import './index.scss'
import { IBarCocktailsCollection } from '../../../../@types/userBar'
import RoutesPaths from '../../../../constants/navigate'
import { api } from '../../../../api'
import useActions from '../../../../hooks/useActions'

import { ReactComponent as PinIcon } from '../../assets/pin-card-icon.svg'
import OwnCollectionImage from '../../assets/own-collection-image.png'
import OwnCollectionImageClose from '../../assets/own-collection-image-close.png'
import AddCollectionImage from '../../assets/add-collection-card-image.png'

interface CollectionCardProps {
  item: IBarCocktailsCollection
}

interface OwnCollectionCardProps {
  isPremium: boolean
}

interface AddCollectionCardProps {
  cb: () => void
}

export default function CollectionCard({ item }: CollectionCardProps) {
  const navigate = useNavigate()
  const { pinCollection, unpinCollection } = useActions()

  const onClickPin = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      e.preventDefault()

      if (!item.isPinned) {
        pinCollection(item.id)
      } else if (item.isPinned) {
        unpinCollection(item.id)
      }
    },
    [item.id, item.isPinned, pinCollection, unpinCollection],
  )

  return (
    <div
      key={Math.random()}
      className="myBarCollectionCard"
      onClick={() =>
        navigate(`/${RoutesPaths.myBarCocktailsCollections}/${item.id}`)
      }
      onKeyPress={e => {
        if (e.key === 'Enter') {
          navigate(`/${RoutesPaths.myBarCocktailsCollections}/${item.id}`)
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="myBarCollectionCardImage">
        <div
          className={`myBarCollectionCardImagePin ${item.isPinned && 'pinnedCollection'}`}
          onClick={onClickPin}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              onClickPin
            }
          }}
          role="button"
          tabIndex={0}
        >
          <PinIcon />
        </div>
        <img
          src={`${api()}/images/collections/${item.image}`}
          alt={item.name || 'Default Alt'}
        />
      </div>
      <p>{item.name}</p>
    </div>
  )
}

export function OwnCollectionCard({ isPremium }: OwnCollectionCardProps) {
  return (
    <div className="myBarCollectionCard">
      <div className="myBarCollectionCardImage">
        <img
          src={isPremium ? OwnCollectionImage : OwnCollectionImageClose}
          alt="Own cocktails"
        />
      </div>
      <p>Own cocktails</p>
    </div>
  )
}

export function AddCollectionCard({ cb }: AddCollectionCardProps) {
  return (
    <div
      className="myBarCollectionCard"
      onClick={() => cb()}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          cb()
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="myBarCollectionCardImage">
        <img src={AddCollectionImage} alt="Add collection" />
      </div>
    </div>
  )
}
