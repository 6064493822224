/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import { useEffect, useMemo, useState } from 'react'
import { FixedSizeGrid as Grid, FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { AutoSizer } from 'react-virtualized'

import ContentWrapper from '../../components/ContentWrapper'
import useActions from '../../hooks/useActions'
import useTypedSelector from '../../hooks/useTypedSelector'
import IngredientsCard from './IngredientsCard'

import { IngredientsSort } from '../../constants/sort'
import {
  ingredientsColumnsCount,
  ingredientsColumnsWidth,
  ingredientsItemIndex,
  ingredientsRowsCount,
  ingredientsRowsHeight,
} from '../../helpers/ingredientsPageHelpers'
import {
  ICocktailFilter,
  ICocktailSelectedFilters,
} from '../../@types/cocktails'
import { PageTitles } from '../../constants/pageTitles'

interface FiltersInterface {
  categories: ICocktailFilter[]
  allergens: ICocktailFilter[]
  selectedFilters: ICocktailSelectedFilters
}

interface CellProps {
  columnIndex: number
  rowIndex: number
  style: object
}

function IngredientsPage() {
  const { getIngredients, clearIngredientsList } = useActions()
  // const context = useContext(ScrollContext)

  const { content, totalPages, totalElements } = useTypedSelector(
    state => state.ingredients,
  )
  const { categories, selectedFilters, allergens } =
    useTypedSelector<FiltersInterface>(state => state.filter)

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [searchField, setSearchValue] = useState<string>('')
  const [needRequest, setNeedRequest] = useState<boolean>(false)
  const [sortItem, setSortItem] = useState<string>('')

  useEffect(() => {
    document.title = PageTitles.ingredientsPage

    return () => {
      // clearSelectedFilters()
      clearIngredientsList()
      setCurrentPage(0)
    }
  }, [])

  // useEffect(() => {
  //   if (needRequest) {
  //     setNeedRequest(false)
  //     getIngredients({
  //       page: currentPage,
  //       size: '54',
  //       selectedFilters,
  //       searchFields: searchField,
  //       sortValue: sortItem,
  //     })
  //   } else {
  //     setCurrentPage(0)
  //     clearIngredientsList()
  //   }
  // }, [selectedFilters, searchField, needRequest])

  // useEffect(() => {
  //   if (!needRequest) {
  //     getIngredients({
  //       page: currentPage,
  //       size: '54',
  //       selectedFilters,
  //       searchFields: searchField,
  //       sortValue: sortItem,
  //     })
  //   }
  // }, [currentPage, needRequest])

  const fetchIngredients = () => {
    getIngredients({
      page: currentPage,
      size: '54',
      selectedFilters,
      searchFields: searchField,
      sortValue: sortItem,
    })
  }

  useEffect(() => {
    setCurrentPage(0)
    clearIngredientsList()
  }, [selectedFilters, searchField, sortItem])

  useEffect(() => {
    fetchIngredients()
  }, [currentPage, selectedFilters, searchField, sortItem])

  const memoContent = useMemo(() => content, [content.length])

  const loadOnScroll = () => {
    if (currentPage !== totalPages - 1) {
      return setCurrentPage(prev => prev + 1)
    }

    return setCurrentPage(prev => prev)
  }

  const Cell = ({ columnIndex, rowIndex, style }: CellProps) => {
    const index = ingredientsItemIndex(rowIndex, columnIndex)
    const item = content[index]
    if (item) {
      return (
        <div style={style}>
          <IngredientsCard key={index} item={item} />
        </div>
      )
    }
    return <div style={style} />
  }

  return (
    <ContentWrapper
      setCurrentPage={setCurrentPage}
      searchField={searchField}
      setSearchValue={setSearchValue}
      setRequest={setNeedRequest}
      sortByList={IngredientsSort}
      sortByListFunc={(name: string) => setSortItem(name)}
      isIngredientsList
    >
      <div className="childrenWrapper">
        <div style={{ width: '100%', height: 'calc(84vh-30px)' }}>
          <AutoSizer>
            {({ width, height }) => (
              <InfiniteLoader
                isItemLoaded={index => index < memoContent.length - 1}
                loadMoreItems={loadOnScroll}
                itemCount={totalElements}
                threshold={12}
              >
                {({ onItemsRendered, ref }) => (
                  <Grid
                    width={width}
                    height={height}
                    rowHeight={ingredientsRowsHeight()}
                    rowCount={ingredientsRowsCount(memoContent.length)}
                    columnCount={ingredientsColumnsCount()}
                    columnWidth={ingredientsColumnsWidth(width)}
                    ref={ref}
                    onItemsRendered={gridData => {
                      const {
                        visibleRowStartIndex,
                        visibleRowStopIndex,
                        visibleColumnStopIndex,
                        overscanRowStartIndex,
                        overscanRowStopIndex,
                        overscanColumnStopIndex,
                      } = gridData

                      const overscanStartIndex =
                        overscanRowStartIndex * (overscanColumnStopIndex + 1)
                      const overscanStopIndex =
                        overscanRowStopIndex * (overscanColumnStopIndex + 1)
                      const visibleStartIndex =
                        visibleRowStartIndex * (visibleColumnStopIndex + 1)
                      const visibleStopIndex =
                        visibleRowStopIndex * (visibleColumnStopIndex + 1)

                      onItemsRendered({
                        overscanStartIndex,
                        overscanStopIndex,
                        visibleStartIndex,
                        visibleStopIndex,
                      })
                    }}
                  >
                    {Cell}
                  </Grid>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
    </ContentWrapper>
  )
}

export default IngredientsPage
