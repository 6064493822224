/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'

import useTypedSelector from '../../../hooks/useTypedSelector'
import useActions from '../../../hooks/useActions'
import { UserState } from '../../../store/reducers/user/types'

import ProfileSettingsBlock from './ProfileSettingsBlock'
import SecuritySettingsBlock from './SecuritySettingsBlock'
import NotificationSettingsBlock from './NotificationSettingsBlock'

import { ReactComponent as ProfileIcon } from '../images/profile-icon.svg'
import { ReactComponent as SecurityIcon } from '../images/security-icon.svg'
import { ReactComponent as NotificationIcon } from '../images/notification-icon.svg'
import { ReactComponent as ArrowButtonIcon } from '../images/arrow-button-icon.svg'
import defaultBlockImage from '../images/default-block-image.png'

import './index.scss'

interface BlockState {
  profile: boolean
  security: boolean
  notification: boolean
  default: boolean
  [key: string]: boolean
}

type UpdateProfileInfoType = {
  email: string
  name: string
  username: string
  image: File
}

function SettingsBlock() {
  const [newImage, setNewImage] = useState('')

  const { userProfile } = useTypedSelector<UserState>(state => state.user)
  const { setUserProfileInfo, setUserImage } = useActions()
  const [whatSettingIsOpen, setWhatSettingIsOpen] = useState<BlockState>({
    profile: false,
    security: false,
    notification: false,
    default: true,
  })

  function setSettingBlockActive(key: keyof BlockState) {
    setWhatSettingIsOpen(prevTabs => {
      const newTabs: BlockState = { ...prevTabs }

      Object.keys(newTabs).forEach(tabKey => {
        if (tabKey === key) {
          newTabs[tabKey] = true
        } else {
          newTabs[tabKey] = false
        }
      })

      return newTabs
    })
  }

  function updateProfileInfo(newData: UpdateProfileInfoType) {
    const { email, name, username, image } = newData
    if (image.size) {
      name
        ? setUserImage(image, { ...userProfile, name })
        : setUserImage(image, userProfile)
    } else {
      setUserProfileInfo({
        ...userProfile,
        name,
      })
    }
  }

  const memoizedUpdateProfileInfo = useCallback(updateProfileInfo, [])

  return (
    <div className="settingsBlockWrapper">
      <div className="settingsBlockChoice">
        <h3>Settings</h3>
        <div className="settingsBlockItems">
          <h4>Change user data</h4>
          <div
            className={
              whatSettingIsOpen.profile
                ? 'settingsBlockItem active'
                : 'settingsBlockItem'
            }
            onClick={() => setSettingBlockActive('profile')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setSettingBlockActive('profile')
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="settingsBlockItemLeftSide">
              <ProfileIcon />
              <div className="settingsBlockItemDescription">
                <h5>Profile</h5>
                <p>Picture, Name, Username</p>
              </div>
            </div>
            <ArrowButtonIcon />
          </div>
          <div
            className={
              whatSettingIsOpen.security
                ? 'settingsBlockItem active'
                : 'settingsBlockItem'
            }
            onClick={() => setSettingBlockActive('security')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setSettingBlockActive('security')
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="settingsBlockItemLeftSide">
              <SecurityIcon />
              <div className="settingsBlockItemDescription">
                <h5>Security</h5>
                <p>Password, Data</p>
              </div>
            </div>
            <ArrowButtonIcon />
          </div>
          <div
            className={
              whatSettingIsOpen.notification
                ? 'settingsBlockItem active'
                : 'settingsBlockItem'
            }
            onClick={() => setSettingBlockActive('notification')}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setSettingBlockActive('notification')
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="settingsBlockItemLeftSide">
              <NotificationIcon />
              <div className="settingsBlockItemDescription">
                <h5>Notifications</h5>
                <p>Daily, Weekly, Monthly notifications</p>
              </div>
            </div>
            <ArrowButtonIcon />
          </div>
        </div>
      </div>
      <div className="settingsBlockContent">
        {whatSettingIsOpen.default && (
          <img src={defaultBlockImage} alt="default" />
        )}
        {whatSettingIsOpen.profile && (
          <ProfileSettingsBlock
            profileInfoUsername={
              userProfile?.username ? userProfile.username : ''
            }
            profileInfoEmail={userProfile?.email ? userProfile.email : ''}
            profileInfoName={userProfile?.name ? userProfile.name : ''}
            profileInfoImage={userProfile?.image ? userProfile.image : ''}
            updateProfileInfo={memoizedUpdateProfileInfo}
          />
        )}
        {whatSettingIsOpen.security && <SecuritySettingsBlock />}
        {whatSettingIsOpen.notification && (
          <NotificationSettingsBlock
            notificationSettings={
              userProfile?.configuration.notificationConfiguration
            }
          />
        )}
      </div>
    </div>
  )
}

export default SettingsBlock
