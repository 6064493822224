import AuthActionCreators from './auth/action-creators'
import CocktailsActionCreators from './cocktails/action-creators'
import IngredientsActionCreators from './ingredients/action-creators'
import ModalActionCreators from './modals/action-creators'
import FiltersActionCreators from './filters/action-creators'
import UserActionCreators from './user/action-creators'
import BarActionCreators from './bar/action-creators'
import SubscriptionsActionCreators from './subscriptions/action-creators'
import EventsCreators from './events/action-creators'

const allActionCreators = {
  ...AuthActionCreators,
  ...CocktailsActionCreators,
  ...IngredientsActionCreators,
  ...ModalActionCreators,
  ...FiltersActionCreators,
  ...UserActionCreators,
  ...BarActionCreators,
  ...SubscriptionsActionCreators,
  ...EventsCreators,
}

export default allActionCreators
