import { notification } from 'antd'

import { ReactComponent as NotificationFavoriteIcon } from '../../assets/images/svg/notification-favorite-icon.svg'

import './index.scss'

export function cocktailsCustomNotification(openModal: () => void) {
  return notification.open({
    message: '',
    description: (
      <div className="favoritesNotificationWrapperInner">
        <NotificationFavoriteIcon />
        <p>Added to the “Favorites” collection</p>
        <button
          type="button"
          onClick={() => {
            openModal()
          }}
        >
          CHANGE
        </button>
      </div>
    ),
    className: 'favoritesNotificationWrapper',
    // onClose: () => {
    // },
    closeIcon: false,
    placement: 'bottom',
    duration: 4,
  })
}
