/* eslint-disable function-paren-newline */
/* eslint-disable no-case-declarations */
import { IEventCocktail } from '../../../@types/events'
import { EventsState, EventsAction, EventsEnum } from './types'

const initialState: EventsState = {
  occasions: [],
  eventTemplates: [],
  event: null,
  cocktailsPreferences: {
    occasionId: -1,
    flavors: [],
    allergens: [],
    categories: [],
    complexity: 'EASY',
    isNonAlcoholicEvent: false,
  },
  userCreateEvent: {
    name: '',
    guestsCount: 1,
    notes: '',
    occasionId: -1,
    cocktails: [],
    eventType: '',
    image: '',
    plannedAt: '',
    location: '',
    isTemplate: false,
  },
  eventCocktails: [],
  eventsSummary: {
    past: [],
    planned: [],
  },
  eventId: '',
}

const eventsReducer = (state = initialState, action: EventsAction) => {
  switch (action.type) {
    case EventsEnum.GET_OCCASIONS:
      return {
        ...state,
        occasions: action.payload,
      }
    case EventsEnum.UPDATE_EVENT_COCKTAILS_PREFERENCES:
      return {
        ...state,
        cocktailsPreferences: {
          ...state.cocktailsPreferences,
          [action.payload.key]: action.payload.value,
        },
      }
    case EventsEnum.COPY_FROM_TEMPLATE:
      return {
        ...state,
        userCreateEvent: action.payload,
      }
    case EventsEnum.UPDATE_USER_CREATE_EVENT:
      return {
        ...state,
        userCreateEvent: {
          ...state.userCreateEvent,
          [action.payload.key]: action.payload.value,
        },
      }
    case EventsEnum.GET_EVENT:
      return {
        ...state,
        event: action.payload,
      }
    case EventsEnum.GET_EVENT_COCKTAILS:
      return {
        ...state,
        eventCocktails: action.payload,
      }
    case EventsEnum.ADD_EVENT_COCKTAILS:
      return {
        ...state,
        eventCocktails: [...state.eventCocktails, ...action.payload],
      }
    case EventsEnum.DELETE_EVENT_COCKTAIL:
      return {
        ...state,
        eventCocktails: state.eventCocktails?.filter(
          (item: IEventCocktail) => item.cocktail.id !== action.payload,
        ),
      }
    case EventsEnum.CHANGE_COCKTAIL_AMOUNT:
      return {
        ...state,
        eventCocktails: state.eventCocktails.map((item: IEventCocktail) =>
          item.cocktail.id === action.payload.id
            ? { ...item, amount: action.payload.amount }
            : item,
        ),
      }
    case EventsEnum.POST_USER_EVENT:
      return {
        ...state,
        eventId: action.payload,
      }
    case EventsEnum.GET_EVENTS_SUMMARY:
      return {
        ...state,
        eventsSummary: action.payload,
      }
    case EventsEnum.GET_TEMPLATES:
      return {
        ...state,
        eventTemplates: action.payload,
      }
    case EventsEnum.CLEAR_CREATE_EVENT_INFO:
      return {
        ...state,
        userCreateEvent: action.payload.userCreateEvent,
        cocktailsPreferences: action.payload.cocktailsPreferences,
        eventCocktails: action.payload.eventCocktails,
      }
    case EventsEnum.CLEAR_EVENT_ID:
      return {
        ...state,
        eventId: action.payload,
      }
    default:
      return state
  }
}

export default eventsReducer
