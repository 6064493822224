/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { useState } from 'react'
import { debounce } from 'lodash'
import { ReactComponent as FiltersIcon } from '../ContentWrapper/filters-icon.svg'
import useActions from '../../hooks/useActions'

import './index.scss'
import { SmallTabletDevicesWidth } from '../../constants/screensWidth'
import SortByBtn from '../ContentWrapper/SortByBtn'
import { IListSortObject } from '../../constants/sort'

interface SearchFiltersProps {
  searchField: string
  setCurrentPage: (page: number) => void
  setRequest: (value: boolean) => void
  setSearchValue: (value: string) => void
  sortByList?: IListSortObject
  sortByListFunc?: (name: string) => void
  isIngredientsList: boolean
  isCocktailsFilters: boolean
  isMixologyFilters: boolean
  isMyIngredientFilters: boolean
}

export function SearchFilters({
  searchField,
  setCurrentPage,
  setRequest,
  setSearchValue,
  sortByList,
  sortByListFunc,
  isIngredientsList,
  isCocktailsFilters,
  isMixologyFilters,
  isMyIngredientFilters,
}: SearchFiltersProps) {
  const { showModal } = useActions()
  const [isPlaceholderHidden, setIsPlaceholderHidden] = useState<boolean>(false)

  const handleFocus = () => {
    setIsPlaceholderHidden(true)
  }

  const handleBlur = () => {
    if (searchField === '') {
      setIsPlaceholderHidden(false)
    }
  }

  const debouncedOnChange = debounce((value: string) => {
    setSearchValue(value)
    setCurrentPage(0)
    setRequest(true)
  }, 200)

  const classNames = ['collectionHeaderInnerFiltersInputWrapper']

  if (isPlaceholderHidden) {
    classNames.push('hidePlaceholder')
  }

  const classString = classNames.join(' ')
  return (
    <div className="collectionHeaderInnerFilters">
      <div className={classString}>
        <input
          className="collectionHeaderInnerFiltersInputInner"
          type="text"
          placeholder={
            isPlaceholderHidden
              ? ''
              : isIngredientsList
                ? 'Search ingredients'
                : 'Search cocktails'
          }
          onChange={event => debouncedOnChange(event.target.value.trim())}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      <button
        type="button"
        onClick={() =>
          showModal(
            'FiltersModal',
            {},
            isCocktailsFilters,
            isMixologyFilters,
            isMyIngredientFilters,
          )
        }
      >
        <FiltersIcon />
        {window.innerWidth > SmallTabletDevicesWidth && <p>filters</p>}
      </button>

      {sortByListFunc !== undefined && sortByList !== undefined && (
        <SortByBtn
          list={sortByList}
          func={sortByListFunc}
          needRequest={() => setRequest(true)}
        />
      )}
    </div>
  )
}
