import { IProfile } from '../../../@types/userProfile'

export interface UserState {
  userProfile: IProfile
}

export enum UserActionEnum {
  GET_USER = 'GET_USER',
  GET_USER_PROFILE = 'GET_USER_PROFILE',
  SET_USER_PROFILE_VALUE = 'SET_USER_PROFILE_VALUE',
  CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE',
}

export interface GetUserAction {
  type: UserActionEnum.GET_USER
  payload: IProfile
}

export interface GetUserProfileAction {
  type: UserActionEnum.GET_USER_PROFILE
  payload: IProfile
}

export interface SetUserProfileValueAction {
  type: UserActionEnum
  payload: IProfile
}

export interface ClearUserprofileAction {
  type: UserActionEnum.CLEAR_USER_PROFILE
}

export type UserAction =
  | GetUserAction
  | GetUserProfileAction
  | SetUserProfileValueAction
  | ClearUserprofileAction
