import {
  LaptopDevicesWidth,
  LargeTabletDevicesWidth,
  MobileDevicesWidth,
  SmallTabletDevicesWidth,
} from '../constants/screensWidth'

export function ingredientsColumnsWidth(width: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return width / 3
  }
  if (window.innerWidth <= MobileDevicesWidth + 100) {
    return width / 4
  }
  if (window.innerWidth <= MobileDevicesWidth + 200) {
    return width / 5
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return width / 6
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return  width / 5
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return width / 7
  }
  return width / 8
}

export function ingredientsColumnsCount() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 3
  }
  if (window.innerWidth <= MobileDevicesWidth + 100) {
    return 4
  }
  if (window.innerWidth <= MobileDevicesWidth + 200) {
    return 5
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return 6
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 5
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 7
  }
  return 8
}

export function ingredientsRowsHeight() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 170
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return 172
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 245
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 227
  }
  return 226
}

export function ingredientsRowsCount(length: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return Math.ceil(length / 3)
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return Math.ceil(length / 6)
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return Math.ceil(length / 5)
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return Math.ceil(length / 7)
  }
  return Math.ceil(length / 8)
}

export function ingredientsItemIndex(
  row: number,
  column: number,
) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return row * 3 + column
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return row * 6 + column
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return row * 5 + column
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return row * 7 + column
  }
  return row * 8 + column
}
