/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { FixedSizeGrid as Grid } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { AutoSizer } from 'react-virtualized'

import './index.scss'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import { IEventCocktail } from '../../../../@types/events'
import { ICocktail } from '../../../../@types/cocktails'
import useActions from '../../../../hooks/useActions'
import { FiltersState } from '../../../../store/reducers/filters/types'
import {
  eventCocktailsColumnsCount,
  eventCocktailsColumnsWidth,
  eventCocktailsItemIndex,
  eventCocktailsRowsCount,
} from '../../../../helpers/cocktailsPageHelpers'
import CocktailsCard from '../../../CocktailsPage/CocktailsCard'
import { CocktailsSort } from '../../../../constants/sort'
import { SearchFilters } from '../../../../components/SearchFilters'

interface CocktailsAddOtherProps {
  nextStep: number
  prevStep: number
  changeStep: (step: number) => void
}

const SIZE = '30'

interface CellProps {
  columnIndex: number
  rowIndex: number
  style: object
}

function CocktailsAddOther({
  changeStep,
  nextStep,
  prevStep,
}: CocktailsAddOtherProps) {
  const [selectedCocktails, setSelectedCocktails] = useState<ICocktail[]>([])
  const { eventCocktails } = useTypedSelector(state => state.events)
  const { addEventCocktails, getCocktails, clearCocktailsList } = useActions()
  const { content, totalPages, totalElements } = useTypedSelector(
    state => state.cocktails,
  )
  const { selectedFilters } = useTypedSelector<FiltersState>(
    state => state.filter,
  )
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [needRequest, setNeedRequest] = useState<boolean>(false)
  const [searchField, setSearchValue] = useState<string>('')
  const [sortItem, setSortItem] = useState<string>('')

  const toggleCocktailSelection = (cocktail: ICocktail) => {
    setSelectedCocktails((prev: ICocktail[]) => {
      if (prev.some(selected => selected.id === cocktail.id)) {
        return prev.filter(selected => selected.id !== cocktail.id)
      }
      return [...prev, cocktail]
    })
  }

  const applyCocktails = () => {
    const arr = selectedCocktails.map((item: ICocktail) => {
      return {
        amount: 1,
        cocktail: item,
      }
    })

    addEventCocktails(arr)
    return changeStep(nextStep)
  }

  useEffect(() => {
    return () => {
      // clearSelectedFilters()
      clearCocktailsList()
      setCurrentPage(0)
    }
  }, [])

  useEffect(() => {
    if (needRequest) {
      setCurrentPage(0)
      clearCocktailsList()
      getCocktails({
        page: currentPage,
        size: SIZE,
        selectedFilters,
        searchField,
        sortValue: sortItem,
        excludeCocktails: eventCocktails.map(
          (item: IEventCocktail) => item.cocktail.id,
        ),
      })
      setNeedRequest(false)
    }
  }, [needRequest])

  useEffect(() => {
    getCocktails({
      page: currentPage,
      size: SIZE,
      selectedFilters,
      searchField,
      sortValue: sortItem,
      excludeCocktails: eventCocktails.map(
        (item: IEventCocktail) => item.cocktail.id,
      ),
    })
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(0)
    clearCocktailsList()
    getCocktails({
      page: 0,
      size: SIZE,
      selectedFilters,
      searchField,
      sortValue: sortItem,
      excludeCocktails: eventCocktails.map(
        (item: IEventCocktail) => item.cocktail.id,
      ),
    })
  }, [selectedFilters])

  const memoContent = useMemo(() => content, [content.length])

  const loadOnScroll = () => {
    if (currentPage !== totalPages - 1) {
      return setCurrentPage(prev => prev + 1)
    }

    return setCurrentPage(prev => prev)
  }

  const Cell = ({ columnIndex, rowIndex, style }: CellProps) => {
    const index = eventCocktailsItemIndex(rowIndex, columnIndex)
    const item = content[index]
    if (item) {
      return (
        <div style={style}>
          <CocktailsCard
            key={index}
            isShowFilters
            item={item}
            isEvents
            selectCocktail={toggleCocktailSelection}
            excludeCocktails={selectedCocktails}
          />
        </div>
      )
    }
    return <div style={style} />
  }

  return (
    <div className="cocktailsAddOtherWrapper">
      <div className="cocktailsAddOtherCocktailsWrapper">
        <SearchFilters
          searchField={searchField}
          setCurrentPage={setCurrentPage}
          setRequest={setNeedRequest}
          setSearchValue={setSearchValue}
          isCocktailsFilters
          isMixologyFilters={false}
          isMyIngredientFilters={false}
          isIngredientsList={false}
          sortByList={CocktailsSort}
          sortByListFunc={(name: string) => setSortItem(name)}
        />
        <div className="cocktailsAddOtherCocktailsInner">
          <div className="childrenWrapper">
            <div style={{ width: '100%', height: 'auto' }}>
              <AutoSizer>
                {({ width, height }) => (
                  <InfiniteLoader
                    isItemLoaded={index => index < memoContent.length - 1}
                    loadMoreItems={loadOnScroll}
                    itemCount={totalElements}
                    threshold={6}
                  >
                    {({ onItemsRendered, ref }) => (
                      <Grid
                        width={width}
                        height={height}
                        rowHeight={260}
                        rowCount={eventCocktailsRowsCount(memoContent.length)}
                        columnCount={eventCocktailsColumnsCount()}
                        columnWidth={eventCocktailsColumnsWidth(width)}
                        ref={ref}
                        onItemsRendered={gridData => {
                          const {
                            visibleRowStartIndex,
                            visibleRowStopIndex,
                            visibleColumnStopIndex,
                            overscanRowStartIndex,
                            overscanRowStopIndex,
                            overscanColumnStopIndex,
                          } = gridData

                          const overscanStartIndex =
                            overscanRowStartIndex *
                            (overscanColumnStopIndex + 1)
                          const overscanStopIndex =
                            overscanRowStopIndex * (overscanColumnStopIndex + 1)
                          const visibleStartIndex =
                            visibleRowStartIndex * (visibleColumnStopIndex + 1)
                          const visibleStopIndex =
                            visibleRowStopIndex * (visibleColumnStopIndex + 1)

                          onItemsRendered({
                            overscanStartIndex,
                            overscanStopIndex,
                            visibleStartIndex,
                            visibleStopIndex,
                          })
                        }}
                      >
                        {Cell}
                      </Grid>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            </div>
          </div>
        </div>
      </div>
      <div className="cocktailsAddOtherBtnsWrapper">
        <button type="button" onClick={() => changeStep(prevStep)}>
          cancel
        </button>
        <button type="button" onClick={applyCocktails}>
          apply {!!selectedCocktails.length && `(${selectedCocktails.length})`}
        </button>
      </div>
    </div>
  )
}

export default CocktailsAddOther
