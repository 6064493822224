import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './index.scss'

import UnsuccessSubImage from '../images/unsuccess-sub-image.png'

import { EngLocales } from '../../../assets/locales/eng'
import { PageTitles } from '../../../constants/pageTitles'

function FailureSub() {
  useEffect(() => {
    document.title = PageTitles.plansPage
  }, [])

  return (
    <div className="unsuccessSubWrapper">
      <div className="unsuccessSubInner">
        <img src={UnsuccessSubImage} alt="failure subscription" />
        <div className="unsuccessSubInnerContent">
          <p>{EngLocales.PlansPage.FailureSubPage.failureText}</p>
          <Link to="/">{EngLocales.PlansPage.FailureSubPage.btnText}</Link>
        </div>
      </div>
    </div>
  )
}

export default FailureSub
