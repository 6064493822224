import axios, { AxiosResponse } from 'axios'
import axiosInstance, { api } from '..'
import { getParams } from '../../store/reducers/auth/authUtils'
import { IIngredient, IIngredientsList } from '../../@types/ingredients'
import { ICocktailSelectedFilters } from '../../@types/cocktails'

interface GetIngredientsRequestParams {
  page: number
  size: string
  selectedFilters: ICocktailSelectedFilters
  searchFields?: string
  sortValue: string
}

class IngredientsService {
  static async getIngredients({
    page,
    size,
    selectedFilters,
    searchFields,
    sortValue,
  }: GetIngredientsRequestParams): Promise<AxiosResponse<IIngredientsList>> {
    const url = '/ingredients'
    const searchParams = new URLSearchParams()

    Object.keys(selectedFilters).forEach(key => {
      const values = selectedFilters[key]
      if (values.length > 0 && key === 'ingAllergen') {
        searchParams.set('allergen', values.join(','))
      }
      if (values.length > 0 && key === 'ingCategory') {
        searchParams.set('category', values.join(','))
      }
    })

    if (sortValue) {
      searchParams.set('sort', sortValue)
    }

    if (searchFields) {
      searchParams.set('name', searchFields)
    }

    searchParams.set('page', page.toString())
    searchParams.set('size', size)

    const fullUrl = `${url}?${searchParams.toString()}`

    return axiosInstance.get(fullUrl, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async getIngredient(id: string): Promise<AxiosResponse<IIngredient>> {
    return axiosInstance.get(`/ingredients/${id}`, {
      headers: {
        Authorization: getParams('access_token')
          ? `Bearer ${getParams('access_token')}`
          : '',
      },
    })
  }

  static async ingredientFavorites(id: number): Promise<AxiosResponse> {
    return axiosInstance.post(
      `/users/bar/ingredients/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getParams('access_token')}`,
        },
      },
    )
  }

  static async deleteIngredientFavorites(id: number): Promise<AxiosResponse> {
    return axiosInstance.delete(`/users/bar/ingredients/${id}`, {
      headers: {
        Authorization: `Bearer ${getParams('access_token')}`,
      },
    })
  }
}

export default IngredientsService
