import { FC } from 'react'
import './index.scss'

interface RecipeBlockProps {
  steps: string[]
}

function RecipeBlock({ steps }: RecipeBlockProps) {
  return (
    <div className="recipeBlockWrapper">
      {steps.map((step: string, index) => {
        return (
          <div className="recipeBlockStep">
            <div className="recipeBlockStepNumber">
              <span>{index + 1}</span>
            </div>
            <p>{step}</p>
          </div>
        )
      })}
    </div>
  )
}

export default RecipeBlock
