/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BarWrapper from '../../BarWrapper'
import CollectionWrapper from '../CollectionWrapper'
import useTypedSelector from '../../../../hooks/useTypedSelector'
import { IBarCocktailsCollection } from '../../../../@types/userBar'
import { api } from '../../../../api'
import useActions from '../../../../hooks/useActions'
import { BarState } from '../../../../store/reducers/bar/types'

function CocktailsCollection() {
  const { id } = useParams()
  const [filterCollection, setFilterCollection] =
    useState<IBarCocktailsCollection>({
      id: '',
      name: '',
      image: '',
      isPinned: false,
    })

  const { setSelectedCollection } = useActions()
  const { cocktailsCollections } = useTypedSelector<BarState>(
    state => state.bar,
  )

  useEffect(() => {
    if (cocktailsCollections[id || '']) {
      setFilterCollection(prev => cocktailsCollections[id || ''] || prev)
    }
  }, [cocktailsCollections])

  useEffect(() => {
    setSelectedCollection(filterCollection)

    return () => {
      setSelectedCollection({
        id: '',
        name: '',
        image: '',
        isPinned: false,
      })
    }
  }, [filterCollection])

  return (
    <BarWrapper openTab="cocktails" secondClass="mediumCockInnerHeight">
      <CollectionWrapper
        isFavorite={false}
        headerTitle={filterCollection.name}
        // requestUrl="users/collections/favorites"
        requestUrl={`users/collections/${id}/cocktails`}
        imageUrl={`${api()}/images/collections/${filterCollection.image}`}
      />
    </BarWrapper>
  )
}

export default CocktailsCollection
