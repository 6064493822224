import { Tooltip } from 'antd'

import { ReactComponent as TooltipIcon } from '../../assets/circle-information-icon.svg'

import './index.scss'

interface CocktailsTitleProps {
  title: string
  tooltip: string
}

export default function CocktailsTitle({
  title,
  tooltip,
}: CocktailsTitleProps) {
  return (
    <div className="cocktailsTitleWrapper">
      <h5>{title}</h5>
      <div className="cocktailsTitleTooltip">
        <Tooltip title={tooltip} placement="bottom">
          <TooltipIcon />
        </Tooltip>
      </div>
    </div>
  )
}
