import { FC } from 'react'
import './index.scss'

interface PlanCardMobileProps {
  image: string
  title: JSX.Element
  list: string[]
  btnText: string
  type: 'basic' | 'premium'
  btnBg: string
  btnAction: () => void
}

function PlanCardMobile({
  image,
  title,
  list,
  btnText,
  type,
  btnBg,
  btnAction,
}: PlanCardMobileProps) {
  return (
    <div className="planCardMobileWrapper">
      <img src={image} alt={image} />
      <div className="planCardMobileInner">
        <div className="planCardMobileInnerTitle">{title}</div>
        <div className="planCardMobileInnerLine" />
        <ul className="planCardMobileInnerList">
          {list.map((item: string) => {
            return (
              <li key={Math.random()} className="planCardMobileInnerListItem">
                {item}
              </li>
            )
          })}
        </ul>
        {type === 'basic' && (
          <button
            className="planCardMobileInnerButton"
            disabled
            style={{ opacity: 0 }}
            type="button"
            aria-label="hidden btn"
          />
        )}
        <button
          className="planCardMobileInnerButton"
          onClick={btnAction}
          style={{ background: btnBg }}
          type="button"
        >
          {btnText}
        </button>
      </div>
    </div>
  )
}

export default PlanCardMobile
