import { ICocktail } from '../../../@types/cocktails'
import { IIngredient } from '../../../@types/ingredients'
import { BarAction, BarActionEnum, BarState } from './types'

const initialState: BarState = {
  barFavoriteIngredients: {
    content: [],
    totalPages: 1,
    number: 0,
    ingredient: null,
    modalIngredient: null,
    totalElements: 0,
    searchField: '',
  },
  barMixologyList: {
    content: [],
    totalPages: 1,
    number: 0,
    cocktail: null,
    analogs: [],
    popularByIngredientCocktail: [],
    modalCocktail: null,
    totalElements: 0,
    searchField: '',
  },
  selectedCollection: {
    id: '',
    image: '',
    name: '',
    isPinned: false,
  },
  cocktailsCollections: {},
  collectionImage: '',
  barCocktails: {
    content: {},
    totalPages: 1,
    number: 0,
    cocktail: null,
    modalCocktail: null,
    totalElements: 0,
    searchField: '',
    mainPageTotalElements: 0,
  },
}

const barReducer = (state = initialState, action: BarAction) => {
  switch (action.type) {
    // INGREDIENTS
    case BarActionEnum.GET_FAVORITES_INGREDIENTS:
      if (action.payload.number === 0) {
        return {
          ...state,
          barFavoriteIngredients: {
            ...state.barFavoriteIngredients,
            content: action.payload.content,
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
          },
        }
      }
      return {
        ...state,
        barFavoriteIngredients: {
          ...state.barFavoriteIngredients,
          content: [
            ...state.barFavoriteIngredients.content,
            ...action.payload.content,
          ],
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        },
      }
    case BarActionEnum.GET_FAVORITES_INGREDIENTS_BY_FILTER_ACTION:
      if (action.payload.number === 0) {
        return {
          ...state,
          barFavoriteIngredients: {
            ...state.barFavoriteIngredients,
            content: action.payload.content,
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
          },
        }
      }
      return {
        ...state,
        barFavoriteIngredients: {
          ...state.barFavoriteIngredients,
          content: [
            ...state.barFavoriteIngredients.content,
            ...action.payload.content,
          ],
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        },
      }
    case BarActionEnum.GET_FAVORITES_INGREDIENTS_SEARCH:
      if (
        state.barFavoriteIngredients.searchField === action.payload.searchField
      ) {
        return {
          ...state,
          barFavoriteIngredients: {
            ...state.barFavoriteIngredients,
            content: [
              ...state.barFavoriteIngredients.content,
              ...action.payload.content,
            ],
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
            searchField: action.payload.searchField,
          },
        }
      }
      return {
        ...state,
        barFavoriteIngredients: {
          ...state.barFavoriteIngredients,
          content: action.payload.content,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
          searchField: '',
        },
      }
    case BarActionEnum.CLEAR_FAVORITES_INGREDIENTS_LIST:
      return {
        ...state,
        barFavoriteIngredients: {
          ...state.barFavoriteIngredients,
          number: 0,
          searchField: '',
          content: [],
        },
      }

      case BarActionEnum.FAVORITES_INGREDIENT:
        return {
          ...state,
          barFavoriteIngredients: {
            content: state.barFavoriteIngredients.content.filter((item: IIngredient) => item.id !== action.payload),
          },
        }
    // MIXOLOGY_LIST
    case BarActionEnum.GET_MIXOLOGY_LIST:
      if (action.payload.number === 0) {
        return {
          ...state,
          barMixologyList: {
            ...state.barMixologyList,
            content: action.payload.content,
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
          },
        }
      }
      return {
        ...state,
        barMixologyList: {
          ...state.barMixologyList,
          content: [
            ...state.barMixologyList.content,
            ...action.payload.content,
          ],
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        },
      }
    case BarActionEnum.GET_MIXOLOGY_LIST_BY_FILTER_ACTION:
      if (action.payload.number === 0) {
        return {
          ...state,
          barMixologyList: {
            ...state.barMixologyList,
            content: action.payload.content,
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
          },
        }
      }
      return {
        ...state,
        barMixologyList: {
          ...state.barMixologyList,
          content: [
            ...state.barMixologyList.content,
            ...action.payload.content,
          ],
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        },
      }
    case BarActionEnum.GET_MIXOLOGY_LIST_SEARCH:
      if (state.barMixologyList.searchField === action.payload.searchField) {
        return {
          ...state,
          barMixologyList: {
            ...state.barMixologyList,
            content: [
              ...state.barMixologyList.content,
              ...action.payload.content,
            ],
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
            searchField: action.payload.searchField,
          },
        }
      }
      return {
        ...state,
        barMixologyList: {
          ...state.barMixologyList,
          content: action.payload.content,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
          searchField: '',
        },
      }
    case BarActionEnum.CLEAR_MIXOLOGY_LIST:
      return {
        ...state,
        barMixologyList: {
          ...state.barMixologyList,
          number: 0,
          searchField: '',
          content: [],
        },
      }
    // COLLECTIONS
    case BarActionEnum.GET_BAR_COLLECTIONS:
      return {
        ...state,
        cocktailsCollections: action.payload,
      }
    case BarActionEnum.CREATE_COCKTAIL_COLLECTION:
      return {
        ...state,
        cocktailsCollections: {
          ...state.cocktailsCollections,
          [action.payload.id]: action.payload,
        },
      }
    case BarActionEnum.SET_SELECTED_COLLECTION:
      return {
        ...state,
        selectedCollection: action.payload,
      }
    case BarActionEnum.PATCH_COCKTAILS_COLLECTION: {
      const { id, ...data } = action.payload
      if (!state.cocktailsCollections[id]) {
        return state
      }
      return {
        ...state,
        cocktailsCollections: {
          ...state.cocktailsCollections,
          [id]: {
            ...state.cocktailsCollections[id],
            ...data,
          },
        },
      }
    }
    case BarActionEnum.DELETE_COCKTAILS_COLLECTION: {
      const updatedCollections = { ...state.cocktailsCollections }
      delete updatedCollections[action.payload]

      return {
        ...state,
        cocktailsCollections: updatedCollections,
      }
    }
    case BarActionEnum.PIN_COCKTAILS_COLLECTION: {
      if (!state.cocktailsCollections[action.payload]) {
        return state
      }
      return {
        ...state,
        cocktailsCollections: {
          ...state.cocktailsCollections,
          [action.payload]: {
            ...state.cocktailsCollections[action.payload],
            isPinned: true,
          },
        },
      }
    }
    case BarActionEnum.UNPIN_COCKTAILS_COLLECTION: {
      if (!state.cocktailsCollections[action.payload]) {
        return state
      }
      return {
        ...state,
        cocktailsCollections: {
          ...state.cocktailsCollections,
          [action.payload]: {
            ...state.cocktailsCollections[action.payload],
            isPinned: false,
          },
        },
      }
    }
    // COCKTAILS
    case BarActionEnum.GET_BAR_COCKTAILS: {
      const updatedContent = {
        ...state.barCocktails.content,
        ...action.payload.content,
      }
      return {
        ...state,
        barCocktails: {
          ...state.barCocktails,
          content: updatedContent,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        },
      }
    }
    case BarActionEnum.GET_BAR_COCKTAILS_MAIN_PAGE: {
      const updatedContent = {
        ...state.barCocktails.content,
        ...action.payload.content,
      }
      return {
        ...state,
        barCocktails: {
          ...state.barCocktails,
          content: updatedContent,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
          mainPageTotalElements: action.payload.mainPageTotalElements,
        },
      }
    }
    case BarActionEnum.GET_BAR_COCKTAILS_SEARCH: {
      const updatedContent = {
        ...state.barCocktails.content,
        ...action.payload.content,
      }
      if (state.barCocktails.searchField === action.payload.searchField) {
        return {
          ...state,
          barCocktails: {
            ...state.barCocktails,
            content: updatedContent,
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
            searchField: action.payload.searchField,
          },
        }
      }
      return {
        ...state,
        barCocktails: {
          ...state.barCocktails,
          content: action.payload.content,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
          searchField: action.payload.searchField,
        },
      }
    }

    case BarActionEnum.GET_BAR_COCKTAILS_BY_FILTER_ACTION: {
      const updatedContent = {
        ...state.barCocktails.content,
        ...action.payload.content,
      }

      if (action.payload.number === 0) {
        return {
          ...state,
          barCocktails: {
            ...state.barCocktails,
            content: action.payload.content,
            totalPages: action.payload.totalPages,
            number: action.payload.number,
            totalElements: action.payload.totalElements,
          },
        }
      }
      return {
        ...state,
        barCocktails: {
          ...state.barCocktails,
          content: updatedContent,
          totalPages: action.payload.totalPages,
          number: action.payload.number,
          totalElements: action.payload.totalElements,
        },
      }
    }

    case BarActionEnum.CLEAR_BAR_COCKTAIL_LIST:
      return {
        ...state,
        barCocktails: {
          ...state.barCocktails,
          content: [],
          number: 0,
          searchField: '',
          totalElements: 0,
          totalPages: 0,
        },
      }
    case BarActionEnum.BAR_FAVORITES_COCKTAIL: {
      const { id, ...data } = action.payload
      if (!state.barCocktails.content[id]) {
        return state
      }
      return {
        ...state,
        barCocktails: {
          ...state.barCocktails,
          content: {
            ...state.barCocktails.content,
            [id]: {
              ...state.barCocktails.content[id],
              ...data,
            },
          },
        },
      }
    }
    // IMAGES
    case BarActionEnum.UPLOAD_COLLECTION_IMAGE:
      return {
        ...state,
        collectionImage: action.payload,
      }
    case BarActionEnum.CLEAR_COLLECTION_IMAGE:
      return {
        ...state,
        collectionImage: '',
      }

    default:
      return state
  }
}

export default barReducer
