import { EngLocales } from '../../../assets/locales/eng'
import { SmallTabletDevicesWidth } from '../../../constants/screensWidth'
import BackgroundImage from '../images/background-image.png'

import './index.scss'

interface UserInfoProps {
  userInfo: {
    username: string
    email: string
    name: string
  }
}

function UserInfo({ userInfo }: UserInfoProps) {
  const { email, name, username } = userInfo

  return (
    <div className="userInfoWrapper">
      <div className="userInfoInner">
        <h3>
          {EngLocales.UserProfilePage.userInfoBlock.userInfoBlockHeaderTitle}
        </h3>
        <p>
          Welcome back, {name ?? username}! Your personal data is displayed
          here.
        </p>
        <div className="userInfoForm">
          <div className="userInfoFormField">
            <p>
              {EngLocales.UserProfilePage.userInfoBlock.userInfoUsernameField}
            </p>
            <p>@{username}</p>
          </div>
          <div className="userInfoFormField">
            <p>{EngLocales.UserProfilePage.userInfoBlock.userInfoEmailField}</p>
            <p>{email}</p>
          </div>
        </div>
      </div>

      {window.innerWidth > SmallTabletDevicesWidth && (
        <img src={BackgroundImage} alt="background" />
      )}
    </div>
  )
}

export default UserInfo
