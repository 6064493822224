import { message, notification } from 'antd'

import {
  MobileDevicesWidth,
  LargeTabletDevicesWidth,
  LaptopDevicesWidth,
  SmallTabletDevicesWidth,
} from '../constants/screensWidth'

export function cocktailsColumnsWidth(width: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return width
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return width / 2
  }
  return width / 3
}

export function cocktailsColumnsCount() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 1
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 2
  }
  return 3
}

export function cocktailsRowsHeight(isFilters: boolean) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 255
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return isFilters ? 225 : 282
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return isFilters ? 310 : 365
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return isFilters ? 253 : 310
  }
  return isFilters ? 310 : 365
}

export function cocktailsRowsCount(length: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return length
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return Math.ceil(length / 2)
  }
  return Math.ceil(length / 3)
}

export function cocktailsItemIndex(row: number, column: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return row + column
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return row * 2 + column
  }
  return row * 3 + column
}

export function barCocktailsItemIndex(row: number, column: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return row * 3 + column
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return row * 4 + column
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return row * 5 + column
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return row * 6 + column
  }
  return row * 7 + column
}

export function barCocktailsRowsCount(length: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return Math.ceil(length / 3)
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return Math.ceil(length / 4)
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return Math.ceil(length / 5)
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return Math.ceil(length / 6)
  }
  return Math.ceil(length / 7)
}

export function barCocktailsRowsHeight() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 180
  }

  return 255
}

export function barCocktailsColumnsWidth(width: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return width / 3
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return width / 4
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return width / 5
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return width / 6
  }
  return width / 7
}

export function barCocktailsColumnsCount() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 3
  }
  if (window.innerWidth <= SmallTabletDevicesWidth) {
    return 4
  }
  if (window.innerWidth <= LargeTabletDevicesWidth) {
    return 5
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 6
  }
  return 7
}

export function eventCocktailsColumnsWidth(width: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return width
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return width / 2
  }
  return width / 3
}

export function eventCocktailsColumnsCount() {
  if (window.innerWidth <= MobileDevicesWidth) {
    return 1
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return 2
  }
  return 3
}

export function eventCocktailsRowsCount(length: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return length
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return Math.ceil(length / 2)
  }
  return Math.ceil(length / 3)
}

export function eventCocktailsItemIndex(row: number, column: number) {
  if (window.innerWidth <= MobileDevicesWidth) {
    return row + column
  }
  if (window.innerWidth <= LaptopDevicesWidth) {
    return row * 2 + column
  }
  return row * 3 + column
}
